//
//
//
//
//
//
//

export default {
	inheritAttrs: false
};
