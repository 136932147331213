import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';

/**
 * Update email triggers.
 * @param {String} token - JWT Authorization token.
 * @param {Object} data -.update data.
 * @return {Promise<Object>}
 */
async function httpPatch(token, data) {
	const result = await request.patch(endpoints.email.triggers, data, token);

	if (!result.success) {
		return Promise.reject(result);
	}

	return result.data;
}

export {
	httpPatch
};
