//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
		isLoading: {
			type: Boolean,
			required: true
		},
		delayBeforeLoadingAnimation: {
			type: Number,
			required: false,
			default: 500
		}
	},
	data: () => ({
		isShowLoading: false
	}),
	watch: {
		isLoading(newVal) {
			if (newVal) {
				setTimeout(() => {
					if (this.isLoading) {
						this.isShowLoading = true;
					}
				}, this.delayBeforeLoadingAnimation);

				return;
			}

			this.isShowLoading = false;
		}
	}
};

