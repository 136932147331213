const getInitialState = () => ({
	lists: [],
	options: {
		mergeFields: [],
		crmFields: []
	},
	list: null,
	isNewFieldVisible: false,
	mappings: [],
	mergeField: null,
	crmField: null,
	generalErrors: []
});

export default getInitialState;
