const getInitialState = () => ({
	counters: {},
	options: {
		emailOpenExpire: {},
		emailClickExpire: {},
		pageViewExpire: {},
		urlScore: {},
		leadScoreExpire: {}
	},
	settings: {
		counters: {}
	},
	visibleFields: [],
	generalErrors: [],
	fieldErrors: {},
	ruleErrors: [],
	crmRuleErrors: [],
	crmFieldRuleErrors: [],
	lead_score_rules_limit: false,
	isLoading: false,
	activityTypes: [],
	contactsFields: [],
	leadScoringCrmActivitiesEnabled: false,
	leadScoreInstructionBoxState: 'closed'
});

export default getInitialState;
