//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import moment from 'moment';
import { getCurrencySymbol } from '../utils/formatting';

export default {
	props: {
		invoices: {
			type: Array,
			required: true
		}
	},

	computed: {
		hasInvoices() {
			return this.invoices.length !== 0;
		}
	},

	methods: {
		formattedInvoiceDate(timestamp) {
			return moment(timestamp).format('llll');
		},

		formattedTotal(amount, currencyCode) {
			const currencySymbol = getCurrencySymbol(currencyCode);

			return `${currencySymbol} ${amount} `;
		}
	}
};

