export const DASHBOARD_NAVIGATE = 'DASHBOARD_NAVIGATE';
export const OPTIONS_GET = 'OPTIONS_GET';
export const STEP_NAVIGATE = 'STEP_NAVIGATE';
export const CAMPAIGN_GET = 'CAMPAIGN_GET';
export const EMAIL_ENGAGEMENT_SETTINGS_GET = 'EMAIL_ENGAGEMENT_SETTINGS_GET';
export const FIELDS_INITIALIZE = 'FIELDS_INITIALIZE';
export const FIELDS_EDIT = 'FIELDS_EDIT';
export const ERRORS_SHOW = 'ERRORS_SHOW';
export const DIALOG_TEST_EMAIL_SHOW = 'DIALOG_TEST_EMAIL_SHOW';
export const DIALOG_TEST_EMAIL_EDIT = 'DIALOG_TEST_EMAIL_EDIT';
export const DIALOG_TEST_EMAIL_CLOSE = 'DIALOG_TEST_EMAIL_CLOSE';
export const DIALOG_TEST_EMAIL_SEND = 'DIALOG_TEST_EMAIL_SEND';
export const DIALOG_TEST_EMAIL_REPLACE_MERGE_FIELDS = 'DIALOG_TEST_EMAIL_REPLACE_MERGE_FIELDS';
export const DIALOG_CONFIRMATION_SHOW = 'DIALOG_CONFIRMATION_SHOW';
export const DIALOG_CONFIRMATION_CONFIRM = 'DIALOG_CONFIRMATION_CONFIRM';
export const DIALOG_CONFIRMATION_CLOSE = 'DIALOG_CONFIRMATION_CLOSE';
export const DIALOG_EDITOR_HTML_SHOW = 'DIALOG_EDITOR_HTML_SHOW';
export const DIALOG_EDITOR_HTML_CONFIRM = 'DIALOG_EDITOR_HTML_CONFIRM';
export const DIALOG_EDITOR_HTML_CANCEL = 'DIALOG_EDITOR_HTML_CANCEL';
export const DIALOG_CUSTOM_HTML_SHOW = 'DIALOG_CUSTOM_HTML_SHOW';
export const DIALOG_CUSTOM_HTML_CONFIRM = 'DIALOG_CUSTOM_HTML_CONFIRM';
export const DIALOG_CUSTOM_HTML_CANCEL = 'DIALOG_CUSTOM_HTML_CANCEL';
export const DIALOG_SENDING_LIMITS_SHOW = 'DIALOG_SENDING_LIMITS_SHOW';
export const DIALOG_SENDING_LIMITS_CLOSE = 'DIALOG_SENDING_LIMITS_CLOSE';
export const INFOBOX_DELIVERABILITY_DISMISS = 'INFOBOX_DELIVERABILITY_DISMISS';
export const IMAGE_UPLOAD = 'IMAGE_UPLOAD';
