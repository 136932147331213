//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_CANCEL = 'cancel';
const EVENT_UNSUBSCRIBE = 'unsubscribe';

export default {
	props: {
		isVisible: {
			type: Boolean,
			default: false
		},
		email: {
			type: String,
			required: true
		}
	},

	methods: {
		/**
		 * Emit unsubscribe event.
		 */
		unsubscribe() {
			this.$emit(EVENT_UNSUBSCRIBE);
		},
		/**
		 * Emit cancel event.
		 */
		cancel() {
			this.$emit(EVENT_CANCEL);
		}
	}
};

