import { moduleStart } from './moduleStart';
import { moduleCreate } from './moduleCreate';

export const moduleResetPassword = {
	namespaced: true,
	modules: {
		moduleStart,
		moduleCreate
	}
};
