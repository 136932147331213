import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { ConnectionsException } from '../helpers';

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {String} params.token
 * @param {Object} params.data
 * @return {Promise<Object>}
 */
export const httpPut = async ({
	connectionId, token, data
}) => {
	const response = await request.put(
		endpoints.connectionsIdSettings(connectionId),
		data,
		token
	);

	if (!response.success) {
		return Promise.reject(new ConnectionsException(response));
	}

	return response.data;
};
