import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { EmailsException } from './helpers';

/**
 * Update an email of a sequence.
 * @param {String} token = User's JWT authorization token.
 * @param {String} sequenceId - ID of the sequence.
 * @param {String} emailId - ID of the email.
 * @param {Object} emailData - Updated fields of the email.
 */
async function httpPut(
	token,
	sequenceId,
	emailId,
	emailData
) {
	const url = endpoints.sequenceEmailCampaignsIdEmailsId(sequenceId, emailId);
	const result = await request.put(url, emailData, token);

	if (!result.success) {
		return Promise.reject(new EmailsException(result));
	}

	return result.data;
}

export {
	httpPut
};
