export const FROM_ADDRESS_ERROR = {
	context: 'fromAddress',
	message: 'Please add "From" email'
};

export const FILTER_ERROR = {
	context: 'filter',
	message: 'Please select recipients.'
};

export const FROM_NAME_ERROR = {
	context: 'fromName',
	message: 'Please add "From" name.'
};

export const SUBJECT_ERROR = {
	context: 'subject',
	message: 'Please add a subject.'
};

export const HTML_ERROR = {
	context: 'html',
	message: 'Email has to include {{unsubscribe_url}} merge field.'
};

export const OPPORTUNITY_FIELD_ERROR = {
	context: 'html',
	message: 'Opportunity merge fields can only be used with opportunity tags. To fix, please remove opportunity merge fields from the email or change the target segment to an opportunity tag.'
};
