import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { TestException } from './helpers';

/**
 * Send out sequence email test.
 * @param {Object} options
 * @param {String} options.token - Users JWT authorization.
 * @param {String} options.sequenceId - ID of the sequence.
 * @param {String} options.emailId - ID of the email.
 * @param {Array} options.testEmails - List of email addresses.
 * @param {Boolean} options.areMergeFieldsReplaced - Should replace merge tags with real values.
 * @param {String} options.captchaToken
 */
async function httpPost({
	token, sequenceId, emailId, testEmails, areMergeFieldsReplaced, captchaToken
}) {
	const url = endpoints.sequenceEmailCampaignsIdEmailsIdTest(sequenceId, emailId);
	const result = await request.post(url, {
		testEmails,
		replaceMergeFields: areMergeFieldsReplaced,
		captchaToken
	}, token);

	if (!result.success) {
		return Promise.reject(new TestException(result));
	}

	return result.data;
}

export {
	httpPost
};
