import { credentials } from './credentials';
import { oauthCode } from './oauth-code';
import { passwordResetEmail } from './password-reset-email';
import { emailVerification } from './email-verification';
import { emailVerificationResend } from './email-verification-resend';
import { passwordChange } from './password-change';
import { passwordlessEmail } from './passwordless-email';
import { passwordlessVerify } from './passwordless-verify';

export const auth = {
	credentials,
	oauthCode,
	emailVerification,
	emailVerificationResend,
	passwordChange,
	passwordlessEmail,
	passwordlessVerify,
	passwordResetEmail
};
