import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { InvoicesException } from './helpers';

/**
 * @param {String} token
 * @return {Promise<Object>}
 */
async function httpGet(token) {
	let result = null;

	result = await request.get(endpoints.billingInvoices, token);

	if (!result.success) {
		return Promise.reject(new InvoicesException(result));
	}

	return result.data;
}

export {
	httpGet
};
