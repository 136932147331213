//
//
//
//
//
//
//
//
//
//
//

import { capitalize } from 'utils/formatting';

export default {
	props: {
		person: {
			type: Object,
			required: true
		}
	},
	computed: {
		color() {
			const status = this.person.subscriptionStatus;
			const isValidEmail = this.person.validEmail;

			let color = 'green';

			if (status === 'unsubscribed') {
				color = 'red';
			}

			if (!isValidEmail) {
				color = 'grey';
			}

			return color;
		},
		text() {
			const status = this.person.subscriptionStatus;
			const isValidEmail = this.person.validEmail;

			let text = status;

			if (!isValidEmail) {
				text = 'invalid email';
			}

			return capitalize(text);
		}
	}
};
