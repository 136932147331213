import { moduleChannelReport } from './moduleChannelReport';
import { moduleChannelReport2 } from './moduleChannelReport2';
import { moduleCreateTrackingLinks } from './moduleCreateTrackingLinks';
import { moduleVisitorFeed } from './moduleVisitorFeed';
import { moduleEngagementRecording } from './moduleEngagementRecording';
import { moduleIdentifiedVisitors } from './moduleIdentifiedVisitors';
import { moduleWorkflows } from './moduleWorkflows';
import { moduleSetup } from './moduleSetup';

import { getInitialState } from './state';
import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

export const moduleWebTracking = {
	namespaced: true,
	state: getInitialState(),
	mutations,
	actions,
	getters,
	modules: {
		moduleChannelReport,
		moduleChannelReport2,
		moduleCreateTrackingLinks,
		moduleVisitorFeed,
		moduleEngagementRecording,
		moduleIdentifiedVisitors,
		moduleWorkflows,
		moduleSetup
	}
};
