import { MutationTypes } from './mutationTypes';

export const mutations = {
	[MutationTypes.OPTIONS]: (state, options) => {
		state.options = options;
	},

	[MutationTypes.SETTINGS]: (state, settings) => {
		state.settings = settings;
	},

	[MutationTypes.FEATURES]: (state, features) => {
		state.features = features;
	},

	[MutationTypes.ERRORS]: (state, errors) => {
		state.errors = errors;
	}
};
