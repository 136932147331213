//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
		id: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: true
		},
		value: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		changed(value) {
			this.$emit('change', { [this.id]: value });
		}
	}
};

