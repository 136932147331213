/* eslint-disable max-lines */
import router from 'router';
import { api } from 'api';
import logger from 'utils/logger';
import { getCaptchaToken } from 'utils/captcha';
import { groupErrors } from 'utils/error';
import ROUTE_NAMES from 'router/route-names';
import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';

import {
	GET_USER
} from 'store/modules/user/actionTypes';

import { Flags } from 'store/modules/user/helpers';

import { loadPaperformScript } from 'utils/paperform';

import {
	MODE,
	STEP,
	GENERAL_ERRORS,
	SEQUENCE_OPTIONS,
	EMAIL_OPTIONS,
	EMAIL_ENGAGEMENT_SETTINGS,
	EMAIL_SCHEDULE_SETTINGS,
	EMAIL_SCHEDULE_SETTINGS_OPTIONS,
	SEQUENCE,
	SEQUENCE_EMAILS,
	FIELDS,
	FIELD_ERRORS,
	FIELD_ERRORS_CLEAR,
	INVALID_EMAIL_DELAYS,
	TEST_SEQUENCE_ID,
	TEST_EMAIL_ID,
	TEST_EMAIL_ADDRESSES,
	REPLACE_MERGE_FIELDS,
	TEST_EMAIL_ERRORS,
	DIALOG_TEST_EMAIL_VISIBILITY,
	DIALOG_CONFIRMATION_VISIBILITY,
	DIALOG_SENDING_LIMITS_VISIBILITY
} from './mutationTypes';

import {
	CONFIRM,
	DIALOG_TEST_EMAIL_SHOW,
	DIALOG_TEST_EMAIL_EDIT,
	DIALOG_TEST_EMAIL_REPLACE_MERGE_FIELDS,
	DIALOG_TEST_EMAIL_SEND,
	DIALOG_TEST_EMAIL_CLOSE,
	DIALOG_CONFIRMATION_ACTIVATE,
	DIALOG_CONFIRMATION_CLOSE,
	DIALOG_SENDING_LIMITS_SHOW,
	DIALOG_SENDING_LIMITS_CLOSE,
	EMAIL_OPTIONS_GET,
	EMAIL_ADD,
	EMAIL_EDIT,
	EMAIL_DELETE,
	EMAIL_ENGAGEMENT_SETTINGS_GET,
	EMAIL_SCHEDULE_SETTINGS_GET,
	EMAIL_SCHEDULE_SETTINGS_OPTIONS_GET,
	ERRORS_SHOW,
	FIELDS_EDIT,
	INFOBOX_DELIVERABILITY_DISMISS,
	LIST_NAVIGATE,
	SEQUENCE_OPTIONS_GET,
	SEQUENCE_GET,
	STEP_NAVIGATE,
	TRIGGER_EDIT
} from './actionTypes';

import { EDITOR_MODE } from './state';

/**
 * Edit actions.
 */
export default {
	/**
	 * Navigate to sequences list page.
	 */
	[LIST_NAVIGATE]() {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE
		});
	},

	/**
	 * Get sequence options from API.
	 * @param {import('vuex').ActionContext} context
	 */
	async [SEQUENCE_OPTIONS_GET]({
		commit,
		dispatch,
		rootState: { token }
	}) {
		try {
			const sequenceOptions = await api.sequenceEmailCampaigns.options.get(token);

			commit(SEQUENCE_OPTIONS, sequenceOptions);
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Get sequence email options from API.
	 * @param {import('vuex').ActionContext} context
	 */
	async [EMAIL_OPTIONS_GET]({
		commit,
		dispatch,
		rootState: { token }
	}) {
		try {
			const emailOptions = await api.sequenceEmailCampaigns.emails.options.get(token);

			commit(EMAIL_OPTIONS, emailOptions);
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Get email engagement settings from API.
	 * @param {import('vuex').ActionContext} context
	 */
	[EMAIL_ENGAGEMENT_SETTINGS_GET]: async ({ commit, dispatch, rootState: { token } }) => {
		try {
			const settings = await api.user.settings.emailEngagement.get(token);

			commit(EMAIL_ENGAGEMENT_SETTINGS, settings);
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Get email schedule settings from API.
	 * @param {import('vuex').ActionContext} context
	 */
	[EMAIL_SCHEDULE_SETTINGS_GET]: async ({ commit, dispatch, rootState: { token } }) => {
		try {
			const settings = await api.user.settings.email.schedule.get(token);

			commit(EMAIL_SCHEDULE_SETTINGS, settings);
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Get email schedule settings options from API.
	 * @param {import('vuex').ActionContext} context
	 */
	[EMAIL_SCHEDULE_SETTINGS_OPTIONS_GET]: async ({ commit, dispatch, rootState: { token } }) => {
		try {
			const settings = await api.user.settings.email.schedule.options.get(token);

			commit(EMAIL_SCHEDULE_SETTINGS_OPTIONS, settings);
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Get sequence data from API.
	 * @param {import('vuex').ActionContext} context
	 * @param {String} sequenceId
	 */
	async [SEQUENCE_GET]({
		commit,
		dispatch,
		rootState: { token }
	}, sequenceId) {
		try {
			const { data } = await api.sequenceEmailCampaigns.get({
				token,
				sequenceId
			});

			commit(SEQUENCE, data);

			const emails = await api.sequenceEmailCampaigns.emails.get(token, sequenceId);

			commit(SEQUENCE_EMAILS, emails);
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Navigate to email creation page.
	 * @param {import('vuex').ActionContext} context
	 */
	async [EMAIL_ADD]({
		dispatch,
		rootState: { token },
		state: { sequence }
	}) {
		try {
			const createdEmail = await api.sequenceEmailCampaigns.emails.post(
				token, sequence._id
			);

			await router.replace({
				name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_EMAILS_EDIT,
				params: {
					id: sequence._id,
					emailId: createdEmail._id
				}
			});
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Save email trigger data through API.
	 * @param {import('vuex').ActionContext} context
	 * @param {Object} options
	 * @param {String} options.emailId
	 * @param {Number} options.delay
	 */
	async [TRIGGER_EDIT](
		{
			state: { sequence, sequenceEmails },
			commit,
			dispatch,
			rootState: { token }
		},
		{
			emailId,
			delay
		}
	) {
		const sequenceEmail = sequenceEmails.find(email => email._id === emailId);

		commit(GENERAL_ERRORS, []);
		commit(INVALID_EMAIL_DELAYS, []);

		try {
			if (!sequenceEmail.trigger) {
				await api.sequenceEmailCampaigns.emails.trigger.post(
					token,
					sequence._id,
					emailId,
					{ delay }
				);

				dispatch(
					`notifications/${SHOW_NOTIFICATION}`,
					'Delay created',
					{ root: true }
				);
			} else {
				await api.sequenceEmailCampaigns.emails.trigger.put(
					token,
					sequence._id,
					emailId,
					{ delay }
				);

				dispatch(
					`notifications/${SHOW_NOTIFICATION}`,
					'Delay changed',
					{ root: true }
				);

				dispatch(SEQUENCE_GET, sequence._id);
			}
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Navigate to sequence email edit page.
	 * @param {import('vuex').ActionContext} context
	 * @param {String} emailId
	 */
	[EMAIL_EDIT]({
		state: { sequence }
	}, emailId) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_EMAILS_EDIT,
			params: {
				id: sequence._id,
				emailId
			}
		});
	},

	/**
	 * Delete sequence email through API.
	 * @param {import('vuex').ActionContext} context
	 * @param {String} emailId
	 */
	async [EMAIL_DELETE]({
		dispatch,
		commit,
		state: { sequence },
		rootState: { token }
	}, emailId) {
		try {
			await api.sequenceEmailCampaigns.emails.delete(token, sequence._id, emailId);

			const emails = await api.sequenceEmailCampaigns.emails.get(token, sequence._id);

			commit(SEQUENCE_EMAILS, emails);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Email deleted',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Show test email dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_TEST_EMAIL_SHOW]({
		commit,
		rootGetters
	}, {
		sequenceId,
		emailId
	}) {
		commit(TEST_SEQUENCE_ID, sequenceId);
		commit(TEST_EMAIL_ID, emailId);
		commit(TEST_EMAIL_ADDRESSES, [rootGetters['user/userEmailAddress']]);
		commit(TEST_EMAIL_ERRORS, []);
		commit(DIALOG_TEST_EMAIL_VISIBILITY, true);
	},

	/**
	 * Edit test email addresses.
	 * @param {import('vuex').ActionContext} context
	 * @param {String} emailsString
	 */
	[DIALOG_TEST_EMAIL_EDIT]({ commit }, emailsString) {
		const emails = emailsString.split(/\s*[,;]\s*|\s+/);

		commit(TEST_EMAIL_ADDRESSES, emails);
	},

	/**
	 *
	 * @param {import('vuex').ActionContext} context
	 * @param {Boolean} areMergeFieldsReplaced
	 */
	[DIALOG_TEST_EMAIL_REPLACE_MERGE_FIELDS]({ commit }, areMergeFieldsReplaced) {
		commit(REPLACE_MERGE_FIELDS, areMergeFieldsReplaced);
	},

	/**
	 * Make request to API to send test emails.
	 * @param {import('vuex').ActionContext} context
	 */
	async [DIALOG_TEST_EMAIL_SEND]({
		commit,
		dispatch,
		rootState: { token },
		state: {
			testSequenceId,
			testEmailId,
			testEmailAddresses,
			areMergeFieldsReplaced
		}
	}) {
		try {
			const captchaToken = await getCaptchaToken('sequence_test_email');

			await api.sequenceEmailCampaigns.emails.test.post(
				{
					token,
					sequenceId: testSequenceId,
					emailId: testEmailId,
					testEmails: testEmailAddresses,
					areMergeFieldsReplaced,
					captchaToken
				}
			);
			commit(DIALOG_TEST_EMAIL_VISIBILITY, false);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Test email is on its way',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(TEST_EMAIL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Close test email dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_TEST_EMAIL_CLOSE]({ commit }) {
		commit(DIALOG_TEST_EMAIL_VISIBILITY, false);
	},

	/**
	 * Navigate to specific step.
	 * @param {import('vuex').ActionContext} context
	 * @param {String} nextStep
	 */
	[STEP_NAVIGATE]({
		commit,
		state: { step, sequence }
	}, nextStep) {
		if (step === nextStep) {
			return;
		}

		commit(STEP, nextStep);

		router.push({
			name: ROUTE_NAMES[`CAMPAIGNS_SEQUENCE_${nextStep}`],
			params: {
				id: sequence._id
			}
		});
	},

	/**
	 * Save field data through API.
	 * @param {import('vuex').ActionContext} context
	 * @param {Object} fields
	 */
	async [FIELDS_EDIT]({
		commit,
		dispatch,
		rootState: { token },
		state: { mode, sequence }
	}, fields) {
		commit(FIELDS, fields);

		if (mode === EDITOR_MODE.CREATE) {
			try {
				const createdSequence = await api.sequenceEmailCampaigns.post(token, sequence);

				commit(SEQUENCE, createdSequence);
				commit(MODE, EDITOR_MODE.EDIT);
				commit(FIELD_ERRORS_CLEAR, fields);

				dispatch(
					`notifications/${SHOW_NOTIFICATION}`,
					'Sequence created',
					{ root: true }
				);

				router.push({
					name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_EMAILS,
					params: {
						id: createdSequence._id
					}
				});
			} catch (exception) {
				if (exception.errors) {
					return dispatch(ERRORS_SHOW, exception.errors);
				}

				logger.error(exception);
			}
		} else {
			try {
				commit(FIELD_ERRORS_CLEAR, fields);

				const updatedSequence = await api.sequenceEmailCampaigns.put(
					token, sequence._id, fields
				);
				const emails = await api.sequenceEmailCampaigns.emails.get(token, sequence._id);

				commit(SEQUENCE, updatedSequence);
				commit(SEQUENCE_EMAILS, emails);

				const message = sequence.active && !updatedSequence.active ? 'Sequence paused' : 'Sequence saved';

				dispatch(`notifications/${SHOW_NOTIFICATION}`, message, { root: true });
			} catch (exception) {
				if (exception.errors) {
					return dispatch(ERRORS_SHOW, exception.errors);
				}

				logger.error(exception);
			}
		}
	},

	/**
	 * Show confirmation dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	[CONFIRM]({ commit }) {
		commit(DIALOG_CONFIRMATION_VISIBILITY, true);
	},

	/**
	 * Activate sequence through API.
	 * @param {import('vuex').ActionContext} context
	 */
	async [DIALOG_CONFIRMATION_ACTIVATE]({
		commit,
		dispatch,
		state: { sequence },
		rootState: { token }
	}) {
		try {
			await api.sequenceEmailCampaigns.start.post(token, sequence._id);
		} catch (exception) {
			if (exception.errors) {
				commit(DIALOG_CONFIRMATION_VISIBILITY, false);

				const invalidEmailDelayError = exception.errors.find(error => error.emails && error.error === 'email_delay_invalid');

				if (invalidEmailDelayError) {
					commit(INVALID_EMAIL_DELAYS, invalidEmailDelayError.emails);
				}

				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}

		commit(DIALOG_CONFIRMATION_VISIBILITY, false);
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE
		});
	},

	/**
	 * Close confirmation dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_CONFIRMATION_CLOSE]({ commit }) {
		commit(DIALOG_CONFIRMATION_VISIBILITY, false);
	},

	/**
	 * Update general and contextual errors in state.
	 * @param {import('vuex').ActionContext} context
	 * @param {Object[]} errors
	 */
	[ERRORS_SHOW]({
		commit,
		state: { visibleFields }
	}, errors) {
		const { generalErrors, contextualErrors } = groupErrors(
			errors,
			visibleFields
		);

		commit(GENERAL_ERRORS, generalErrors);
		commit(FIELD_ERRORS, contextualErrors);
	},

	[DIALOG_SENDING_LIMITS_SHOW]({ commit }) {
		loadPaperformScript();
		commit(DIALOG_SENDING_LIMITS_VISIBILITY, true);
	},

	[DIALOG_SENDING_LIMITS_CLOSE]({ commit }) {
		commit(DIALOG_SENDING_LIMITS_VISIBILITY, false);
	},

	async [INFOBOX_DELIVERABILITY_DISMISS]({
		dispatch,
		rootState: { token }
	}) {
		await api.user.flags.post(token, Flags.EMAIL_DELIVERABILITY_MSG);
		dispatch(`user/${GET_USER}`, null, { root: true });
	}
};
