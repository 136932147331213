import { groupErrors } from 'utils/error';
import {
	EMAIL,
	PASSWORD,
	IN_PROGRESS,
	SUCCESS,
	FAILED,
	ERRORS,
	FIELD_ERROR,
	VISIBLE_FIELDS,
	RESET,
	INTEGRATION_TO_CONNECT
} from './mutationTypes';

import getInitialState from './state';

export default {
	[EMAIL](state, email) {
		state.fieldErrors.email = '';
		state.emailField.value = email;
	},

	[PASSWORD](state, email) {
		state.fieldErrors.password = '';
		state.passwordField.value = email;
	},

	[IN_PROGRESS]: state => {
		state.loginStatus = 'in_progress';
	},

	[SUCCESS]: state => {
		state.loginStatus = 'logged_in';
	},

	[FAILED]: (state, reason) => {
		state.loginStatus = reason;
	},

	[ERRORS](state, errors) {
		const { visibleFields } = state;
		const { generalErrors, contextualErrors } = groupErrors(
			errors, visibleFields
		);

		contextualErrors.forEach(error => {
			state.fieldErrors[error.context] = error.message;
		});

		state.generalErrors = generalErrors;
	},

	[FIELD_ERROR](state, error) {
		state.fieldErrors = {
			...state.fieldErrors,
			[error.context]: error.message
		};
	},

	[VISIBLE_FIELDS](state, fields) {
		state.visibleFields = fields;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	},

	[INTEGRATION_TO_CONNECT](state, integration) {
		state.integrationToConnect = integration;
	}
};
