import { groupErrors } from 'utils/error';
import { getInitialState } from './state';

import {
	RESET,
	VISIBLE_FIELDS,
	EMAIL,
	TOKEN,
	PASSWORD,
	PASSWORD_CONFIRMATION,
	ERRORS,
	CHANGED
} from './mutationTypes';

export const mutations = {
	[RESET](state) {
		Object.assign(state, getInitialState());
	},

	[VISIBLE_FIELDS](state, visibleFields) {
		state.visibleFields = visibleFields;
	},

	[EMAIL](state, email) {
		state.email = email;
	},

	[TOKEN](state, token) {
		state.token = token;
	},

	[PASSWORD](state, password) {
		state.password = password;
	},

	[PASSWORD_CONFIRMATION](state, passwordConfirmation) {
		state.passwordConfirmation = passwordConfirmation;
	},

	[ERRORS](state, errors) {
		const { visibleFields } = state;
		const {
			generalErrors,
			contextualErrors
		} = groupErrors(errors, visibleFields);

		contextualErrors.forEach(error => {
			state.fieldErrors[error.context] = error.message;
		});

		state.generalErrors = generalErrors;
	},

	[CHANGED](state, isChanged) {
		state.isChanged = isChanged;
	}
};
