import { apps } from './apps';
import { auth } from './auth';
import { automations } from './automations';
import { billing } from './billing';
import { connections } from './connections';
import { content } from './content';
import { integrations } from './integrations';
import { persons } from './persons';
import { segments } from './segments';
import { sequenceEmailCampaigns } from './sequence-email-campaigns';
import { singleEmailCampaigns } from './single-email-campaigns';
import { tracking } from './tracking';
import { user } from './user';
import { users } from './users';

export const api = {
	apps,
	auth,
	automations,
	billing,
	connections,
	content,
	integrations,
	persons,
	segments,
	singleEmailCampaigns,
	sequenceEmailCampaigns,
	tracking,
	user,
	users
};
