/* eslint-disable max-lines */
import throttle from 'lodash/throttle';

import { api } from 'api';
import router from 'router';
import { groupErrors } from 'utils/error';
import { getCaptchaToken } from 'utils/captcha';
import logger from 'utils/logger';
import shouldLoadHtmlContent from 'utils/migration';
import ROUTE_NAMES from 'router/route-names';
import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';

import {
	GET_USER
} from 'store/modules/user/actionTypes';

import { Flags } from 'store/modules/user/helpers';

import { loadPaperformScript } from 'utils/paperform';

import {
	MODE,
	STEP,
	CAMPAIGN,
	EMAIL_ENGAGEMENT_SETTINGS,
	GENERAL_ERRORS,
	SET_OPTIONS,
	FIELDS,
	FIELD_ERRORS,
	FIELD_ERRORS_CLEAR,
	DIALOG_CONFIRMATION_VISIBILITY,
	DIALOG_TEST_EMAIL_VISIBILITY,
	CONTACTS_COUNT,
	TEST_EMAILS,
	TEST_EMAILS_ERRORS,
	RESET,
	DIALOG_EDITOR_HTML_VISIBILITY,
	DIALOG_CUSTOM_HTML_VISIBILITY,
	REPLACE_MERGE_FIELDS,
	DIALOG_SENDING_LIMITS_VISIBILITY,
	IMAGE
} from './mutationTypes';

import {
	DASHBOARD_NAVIGATE,
	OPTIONS_GET,
	STEP_NAVIGATE,
	CAMPAIGN_GET,
	EMAIL_ENGAGEMENT_SETTINGS_GET,
	FIELDS_INITIALIZE,
	FIELDS_EDIT,
	ERRORS_SHOW,
	DIALOG_TEST_EMAIL_SHOW,
	DIALOG_TEST_EMAIL_EDIT,
	DIALOG_TEST_EMAIL_SEND,
	DIALOG_TEST_EMAIL_CLOSE,
	DIALOG_CONFIRMATION_SHOW,
	DIALOG_CONFIRMATION_CLOSE,
	DIALOG_CONFIRMATION_CONFIRM,
	DIALOG_EDITOR_HTML_SHOW,
	DIALOG_EDITOR_HTML_CONFIRM,
	DIALOG_EDITOR_HTML_CANCEL,
	DIALOG_CUSTOM_HTML_SHOW,
	DIALOG_CUSTOM_HTML_CONFIRM,
	DIALOG_CUSTOM_HTML_CANCEL,
	DIALOG_TEST_EMAIL_REPLACE_MERGE_FIELDS,
	DIALOG_SENDING_LIMITS_SHOW,
	DIALOG_SENDING_LIMITS_CLOSE,
	INFOBOX_DELIVERABILITY_DISMISS,
	IMAGE_UPLOAD
} from './actionTypes';

import {
	EDITOR_MODE
} from './state';

import { validateCampaign, validateCampaignFields } from './helpers/validation';

/**
 * Minimum number of milliseconds
 * between save requests to API.
 * @constant {Number}
 */
const SAVING_INTERVAL = 3000;

/**
 * Edit action.
 */
export default {
	/**
	 * Navigate to emails dashboard.
	 * @param {import('vuex').ActionContext} context
	 */
	[DASHBOARD_NAVIGATE]({ commit }) {
		commit(RESET);
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL
		});
	},

	/**
	 * Get campaign options from API.
	 * @param {import('vuex').ActionContext} context
	 */
	async [OPTIONS_GET]({ commit, rootState: { token } }) {
		try {
			const options = await api.singleEmailCampaigns.options.get(token);

			commit(SET_OPTIONS, options);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Navigate to specific step.
	 * @param {import('vuex').ActionContext} context
	 * @param {String} nextStep
	 */
	async [STEP_NAVIGATE]({
		commit,
		state: {
			campaign,
			step
		}
	}, nextStep) {
		const errors = validateCampaignFields(campaign, step);

		if (errors.length) {
			return commit(FIELD_ERRORS, errors);
		}

		commit(STEP, nextStep);

		router.push({
			name: ROUTE_NAMES[`CAMPAIGNS_SINGLE_EMAIL_${nextStep}`],
			params: {
				id: campaign._id
			}
		});
	},

	/**
	 * Get campaign data from API.
	 * @param {import('vuex').ActionContext} context
	 * @param {String} campaignId
	 */
	async [CAMPAIGN_GET]({
		commit,
		dispatch,
		state: { options },
		rootState: { token }
	}, campaignId) {
		try {
			const { data } = await api.singleEmailCampaigns.get({
				token,
				campaignId
			});

			const defaultDelta = options && options.delta && options.delta.default;

			if (shouldLoadHtmlContent(data.delta, defaultDelta)) {
				data.contentJSON = null;
			}

			commit(CAMPAIGN, data);
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Get email engagement settings from API.
	 * @param {import('vuex').ActionContext} context
	 */
	[EMAIL_ENGAGEMENT_SETTINGS_GET]: async ({ commit, rootState: { token } }) => {
		try {
			const settings = await api.user.settings.emailEngagement.get(token);

			commit(EMAIL_ENGAGEMENT_SETTINGS, settings);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Initialize fields default values.
	 * @param {import('vuex').ActionContext} context
	 * @param {Object} options
	 */
	[FIELDS_INITIALIZE]({ commit }, options) {
		Object.entries(options).forEach(option => {
			const [key, value] = option;

			if (value.default) {
				commit(FIELDS, { [key]: value.default });
			}
		});
	},

	/**
	 * A field was edited. If campaing is just being created, then POST the campaign,
	 * otherwise PUT changes to update it.
	 * Throttle is used to prevent saving on every keystroke.
	 * @param {import('vuex').ActionContext} context - Action's context.
	 * @param {Object} field - Field that was edited.
	 */
	[FIELDS_EDIT]: throttle(async ({
		commit,
		dispatch,
		rootState: { token },
		state: { mode, campaign }
	}, fields) => {
		commit(FIELDS, fields);

		if (mode === EDITOR_MODE.CREATE) {
			try {
				const createdCampaign = await api.singleEmailCampaigns.post(token, campaign);

				commit(CAMPAIGN, createdCampaign);
				commit(MODE, EDITOR_MODE.EDIT);
				commit(GENERAL_ERRORS, []);
				commit(FIELD_ERRORS_CLEAR, fields);

				dispatch(
					`notifications/${SHOW_NOTIFICATION}`,
					'Campaign saved as draft',
					{ root: true }
				);

				router.push({
					name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_BASICS,
					params: {
						id: createdCampaign._id
					}
				});
			} catch (exception) {
				if (exception.errors) {
					return dispatch(ERRORS_SHOW, exception.errors);
				}

				logger.error(exception);
			}
		} else {
			try {
				commit(FIELD_ERRORS_CLEAR, fields);
				const updatedCampaign = await api.singleEmailCampaigns.put(token, campaign._id, fields);

				commit(CAMPAIGN, updatedCampaign);

				dispatch(
					`notifications/${SHOW_NOTIFICATION}`,
					'Draft saved',
					{ root: true }
				);
			} catch (exception) {
				if (exception.errors) {
					return dispatch(ERRORS_SHOW, exception.errors);
				}

				logger.error(exception);
			}
		}
	}, SAVING_INTERVAL, { trailing: true }),

	/**
	 * Show test email dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_TEST_EMAIL_SHOW]({ commit, rootGetters }) {
		commit(TEST_EMAILS, [rootGetters['user/userEmailAddress']]);
		commit(TEST_EMAILS_ERRORS, []);
		commit(DIALOG_TEST_EMAIL_VISIBILITY, true);
	},

	/**
	 * Edit test email addresses.
	 * @param {import('vuex').ActionContext} context
	 * @param {String} emailsString
	 */
	[DIALOG_TEST_EMAIL_EDIT]({ commit }, emailsString) {
		const emails = emailsString.split(/\s*[,;]\s*|\s+/);

		commit(TEST_EMAILS, emails);
	},

	/**
	 * Make request to API to send test emails.
	 * @param {import('vuex').ActionContext} context
	 */
	async [DIALOG_TEST_EMAIL_SEND]({
		commit,
		dispatch,
		rootState: { token },
		state: { campaign, testEmails, areMergeFieldsReplaced }
	}) {
		try {
			const captchaToken = await getCaptchaToken('campaign_test_email');

			await api.singleEmailCampaigns.test.post({
				token,
				campaignId: campaign._id,
				testEmails,
				areMergeFieldsReplaced,
				captchaToken
			});
			commit(DIALOG_TEST_EMAIL_VISIBILITY, false);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Test email is on its way',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(TEST_EMAILS_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Close test email dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_TEST_EMAIL_CLOSE]({ commit }) {
		commit(DIALOG_TEST_EMAIL_VISIBILITY, false);
	},

	/**
	 * Show send confirmation dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	async [DIALOG_CONFIRMATION_SHOW]({
		commit,
		dispatch,
		rootState: { token },
		state: { campaign }
	}) {
		try {
			const result = await api.singleEmailCampaigns.put(
				token,
				campaign._id,
				{
					filter: campaign.filter,
					filterSource: campaign.filterData.source,
					filterType: campaign.filterData.type,
					filterEntity: campaign.filterData.entity
				}
			);

			commit(CONTACTS_COUNT, result.contactsCount);
			commit(DIALOG_CONFIRMATION_VISIBILITY, true);
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Make request to API to send email campaign.
	 * @param {import('vuex').ActionContext} context
	 */
	async [DIALOG_CONFIRMATION_CONFIRM]({
		commit,
		dispatch,
		state: { campaign },
		rootState: { token }
	}) {
		const errors = validateCampaign(campaign);

		commit(DIALOG_CONFIRMATION_VISIBILITY, false);

		if (errors.length) {
			return commit(GENERAL_ERRORS, errors);
		}

		try {
			await api.singleEmailCampaigns.send.post(token, campaign._id);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				`${campaign.name} sent`,
				{ root: true }
			);

			router.push({
				name: ROUTE_NAMES.CAMPAIGNS
			});
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Close campaign send confirmation dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_CONFIRMATION_CLOSE]({ commit }) {
		commit(DIALOG_CONFIRMATION_VISIBILITY, false);
	},

	/**
	 * Show confirmation dialog for switching editor to editor html mode.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_EDITOR_HTML_SHOW]({ commit }) {
		commit(DIALOG_EDITOR_HTML_VISIBILITY, true);
	},

	/**
	 * Switch campaign to editor html and hide the confirmation dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	async [DIALOG_EDITOR_HTML_CONFIRM]({
		commit,
		dispatch,
		rootState: { token },
		state: { campaign }
	}) {
		try {
			const updatedCampaign = await api.singleEmailCampaigns.put(
				token,
				campaign._id,
				{ isCustomHtml: false }
			);

			commit(CAMPAIGN, updatedCampaign);
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}
		}

		commit(DIALOG_EDITOR_HTML_VISIBILITY, false);
	},

	/**
	 * Hide editor html confirmation dialog when switching is cancelled.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_EDITOR_HTML_CANCEL]({ commit }) {
		commit(DIALOG_EDITOR_HTML_VISIBILITY, false);
	},

	/**
	 * Show confirmation dialog for switching editor to custm html mode.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_CUSTOM_HTML_SHOW]({ commit }) {
		commit(DIALOG_CUSTOM_HTML_VISIBILITY, true);
	},

	/**
	 * Switch campaign to custom html and hide confirmation dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	async [DIALOG_CUSTOM_HTML_CONFIRM]({
		commit,
		dispatch,
		rootState: { token },
		state: { campaign }
	}) {
		try {
			const updatedCampaign = await api.singleEmailCampaigns.put(
				token,
				campaign._id,
				{ isCustomHtml: true }
			);

			commit(CAMPAIGN, updatedCampaign);
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}
		}

		commit(DIALOG_CUSTOM_HTML_VISIBILITY, false);
	},

	/**
	 * Hide custom html confirmation dialog when switching is cancelled.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_CUSTOM_HTML_CANCEL]({ commit }) {
		commit(DIALOG_CUSTOM_HTML_VISIBILITY, false);
	},

	/**
	 * Update general and contextual errors in state.
	 * @param {import('vuex').ActionContext} context
	 * @param {Object[]} errors
	 */
	[ERRORS_SHOW]({
		commit,
		state: { visibleFields }
	}, errors) {
		const { generalErrors, contextualErrors } = groupErrors(
			errors, visibleFields
		);

		commit(GENERAL_ERRORS, generalErrors);
		commit(FIELD_ERRORS, contextualErrors);
	},
	/**
	 *
	 * @param {import('vuex').ActionContext} context
	 * @param {Boolean} areMergeFieldsReplaced
	 */
	[DIALOG_TEST_EMAIL_REPLACE_MERGE_FIELDS]({ commit }, areMergeFieldsReplaced) {
		commit(REPLACE_MERGE_FIELDS, areMergeFieldsReplaced);
	},

	[DIALOG_SENDING_LIMITS_SHOW]({ commit }) {
		loadPaperformScript();
		commit(DIALOG_SENDING_LIMITS_VISIBILITY, true);
	},

	[DIALOG_SENDING_LIMITS_CLOSE]({ commit }) {
		commit(DIALOG_SENDING_LIMITS_VISIBILITY, false);
	},

	async [INFOBOX_DELIVERABILITY_DISMISS]({
		dispatch,
		rootState: { token }
	}) {
		await api.user.flags.post(token, Flags.EMAIL_DELIVERABILITY_MSG);
		dispatch(`user/${GET_USER}`, null, { root: true });
	},

	async [IMAGE_UPLOAD]({
		commit,
		rootState: { token }
	}, { imageFile, uploadId }) {
		try {
			const result = await api.content.upload.post(token, { file: imageFile });

			commit(IMAGE, { uploadId, imageUrl: result.data.location });
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
