export const EDITOR_MODE = {
	CREATE: 'CREATE',
	EDIT: 'EDIT'
};

const getInitialState = () => ({
	mode: EDITOR_MODE.CREATE,
	sequenceId: null,
	options: {
		name: {
			default: ''
		},
		fromName: {
			default: ''
		},
		fromAddress: {
			default: ''
		},
		filter: {
			options: []
		},
		html: {
			default: ''
		},
		mergeFields: {
			options: []
		}
	},
	email: {
		fromAddress: '',
		fromName: '',
		html: '',
		delta: {}
	},
	visibleFields: [],
	areMergeFieldsReplaced: true,
	isDialogEditorHtmlVisible: false,
	isDialogCustomHtmlVisible: false,
	generalErrors: [],
	fieldErrors: {
		fromAddress: null,
		fromName: null,
		subject: null,
		preHeader: null,
		html: null
	},
	uploadedImages: {}
});

export default getInitialState;
