import getInitialState from './state';

import {
	SET_MODE,
	SET_STATUS,
	SET_OPTIONS,
	SET_AUTOMATION,
	SET_FILTER,
	SET_LIST,
	SET_SELECTED_PERSONFIELDS,
	SET_SELECTED_ORGFIELDS,
	RESET_STATE
} from './mutationTypes';

export default {
	[SET_MODE](state, mode) {
		state.mode = mode;
	},

	[SET_STATUS](state, status) {
		state.status = status;
	},

	[SET_OPTIONS](state, options) {
		state.fields = options;
	},

	[SET_AUTOMATION](state, automation) {
		state.automation = automation;
	},

	[SET_FILTER](state, filterId) {
		state.selected.filter = filterId;
	},

	[SET_LIST](state, listId) {
		state.selected.list = listId;
	},

	[SET_SELECTED_PERSONFIELDS](state, personFields) {
		state.selected.personFields = personFields;
	},

	[SET_SELECTED_ORGFIELDS](state, orgFields) {
		state.selected.orgFields = orgFields;
	},

	[RESET_STATE](state) {
		Object.assign(state, getInitialState());
	}
};
