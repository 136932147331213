import { leadScoring } from './lead-scoring';
import { webTracking } from './web-tracking';
import { emailEngagement } from './email-engagement';
import { email } from './email';

export const settings = {
	leadScoring,
	webTracking,
	emailEngagement,
	email
};
