import utils from 'utils';

import SHOW_NOTIFICATION from './actionTypes';

import {
	MESSAGE,
	VISIBILITY
} from './mutationTypes';

export default {
	async [SHOW_NOTIFICATION]({ commit }, message) {
		commit(VISIBILITY, true);
		commit(MESSAGE, message);

		const NOTIFICATION_DELAY = 6;

		await utils.delay(NOTIFICATION_DELAY);

		commit(VISIBILITY, false);
	}
};
