//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
		offsetY: {
			type: Boolean,
			default: true
		}
	}
};
