//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ROUTE_NAMES from 'router/route-names';

export default {
	props: {
		outfunnelEmailsCount: {
			type: Number,
			default: 0
		},
		outfunnelEmailsLimit: {
			type: Number,
			default: 1
		},
		appConnectorEventsCount: {
			type: Number,
			default: 0
		},
		appConnectorEventsLimit: {
			type: Number,
			default: 1
		},
		activeContactsCounts: {
			type: Object,
			default: () => ({})
		},
		activeContactsLimit: {
			type: Number,
			default: 1
		},
		nextBillingDate: {
			type: String,
			default: null
		},
		isNonRenewing: {
			type: Boolean,
			default: false
		},
		isBillingVisible: {
			type: Boolean,
			default: true
		},
		connectorStats: {
			type: Object,
			default: () => ({})
		},
		areActiveContactPricingPlansEnabled: {
			type: Boolean,
			default: false
		},
		isActiveContactsPlan: {
			type: Boolean,
			default: false
		},
		areSequenceCampaignsEnabled: {
			type: Boolean,
			default: false
		},
		hasActiveConnection: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		paymentsRoute() {
			return { name: ROUTE_NAMES.ACCOUNT_BILLING_PAYMENTS };
		},
		outfunnelEmailsPercentage() {
			return (this.outfunnelEmailsCount / this.outfunnelEmailsLimit) * 100;
		},
		appConnectorEventsPercentage() {
			return (this.appConnectorEventsCount / this.appConnectorEventsLimit) * 100;
		},
		activeContactsPercentage() {
			return (this.activeContactsCounts.activeContactsTotalCount / this.activeContactsLimit) * 100;
		},
		isOutfunnelEmailsLimitExceeded() {
			return this.outfunnelEmailsPercentage > 100;
		},
		isAppConnectorEventsLimitExceeded() {
			return this.appConnectorEventsPercentage > 100;
		},

		supportLink() {
			if (this.areActiveContactPricingPlansEnabled) {
				return 'http://support.outfunnel.com/en/articles/6612171-what-are-active-contacts-and-how-is-it-calculated';
			}

			return 'https://support.outfunnel.com/en/articles/5667770-what-are-app-connector-events-and-how-are-they-calculated';
		},

		isConnectorEventsVisible() {
			if (this.isActiveContactsPlan) {
				return false;
			}

			if (this.hasActiveConnection) {
				return true;
			}

			if (this.appConnectorEventsCount) {
				return true;
			}

			return false;
		},

		isActiveContactsLimitExceeded() {
			return this.activeContactsPercentage > 100;
		},

		isEmailUsageVisible() {
			if (this.isActiveContactsPlan) {
				return false;
			}

			return this.areSequenceCampaignsEnabled;
		}
	},

	methods: {
		progressBarColor(percentage) {
			let color = 'primary';

			if (percentage >= 100) {
				color = 'deep-orange';
			}

			return color;
		}
	}
};

