//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
		dense: {
			type: String,
			default: 'dense'
		},
		color: {
			type: String,
			required: true
		}
	}
};

