import { api } from 'api';
import { Flags } from 'store/modules/user/helpers';
import { GET_USER } from 'store/modules/user/actionTypes';
import logger from 'utils/logger';

import {
	COUNTERS,
	VISITS,
	PAGINATION,
	GENERAL_ERRORS
} from './mutationTypes';

import {
	COUNTERS_GET,
	VISITS_GET,
	WEBTRACKING_INFOBOX_TOGGLE
} from './actionTypes';

const VISITS_PER_PAGE = 100;

export const actions = {
	[COUNTERS_GET]: async ({ rootState: { token }, commit }) => {
		try {
			const counters = await api.tracking.counters.get(token);

			commit(COUNTERS, counters);
		} catch (exception) {
			if (exception.errors) {
				commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[VISITS_GET]: async ({
		rootState: { token },
		commit
	}, page = 1) => {
		try {
			const { data: visits, pagination } = await api.tracking.visits.get({
				token,
				limit: VISITS_PER_PAGE,
				page
			});

			commit(VISITS, visits);
			commit(PAGINATION, pagination);
		} catch (exception) {
			if (exception.errors) {
				commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[WEBTRACKING_INFOBOX_TOGGLE]: async ({
		rootState: { token, user },
		dispatch,
		commit
	}) => {
		try {
			if (user.profile.flags.includes(Flags.WEB_TRACKING_MSG)) {
				await api.user.flags.delete(token, Flags.WEB_TRACKING_MSG);
			} else {
				await api.user.flags.post(token, Flags.WEB_TRACKING_MSG);
			}

			dispatch(`user/${GET_USER}`, null, { root: true });
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
