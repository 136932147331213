import { MutationTypes } from './mutationTypes';

export const mutations = {
	[MutationTypes.COUNTERS](state, counters) {
		state.counters = counters;
	},

	[MutationTypes.GENERAL_ERRORS](state, generalErrors) {
		state.generalErrors = generalErrors;
	},

	[MutationTypes.VERIFICATION_IN_PROGRESS](state, isInProgress) {
		state.isVerificationInProgress = isInProgress;
	},

	[MutationTypes.FEATURES](state, features) {
		state.features = features;
	}
};
