import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { SegmentsException } from './helpers';

/**
 * Send DELETE request to /segments/:id endpoint.
 * @param {String} token JWT token.
 * @param {String} segmentId Segment ID.
 * @return {Promise<{Object}>}
 */
export const httpDelete = async (token, segmentId) => {
	const response = await request.delete(endpoints.segmentsId(segmentId), token);

	if (!response.success) {
		return Promise.reject(new SegmentsException(response));
	}

	return true;
};
