import ROUTE_NAMES from 'router/route-names';

export const leadScoringRoutes = [
	{
		path: '/lead-scoring',
		name: ROUTE_NAMES.LEAD_SCORING,
		/**
		 * @return {Promise<Object>}
		 */
		component: () => import('pages/lead-scoring/LeadScoring.vue'),
		meta: {
		}
	},
	{
		path: '/lead-scoring/edit',
		name: ROUTE_NAMES.LEAD_SCORING_EDIT,
		/**
		 * @return {Promise<Object>}
		 */
		component: () => import('pages/lead-scoring/LeadScoringBasePage.vue'),
		meta: {
			title: 'Edit lead scoring settings'
		},
		children: [
			{
				path: 'dashboard',
				name: ROUTE_NAMES.LEAD_SCORING_DASHBOARD,
				/**
				 * @return {Promise<Object>}
				 */
				component: () => import('pages/lead-scoring/dashboard/LeadScoringDashboard.vue'),
				meta: {
					title: 'Edit lead scoring settings'
				}
			},
			{
				path: 'workflows',
				name: ROUTE_NAMES.LEAD_SCORING_WORKFLOWS,
				/**
				 * @return {Promise<Object>}
				 */
				component: () => import('pages/lead-scoring/workflows/Workflows.vue'),
				meta: {
					title: 'Workflows'
				}
			}
		]
	}
];
