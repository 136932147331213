//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
		openNewWindow: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: 'primary'
		}
	},

	computed: {
		style() {
			if (this.color) {
				return `color:${this.color}`;
			}

			return '';
		}
	}
};
