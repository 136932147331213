export const GET_OPTIONS = 'GET_OPTIONS';
export const GET_AUTOMATION = 'GET_AUTOMATION';
export const PUT_AUTOMATION = 'PUT_AUTOMATION';
export const POST_AUTOMATION = 'POST_AUTOMATION';
export const SCHEDULE_SYNC = 'SCHEDULE_SYNC';
export const POST_SCHEDULE_SYNC = 'POST_SCHEDULE_SYNC';
export const DELETE = 'DELETE';
export const CHANGE_OPTION = 'CHANGE_OPTION';
export const SELECT_LIST = 'SELECT_LIST';
export const SELECT_FILTER = 'SELECT_FILTER';
export const SELECT_ORGFIELDS = 'SELECT_ORGFIELDS';
export const SELECT_PERSONFIELDS = 'SELECT_PERSONFIELDS';
