import logger from 'utils/logger';
import { api } from 'api';

import {
	VERIFY_USER_EMAIL,
	STORE_QUERY_PARAMS
} from './actionTypes';

import {
	GET_USER
} from '../user/actionTypes';

import {
	SET_IN_PROGRESS,
	SET_ERROR,
	SET_SUCCESS,
	SET_EMAIL,
	SET_TOKEN
} from './mutationTypes';

export default {
	[VERIFY_USER_EMAIL]: async ({ commit, dispatch, state }) => {
		try {
			commit(SET_IN_PROGRESS, true);

			await api.auth.emailVerification.post(state.email, state.token);

			dispatch(`user/${GET_USER}`, null, { root: true });
			commit(SET_SUCCESS, true);
		} catch (exception) {
			if (exception.message) {
				return commit(SET_ERROR, exception.message);
			}

			commit(SET_ERROR, 'Something went wrong.');
			logger.error(exception);
		}
	},
	[STORE_QUERY_PARAMS]: ({ commit, rootState }) => {
		const { email, token } = rootState.route.query;

		commit(SET_EMAIL, email);
		commit(SET_TOKEN, token);
	}
};
