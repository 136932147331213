import { BaseException } from 'utils/error';

/**
 * Exception subclass for /channel-report endpoint.
 */
class LeadScoringSettingsException extends BaseException {
	/**
	 * @param {{
	 * success: Boolean,
	 * status: Number,
	 * errors: Object[]
	 * }} result
	 */
	constructor(result) {
		super(result);
		this.name = 'LeadScoringSettingsException';
	}
}

export {
	LeadScoringSettingsException
};
