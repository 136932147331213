import ROUTE_NAMES from 'router/route-names';

export const connectionsRoutes = [
	{
		path: '/connections',
		name: ROUTE_NAMES.CONNECTIONS,
		/**
		 * @return {Promise<Object>}
		 */
		component: () => import('pages/connections/ConnectionList.vue'),
		meta: {
			title: 'Connections'
		}
	},
	{
		path: '/connections/welcome',
		name: ROUTE_NAMES.CONNECTIONS_WELCOME,
		/**
		 * @return {Promise<Object>}
		 */
		component: () => import('pages/ConnectionWelcome.vue'),
		meta: {
			title: 'App Connector'
		}
	},
	{
		path: '/connections/create',
		name: ROUTE_NAMES.CONNECTIONS_CREATE,
		/**
		 * @return {Promise<Object>}
		 */
		component: () => import('pages/ConnectionCreate.vue'),
		meta: {
			title: 'Create connection'
		}
	},
	{
		path: '/connections/create/select-type',
		name: ROUTE_NAMES.CONNECTIONS_CREATE_SELECT_TYPE,
		/**
		 * @return {Promise<Object>}
		 */
		component: () => import('pages/ConnectionCreateType.vue'),
		meta: {
			title: 'Create connection'
		}
	},
	{
		path: '/connections/:id',
		name: ROUTE_NAMES.CONNECTIONS_EDIT,
		/**
		 * @return {Promise<Object>}
		 */
		component: () => import('pages/connections/ConnectionEdit.vue'),
		meta: {
			title: 'Edit connection'
		},
		children: [
			{
				// component will be rendered inside ConnectionEdit's <router-view>
				// when /connections/:id/contact-sync is matched
				path: 'contact-sync',
				name: ROUTE_NAMES.CONNECTIONS_EDIT_CONTACT_SYNC,
				/**
				 * @return {Promise<Object>}
				 */
				component: () => import('pages/connections/contact-sync/ConnectionEditContactSync.vue')
			},
			{
				path: 'engagement-sync',
				name: ROUTE_NAMES.CONNECTIONS_EDIT_ENGAGEMENT_SYNC,
				/**
				 * @return {Promise<Object>}
				 */
				component: () => import('pages/connections/engagement-sync/ConnectionEditEngagementSync.vue')
			},
			{
				path: 'status',
				name: ROUTE_NAMES.CONNECTIONS_EDIT_STATUS,
				/**
				 * @return {Promise<Object>}
				 */
				component: () => import('pages/ConnectionEditStatus.vue')
			},
			{
				path: 'field-mapping',
				name: ROUTE_NAMES.CONNECTIONS_EDIT_FIELD_MAPPING,
				/**
				 * @return {Promise<Object>}
				 */
				component: () => import('pages/connections/field-mapping/ConnectionEditFieldMapping.vue')
			},
			{
				path: 'contact-sync-direction',
				name: ROUTE_NAMES.CONNECTIONS_EDIT_CONTACT_SYNC_DIRECTION,
				/**
				 * @return {Promise<Object>}
				 */
				component: () => import('pages/connections/contact-sync-direction/ConnectionEditContactSyncDirection.vue')
			},
			{
				path: 'contact-sync-settings',
				name: ROUTE_NAMES.CONNECTIONS_EDIT_CONTACT_SYNC_SETTINGS,
				/**
				 * @return {Promise<Object>}
				 */
				component: () => import('pages/connection-edit-contact-sync-settings/ConnectionEditContactSyncSettings.vue')
			},
			{
				path: 'engagement-settings',
				name: ROUTE_NAMES.CONNECTIONS_EDIT_ENGAGEMENT_SETTINGS,
				/**
				 * @return {Promise<Object>}
				 */
				component: () => import('pages/ConnectionEditEngagementSettings.vue')
			}
		]
	},
	{
		path: '/connections/:id/contact-sync-status',
		name: ROUTE_NAMES.CONNECTIONS_CONTACT_SYNC_STATUS,
		/**
		 * @return {Promise<Object>}
		 */
		component: () => import('pages/connection-status/ConnectionStatusPage.vue'),
		meta: {
			title: 'Connection status'
		}
	},
	{
		path: '/connections/:id/contact-sync/select-direction',
		name: ROUTE_NAMES.CONNECTIONS_EDIT_CONTACT_SYNC_SELECT_DIRECTION,
		/**
		 * @return {Promise<Object>}
		 */
		component: () => import('pages/connections/contact-sync-direction/ConnectionEditSelectContactSyncDirection.vue'),
		meta: {
			title: 'Sync Direction'
		}
	},
	{
		path: '/connections/:id/form-status',
		name: ROUTE_NAMES.CONNECTIONS_FORM_STATUS,
		/**
		 * @return {Promise<Object>}
		 */
		component: () => import('pages/connection-status-form/ConnectionStatusFormPage.vue'),
		meta: {
			title: 'Connection status'
		}
	}
];
