import getInitialState from './state';

import {
	PERSON_ID,
	PERSON,
	GENERAL_ERRORS,
	RESET,
	EVENTS,
	PAGINATION,
	DIALOG_PERSON_UNSUBSCRIBE_VISIBILITY,
	DIALOG_PERSON_LEAD_SCORE_RESET_VISIBILITY
} from './mutationTypes';

export default {
	[PERSON_ID](state, personId) {
		state.personId = personId;
	},

	[PERSON](state, person) {
		state.person = person;
	},

	[EVENTS](state, events) {
		state.events = events;
	},

	[PAGINATION](state, pagination) {
		state.pagination = pagination;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	},

	[DIALOG_PERSON_UNSUBSCRIBE_VISIBILITY](state, isVisible) {
		state.isDialogPersonUnsubscribeVisible = isVisible;
	},

	[DIALOG_PERSON_LEAD_SCORE_RESET_VISIBILITY](state, isVisible) {
		state.isDialogPersonLeadScoreResetVisible = isVisible;
	}
};
