export const CONTACTS_NAVIGATE = 'CONTACTS_NAVIGATE';
export const PERSONS_ALL = 'PERSONS_ALL';
export const PERSONS_GET = 'PERSONS_GET';
export const PERSONS_SEARCH = 'PERSONS_SEARCH';
export const PROFILE_VIEW = 'PROFILE_VIEW';
export const ERRORS_SHOW = 'ERRORS_SHOW';
export const DIALOG_CONTACTS_IMPORT_SHOW = 'DIALOG_CONTACTS_IMPORT_SHOW';
export const DIALOG_CONTACTS_IMPORT_CLOSE = 'DIALOG_CONTACTS_IMPORT_CLOSE';
export const DIALOG_CONTACTS_IMPORT_CONFIRM = 'DIALOG_CONTACTS_IMPORT_CONFIRM';
export const SEGMENTS_GET = 'SEGMENTS_GET';
export const SEGMENT_CREATE = 'SEGMENT_CREATE';
export const SEGMENT_EDIT = 'SEGMENT_EDIT';
export const SEGMENT_DELETE = 'SEGMENT_DELETE';
export const PERSONS_SEGMENT = 'PERSONS_SEGMENT';
