export const getInitialState = () => ({
	errors: [],
	apps: [],
	connectionContactSource: null,
	connectionEngagementSource: null,
	generalErrors: [],
	isAppAuthDialogVisible: false,
	appAuthAppId: '',
	appAuthData: {},
	appAuthAccountId: '',
	appAuthApiKey: '',
	appAuthErrors: [],
	isWpPluginAuthDialogVisible: false,
	wpPluginAuthPluginName: '',
	wpPluginAuthErrors: [],
	isConnectionDialogResearchVisible: false,
	isDialogSuggestAppVisible: false,
	isDialogHubspotMktContactSyncVisible: false,
	isContactSyncLearnMoreDialogVisible: false,
	isEngagementSyncLearnMoreDialogVisible: false
});
