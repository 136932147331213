//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
		active: {
			type: Boolean,
			required: true
		}
	}
};

