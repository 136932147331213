/**
 * @param {*} value
 * @return {Boolean}
 */
export function isNumber(value) {
	return (typeof value === 'number' && !Number.isNaN(value));
}

/**
 * @param {*} value
 * @return {Boolean}
 */
export function isString(value) {
	return (typeof value === 'string');
}

/**
 * @param {*} value
 * @return {Boolean}
 */
export function isBoolean(value) {
	return (typeof value === 'boolean');
}

/**
 * Check if the variable has been assigned a value.
 * @param {*} value
 * @return {Boolean}
 */
export function isUndefined(value) {
	return (typeof value === 'undefined');
}

/**
 * @param {*} value
 * @return {Boolean}
 */
export function isObject(value) {
	return (typeof value === 'object');
}

/**
 * @param {Object} object
 * @param {Function} constructor
 * @return {Boolean}
 */
export function isInstanceOf(object, constructor) {
	return (object instanceof constructor);
}

/**
 * @param {*} value
 * @return {Boolean}
 */
export function isNull(value) {
	return value === null;
}
