import { BaseException } from 'utils/error';

/**
 * Exception subclass for /email/schedule endpoint.
 */
class EmailScheduleSettingsException extends BaseException {
	/**
	 * @param {{
	 * success: Boolean,
	 * status: Number,
	 * errors: Object[]
	 * }} result
	 */
	constructor(result) {
		super(result);
		this.name = 'EmailScheduleSettingsException';
	}
}

export {
	EmailScheduleSettingsException
};
