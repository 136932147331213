import {
	CAMPAIGNS,
	CAMPAIGNS_OPTIONS,
	SEQUENCES,
	GENERAL_ERRORS,
	GENERAL_ERRORS_APPEND,
	RESET
} from './mutationTypes';

import getInitialState from './state';

export default {
	[CAMPAIGNS](state, campaigns) {
		state.campaigns = campaigns;
	},

	[CAMPAIGNS_OPTIONS](state, options) {
		state.campaignsOptions = options;
	},

	[SEQUENCES](state, sequences) {
		state.sequences = sequences;
	},

	[GENERAL_ERRORS](state, generalErrors) {
		state.generalErrors = generalErrors;
	},

	[GENERAL_ERRORS_APPEND](state, generalErrors) {
		state.generalErrors = state.generalErrors
			.concat(generalErrors)
			.filter((currentItem, currentIndex, array) => {
				const isMatch = item => item.error === currentItem.error;
				const firstItemIndex = array.findIndex(isMatch);

				return (currentIndex === firstItemIndex);
			});
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	}
};
