import getInitialState from './state';
import mutations from './mutations';
import actions from './actions';

export const moduleFooter = {
	namespaced: true,
	state: getInitialState(),
	mutations,
	actions
};
