export const GENERAL_ERRORS = 'GENERAL_ERRORS';
export const DIALOG_CONTACTS_IMPORT_VISIBILITY = 'DIALOG_CONTACTS_IMPORT_VISIBILITY';
export const DIALOG_CONTACTS_IMPORT_LOADING = 'DIALOG_CONTACTS_IMPORT_LOADING';
export const DIALOG_CONTACTS_IMPORT_SELECTED_FILE = 'DIALOG_CONTACTS_IMPORT_SELECTED_FILE';
export const DIALOG_CONTACTS_IMPORT_RESET = 'DIALOG_CONTACTS_IMPORT_RESET';
export const DIALOG_CONTACTS_IMPORT_SEGMENT_NAME = 'DIALOG_CONTACTS_IMPORT_SEGMENT_NAME';
export const DIALOG_CONTACTS_IMPORT_DELIMITER = 'DIALOG_CONTACTS_IMPORT_DELIMITER';
export const DIALOG_CONTACTS_IMPORT_OPT_IN = 'DIALOG_CONTACTS_IMPORT_OPT_IN';
export const DIALOG_CONTACTS_IMPORT_OPT_IN_ERROR = 'DIALOG_CONTACTS_IMPORT_OPT_IN_ERROR';

export const APP_AUTH_DATA = 'APP_AUTH_DATA';
export const APP_AUTH_APP_ID = 'APP_AUTH_APP_ID';
export const APP_AUTH_DIALOG_VISIBILITY = 'APP_AUTH_DIALOG_VISIBILITY';
export const APP_AUTH_ERRORS = 'APP_AUTH_ERRORS';
export const RESET = 'RESET';

export const WP_PLUGIN_AUTH_DIALOG_VISIBILITY = 'WP_PLUGIN_AUTH_DIALOG_VISIBILITY';
export const WP_PLUGIN_AUTH_PLUGIN_NAME = 'WP_PLUGIN_AUTH_PLUGIN_NAME';
export const WP_PLUGIN_AUTH_ERRORS = 'WP_PLUGIN_AUTH_ERRORS';

export const OAUTH_APP_PKCE_CODE_VERIFIER = 'APP_PKCE_CODE_VERIFIER';
