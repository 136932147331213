import { addMinutes } from 'date-fns';
import { ConnectionTypes } from 'utils/constants/connections';
import { EventNames, track } from 'utils/analytics';

/**
 * @constant {Number}
 */
const SYNC_PERIOD_IN_MINUTES = 5;

/**
 * @param {{syncStartedAt: String}} params
 * @return {Boolean}
 */
const isSyncInProgress = ({ syncStartedAt }) => {
	if (!syncStartedAt) {
		return false;
	}

	const syncEndTimestamp = addMinutes(new Date(syncStartedAt), SYNC_PERIOD_IN_MINUTES);

	return new Date() < syncEndTimestamp;
};

/**
 * @param {{syncStartedAt: String}} params
 * @return {Number}
 */
const getSyncProgress = ({ syncStartedAt }) => {
	if (!syncStartedAt) {
		return 0;
	}

	const currentTimestamp = new Date().getTime();
	const syncStartedTimestamp = new Date(syncStartedAt).getTime();

	const syncPeriodInMs = SYNC_PERIOD_IN_MINUTES * 60 * 1000;

	const syncProgress = (((currentTimestamp - syncStartedTimestamp) / syncPeriodInMs) * 100)
		.toFixed(1);

	if (syncProgress > 100) {
		return 0;
	}

	return syncProgress;
};

const isContactSyncTabVisible = connection => {
	const {
		contactSyncRulesEnabled,
		contactCreationRulesEnabled,
		type
	} = connection;

	if (type) {
		return type === ConnectionTypes.CONTACT_SYNC;
	}

	return contactSyncRulesEnabled || contactCreationRulesEnabled;
};

/**
 * @param {Object} connection
 * @param {"status" | "edit" | "card"} location
 */
const trackConnectionManualSync = (connection, location) => {
	if (!connection) {
		return;
	}

	track({
		event: EventNames.MANUAL_SYNC_INITIATED,
		properties: {
			connectionId: connection._id,
			syncType: connection.type,
			selectedAppsSlug: `${connection.contactSource}:${connection.engagementEventSource}`,
			location
		}
	});
};

export {
	isSyncInProgress,
	getSyncProgress,
	isContactSyncTabVisible,
	trackConnectionManualSync
};
