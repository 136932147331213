import ROUTE_NAMES from 'router/route-names';
import { api } from 'api';
import { TYPES } from 'api/automations';
import router from 'router';
import logger from 'utils/logger';

import { Flags } from 'store/modules/user/helpers';
import {
	PIPEDRIVE_TO_MAILCHIMP_LIST,
	MAILCHIMP_TO_PIPEDRIVE_LIST,
	MAILCHIMP_TO_COPPER_LIST,
	COPPER_TO_MAILCHIMP_LIST,
	MAILCHIMP_TO_HUBSPOT_LIST,
	LEAD_SCORING_SETTINGS,
	WEB_TRACKING_SETTINGS,
	GENERAL_ERRORS
} from './mutationTypes';

import {
	GET_AUTOMATIONS,
	DISMISS_WELCOME,
	BACK_TO_AUTOMATIONS,
	CREATE_PIPEDRIVE_TO_MAILCHIMP,
	EDIT_PIPEDRIVE_TO_MAILCHIMP,
	CREATE_MAILCHIMP_TO_PIPEDRIVE,
	EDIT_MAILCHIMP_TO_PIPEDRIVE,
	CREATE_MAILCHIMP_TO_COPPER,
	EDIT_MAILCHIMP_TO_COPPER,
	CREATE_COPPER_TO_MAILCHIMP,
	EDIT_COPPER_TO_MAILCHIMP,
	CREATE_MAILCHIMP_TO_HUBSPOT,
	EDIT_MAILCHIMP_TO_HUBSPOT,
	LEAD_SCORING_SETTINGS_GET,
	WEB_TRACKING_SETTINGS_GET,
	SET_EARLY_ACCESS_FLAG
} from './actionTypes';

import {
	GET_USER
} from '../user/actionTypes';

const ARE_ALL_INTEGRATIONS_CONNECTED_GETTER = 'user/areAllIntegrationsConnected';
const IS_MAILCHIMP_CONNECTED_GETTER = 'user/isMailchimpConnected';

export default {
	[DISMISS_WELCOME]: async ({ dispatch, commit, rootState: { token } }) => {
		try {
			await api.user.flags.post(token, Flags.WELCOMEMSG);

			dispatch(`user/${GET_USER}`, null, { root: true });
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[GET_AUTOMATIONS]: async ({
		commit,
		dispatch,
		rootState: { token },
		rootGetters
	}) => {
		try {
			await dispatch(`user/${GET_USER}`, null, { root: true });

			if (rootGetters[IS_MAILCHIMP_CONNECTED_GETTER] && rootGetters['user/isPipedriveConnected']) {
				const pipedriveToMailchimpAutomations = await api
					.automations
					.get(token, TYPES.PIPEDRIVE_TO_MAILCHIMP);

				commit(PIPEDRIVE_TO_MAILCHIMP_LIST, pipedriveToMailchimpAutomations);

				const mailchimpToPipedriveAutomations = await api
					.automations
					.get(token, TYPES.MAILCHIMP_TO_PIPEDRIVE);

				commit(MAILCHIMP_TO_PIPEDRIVE_LIST, mailchimpToPipedriveAutomations);
			}

			if (rootGetters[IS_MAILCHIMP_CONNECTED_GETTER] && rootGetters['user/isCopperConnected']) {
				const mailchimpToCopperAutomations = await api
					.automations
					.get(token, TYPES.MAILCHIMP_TO_COPPER);

				commit(MAILCHIMP_TO_COPPER_LIST, mailchimpToCopperAutomations);

				const copperToMailchimpAutomations = await api
					.automations
					.get(token, TYPES.COPPER_TO_MAILCHIMP);

				commit(COPPER_TO_MAILCHIMP_LIST, copperToMailchimpAutomations);
			}

			if (rootGetters[IS_MAILCHIMP_CONNECTED_GETTER] && rootGetters['user/isHubspotCrmConnected']) {
				const mailchimpToHubspotAutomations = await api
					.automations
					.get(token, TYPES.MAILCHIMP_TO_HUBSPOT);

				commit(MAILCHIMP_TO_HUBSPOT_LIST, mailchimpToHubspotAutomations);
			}
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[BACK_TO_AUTOMATIONS]: () => {
		router.push({ name: ROUTE_NAMES.AUTOMATIONS });
	},

	[CREATE_PIPEDRIVE_TO_MAILCHIMP]: ({ rootGetters }) => {
		const areAllIntegrationsConnected = rootGetters[ARE_ALL_INTEGRATIONS_CONNECTED_GETTER];

		if (areAllIntegrationsConnected) {
			router.push({ name: ROUTE_NAMES.AUTOMATIONS_PIPEDRIVE_TO_MAILCHIMP_CREATE });
		} else {
			router.push({ name: ROUTE_NAMES.ACCOUNT_INTEGRATIONS });
		}
	},

	[EDIT_PIPEDRIVE_TO_MAILCHIMP]: (context, id) => {
		router.push({
			name: ROUTE_NAMES.AUTOMATIONS_PIPEDRIVE_TO_MAILCHIMP_EDIT,
			params: { id }
		});
	},

	[CREATE_MAILCHIMP_TO_PIPEDRIVE]: ({ rootGetters }) => {
		const areAllIntegrationsConnected = rootGetters[ARE_ALL_INTEGRATIONS_CONNECTED_GETTER];

		if (areAllIntegrationsConnected) {
			router.push({ name: ROUTE_NAMES.AUTOMATIONS_MAILCHIMP_TO_PIPEDRIVE_CREATE });
		} else {
			router.push(ROUTE_NAMES.ACCOUNT_INTEGRATIONS);
		}
	},

	[EDIT_MAILCHIMP_TO_PIPEDRIVE]: (context, id) => {
		router.push({
			name: ROUTE_NAMES.AUTOMATIONS_MAILCHIMP_TO_PIPEDRIVE_EDIT,
			params: { id }
		});
	},

	[CREATE_MAILCHIMP_TO_COPPER]: ({ rootGetters }) => {
		const areAllIntegrationsConnected = rootGetters[ARE_ALL_INTEGRATIONS_CONNECTED_GETTER];

		if (areAllIntegrationsConnected) {
			router.push({ name: ROUTE_NAMES.AUTOMATIONS_MAILCHIMP_TO_COPPER_CREATE });
		} else {
			router.push(ROUTE_NAMES.ACCOUNT_INTEGRATIONS);
		}
	},

	[EDIT_MAILCHIMP_TO_COPPER]: (context, id) => {
		router.push({
			name: ROUTE_NAMES.AUTOMATIONS_MAILCHIMP_TO_COPPER_EDIT,
			params: { id }
		});
	},

	[CREATE_COPPER_TO_MAILCHIMP]: ({ rootGetters }) => {
		const areAllIntegrationsConnected = rootGetters[ARE_ALL_INTEGRATIONS_CONNECTED_GETTER];

		if (areAllIntegrationsConnected) {
			router.push({ name: ROUTE_NAMES.AUTOMATIONS_COPPER_TO_MAILCHIMP_CREATE });
		} else {
			router.push(ROUTE_NAMES.ACCOUNT_INTEGRATIONS);
		}
	},

	[EDIT_COPPER_TO_MAILCHIMP]: (context, id) => {
		router.push({
			name: ROUTE_NAMES.AUTOMATIONS_COPPER_TO_MAILCHIMP_EDIT,
			params: { id }
		});
	},

	[CREATE_MAILCHIMP_TO_HUBSPOT]: ({ rootGetters }) => {
		const areAllIntegrationsConnected = rootGetters[ARE_ALL_INTEGRATIONS_CONNECTED_GETTER];

		if (areAllIntegrationsConnected) {
			router.push({ name: ROUTE_NAMES.AUTOMATIONS_MAILCHIMP_TO_HUBSPOT_CREATE });
		} else {
			router.push(ROUTE_NAMES.ACCOUNT_INTEGRATIONS);
		}
	},

	[EDIT_MAILCHIMP_TO_HUBSPOT]: (context, id) => {
		router.push({
			name: ROUTE_NAMES.AUTOMATIONS_MAILCHIMP_TO_HUBSPOT_EDIT,
			params: { id }
		});
	},

	[LEAD_SCORING_SETTINGS_GET]: async ({ commit, rootState: { token } }) => {
		try {
			const settings = await api.user.settings.leadScoring.get(token);

			commit(LEAD_SCORING_SETTINGS, settings);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[WEB_TRACKING_SETTINGS_GET]: async ({ commit, rootState: { token } }) => {
		try {
			const settings = await api.user.settings.webTracking.get(token);

			commit(WEB_TRACKING_SETTINGS, settings);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[SET_EARLY_ACCESS_FLAG]: async ({
		dispatch, commit, rootState: { token }
	}, flag) => {
		try {
			await api.user.flags.post(token, flag);
			dispatch(`user/${GET_USER}`, null, { root: true });
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
