//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getLocaleFormattedAmount } from 'utils/formatting';

export default {
	props: {
		conversion: {
			type: Object,
			default: null
		},
		locale: {
			type: String,
			default: 'en-US'
		},
		isPipedriveConnected: {
			type: Boolean,
			default: false
		},
		isCopperConnected: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		dealsWord() {
			if (this.isCopperConnected) {
				return 'opportunities';
			}

			return 'deals';
		},

		dealsCreatedDuringWeekHeader() {
			return `New ${this.dealsWord} created in 7 days`;
		},

		dealsWonDuringMonthHeader() {
			return `Won ${this.dealsWord} in 30 days`;
		}
	},

	methods: {
		getConversionLabel(name) {
			let label = '';

			if (name === 'noAction') {
				label = 'Contacts with no action';
			} else if (name === 'open') {
				label = 'Contacts who only opened';
			} else if (name === 'openAndClick') {
				label = 'Contacts who opened and clicked';
			} else if (name === 'reply') {
				label = 'Contacts who replied';
			}

			return label;
		},

		getConversionActivity(name) {
			return this.conversion[name] || {};
		},

		activityLabel(name) {
			return this.getConversionLabel(name);
		},

		contactsCount(name) {
			const row = this.getConversionActivity(name);

			return `${row && row.contactsCount}`;
		},

		dealsCreated(name) {
			const row = this.getConversionActivity(name);
			const { totalValue, currency } = row && row.created;

			return `${row.created.dealCount} / ${getLocaleFormattedAmount(totalValue, currency, this.locale)}`;
		},

		dealsWon(name) {
			const row = this.getConversionActivity(name);
			const { totalValue, currency } = row && row.won;

			return `${row.won.dealCount} / ${getLocaleFormattedAmount(totalValue, currency, this.locale)}`;
		},

		dealConversion(name) {
			const row = this.getConversionActivity(name);

			return `${row && row.conversion}%`;
		}
	}
};

