export const getters = {
	isWebTrackingVerified(state) {
		return Boolean(state.counters?.totalEvents);
	},

	isWebTrackingDisabled(state) {
		return !state.features?.webTracking?.enabled ?? true;
	},

	isTrackingActive(state) {
		return state.counters?.totalEvents ?? 0;
	},

	identifiedContacts(state) {
		return state.counters?.identifiedContacts ?? 0;
	}
};
