import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { FieldMappingsException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {String} params.fieldMappingId
 * @param {String} params.token
 * @return {Promise<Object>}
 */
export const httpDelete = async ({
	connectionId,
	fieldMappingId,
	token
}) => {
	const response = await request.delete(
		endpoints.connectionsIdFieldMappingsId(connectionId, fieldMappingId),
		token
	);

	if (!response.success) {
		return Promise.reject(new FieldMappingsException(response));
	}

	return response.data;
};
