/**
 * @constant {{
 * GOOD: String,
 * FAIR: String,
 * BAD: String
 * }}
 */
export const EmailHealthStatuses = Object.freeze({
	GOOD: 'good',
	FAIR: 'fair',
	BAD: 'bad'
});

/**
 * @constant {{
 * GOOD: Number,
 * FAIR: Number
 * }}
 */
export const OpenRateThresholds = Object.freeze({
	GOOD: 0.3,
	FAIR: 0.1
});

/**
 * @constant {{
 * GOOD: Number,
 * FAIR: Number
 * }}
 */
export const ClickRateTresholds = Object.freeze({
	GOOD: 0.04,
	FAIR: 0.01
});

/**
 * @constant {{
 * GOOD: Number,
 * FAIR: Number
 * }}
 */
export const BounceRateThesholds = Object.freeze({
	GOOD: 0.02,
	FAIR: 0.1
});

/**
 * @constant {{
 * GOOD: Number,
 * FAIR: Number
 * }}
 */
export const UnsubscribeRateThresholds = Object.freeze({
	GOOD: 0.01,
	FAIR: 0.02
});

export const MINIMUM_EMAIL_HEALTH_SENT_COUNT = 250;

export const EMAIL_STATISTICS_PERIOD = 30;
