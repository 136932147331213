//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import WebTracking from 'images/web-tracking.svg';

/**
 * @constant {String}
 */
const EVENT_NAVIGATE_BILLING = 'navigate:billing';

/**
 * @constant {String}
 */
const EVENT_UPGRADE_PLAN = 'upgrade-plan';

export default {
	components: {
		WebTracking
	},

	props: {
		isTrialActive: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		learnMoreLink: 'https://outfunnel.com/web-visitor-tracking/'
	}),

	methods: {
		onNavigateBilling() {
			this.$emit(EVENT_NAVIGATE_BILLING);
		},

		onUpgradePlan() {
			this.$emit(EVENT_UPGRADE_PLAN);
		}
	}
};

