import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { EmailSettingsDomainsException } from './helpers';

/**
 * @param {String} domainId
 * @return {String}
 */
const getUrl = domainId => {
	if (domainId) {
		return endpoints.userSettings.email.domainsId(domainId);
	}

	return endpoints.userSettings.email.domains;
};

/**
 * @param {String} token
 * @param {String=} domainId
 * @return {Promise<Object>}
 */
export const httpGet = async (token, domainId) => {
	const url = getUrl(domainId);

	const result = await request.get(url, token);

	if (!result.success) {
		return Promise.reject(new EmailSettingsDomainsException(result));
	}

	return result.data;
};
