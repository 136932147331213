export const DISMISS_WELCOME = 'DISMISS_WELCOME';
export const GET_AUTOMATIONS = 'GET_AUTOMATIONS';
export const BACK_TO_AUTOMATIONS = 'BACK_TO_AUTOMATIONS';

export const CREATE_PIPEDRIVE_TO_MAILCHIMP = 'CREATE_PIPEDRIVE_TO_MAILCHIMP';
export const EDIT_PIPEDRIVE_TO_MAILCHIMP = 'EDIT_PIPEDRIVE_TO_MAILCHIMP';

export const CREATE_MAILCHIMP_TO_PIPEDRIVE = 'CREATE_MAILCHIMP_TO_PIPEDRIVE';
export const EDIT_MAILCHIMP_TO_PIPEDRIVE = 'EDIT_MAILCHIMP_TO_PIPEDRIVE';

export const CREATE_MAILCHIMP_TO_COPPER = 'CREATE_MAILCHIMP_TO_COPPER';
export const EDIT_MAILCHIMP_TO_COPPER = 'EDIT_MAILCHIMP_TO_COPPER';

export const CREATE_COPPER_TO_MAILCHIMP = 'CREATE_COPPER_TO_MAILCHIMP';
export const EDIT_COPPER_TO_MAILCHIMP = 'EDIT_COPPER_TO_MAILCHIMP';

export const CREATE_MAILCHIMP_TO_HUBSPOT = 'CREATE_MAILCHIMP_TO_HUBSPOT';
export const EDIT_MAILCHIMP_TO_HUBSPOT = 'EDIT_MAILCHIMP_TO_HUBSPOT';

export const LEAD_SCORING_SETTINGS_GET = 'LEAD_SCORING_SETTINGS_GET';
export const WEB_TRACKING_SETTINGS_GET = 'WEB_TRACKING_SETTINGS_GET';
export const SET_EARLY_ACCESS_FLAG = 'SET_EARLY_ACCESS_FLAG';
