import { groupErrors } from 'utils/error';
import {
	RESET,
	EMAIL,
	SENT,
	ERRORS,
	VISIBLE_FIELDS
} from './mutationTypes';

import { getInitialState } from './state';

export const mutations = {
	[RESET](state) {
		Object.assign(state, getInitialState());
	},

	[VISIBLE_FIELDS](state, visibleFields) {
		state.visibleFields = visibleFields;
	},

	[EMAIL](state, email) {
		state.email = email;
	},

	[SENT](state, isSent) {
		state.isSent = isSent;
	},

	[ERRORS](state, errors) {
		const { visibleFields } = state;
		const {
			generalErrors,
			contextualErrors
		} = groupErrors(errors, visibleFields);

		contextualErrors.forEach(error => {
			state.fieldErrors[error.context] = error.message;
		});

		state.generalErrors = generalErrors;
	}
};
