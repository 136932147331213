import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { PersonsException } from './helpers';

/**
 * Update a person.
 * @param {Object} params
 * @param {String} params.token
 * @param {String} params.personId
 * @param {Object} params.person
 * @return {Promise<{data: Object}>}
 */
export const httpPut = async ({ token, personId, person }) => {
	const url = new URL(endpoints.personsId(personId));
	const response = await request.put(url, person, token);

	if (!response.success) {
		return Promise.reject(new PersonsException(response));
	}

	return { data: response.data };
};
