export const getInitialState = () => ({
	counters: {
		identifiedContacts: 0
	},
	pagination: {
		currentPage: 0
	},
	visits: null,
	generalErrors: []
});
