import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { PasswordChangeException } from './helpers';

/**
 * Change user's password.
 * @param {Object} params
 * @param {String} params.email - User's email address connected to their Outfunnel account.
 * @param {String} params.token - Password reset token from the email.
 * @param {String} params.password - New password created by user.
 * @param {String} params.captchaToken
 */
export const httpPost = async ({
	email,
	token,
	password,
	captchaToken
}) => {
	const response = await request.post(endpoints.auth.passwordChange, {
		email, token, password, captchaToken
	});

	if (!response.success) {
		return Promise.reject(new PasswordChangeException(response.error));
	}

	return response;
};
