//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const GIT_HASH_LENGTH = 7;

export default {
	props: {
		isReleaseVisible: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		linkClasses: 'caption grey--text px-2',
		privacyLink: 'https://outfunnel.com/privacy/',
		termsLink: 'https://outfunnel.com/terms/',
		dpaLink: 'https://outfunnel.com/data-processing-agreement',
		processorsLink: 'https://outfunnel.com/processors/'
	}),

	computed: {
		release() {
			return process.env.RELEASE.substring(0, GIT_HASH_LENGTH);
		}
	}
};

