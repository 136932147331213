import getInitialState from './state';

import {
	SEQUENCE_ID,
	EMAIL_ID,
	EMAIL,
	RECIPIENTS,
	CSV_DOWNLOAD_URL,
	SORT_ORDER,
	SORT_BY,
	GENERAL_ERRORS,
	PAGINATION,
	RESET
} from './mutationTypes';

export default {
	[SEQUENCE_ID](state, sequenceId) {
		state.sequenceId = sequenceId;
	},

	[EMAIL_ID](state, emailId) {
		state.emailId = emailId;
	},

	[EMAIL](state, email) {
		state.email = email;
	},

	[RECIPIENTS](state, recipients) {
		state.recipients = recipients;
	},

	[CSV_DOWNLOAD_URL](state, csvDownloadUrl) {
		state.csvDownloadUrl = csvDownloadUrl;
	},

	[SORT_ORDER](state, sortOrder) {
		state.sortOrder = sortOrder;
	},

	[SORT_BY](state, sortBy) {
		state.sortBy = sortBy;
	},

	[PAGINATION](state, pagination) {
		state.pagination = pagination;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	}
};
