//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_CLICK_PRIMARY = 'click:primary';
const EVENT_CLICK_SECONDARY = 'click:secondary';
const EVENT_KEYDOWN_ESCAPE = 'keydown:escape';

export default {
	props: {
		isVisible: {
			type: Boolean,
			required: true
		},
		isLoading: {
			type: Boolean,
			default: false,
			required: false
		},
		isPrimaryButtonDisabled: {
			type: Boolean,
			default: false,
			required: false
		},
		isSecondaryButtonDisabled: {
			type: Boolean,
			default: false,
			required: false
		},
		title: {
			type: String,
			required: true
		},
		buttonPrimary: {
			type: String,
			required: true
		},
		buttonSecondary: {
			type: String,
			required: true
		},
		errors: {
			type: Array,
			default: null
		}
	},

	methods: {
		primaryAction() {
			this.$emit(EVENT_CLICK_PRIMARY);
		},
		secondaryAction() {
			this.$emit(EVENT_CLICK_SECONDARY);
		},
		escapeAction() {
			this.$emit(EVENT_KEYDOWN_ESCAPE);
		}
	}
};

