//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_CONFIRM = 'confirm';
const EVENT_CLOSE = 'close';

export default {
	props: {
		isVisible: {
			type: Boolean,
			default: false
		},
		filterName: {
			type: String,
			required: true
		},
		filterEntityType: {
			type: String,
			required: true
		},
		contactsCountText: {
			type: String,
			required: true
		}
	},

	computed: {
		isDealTypeFilter() {
			return this.filterEntityType === 'deal';
		}
	},

	methods: {
		confirm() {
			this.$emit(EVENT_CONFIRM);
		},
		close() {
			this.$emit(EVENT_CLOSE);
		}
	}
};

