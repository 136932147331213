/**
 * @constant {{
 * NO_CACHE: String
 * }}
 */
const CacheControlHeaderValues = Object.freeze({
	NO_CACHE: 'no-cache'
});

module.exports = CacheControlHeaderValues;
