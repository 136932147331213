import { BaseException } from 'utils/error';

/**
 * Exception subclass for /options endpoint.
 */
export class OptionsException extends BaseException {
	/**
	 * @param {{
	 * success: Boolean,
	 * status: Number,
	 * errors: Object[]
	 * }} response
	 */
	constructor(response) {
		super(response);
		this.name = 'OptionsException';
	}
}
