/* eslint-disable max-lines */
import Vue from 'vue';
import Router from 'vue-router';
import { trackPageChange } from 'utils/live-chat';
import ROUTE_NAMES from './route-names';
import utils from '../utils';

import { connectionsRoutes } from './routes/connections';
import { leadScoringRoutes } from './routes/leadscoring';
import { webTrackingRoutes } from './routes/web-tracking';

const { page } = require('utils/analytics');

Vue.use(Router);

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	/**
	 * @return {{ x: Number, y: Number }}
	 */
	scrollBehavior: () => ({ x: 0, y: 0 }), // eslint-disable-line id-length
	routes: [
		{
			path: '/',
			name: ROUTE_NAMES.HOME,
			redirect: {
				name: ROUTE_NAMES.DASHBOARD
			}
		},

		/**
		 * Log in using credentials or Oauth service.
		 */
		{
			path: '/login',
			name: ROUTE_NAMES.LOGIN,
			/** @return {Object} */
			component: () => import('../pages/Login.vue'),
			meta: {
				title: 'Log in',
				public: true
			}
		},
		/**
		 * Get redirected here when coming back from Oauth.
		 */
		{
			path: '/oauth/:provider',
			name: ROUTE_NAMES.OAUTH,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/Oauth.vue'),
			meta: {
				public: true
			}
		},
		/**
		 * Create new Outfunnel account.
		 */
		{
			path: '/register',
			name: ROUTE_NAMES.REGISTER,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/Register.vue'),
			meta: {
				title: 'Try Outfunnel',
				public: true
			}
		},
		/**
		 * Start the Password Reset flow. Enter your email address to receive Password Reset email.
		 */
		{
			path: '/reset-password',
			name: ROUTE_NAMES.RESET_PASSWORD,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/ResetPasswordStart.vue'),
			meta: {
				title: 'Reset password',
				public: true
			}
		},
		/**
		 * Click the link in the email and open this page to enter your new password.
		 */
		{
			path: '/reset-password/create',
			name: ROUTE_NAMES.RESET_PASSWORD_CREATE,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/ResetPasswordCreate.vue'),
			meta: {
				title: 'Create new password',
				public: true
			}
		},
		/**
		 * Start the passwordless login flow.
		 * Enter your email address to receive passwordless login email.
		 */
		{
			path: '/passwordless-login',
			name: ROUTE_NAMES.PASSWORDLESS_LOGIN,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/PasswordlessLogin.vue'),
			meta: {
				title: 'Passwordless login',
				public: true
			}
		},
		/**
		 * Click the link in the email and open this page to log in.
		 */
		{
			path: '/passwordless-login/verify',
			name: ROUTE_NAMES.PASSWORDLESS_LOGIN_VERIFY,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/PasswordlessLoginVerify.vue'),
			meta: {
				title: 'Login',
				public: true
			}
		},
		{
			path: '/email-verification',
			name: ROUTE_NAMES.EMAIL_VERIFICATION,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/EmailVerification.vue'),
			meta: {
				title: 'Verify your email',
				public: true
			}
		},
		{
			path: '/install-wix',
			name: ROUTE_NAMES.INSTALL_WIX,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/InstallWix.vue'),
			meta: {
				title: 'Install wix',
				public: true
			}
		},

		{
			path: '/dashboard',
			name: ROUTE_NAMES.DASHBOARD,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/dashboard/Dashboard.vue'),
			meta: {
				title: 'Dashboard'
			}
		},

		// Campaigns

		{
			path: '/campaigns',
			name: ROUTE_NAMES.CAMPAIGNS,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/Campaign.vue'),
			meta: {
				title: 'Campaigns'
			}
		},

		{
			path: '/campaigns/dashboard',
			name: ROUTE_NAMES.CAMPAIGNS_DASHBOARD,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignDashboard.vue'),
			meta: {
				title: 'Campaigns dashboard'
			}
		},

		// Campaigns - Single Email

		{
			path: '/campaigns/single-email',
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSingleEmailList.vue'),
			meta: {
				title: 'Campaigns'
			}
		},
		{
			path: '/campaigns/single-email/create',
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_CREATE,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSingleEmailBasics.vue'),
			meta: {
				title: 'Create campaign'
			}
		},
		{
			path: '/campaigns/single-email/:id/basics',
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_BASICS,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSingleEmailBasics.vue'),
			meta: {
				title: 'Campaign basics'
			}
		},
		{
			path: '/campaigns/single-email/:id/compose',
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_COMPOSE,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSingleEmailCompose.vue'),
			meta: {
				title: 'Compose email'
			}
		},
		{
			path: '/campaigns/single-email/:id/review',
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_REVIEW,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSingleEmailReview.vue'),
			meta: {
				title: 'Review campaign'
			}
		},
		{
			path: '/campaigns/single-email/:id/report',
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_REPORT,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSingleEmailReport.vue'),
			meta: {
				title: 'Campaign report'
			}
		},
		{
			path: '/campaigns/single-email/:id/preview',
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_PREVIEW,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSingleEmailPreview.vue'),
			meta: {
				title: 'Campaign preview'
			}
		},

		// Campaigns - Sequence

		{
			path: '/campaigns/sequence',
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSequenceList.vue'),
			meta: {
				title: 'Sequences'
			}
		},
		{
			path: '/campaigns/sequence/create',
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_CREATE,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSequenceEmails.vue'),
			meta: {
				title: 'Create sequence'
			}
		},
		{
			path: '/campaigns/sequence/:id/emails',
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_EMAILS,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSequenceEmails.vue'),
			meta: {
				title: 'Edit sequence'
			}
		},
		{
			path: '/campaigns/sequence/:id/report',
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_REPORT,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSequenceReport.vue'),
			meta: {
				title: 'Sequence report'
			}
		},
		{
			path: '/campaigns/sequence/:id/emails/create',
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_EMAILS_CREATE,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSequenceEmailsEdit.vue'),
			meta: {
				title: 'Add email to sequence'
			}
		},
		{
			path: '/campaigns/sequence/:id/emails/:emailId',
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_EMAILS_EDIT,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSequenceEmailsEdit.vue'),
			meta: {
				title: 'Edit email'
			}
		},
		{
			path: '/campaigns/sequence/:sequenceId/emails/:emailId/report',
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_EMAILS_REPORT,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSequenceEmailReport.vue'),
			meta: {
				title: 'Sequence email report'
			}
		},
		{
			path: '/campaigns/sequence/:sequenceId/emails/:emailId/preview',
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_EMAILS_PREVIEW,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSequenceEmailPreview.vue'),
			meta: {
				title: 'Sequence email preview'
			}
		},
		{
			path: '/campaigns/sequence/:id/settings',
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_SETTINGS,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/CampaignSequenceSettings.vue'),
			meta: {
				title: 'Change settings'
			}
		},

		// Contacts

		{
			path: '/contacts',
			name: ROUTE_NAMES.CONTACTS,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/PersonList.vue'),
			meta: {
				title: 'Contacts'
			}
		},
		{
			path: '/contacts/:id',
			name: ROUTE_NAMES.CONTACTS_PROFILE,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/PersonProfile.vue'),
			meta: {
				title: 'Contact details'
			}
		},
		{
			path: '/segments/create',
			name: ROUTE_NAMES.SEGMENTS_CREATE,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/SegmentEdit.vue'),
			meta: {
				title: 'Create segment'
			}
		},
		{
			path: '/segments/:id',
			name: ROUTE_NAMES.SEGMENTS_EDIT,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/SegmentEdit.vue'),
			meta: {
				title: 'Edit segment'
			}
		},

		{
			path: '/account',
			redirect: {
				name: ROUTE_NAMES.ACCOUNT_PROFILE
			}
		},
		{
			path: '/account/profile',
			name: ROUTE_NAMES.ACCOUNT_PROFILE,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/AccountProfile.vue'),
			meta: {
				title: 'Profile - Account'
			}
		},
		{
			path: '/account/billing',
			name: ROUTE_NAMES.ACCOUNT_BILLING,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/AccountBilling.vue'),
			meta: {
				title: 'Billing - Account'
			}
		},
		{
			path: '/account/usage',
			name: ROUTE_NAMES.ACCOUNT_USAGE,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/AccountUsage.vue'),
			meta: {
				title: 'Usage - Account'
			}
		},
		{
			path: '/account/billing/payments',
			name: ROUTE_NAMES.ACCOUNT_BILLING_PAYMENTS,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/AccountBillingPayments.vue'),
			meta: {
				title: 'Add payment method'
			}
		},
		{
			path: '/integrations',
			name: ROUTE_NAMES.INTEGRATIONS,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/account/integrations/AccountIntegrations.vue'),
			meta: {
				title: 'Integrations'
			}
		},
		{
			path: '/account/fields',
			name: ROUTE_NAMES.ACCOUNT_FIELDS,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/AccountFields.vue'),
			meta: {
				title: 'Fields - Account'
			}
		},
		{
			path: '/campaigns/email-settings/sending-schedule',
			name: ROUTE_NAMES.EMAIL_SETTINGS,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/AccountEmailSettingsSendingSchedule.vue'),
			meta: {
				title: 'Sending Schedule - Email settings'
			}
		},
		{
			path: '/campaigns/email-settings/reply-tracking',
			name: ROUTE_NAMES.EMAIL_SETTINGS_REPLY_TRACKING,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/AccountEmailSettingsReplyTracking.vue'),
			meta: {
				title: 'Reply tracking - Email settings'
			}
		},
		{
			path: '/campaigns/email-settings/domains',
			name: ROUTE_NAMES.EMAIL_SETTINGS_DOMAINS,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('pages/AccountEmailSettingsDomainList.vue'),
			meta: {
				title: 'Verified domains - Email settings'
			}
		},
		{
			path: '/campaigns/email-settings/domains/:id',
			name: ROUTE_NAMES.EMAIL_SETTINGS_DOMAINS_EDIT,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('pages/AccountEmailSettingsDomain.vue'),
			meta: {
				title: 'Verified domains - Email settings'
			}
		},
		{
			path: '/campaigns/email-settings/footer',
			name: ROUTE_NAMES.EMAIL_SETTINGS_FOOTER,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/AccountEmailSettingsFooter.vue'),
			meta: {
				title: 'Footer - Email settings'
			}
		},
		{
			path: '/campaigns/email-settings/email-health',
			name: ROUTE_NAMES.EMAIL_SETTINGS_EMAIL_HEALTH,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/AccountEmailSettingsEmailHealth.vue'),
			meta: {
				title: 'Email health - Email settings'
			}
		},
		{
			path: '/campaigns/email-settings/engagement-recording',
			name: ROUTE_NAMES.EMAIL_SETTINGS_ENGAGEMENT_RECORDING,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/AccountEmailSettingsEngagementRecording.vue'),
			meta: {
				title: 'Engagement recording - Email settings'
			}
		},
		{
			path: '/account/join',
			redirect: {
				name: ROUTE_NAMES.REGISTER,
				params: { iframe: true }
			}
		},
		{
			path: '/account/login',
			redirect: {
				name: ROUTE_NAMES.LOGIN
			}
		},
		{
			path: '/404',
			name: ROUTE_NAMES.ERROR_404,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/NotFound.vue'),
			meta: {
				title: 'Page not found',
				public: true
			}
		},
		{
			path: '/500',
			name: ROUTE_NAMES.ERROR_500,
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/ServerError.vue'),
			meta: {
				title: 'Something went wrong',
				public: true
			}
		},
		{
			path: '*',
			/**
			 * @return {Promise<Object>}
			 */
			component: () => import('../pages/NotFound.vue'),
			meta: {
				title: 'Page not found',
				public: true
			}
		},
		...connectionsRoutes,
		...leadScoringRoutes,
		...webTrackingRoutes
	]
});

router.afterEach(to => {
	page({ routeName: to.name });

	const email = router?.app?.$store?.state?.user?.profile?.email ?? '';

	trackPageChange({ email });
});

router.beforeResolve((to, from, next) => {
	const { name, meta } = to;
	const { token } = router.app.$store.state;

	if (!meta.public && !token) {
		next('/login');
	} else if (name === ROUTE_NAMES.LOGIN && token) {
		next({ name: ROUTE_NAMES.HOME, query: to.query });
	} else {
		if (meta.title) {
			utils.setPageTitle(`${meta.title} | Outfunnel`);
		}

		next();
	}
});

export default router;
