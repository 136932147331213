//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_SAVE = 'save';
const EVENT_CLOSE = 'close';
const EVENT_CHANGE_AUTH_DATA = 'change:auth-data';

export default {
	props: {
		isVisible: {
			type: Boolean,
			default: false
		},
		dialogTitle: {
			type: String,
			required: true
		},
		firstField: {
			type: Object,
			default: null
		},
		secondField: {
			type: Object,
			default: null
		},
		thirdField: {
			type: Object,
			default: null
		},
		authData: {
			type: Object,
			default: () => ({})
		},
		errors: {
			type: Array,
			default: () => ([])
		}
	},

	computed: {
		firstFieldValue() {
			return this.authData[this.firstField?.id];
		},
		secondFieldValue() {
			return this.authData[this.secondField?.id];
		},
		thirdFieldValue() {
			return this.authData[this.thirdField?.id];
		}
	},

	methods: {
		onSave() {
			this.$emit(EVENT_SAVE);
		},

		onClose() {
			this.$emit(EVENT_CLOSE);
		},

		onChangeFirstFieldValue(value) {
			this.$emit(EVENT_CHANGE_AUTH_DATA, { [this.firstField.id]: value });
		},

		onChangeSecondFieldValue(value) {
			this.$emit(EVENT_CHANGE_AUTH_DATA, { [this.secondField.id]: value });
		},

		onChangeThirdFieldValue(value) {
			this.$emit(EVENT_CHANGE_AUTH_DATA, { [this.thirdField.id]: value });
		},

		isFirstFieldValid() {
			return !this.firstField || this.firstFieldValue;
		},
		isSecondFieldValid() {
			return !this.secondField || this.secondFieldValue;
		},
		isThirdFieldValid() {
			return !this.thirdField || this.thirdFieldValue;
		},

		isPrimaryButtonDisabled() {
			return !(this.isFirstFieldValid() && this.isSecondFieldValid() && this.isThirdFieldValid());
		}
	}
};

