/** Get Mailchimp to Pipedrive automation's options. */
export const GET_OPTIONS = 'GET_OPTIONS';

/** Get Mailchimp to Pipedrive automation from backend. */
export const GET_AUTOMATION = 'GET_AUTOMATION';

/** Put changes to a Mailchimp to Pipedrive automation. */
export const PUT_AUTOMATION = 'PUT_AUTOMATION';

/** Create a new Mailchimp to Pipedrive automation. */
export const POST_AUTOMATION = 'POST_AUTOMATION';

/** Trigger sync and set automation as active. */
export const SCHEDULE_SYNC = 'SCHEDULE_SYNC';

/** Schedule sync for automation. */
export const POST_SCHEDULE_SYNC = 'POST_SCHEDULE_SYNC';

/** Delete the automation. */
export const DELETE = 'DELETE';

/** Turn an option on or off. */
export const CHANGE_OPTION = 'CHANGE_OPTION';

/** Select Mailchimp list. */
export const SELECT_LIST = 'SELECT_LIST';

/** Select Pipedrive's Pipeline. */
export const SELECT_PIPELINE = 'SELECT_PIPELINE';

/** Select Pipedrive's Pipeline's Stage. */
export const SELECT_STAGE = 'SELECT_STAGE';

/** Select deal owner for deals created when email is opened. */
export const SELECT_DEAL_OPEN_OWNER = 'SELECT_DEAL_OPEN_OWNER';

/** Select deal owner for deals created when a link in email is clicked. */
export const SELECT_DEAL_CLICK_OWNER = 'SELECT_DEAL_CLICK_OWNER';

/** Select Pipedrive Contact owner for contacts created from new subscribers. */
export const SELECT_MEMBER_SUBSCRIBE_OWNER = 'SELECT_MEMBER_SUBSCRIBE_OWNER';

/** Select Pipedrive contact's field to be updated when user unsubscribes on Mailchimp. */
export const SELECT_UNSUBSCRIBE_FIELD = 'SELECT_UNSUBSCRIBE_FIELD';

/** Select the value to be set for the Pipedrive contact field, when user unsubscribes. */
export const SELECT_UNSUBSCRIBE_FIELD_OPTION = 'SELECT_UNSUBSCRIBE_FIELD_OPTION';
