import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { FieldsException } from './helpers';

/**
 * @param {Object} params
 * @param {Date} params.source
 * @return {String}
 */
const getUserContactFieldsUrl = ({ source }) => {
	const url = new URL(endpoints.userSettings.leadScoringOptionsContactsFields);

	url.searchParams.append('source', source);

	return url.toString();
};

/**
 * @param {Object} params
 * @param {Date} params.source
 * @param {Date} params.token
 * @return {Promise<Object>}
 */
const httpGet = async ({ token, source }) => {
	const result = await request.get(getUserContactFieldsUrl({ source }), token);

	if (!result.success) {
		return Promise.reject(new FieldsException(result));
	}

	return result.data;
};

export {
	httpGet
};
