//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import AutomationStatus from './AutomationStatus.vue';
import IntegrationIcon from './IntegrationIcon.vue';
import { MODE } from '../store/modules/automations/types';

export default {
	components: {
		AutomationStatus,
		IntegrationIcon
	},

	props: {
		createText: {
			type: String,
			required: true
		},
		sourceIntegration: {
			type: String,
			required: true
		},
		sourceName: {
			type: String,
			required: true
		},
		destinationIntegration: {
			type: String,
			required: true
		},
		destinationName: {
			type: String,
			required: true
		},
		mode: {
			type: String,
			required: true
		},
		status: {
			type: Object,
			required: true
		}
	},

	computed: {
		isCreateMode() {
			return (this.mode === MODE.CREATE);
		},

		isEditMode() {
			return (this.mode === MODE.EDIT);
		}
	},

	methods: {
		scheduleSync() {
			this.$emit('schedule-sync');
		},

		deleteAutomation() {
			this.$emit('delete-automation');
		},

		backToAutomations() {
			this.$emit('back-to-automations');
		}
	}
};
