import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { SequenceEmailCampaignsException } from './helpers';

/**
 * Put changes for a Sequence Email Campaign.
 * @param {String} token - User's JWT authorization token.
 * @param {String} sequenceId - ID of the Sequence to be updated.
 * @param {Object} sequenceData - Updated fields of the Sequence.
 */
async function httpPut(token, sequenceId, sequenceData) {
	const url = endpoints.sequenceEmailCampaignsId(sequenceId);
	const result = await request.put(url, sequenceData, token);

	if (!result.success) {
		return Promise.reject(new SequenceEmailCampaignsException(result));
	}

	return result.data;
}

export {
	httpPut
};
