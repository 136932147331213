export const SET_MODE = 'SET_MODE';
export const SET_STATUS = 'SET_STATUS';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SET_AUTOMATION = 'SET_AUTOMATION';
export const RESET_STATE = 'RESET_STATE';

export const SET_LIST = 'SET_LIST';
export const SET_OPTION = 'SET_OPTION';
export const SET_PIPELINE = 'SET_PIPELINE';
export const SET_UNSUBSCRIBE_FIELD = 'SET_UNSUBSCRIBE_FIELD';
export const SET_UNSUBSCRIBE_FIELD_OPTION = 'SET_UNSUBSCRIBE_FIELD_OPTION';
export const SET_DEAL_OPEN_OWNER = 'SET_DEAL_OPEN_OWNER';
export const SET_DEAL_CLICKED_OWNER = 'SET_DEAL_CLICK_OWNER';
