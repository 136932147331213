import utils from 'utils';

/**
 * Sequence field names.
 * @type {{
 * OPEN_STOP_CONDITION: String,
 * REPLY_STOP_CONDITION: String,
 * CLICK_STOP_CONDITION: String
 * }}
 */
const FieldNames = Object.freeze({
	OPEN_STOP_CONDITION: 'openStopCondition',
	REPLY_STOP_CONDITION: 'replyStopCondition',
	CLICK_STOP_CONDITION: 'clickStopCondition',
	FROM_OWNER: 'fromOwner'
});

export default {
	/**
	 * Get filter name.
	 * @param {Object} state
	 * @param {Object} state.sequence
	 */
	filterName({ sequence }) {
		if (sequence.filterData) {
			return sequence.filterData.name;
		}

		return '';
	},

	/**
	 * Get all available CRM filters.
	 * @param {Object} state - Sequences editor module state.
	 * @param {Object} state.sequenceOptions
	 * @return {Array} Options - array of CRM filters.
	 */
	filterOptions({ sequenceOptions }) {
		let filterOptions = [];

		if (sequenceOptions && sequenceOptions.filter) {
			filterOptions = sequenceOptions.filter.options;
		}

		return filterOptions;
	},

	/**
	 * Get filters of type 'contact' from available CRM filters.
	 * @param {Object} state - Sequence email campaign module state.
	 * @param {Array} state.sequenceOptions - Sequence email campaign form options.
	 * @return {Array} Options - array of 'contact' type CRM filters.
	 */
	contactTypeFilters({ sequenceOptions }) {
		let filters = [];

		if (sequenceOptions && sequenceOptions.filter) {
			filters = sequenceOptions.filter.options.filter(({ type }) => type === 'contact');
		}

		return filters;
	},

	/**
	 * Get filters of type 'lead' from available CRM filters.
	 * @param {Object} state - Sequence email campaign module state.
	 * @param {Array} state.sequenceOptions - Sequence email campaign form options.
	 * @return {Array} Options - array of 'lead' type CRM filters.
	 */
	leadStatusFilters({ sequenceOptions }) {
		let filters = [];

		if (sequenceOptions && sequenceOptions.filter) {
			filters = sequenceOptions.filter.options.filter(({ type }) => type === 'lead');
		}

		return filters;
	},

	/**
	 * Get filters of type 'tag' from available CRM filters.
	 * @param {Object} state - Sequence email campaign module state.
	 * @param {Array} state.sequenceOptions - Sequence email campaign form options.
	 * @return {Array} Options - array of 'tag' type CRM filters.
	 */
	tagFilters({ sequenceOptions }) {
		let filters = [];

		if (sequenceOptions && sequenceOptions.filter) {
			filters = sequenceOptions.filter.options.filter(({ type }) => type === 'tag');
		}

		return filters;
	},

	/**
	 * Check if sequence can be activated.
	 * @param {Object} state
	 * @param {Array} state.sequenceEmails
	 * @param {import('vuex').GetterTree} getters
	 * @param {Object} rootState
	 * @param {import('vuex').GetterTree} rootGetters
	 */
	isActivationEnabled({ sequenceEmails }, getters, rootState, rootGetters) {
		const isCrmConnected = rootGetters['user/isCrmConnected'];
		const areSequenceEmailsCreated = Boolean(sequenceEmails.length);
		const areAllSequenceEmailsValid = sequenceEmails.every(email => email.isValid);

		return (
			isCrmConnected
			&& areSequenceEmailsCreated
			&& areAllSequenceEmailsValid
		);
	},

	/**
	 * Get events configured to record notes.
	 * @param {Object} state
	 * @param {Array} state.emailEngagementSettings
	 */
	noteEngagementEvents({ emailEngagementSettings }) {
		const keys = ['sentNote', 'openNote', 'clickNote', 'bounceNote', 'unsubscribeNote'];

		return Object.entries(emailEngagementSettings)
			.filter(([key, value]) => value && keys.includes(key))
			.map(([key]) => key.replace('Note', ''));
	},

	/**
	 * Get events configured to record activities.
	 * @param {Object} state
	 * @param {Object} state.emailEngagementSettings
	 */
	activityEngagementEvents({ emailEngagementSettings }) {
		const keys = [
			'sentActivity',
			'openActivity',
			'clickActivity',
			'bounceActivity',
			'unsubscribeActivity',
			'replyActivity'
		];

		return Object.entries(emailEngagementSettings)
			.filter(([key, value]) => value && keys.includes(key))
			.map(([key]) => key.replace('Activity', ''));
	},

	emailScheduleDays({ emailScheduleSettings }) {
		const dayLabels = {
			monday: 'Mon',
			tuesday: 'Tue',
			wednesday: 'Wed',
			thursday: 'Thu',
			friday: 'Fri',
			saturday: 'Sat',
			sunday: 'Sun'
		};
		const keys = Object.keys(dayLabels);

		return Object.entries(emailScheduleSettings)
			.filter(([key, value]) => value && keys.includes(key))
			.map(([key]) => dayLabels[key]);
	},

	emailScheduleHours({ emailScheduleSettings, emailScheduleSettingsOptions }) {
		const { startHours, endHours } = emailScheduleSettings;
		const {
			startHours: startHoursOptions,
			endHours: endHoursOptions
		} = emailScheduleSettingsOptions;
		let startHoursText = `${startHours}:00`;
		let endHoursText = `${endHours}:00`;

		if (startHoursOptions) {
			const startHoursOption = startHoursOptions.options
				.find(option => option.value === startHours);

			if (startHoursOption) {
				startHoursText = startHoursOption.text;
			}
		}

		if (endHoursOptions) {
			const endHoursOption = endHoursOptions.options
				.find(option => option.value === endHours);

			if (endHoursOption) {
				endHoursText = endHoursOption.text;
			}
		}

		return `${startHoursText} - ${endHoursText}`;
	},

	/**
	 * Get open stop condtion value.
	 * @param {Object} state
	 * @param {Object} state.sequenceOptions
	 * @param {Object} state.sequence
	 * @return {Boolean}
	 */
	openStopConditionValue({ sequenceOptions, sequence }) {
		return utils.getValueWithFallback({
			object: sequence,
			options: sequenceOptions,
			field: FieldNames.OPEN_STOP_CONDITION
		});
	},

	/**
	 * Get click stop condtion value.
	 * @param {Object} state
	 * @param {Object} state.sequenceOptions
	 * @param {Object} state.sequence
	 * @return {Boolean}
	 */
	clickStopConditionValue({ sequenceOptions, sequence }) {
		return utils.getValueWithFallback({
			object: sequence,
			options: sequenceOptions,
			field: FieldNames.CLICK_STOP_CONDITION
		});
	},

	/**
	 * Get reply stop condtion value.
	 * @param {Object} state
	 * @param {Object} state.sequenceOptions
	 * @param {Object} state.sequence
	 * @return {Boolean}
	 */
	replyStopConditionValue({ sequenceOptions, sequence }) {
		return utils.getValueWithFallback({
			object: sequence,
			options: sequenceOptions,
			field: FieldNames.REPLY_STOP_CONDITION
		});
	},

	/**
	 * Get from owner value.
	 * @param {Object} state
	 * @param {Object} state.sequenceOptions
	 * @param {Object} state.sequence
	 * @return {Boolean}
	 */
	fromOwnerValue({ sequenceOptions, sequence }) {
		return utils.getValueWithFallback({
			object: sequence,
			options: sequenceOptions,
			field: FieldNames.FROM_OWNER
		});
	},

	segmentTypeOptions({ sequenceOptions }) {
		if (!sequenceOptions || !sequenceOptions.segmentTypes) {
			return [];
		}

		return sequenceOptions.segmentTypes.options;
	}
};
