import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { UserException } from './helpers';

/**
 * Put changes to user profile data.
 * @param {String} token - JWT Authorization token.
 * @param {Object} user - User profile data.
 */
async function httpPut(token, user) {
	const result = await request.put(endpoints.user, user, token);

	if (!result.success) {
		return Promise.reject(new UserException(result));
	}

	return result.data;
}

export {
	httpPut
};
