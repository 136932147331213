import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { SegmentsException } from './helpers';

/**
 * Send GET request to /segments endpoint.
 * @param {String} token JWT token.
 * @param {String=} segmentId Segment id.
 * @return {Promise<{Object}>}
 */
export const httpGet = async (token, segmentId) => {
	let url = endpoints.segments;

	if (segmentId) {
		url = endpoints.segmentsId(segmentId);
	}

	const response = await request.get(url, token);

	if (!response.success) {
		return Promise.reject(new SegmentsException(response));
	}

	return response.data;
};
