//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
		title: {
			type: String,
			required: true
		},
		learnLink: {
			type: String,
			default: null
		},
		action: {
			type: String,
			required: true
		},
		automationsCount: {
			type: Number,
			required: true
		},
		noAutomations: {
			type: String,
			required: true
		},
		sourceTitle: {
			type: String,
			required: true
		},
		destinationTitle: {
			type: String,
			default: ''
		},
		tooltip: {
			type: String,
			default: null
		},
		isComingSoonLabelVisible: {
			type: Boolean,
			default: false
		},
		isActionDisabled: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		footerText() {
			const automations = (this.automationsCount === 1 ? 'automation' : 'automations');

			return `${this.automationsCount} ${automations}`;
		}
	},

	methods: {
		actionClick() {
			this.$emit('action-click');
		}
	}
};

