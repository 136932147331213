export const MODE = 'MODE';
export const SEQUENCE_ID = 'SEQUENCE_ID';
export const OPTIONS = 'OPTIONS';
export const EMAIL = 'EMAIL';
export const VISIBLE_FIELDS = 'VISIBLE_FIELDS';
export const FIELDS = 'FIELDS';
export const DIALOG_EDITOR_HTML_VISIBILITY = 'DIALOG_EDITOR_HTML_VISIBILITY';
export const DIALOG_CUSTOM_HTML_VISIBILITY = 'DIALOG_CUSTOM_HTML_VISIBILITY';
export const GENERAL_ERRORS = 'GENERAL_ERRORS';
export const FIELD_ERRORS = 'FIELD_ERRORS';
export const FIELD_ERRORS_CLEAR = 'FIELD_ERRORS_CLEAR';
export const RESET = 'RESET';
export const REPLACE_MERGE_FIELDS = 'REPLACE_MERGE_FIELDS';
export const IMAGE = 'IMAGE';
