/**
 * @constant {{
 * CAMPAIGNS_SEQUENCE_WELCOME_MSG: String,
 * WELCOMEMSG: String,
 * CONTACTS_IMPORTED: String,
 * CAMPAIGNS_SINGLE_EMAIL_WELCOME_MSG: String,
 * PAYMENT_FAILED: String,
 * CAMPAIGNS_WELCOME_MSG: String,
 * TRIAL_ENDING: String,
 * ZENDESK: String,
 * HUBSPOT: String,
 * HUBSPOT_TO_MAILCHIMP: String,
 * MAILCHIMP_TO_HUBSPOT: String
 * }}
 */
const Flags = Object.freeze({
	WELCOMEMSG: 'welcomemsg',
	CAMPAIGNS_WELCOME_MSG: 'campaigns_welcome_msg',
	CAMPAIGNS_SINGLE_EMAIL_WELCOME_MSG: 'campaigns_single_email_welcome_msg',
	CAMPAIGNS_SEQUENCE_WELCOME_MSG: 'campaigns_sequence_welcome_msg',
	WEB_TRACKING_MSG: 'web_tracking_msg',
	EMAIL_DELIVERABILITY_MSG: 'email_deliverability_msg',
	TRIAL_ENDING: 'trial_ending',
	PAYMENT_FAILED: 'payment_failed',
	CONTACTS_IMPORTED: 'contacts_imported',
	ZENDESK: 'zendesk',
	HUBSPOT: 'hubspot',
	HUBSPOT_TO_MAILCHIMP: 'hubspot_to_mailchimp',
	MAILCHIMP_TO_HUBSPOT: 'mailchimp_to_hubspot'
});

/**
 * @param {Object} params
 * @param {Object} params.getters
 */
const isAppConnectorRouteVisible = ({ getters }) => getters.isConnectorEnabled;

/**
 * @param {Object} params
 * @param {Object} params.getters
 */
const isCampaignsRouteVisible = ({ getters }) => {
	if (getters.isAirtableConnected) {
		return false;
	}

	if (!getters.areSingleEmailCampaignsEnabled && !getters.areSequenceCampaignsEnabled) {
		return false;
	}

	return true;
};

/**
 * @param {Object} params
 * @param {Object} params.getters
 */
const isContactsRouteVisible = ({ getters }) => {
	if (getters.isContactsPageDisabled) {
		return false;
	}

	return true;
};

/**
 * @param {Object} params
 * @param {Object} params.getters
 */
// eslint-disable-next-line sonarjs/no-identical-functions
const isEmailSettingVisible = ({ getters }) => {
	if (getters.isAirtableConnected) {
		return false;
	}

	if (!getters.areSingleEmailCampaignsEnabled && !getters.areSequenceCampaignsEnabled) {
		return false;
	}

	return true;
};

/**
 * @param {Object} params
 * @param {Object} params.getters
 */
// eslint-disable-next-line sonarjs/no-identical-functions
const isLeadScoringRouteVisible = ({ getters }) => {
	if (getters.isAirtableConnected) {
		return false;
	}

	return true;
};

export {
	Flags,
	isAppConnectorRouteVisible,
	isCampaignsRouteVisible,
	isEmailSettingVisible,
	isLeadScoringRouteVisible,
	isContactsRouteVisible
};
