import mutations from './mutations';
import actions from './actions';
import getInitialState from './state';

import profile from './profile';

export default {
	namespaced: true,
	state: getInitialState(),
	mutations,
	actions,
	modules: {
		profile
	}
};
