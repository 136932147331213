const TOKEN = 'of.token';
const IS_LOGGED_IN_AS_USER = 'of.isLoggedInAsUser';
const SIGNUP_PLAN = 'of.signupPlan';
const LEAD_SCORE_INSTRUCTION_BOX_STATE = 'of.leadScoreInstructionBoxState';
const WEB_TRACKING_INSTRUCTION_BOX_STATE = 'of.webTrackingInstructionBoxState';

/**
 * Save user's authorization token.
 * @param {String} token
 */
export function setToken(token) {
	window.localStorage.setItem(TOKEN, token);
}

/**
 * Get currently stored authorization token.
 * @return {String}
 */
export function getToken() {
	return window.localStorage.getItem(TOKEN);
}

/**
 * Remove authorization token.
 */
export function removeToken() {
	window.localStorage.removeItem(TOKEN);
}

/**
 * Save info that user is logged in as another user.
 * @param {Boolean} value
 */
export function setIsLoggedInAsUser(value) {
	window.localStorage.setItem(IS_LOGGED_IN_AS_USER, Boolean(value));
}

/**
 * Get info whether user is logged in as another user.
 * @return {Boolean}
 */
export function getIsLoggedInAsUser() {
	return Boolean(window.localStorage.getItem(IS_LOGGED_IN_AS_USER));
}

/**
 * @return {String}
 */
export function getLeadScoreInstructionBoxState() {
	return window.localStorage.getItem(LEAD_SCORE_INSTRUCTION_BOX_STATE);
}

/**
 * @param {String} value
 */
export function setLeadScoreInstructionBoxState(value) {
	window.localStorage.setItem(LEAD_SCORE_INSTRUCTION_BOX_STATE, value);
}

/**
 * @param {String} state
 * @return {void}
 */
export function setWebTrackingInstructionBoxState(state) {
	window.localStorage.setItem(WEB_TRACKING_INSTRUCTION_BOX_STATE, state);
}

/**
 * @return {String}
 */
export function getWebTrackingInstructionBoxState() {
	return window.localStorage.getItem(WEB_TRACKING_INSTRUCTION_BOX_STATE);
}

/**
 * Remove info that user is logged in as another user.
 */
export function removeIsLoggedInAsUser() {
	window.localStorage.removeItem(IS_LOGGED_IN_AS_USER);
}

/**
 * Save signupPlan to local storage.
 * @param {String} signupPlan
 */
export function setSignupPlan(signupPlan) {
	window.localStorage.setItem(SIGNUP_PLAN, signupPlan);
}

/**
 * Get signup plan from local storage.
 * @return {String}
 */
export function getSignupPlan() {
	return window.localStorage.getItem(SIGNUP_PLAN);
}
