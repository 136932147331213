//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CampaignSplashImg from 'images/campaign-splash-img.svg';

/**
 * @constant {String}
 */
const EVENT_NAVIGATE_BILLING = 'navigate:billing';

/**
 * @constant {String}
 */
const EVENT_PLAN_UPGRADE = 'plan:upgrade';

export default {
	components: {
		CampaignSplashImg
	},

	props: {
		isTrialActive: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		learnMoreLink: 'https://outfunnel.com/marketing-automation/'
	}),

	methods: {
		onNavigateBilling() {
			this.$emit(EVENT_NAVIGATE_BILLING);
		},

		onPlanUpgrade() {
			this.$emit(EVENT_PLAN_UPGRADE);
		}
	}
};
