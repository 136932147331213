import { api } from 'api';
import logger from 'utils/logger';

import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import {
	OPTIONS_GET,
	SETTINGS_GET,
	FIELDS_EDIT
} from './actionTypes';
import {
	OPTIONS,
	SETTINGS,
	FIELDS,
	ERRORS
} from './mutationTypes';

export default {
	[OPTIONS_GET]: async ({ commit, rootState: { token } }) => {
		try {
			const options = await api.user.settings.email.schedule.options.get(token);

			commit(OPTIONS, options);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[SETTINGS_GET]: async ({ commit, rootState: { token } }) => {
		try {
			const settings = await api.user.settings.email.schedule.get(token);

			commit(SETTINGS, settings);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [FIELDS_EDIT]({
		commit,
		dispatch,
		rootState: { token }
	}, fields) {
		commit(FIELDS, fields);

		try {
			commit(ERRORS, []);
			const updatedSettings = await api.user.settings.email.schedule.put(
				token, fields
			);

			commit(SETTINGS, updatedSettings);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Settings saved',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
