/* eslint-disable no-console */
import { isString, isInstanceOf } from './types';
import { isDevelopmentEnv } from './env';
import Sentry from './sentry';

export default {
	/**
	 * @param {String} message
	 * @param {Object} data
	 */
	debug(message, data) {
		if (!isDevelopmentEnv()) {
			return;
		}

		if (data) {
			console.info(message, data);
		} else {
			console.info(message);
		}
	},

	/**
	 * @param {String} message
	 */
	info(message) {
		Sentry.captureMessage(message);

		if (isDevelopmentEnv()) {
			console.info(message);
		}
	},

	/**
	 * @param {*} exception
	 * @return {String}
	 */
	error(exception) {
		let error = null;

		if (isString(exception)) {
			error = new Error(exception);
		} else if (isInstanceOf(exception, Error)) {
			error = exception;
		}

		if (isDevelopmentEnv()) {
			console.error(exception);
		}

		return Sentry.captureException(error);
	}
};
