const getInitialState = () => ({
	profile: null,
	usage: {
		outfunnel: {
			emails: {
				count: 0,
				limit: 1
			}
		},
		mailchimp: {
			emails: {
				count: 0,
				limit: 1
			}
		},
		appConnector: {
			count: 0,
			allConnectorEventsCount: {
				allEventsCount: 0,
				allErrorsCount: 0,
				contactSyncEventsCreatedCount: 0,
				contactSyncEventsUpdatedCount: 0,
				emailEngagementEventSentCount: 0,
				emailEngagementEventOpenCount: 0,
				emailEngagementEventClickCount: 0,
				emailEngagementEventBounceCount: 0,
				emailEngagementEventUnsubscribeCount: 0,
				formEventsCount: 0,
				meetingEventsCount: 0
			},
			limit: 1
		}
	},
	isBannerTrialEndingHidden: false,
	views: {
		navigation: {
			appConnector: false,
			appConnectorCreate: false,
			campaigns: false,
			contacts: false,
			web: true,
			leadScoring: true,
			help: true,
			account: true
		},
		settings: {
			profile: true,
			billing: true,
			integrations: true,
			emailSetting: false
		}
	},
	features: null
});

export default getInitialState;
