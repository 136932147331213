import goTo from 'vuetify/lib/services/goto';
import {
	MODE,
	STEP,
	CAMPAIGN,
	EMAIL_ENGAGEMENT_SETTINGS,
	VISIBLE_FIELDS,
	SET_OPTIONS,
	FIELDS,
	FIELD_ERRORS,
	FIELD_ERRORS_CLEAR,
	TEST_EMAILS,
	TEST_EMAILS_ERRORS,
	GENERAL_ERRORS,
	DIALOG_CONFIRMATION_VISIBILITY,
	DIALOG_TEST_EMAIL_VISIBILITY,
	DIALOG_EDITOR_HTML_VISIBILITY,
	DIALOG_CUSTOM_HTML_VISIBILITY,
	CONTACTS_COUNT,
	RESET,
	RECIPIENTS_TYPE,
	SEGMENT_TYPE,
	REPLACE_MERGE_FIELDS,
	DIALOG_SENDING_LIMITS_VISIBILITY,
	IMAGE
} from './mutationTypes';

import getInitialState from './state';

import { getRecipientsType } from './helpers';

export default {
	[MODE](state, mode) {
		state.mode = mode;
	},

	[STEP](state, step) {
		state.step = step;
	},

	[CAMPAIGN](state, campaign) {
		state.campaign = campaign;

		if (campaign.filter) {
			state.campaign.filterData = campaign.filter;
			state.campaign.filter = campaign.filter.id;
			state.recipientsType = getRecipientsType(campaign.filterData);
		}
	},

	[EMAIL_ENGAGEMENT_SETTINGS](state, settings) {
		state.emailEngagementSettings = settings;
	},

	[SET_OPTIONS](state, options) {
		state.options = options;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[VISIBLE_FIELDS](state, fields) {
		state.visibleFields = fields;
	},

	[FIELDS](state, fields) {
		Object.assign(state.campaign, fields);
	},

	[FIELD_ERRORS](state, errors) {
		goTo(0);
		errors.forEach(error => {
			state.fieldErrors[error.context] = error.message;
		});
	},

	[FIELD_ERRORS_CLEAR](state, fields) {
		Object.keys(fields).forEach(fieldKey => {
			state.fieldErrors[fieldKey] = null;
		});
	},

	[CONTACTS_COUNT](state, count) {
		state.contactsCount = count;
	},

	[DIALOG_CONFIRMATION_VISIBILITY](state, isVisible) {
		state.isDialogConfirmationVisible = isVisible;
	},

	[DIALOG_TEST_EMAIL_VISIBILITY](state, isVisible) {
		state.isDialogTestEmailVisible = isVisible;
	},

	[DIALOG_EDITOR_HTML_VISIBILITY](state, isVisible) {
		state.isDialogEditorHtmlVisible = isVisible;
	},

	[DIALOG_CUSTOM_HTML_VISIBILITY](state, isVisible) {
		state.isDialogCustomHtmlVisible = isVisible;
	},

	[DIALOG_SENDING_LIMITS_VISIBILITY](state, isVisible) {
		state.isDialogSendingLimitsVisible = isVisible;
	},

	[TEST_EMAILS](state, emails) {
		state.testEmails = emails;
	},

	[REPLACE_MERGE_FIELDS](state, areMergeFieldsReplaced) {
		state.areMergeFieldsReplaced = areMergeFieldsReplaced;
	},

	[TEST_EMAILS_ERRORS](state, errors) {
		state.testEmailErrors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	},

	[RECIPIENTS_TYPE](state, type) {
		state.recipientsType = type;
	},

	[SEGMENT_TYPE](state, type) {
		state.segmentType = type;
	},

	[IMAGE](state, { uploadId, imageUrl }) {
		state.uploadedImages = {
			...state.uploadedImages,
			[uploadId]: imageUrl
		};
	}
};
