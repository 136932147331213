import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { EmailSettingsReplyTrackingException } from './helpers';

/**
 * @param {String} token
 * @return {Promise<Object>}
 */
export const httpGet = async token => {
	const result = await request.get(endpoints.userSettings.email.replyTracking, token);

	if (!result.success) {
		return Promise.reject(new EmailSettingsReplyTrackingException(result));
	}

	return result.data;
};
