
export const getInitialState = () => ({
	counters: {
		identifiedContacts: 0
	},
	channelReport: null,
	options: [],
	period: null,
	generalErrors: []
});
