import { invoices } from './invoices';
import { plans } from './plans';
import { subscription } from './subscription';
import { paymentMethod } from './payment-method';

export const billing = {
	invoices,
	plans,
	subscription,
	paymentMethod
};
