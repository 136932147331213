//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import AccountBillingEnterprisePlan from './AccountBillingEnterprisePlan.vue';
import AccountBillingScalePlan from './AccountBillingScalePlan.vue';

/**
 * @param {String}
 */
const EVENT_PLAN_PREVIEW = 'plan:preview';

/**
 * @param {String}
 */
const EVENT_PLAN_UPGRADE = 'plan:upgrade';

/**
 * @param {String}
 */
const EVENT_PLAN_DOWNGRADE = 'plan:downgrade';

/**
 * @param {String}
 */
const EVENT_PLAN_SELECT = 'plan:select';

const ACTIVE_CONTACT_PLAN_GROUPS = ['Basic', 'Professional'];

export default {
	components: {
		AccountBillingEnterprisePlan,
		AccountBillingScalePlan
	},

	props: {
		plans: {
			type: Array,
			default: () => ([])
		},
		areActiveContactPricingPlansEnabled: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		selectedPlan() {
			return this.plans.find(plan => plan.selected);
		},

		displayedPlans() {
			if (this.areActiveContactPricingPlansEnabled) {
				const displayedPlans = this.plans
					.filter(plan => plan.is_displayed)
					.filter(plan => ACTIVE_CONTACT_PLAN_GROUPS.includes(plan.group) || plan.selected);

				return displayedPlans.sort((planA, planB) => {
					if (planA.groupRank > planB.groupRank) {
						return 1;
					}

					return -1;
				});
			}

			return this.plans
				.filter(plan => plan.is_displayed)
				.filter(plan => !ACTIVE_CONTACT_PLAN_GROUPS.includes(plan.group) || plan.selected);
		},

		popularPlanGroups() {
			if (this.areActiveContactPricingPlansEnabled) {
				return ['Professional'];
			}

			return ['Growth'];
		}
	},

	methods: {
		tiers(group) {
			return this.plans
				.filter(plan => plan.group === group)
				.map(plan => {
					if (plan.active_contacts) {
						return {
							text: `${plan.active_contacts} active contacts`,
							value: plan._id
						};
					}

					return {
						text: `${plan.events_count} events/month`,
						value: plan._id
					};
				});
		},

		onPreviewPlan(planId) {
			this.$emit(EVENT_PLAN_PREVIEW, planId);
		},

		onUpgradePlan(planId) {
			this.$emit(EVENT_PLAN_UPGRADE, planId);
		},

		onDowngradePlan(planId) {
			this.$emit(EVENT_PLAN_DOWNGRADE, planId);
		},

		onSelectPlan(planId) {
			this.$emit(EVENT_PLAN_SELECT, planId);
		},

		isPopular(plan) {
			if (this.popularPlanGroups.includes(plan.group)) {
				return true;
			}

			return false;
		},

		isPlanUpgradable(plan) {
			if (plan.selected) {
				return false;
			}

			if (!this.areActiveContactPricingPlansEnabled) {
				return this.selectedPlan.events_count < plan.events_count;
			}

			if (this.selectedPlan.groupRank < plan.groupRank) {
				return true;
			}

			if (this.selectedPlan.groupRank === plan.groupRank) {
				return this.selectedPlan.active_contacts < plan.active_contacts;
			}

			return false;
		},

		isPlanDowngradable(plan) {
			if (plan.selected) {
				return false;
			}

			if (!this.areActiveContactPricingPlansEnabled) {
				return this.selectedPlan.events_count > plan.events_count;
			}

			if (this.selectedPlan.groupRank > plan.groupRank) {
				return true;
			}

			if (this.selectedPlan.groupRank === plan.groupRank) {
				return this.selectedPlan.active_contacts > plan.active_contacts;
			}

			return false;
		},

		isPlanSelectable(plan) {
			return !plan.selected && !this.isPlanUpgradable(plan) && !this.isPlanDowngradable(plan);
		},

		formattedPrice(price) {
			return Math.ceil(price);
		},

		isPlanPreviewDisabled(plan) {
			if (this.areActiveContactPricingPlansEnabled && !plan.active_contacts) {
				return true;
			}

			return false;
		}
	}
};

