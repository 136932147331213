export const APPS_GET = 'APPS_GET';
export const BILLING_NAVIGATE = 'BILLING_NAVIGATE';
export const CONNECTIONS_GET = 'CONNECTIONS_GET';
export const CONNECTIONS_SYNC_PROGRESS_UPDATE = 'CONNECTIONS_SYNC_PROGRESS_UPDATE';
export const CONNECTION_EDIT = 'CONNECTION_EDIT';
export const CONNECTION_SHOW_STATUS = 'CONNECTION_SHOW_STATUS';
export const CONNECTION_ACTIVATE = 'CONNECTION_ACTIVATE';
export const CONNECTION_REQUEST_SYNC = 'CONNECTION_REQUEST_SYNC';
export const CONTACT_CREATION_RULES_GET = 'CONTACT_CREATION_RULES_GET';
export const FEATURES_GET = 'FEATURES_GET';
export const UPGRADE_DIALOG_CLOSE = 'UPGRADE_DIALOG_CLOSE';
export const UPGRADE_DIALOG_OPEN = 'UPGRADE_DIALOG_OPEN';
export const CONTACT_SYNC_CONFIRMATION_DIALOG_CLOSE = 'CONTACT_SYNC_CONFIRMATION_DIALOG_CLOSE';
export const CONTACT_SYNC_CONFIRMATION_DIALOG_OPEN = 'CONTACT_SYNC_CONFIRMATION_DIALOG_OPEN';
export const ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_CLOSE = 'ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_CLOSE';
export const ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_OPEN = 'ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_OPEN';
export const SPLIT_CONTACT_SYNC_CONFIRMATION_DIALOG_CLOSE = 'SPLIT_CONTACT_SYNC_CONFIRMATION_DIALOG_CLOSE';
export const SPLIT_CONTACT_SYNC_CONFIRMATION_DIALOG_OPEN = 'SPLIT_CONTACT_SYNC_CONFIRMATION_DIALOG_OPEN';
export const ENGAGEMENT_SYNC_UPGRADE_DIALOG_CLOSE = 'ENGAGEMENT_SYNC_UPGRADE_DIALOG_CLOSE';
export const ENGAGEMENT_SYNC_UPGRADE_DIALOG_OPEN = 'ENGAGEMENT_SYNC_UPGRADE_DIALOG_OPEN';
export const OPTIONS_GET = 'OPTIONS_GET';
export const SHOW_TWO_WAY_SYNC_ERRORS = 'SHOW_TWO_WAY_SYNC_ERRORS';
export const HIDE_TWO_WAY_SYNC_ERRORS = 'HIDE_TWO_WAY_SYNC_ERRORS';
export const SHOW_ENGAGEMENT_SYNC_VALIDATION_ERRORS = 'SHOW_ENGAGEMENT_SYNC_VALIDATION_ERRORS';
export const HIDE_ENGAGEMENT_SYNC_VALIDATION_ERRORS = 'HIDE_ENGAGEMENT_SYNC_VALIDATION_ERRORS';
