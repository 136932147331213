import {
	EMAIL_STATISTICS,
	ERRORS
} from './mutationTypes';

export const mutations = {
	[EMAIL_STATISTICS](state, emailStatistics) {
		state.emailStatistics = emailStatistics;
	},

	[ERRORS](state, errors) {
		state.errors = errors;
	}
};
