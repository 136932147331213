import { getInitialState } from './state';
import { mutations } from './mutations';
import { actions } from './actions';

import { moduleEdit } from './moduleEdit';

export const moduleDomains = {
	namespaced: true,
	state: getInitialState(),
	mutations,
	actions,
	modules: {
		moduleEdit
	}
};
