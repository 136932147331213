import {
	SIGNUP_FORM_VISIBLE,
	NAME_EDIT,
	COMPANY_EDIT,
	EMAIL_EDIT,
	PASSWORD_EDIT,
	TERMS_EDIT,
	TERMS_SET_ERROR,
	TIPS_EDIT,
	NAME_SET_ERROR,
	EMAIL_SET_ERROR,
	PASSWORD_SET_ERROR,
	RESET_STATE,
	SIGNUP_PLAN,
	SIGNUP_COUPON,
	SIGNUP_PLAN_GROUP,
	SIGNUP_PLAN_EVENTS,
	SIGNUP_PLAN_ACTIVE_CONTACTS,
	OAUTH_SIGNUP_BUTTON_VISIBLE,
	PIPEDRIVE_OAUTH_VISIBLE,
	HUBSPOT_OAUTH_VISIBLE,
	GENERAL_ERRORS,
	TERMS_CONFIRMATION_DIALOG_VISIBLE,
	INTEGRATION_TO_CONNECT
} from './mutationTypes';

import getInitialState from './state';

export default {
	[SIGNUP_FORM_VISIBLE](state, isVisible) {
		state.isSignupFormVisible = isVisible;
	},

	[NAME_EDIT](state, name) {
		state.nameField.value = name;
		state.nameField.errorMessage = null;
	},

	[NAME_SET_ERROR](state, { error }) {
		state.nameField.errorMessage = error;
	},

	[EMAIL_EDIT](state, email) {
		state.emailField.errorMessage = null;
		state.emailField.value = email;
	},

	[EMAIL_SET_ERROR](state, { error }) {
		state.emailField.errorMessage = error;
	},

	[COMPANY_EDIT](state, company) {
		state.companyField.value = company;
	},

	[PASSWORD_EDIT](state, password) {
		state.passwordField.errorMessage = null;
		state.passwordField.value = password;
	},

	[PASSWORD_SET_ERROR](state, { error }) {
		state.passwordField.errorMessage = error;
	},

	[TERMS_EDIT](state, termsAgreed) {
		state.termsCheckbox.errorMessage = null;
		state.termsCheckbox.value = termsAgreed;
	},

	[TERMS_SET_ERROR](state, error) {
		state.termsCheckbox.errorMessage = error;
	},

	[TIPS_EDIT](state, tips) {
		state.tipsCheckbox.value = tips;
	},

	[RESET_STATE](state) {
		Object.assign(state, getInitialState());
	},

	[SIGNUP_PLAN](state, signupPlan) {
		state.signupPlan = signupPlan;
	},

	[SIGNUP_PLAN_GROUP](state, signupPlanGroup) {
		state.signupPlanGroup = signupPlanGroup;
	},

	[SIGNUP_PLAN_EVENTS](state, signupPlanEvents) {
		state.signupPlanEvents = Number(signupPlanEvents) || null;
	},

	[SIGNUP_PLAN_ACTIVE_CONTACTS](state, activeContacts) {
		state.signupPlanActiveContacts = Number(activeContacts) || null;
	},

	[SIGNUP_COUPON](state, signupCoupon) {
		state.signupCoupon = signupCoupon;
	},

	[OAUTH_SIGNUP_BUTTON_VISIBLE](state, isVisible) {
		state.isOauthSignupButtonVisible = isVisible;
	},

	[PIPEDRIVE_OAUTH_VISIBLE](state, isVisible) {
		state.isPipedriveOauthVisible = isVisible;
	},

	[HUBSPOT_OAUTH_VISIBLE](state, isVisible) {
		state.isHubspotOauthVisible = isVisible;
	},

	[GENERAL_ERRORS](state, generalErrors) {
		state.generalErrors = generalErrors;
	},

	[TERMS_CONFIRMATION_DIALOG_VISIBLE](state, isVisible) {
		state.isTermsConfirmationDialogVisible = isVisible;
	},

	[INTEGRATION_TO_CONNECT](state, integration) {
		state.integrationToConnect = integration;
	}
};
