import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { SendException } from './helpers';

/**
 * Send out single email campaign.
 * @param {String} token - User's JWT authorization token.
 * @param {String} campaignId - ID of the campaign to send out.
 */
async function httpPost(token, campaignId) {
	const url = endpoints.singleEmailCampaignsIdSend(campaignId);
	const result = await request.post(url, null, token);

	if (!result.success) {
		return Promise.reject(new SendException(result));
	}

	return result.data;
}

export {
	httpPost
};
