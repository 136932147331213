//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
	getSubscriptionText,
	getNextPayment,
	isCancelableSubscriptionStatus
} from 'api/billing/helpers';

const EVENT_CANCEL_SUBSCRIPTION = 'cancel-subscription';
const EVENT_EDIT_PAYMENT_METHOD = 'edit-payment-method';

export default {
	props: {
		subscription: {
			type: Object,
			default: () => ({})
		},
		plans: {
			type: Array,
			default: () => []
		}
	},

	computed: {
		subscriptionText() {
			return getSubscriptionText(this.subscription);
		},

		nextBilling() {
			return getNextPayment(this.subscription);
		},

		isCancelable() {
			const { status, cancelled } = this.subscription;

			return (isCancelableSubscriptionStatus(status) && !cancelled);
		}
	},

	methods: {
		onCancelSubscription() {
			this.$emit(EVENT_CANCEL_SUBSCRIPTION);
		},

		onEditPaymentMethod() {
			this.$emit(EVENT_EDIT_PAYMENT_METHOD);
		}
	}
};

