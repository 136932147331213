import { api } from 'api';
import { TYPES } from 'api/automations';
import utils from 'utils';
import router from 'router';
import logger from 'utils/logger';

import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';

import {
	MODE,
	STATUS,
	AUTOMATION,
	OPTIONS,
	LIST,
	OPTION,
	GENERAL_ERRORS
} from './mutationTypes';

import {
	GET_OPTIONS,
	GET_AUTOMATION,
	PUT_AUTOMATION,
	POST_AUTOMATION,
	SCHEDULE_SYNC,
	POST_SCHEDULE_SYNC,
	DELETE,
	SELECT_LIST,
	CHANGE_OPTION
} from './actionTypes';

import { MODE as PAGE_MODE, STATUS_TYPE } from '../types';

export default {
	[GET_OPTIONS]: async ({
		commit, state, rootState: { token }
	}) => {
		try {
			const options = await api.automations.options.get(token, TYPES.MAILCHIMP_TO_COPPER);

			commit(OPTIONS, options);

			// In create mode, default values need to be populated
			if (state.mode === PAGE_MODE.CREATE) {
				const booleanOptions = Object.entries(options).filter(field => typeof field[1] === 'boolean');

				booleanOptions.forEach(([key, value]) => {
					commit(OPTION, { [key]: value });
				});
			}
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[GET_AUTOMATION]: async ({ commit, rootState: { token } }, id) => {
		try {
			const automation = await api.automations.get(token, TYPES.MAILCHIMP_TO_COPPER, id);

			commit(AUTOMATION, automation);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[PUT_AUTOMATION]: async ({
		dispatch, commit, state: { automation }, rootState: { token }
	}, changes) => {
		commit(STATUS, { type: STATUS_TYPE.SAVING, message: 'Saving' });

		try {
			const updatedAutomation = await api.automations.put(token, automation._id, changes);

			commit(AUTOMATION, updatedAutomation);
			let message = 'Saved';

			if (!automation.active && updatedAutomation.active) {
				message = 'Saved and queued sync';
			} else if (!updatedAutomation.active) {
				message = 'Draft saved';
			}

			if (changes.fieldUnsubscribeTarget && changes.fieldUnsubscribeTarget.field === 0) {
				dispatch(GET_OPTIONS);
			}

			commit(STATUS, { type: STATUS_TYPE.SAVED, message });
		} catch (exception) {
			if (exception.errors) {
				commit(STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to save changes' });

				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[POST_AUTOMATION]: async ({ commit, rootState: { token } }, options) => {
		commit(STATUS, { type: STATUS_TYPE.SAVING, message: 'Saving' });
		const data = {
			type: TYPES.MAILCHIMP_TO_COPPER,
			...options
		};

		try {
			const automation = await api.automations.post(token, data);

			commit(AUTOMATION, automation);
			commit(MODE, PAGE_MODE.EDIT);
			commit(STATUS, { type: STATUS_TYPE.SAVED, message: 'Draft saved' });

			router.replace(automation._id);
		} catch (exception) {
			if (exception.errors) {
				commit(STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to create automation' });

				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[SCHEDULE_SYNC]: async ({ dispatch, state: { automation } }) => {
		if (automation && automation.active) {
			dispatch(POST_SCHEDULE_SYNC);
		} else if (automation) {
			dispatch(PUT_AUTOMATION, { active: true });
		} else {
			dispatch(POST_AUTOMATION, { active: true });
		}

		dispatch(
			`notifications/${SHOW_NOTIFICATION}`,
			'Sync is queued',
			{ root: true }
		);

		await utils.delay(1);
		router.push('/automations');
	},

	[POST_SCHEDULE_SYNC]: async ({ commit, state: { automation }, rootState: { token } }) => {
		commit(STATUS, { type: STATUS_TYPE.SAVING, message: 'Scheduling sync' });

		try {
			const id = automation._id;
			const type = TYPES.MAILCHIMP_TO_COPPER;

			await api.automations.sync.post(token, type, id);

			commit(STATUS, { type: STATUS_TYPE.SAVED, message: 'Sync queued' });
		} catch (exception) {
			if (exception.errors) {
				commit(STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to queue sync' });

				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[DELETE]: async ({ commit, state: { automation }, rootState: { token } }) => {
		try {
			commit(STATUS, { type: STATUS_TYPE.DELETING, message: 'Deleting' });
			await api.automations.delete(token, automation._id);
			router.push('/automations');
		} catch (exception) {
			if (exception.errors) {
				commit(STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to delete automation' });

				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * CHANGE_OPTION action is dispatched when user checks/unchecks a checkbox
	 * in the automation settings. Some settings need to have additional settings with them.
	 */
	[CHANGE_OPTION]: ({ commit, dispatch, state: { mode } }, option) => {
		commit(OPTION, option);
		const automationData = { ...option };

		if (mode === PAGE_MODE.CREATE) {
			dispatch(POST_AUTOMATION, automationData);
		} else {
			dispatch(PUT_AUTOMATION, automationData);
		}
	},

	[SELECT_LIST]: ({ commit, dispatch, state: { mode } }, listId) => {
		commit(LIST, listId);
		const automationData = { list: listId };

		if (mode === PAGE_MODE.CREATE) {
			dispatch(POST_AUTOMATION, automationData);
		} else {
			dispatch(PUT_AUTOMATION, automationData);
		}
	}
};
