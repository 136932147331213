import Vue from 'vue';
import Vuex from 'vuex';

import {
	getToken,
	getIsLoggedInAsUser
} from '../utils/storage';

import dashboard from './modules/dashboard';
import user from './modules/user';
import login from './modules/login';
import register from './modules/register';
import passwordless from './modules/passwordless';
import emailVerification from './modules/emailVerification';
import leadScoring from './modules/leadScoring';
import automations from './modules/automations';
import campaigns from './modules/campaigns';
import persons from './modules/persons';
import account from './modules/account';
import notifications from './modules/notifications';
import { moduleSegments } from './modules/moduleSegments';
import { moduleWebTracking } from './modules/moduleWebTracking';
import { moduleResetPassword } from './modules/moduleResetPassword';
import { moduleConnections } from './modules/moduleConnections';
import { moduleWixInstall } from './modules/moduleWixInstall';

import actions from './actions';
import mutations from './mutations';

Vue.use(Vuex);

const getRootState = () => ({
	token: getToken(),
	isLoggedInAsUser: getIsLoggedInAsUser()
});

const store = new Vuex.Store({
	strict: true,
	state: getRootState(),
	modules: {
		dashboard,
		user,
		login,
		register,
		passwordless,
		emailVerification,
		leadScoring,
		automations,
		campaigns,
		persons,
		account,
		notifications,
		moduleSegments,
		moduleWebTracking,
		moduleResetPassword,
		moduleConnections,
		moduleWixInstall
	},
	actions,
	mutations
});

export default store;
