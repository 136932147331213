export const PERSONS = 'PERSONS';
export const SEARCH = 'SEARCH';
export const PAGINATION = 'PAGINATION';
export const SEGMENT_ID = 'SEGMENT_ID';
export const SEGMENTS = 'SEGMENTS';
export const GENERAL_ERRORS = 'GENERAL_ERRORS';
export const RESET = 'RESET';
export const DIALOG_CONTACTS_IMPORT_VISIBILITY = 'DIALOG_CONTACTS_IMPORT_VISIBILITY';
export const DIALOG_CONTACTS_IMPORT_LOADING = 'DIALOG_CONTACTS_IMPORT_LOADING';
export const DIALOG_CONTACTS_IMPORT_SELECT_FILE = 'DIALOG_CONTACTS_IMPORT_SELECT_FILE';
export const DIALOG_CONTACTS_IMPORT_RESET = 'DIALOG_CONTACTS_IMPORT_RESET';
export const DIALOG_CONTACTS_IMPORT_SEGMENT_NAME = 'DIALOG_CONTACTS_IMPORT_SEGMENT_NAME';
export const DIALOG_CONTACTS_IMPORT_DELIMITER = 'DIALOG_CONTACTS_IMPORT_DELIMITER';
export const DIALOG_CONTACTS_IMPORT_OPT_IN = 'DIALOG_CONTACTS_IMPORT_OPT_IN';
export const DIALOG_CONTACTS_IMPORT_OPT_IN_ERROR = 'DIALOG_CONTACTS_IMPORT_OPT_IN_ERROR';
