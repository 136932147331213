import { options } from './options';
import { httpGet } from './httpGet';
import { httpDelete } from './httpDelete';
import { httpPost } from './httpPost';
import { httpPatch } from './httpPatch';

export const fieldMappings = {
	options,
	get: httpGet,
	delete: httpDelete,
	post: httpPost,
	patch: httpPatch
};
