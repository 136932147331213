import { httpGet } from './httpGet';
import { httpPut } from './httpPut';
import { httpDelete } from './httpDelete';
import { options } from './options';

export const engagementSyncRules = {
	get: httpGet,
	put: httpPut,
	delete: httpDelete,
	options
};
