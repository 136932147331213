//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import moment from 'moment';

import IntegrationIcon from './IntegrationIcon.vue';

export default {
	components: {
		IntegrationIcon
	},

	props: {
		automation: {
			type: Object,
			required: true
		},
		type: {
			type: String,
			required: true
		},
		sourceIntegration: {
			type: String,
			required: true
		},
		sourceName: {
			type: String,
			required: true
		},
		destinationIntegration: {
			type: String,
			default: null
		},
		destinationName: {
			type: String,
			default: null
		}
	},

	computed: {
		lastSyncFromNow() {
			let time = '';

			if (this.automation.counters.time) {
				time = moment(this.automation.counters.time).fromNow();
			}

			return time;
		},

		statusText() {
			const { total } = this.automation.counters;
			const suffix = total === 1 ? '' : 's';

			return `${total} record${suffix} synced`;
		}
	},

	methods: {
		editAutomation() {
			this.$emit('edit-automation', this.automation._id);
		}
	}
};

