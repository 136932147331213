const getInitialState = () => ({
	plans: [],
	subscription: {},
	invoices: [],
	billingErrors: [],
	isBannerTrialEndingHidden: false,
	isDowngradeDialogVisible: false,
	isUpgradeDialogVisible: false,
	downgradeDialogContent: {
		mainContent: '',
		secondaryContent: ''
	},
	selectedPlanToDowngrade: null,
	selectedPlanToUpgrade: null,
	connections: [],
	errors: []
});

export default getInitialState;
