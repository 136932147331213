import { getConfValue } from 'utils/appconfig';
import urljoin from 'url-join';
import { Providers } from './constants';

/**
 * @param {String} action
 * @return {String}
 */
const getHubspotOauthDialogUrl = (action = 'default') => {
	const {
		clientId,
		oauthUrl,
		redirectUrl,
		scope
	} = getConfValue(Providers.HUBSPOT);

	const oauthDialogUrl = new URL(urljoin(oauthUrl, 'authorize'));

	oauthDialogUrl.searchParams.set('client_id', clientId);
	oauthDialogUrl.searchParams.set('redirect_uri', redirectUrl);
	oauthDialogUrl.searchParams.set('response_type', 'code');
	oauthDialogUrl.searchParams.set('scope', scope);
	oauthDialogUrl.searchParams.set('state', action);

	return oauthDialogUrl.href;
};

/**
 * @return {String}
 */
const getHubspotMktOauthDialogUrl = () => {
	const {
		clientId,
		oauthUrl,
		redirectUrl,
		scope
	} = getConfValue(Providers.HUBSPOT_MARKETING);

	const oauthDialogUrl = new URL(urljoin(oauthUrl, 'authorize'));

	oauthDialogUrl.searchParams.set('client_id', clientId);
	oauthDialogUrl.searchParams.set('redirect_uri', redirectUrl);
	oauthDialogUrl.searchParams.set('response_type', 'code');
	oauthDialogUrl.searchParams.set('scope', scope);
	oauthDialogUrl.searchParams.set('state', Providers.HUBSPOT_MARKETING);

	return oauthDialogUrl.href;
};

export {
	getHubspotOauthDialogUrl,
	getHubspotMktOauthDialogUrl
};
