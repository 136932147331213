export const COUNTERS = 'COUNTERS';
export const CRM_RULE_ERRORS = 'CRM_RULE_ERRORS';
export const CRM_FIELD_ERRORS = 'CRM_FIELD_ERRORS';
export const LOADING = 'LOADING';
export const LEAD_SCORE_RULES_LIMIT = 'LEAD_SCORE_RULES_LIMIT';
export const OPTIONS = 'OPTIONS';
export const SETTINGS = 'SETTINGS';
export const RULES = 'RULES';
export const ADD_RULE = 'ADD_RULE';
export const FIELDS = 'FIELDS';
export const VISIBLE_FIELDS = 'VISIBLE_FIELDS';
export const ERRORS = 'ERRORS';
export const RESET = 'RESET';
export const RULE_ERRORS = 'RULE_ERRORS';
export const ACTIVITY_TYPES = 'ACTIVITY_TYPES';
export const LEAD_SCORE_CRM_ACTIVITIES_ENABLED = 'LEAD_SCORE_CRM_ACTIVITIES_ENABLED';
export const LEAD_SCORE_INSTRUCTION_BOX = 'LEAD_SCORE_INSTRUCTION_BOX';
export const CONTACTS_FIELDS = 'CONTACTS_FIELDS';
