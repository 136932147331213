/**
 * @param {Object} params
 * @param {{ sent: Number, open: Number }} params.emailStatistics
 * @return {Number}
 */
export const openRate = ({ emailStatistics: { sent, open } }) => {
	if (!sent || !open) {
		return 0;
	}

	return open / sent;
};

/**
 * @param {Object} params
 * @param {{ sent: Number, click: Number }} params.emailStatistics
 * @return {Number}
 */
export const clickRate = ({ emailStatistics: { sent, click } }) => {
	if (!sent || !click) {
		return 0;
	}

	return click / sent;
};

/**
 * @param {Object} params
 * @param {{ sent: Number, bounce: Number }} params.emailStatistics
 * @return {Number}
 */
export const bounceRate = ({ emailStatistics: { sent, bounce } }) => {
	if (!sent || !bounce) {
		return 0;
	}

	return bounce / sent;
};

/**
 * @param {Object} params
 * @param {{ sent: Number, unsubscribe: Number }} params.emailStatistics
 * @return {Number}
 */
export const unsubscribeRate = ({ emailStatistics: { sent, unsubscribe } }) => {
	if (!sent || !unsubscribe) {
		return 0;
	}

	return unsubscribe / sent;
};
