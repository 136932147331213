import { api } from 'api';
import router from 'router';
import logger from 'utils/logger';

import ROUTE_NAMES from 'router/route-names';

import {
	GET_USER
} from '../../user/actionTypes';

import {
	SET_OPTIONS,
	SET_CAMPAIGNS,
	PAGINATION,
	SET_ERRORS
} from './mutationTypes';

import {
	WELCOME_DISMISS,
	NAVIGATE_DASHBOARD,
	OPTIONS_GET,
	CAMPAIGNS_GET,
	CAMPAIGN_CREATE,
	CAMPAIGN_EDIT,
	CAMPAIGN_DUPLICATE,
	CAMPAIGN_DELETE,
	CAMPAIGN_REPORT_VIEW,
	EMAIL_VIEW
} from './actionTypes';

import { Flags } from '../../user/helpers';
import SHOW_NOTIFICATION from '../../notifications/actionTypes';

const CAMPAIGNS_PER_PAGE = 30;

export default {
	async [WELCOME_DISMISS]({
		dispatch,
		rootState: { token }
	}) {
		await api.user.flags.post(token, Flags.CAMPAIGNS_SINGLE_EMAIL_WELCOME_MSG);
		dispatch(`user/${GET_USER}`, null, { root: true });
	},

	[NAVIGATE_DASHBOARD]() {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS
		});
	},

	async [OPTIONS_GET]({ commit, rootState: { token } }) {
		try {
			const options = await api.singleEmailCampaigns.options.get(token);

			commit(SET_OPTIONS, options);
		} catch (exception) {
			if (exception.errors) {
				return commit(SET_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [CAMPAIGNS_GET]({
		commit,
		rootState: { token }
	}, page = 1) {
		try {
			const { data, pagination } = await api.singleEmailCampaigns.get({
				token,
				limit: CAMPAIGNS_PER_PAGE,
				page
			});

			commit(SET_CAMPAIGNS, data);
			commit(PAGINATION, pagination);
		} catch (exception) {
			if (exception.errors) {
				return commit(SET_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CAMPAIGN_CREATE]() {
		router.push({ name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_CREATE });
	},

	[CAMPAIGN_EDIT](context, campaignId) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_BASICS,
			params: {
				id: campaignId
			}
		});
	},

	[CAMPAIGN_REPORT_VIEW](context, campaignId) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_REPORT,
			params: {
				id: campaignId
			}
		});
	},

	[EMAIL_VIEW](context, campaignId) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_PREVIEW,
			params: {
				id: campaignId
			}
		});
	},

	async [CAMPAIGN_DUPLICATE]({
		dispatch,
		commit,
		rootState: { token }
	}, campaignId) {
		try {
			await api.singleEmailCampaigns.duplicate.post(token, campaignId);
			dispatch(CAMPAIGNS_GET);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Campaign duplicated',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(SET_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [CAMPAIGN_DELETE]({
		state, commit, dispatch, rootState: { token }
	}, campaignId) {
		try {
			const campaigns = state.campaigns.filter(campaign => campaign._id !== campaignId);

			await api.singleEmailCampaigns.delete(token, campaignId);

			commit(SET_CAMPAIGNS, campaigns);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Campaign deleted',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(SET_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
