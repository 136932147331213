/**
 * @return {Object}
 */
export const getInitialState = () => ({
	email: null,
	token: null,
	password: '',
	passwordConfirmation: '',
	isChanged: false,
	fieldErrors: {},
	generalErrors: []
});
