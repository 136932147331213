import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { SegmentsException } from './helpers';

/**
 * Send PUT request to /segments/:id endpoint.
 * @param {String} token JWT token.
 * @param {String} segmentId Segment id.
 * @param {Object} segmentData Segment data.
 * @return {Promise<{Object}>}
 */
export const httpPut = async (token, segmentId, segmentData) => {
	const url = endpoints.segmentsId(segmentId);
	const response = await request.put(url, segmentData, token);

	if (!response.success) {
		return Promise.reject(new SegmentsException(response));
	}

	return response.data;
};
