export const getInitialState = () => ({
	emailStatistics: {
		sent: 0,
		open: 0,
		click: 0,
		bounce: 0,
		unsubscribe: 0
	},
	errors: []
});
