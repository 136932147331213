import logger from 'utils/logger';
import { api } from 'api';
import router from 'router';
import braintree from 'utils/braintree';
import normalizeBraintreeError from 'utils/braintree/error';
import { groupErrors, BaseException } from 'utils/error';
import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import { isString, isObject } from 'utils/types';

import {
	BANNER_TRIAL_ENDING_HIDDEN
} from 'store/modules/user/mutationTypes';

import {
	PLANS,
	SUBSCRIPTION,
	FIELDS,
	FIELD_ERRORS,
	FIELD_ERRORS_CLEAR,
	GENERAL_ERRORS,
	BUTTON_ENABLE
} from './mutationTypes';

import {
	BRAINTREE_DROPIN_CREATE,
	PAYMENT_METHOD_POST,
	PAYMENT_METHOD_GET,
	PLANS_GET,
	BILLING_NAVIGATE,
	ERRORS_SHOW
} from './actionTypes';

class PaymentException extends BaseException {
	constructor(result) {
		super(result);
		this.name = 'PaymentException';
	}
}

const REQUIRED_FIELDS = [
	'firstName',
	'lastName',
	'street',
	'state',
	'city',
	'zip',
	'country'
];

const DEFAULT_ERROR_MESSAGE = 'Required field';

const ERROR_MESSAGES = {
	companyName: 'Enter your company name',
	vatNumber: 'Enter your VAT number',
	firstName: 'Enter your first name',
	lastName: 'Enter your last name',
	street: 'Enter your street address',
	state: 'Enter your state',
	city: 'Enter your city',
	zip: 'Enter your ZIP code',
	country: 'Enter your country'
};

export default {
	async [PLANS_GET]({
		rootState: { token }, commit
	}) {
		const plans = await api.billing.plans.get(token);

		commit(PLANS, plans);
	},
	async [PAYMENT_METHOD_GET]({
		state, rootState: { token }, rootGetters, commit
	}) {
		const subscription = await api.billing.subscription.get(token);
		const companyName = subscription.company || rootGetters['user/company'];
		const firstName = subscription.first_name || rootGetters['user/firstName'];
		const lastName = subscription.last_name || rootGetters['user/lastName'];
		const vatNumber = subscription.vat_number;

		commit(SUBSCRIPTION, subscription);

		commit(FIELDS, { companyName });
		commit(FIELDS, { firstName });
		commit(FIELDS, { lastName });
		commit(FIELDS, { vatNumber });

		// eslint-disable-next-line camelcase
		if (state.subscription.billing_address) {
			// eslint-disable-next-line camelcase
			const { billing_address } = state.subscription;

			// eslint-disable-next-line camelcase
			commit(FIELDS, { firstName: billing_address.first_name });

			// eslint-disable-next-line camelcase
			commit(FIELDS, { lastName: billing_address.last_name });

			// eslint-disable-next-line camelcase
			commit(FIELDS, { street: billing_address.line1 });

			// eslint-disable-next-line camelcase
			commit(FIELDS, { state: billing_address.state });

			// eslint-disable-next-line camelcase
			commit(FIELDS, { city: billing_address.city });

			// eslint-disable-next-line camelcase
			commit(FIELDS, { zip: billing_address.zip });

			// eslint-disable-next-line camelcase
			commit(FIELDS, { country: billing_address.country });
		}
	},

	async [BRAINTREE_DROPIN_CREATE]({
		commit,
		rootState: {
			token,
			user: {
				profile: {
					featureFlags: {
						disableThreeDSecure
					}
				}
			}
		}
	}) {
		const response = await api.billing.paymentMethod.token.get(
			token
		);

		const { ip, userAgent } = response;
		let braintreeClientToken;

		if (isString(response)) {
			braintreeClientToken = response;
		} else if (isObject(response)) {
			braintreeClientToken = response.clientToken;
		}

		await braintree.create({
			disableThreeDSecure,
			braintreeClientToken,
			ip,
			userAgent,
			container: '#braintree-dropin-container'
		});

		commit(BUTTON_ENABLE, true);
	},

	async [PAYMENT_METHOD_POST]({
		state,
		commit,
		dispatch,
		getters: { subscriptionPrice },
		rootState: { token, user: { profile } }
	}) {
		const { fields } = state;

		commit(GENERAL_ERRORS, []);
		commit(FIELD_ERRORS_CLEAR, fields);

		const errors = REQUIRED_FIELDS
			.filter(key => fields[key])
			.filter(key => fields[key].length === 0)
			.map(key => ({
				context: key,
				message: ERROR_MESSAGES[key] || DEFAULT_ERROR_MESSAGE
			}));

		if (errors.length) {
			return dispatch(ERRORS_SHOW, errors);
		}

		commit(BUTTON_ENABLE, false);

		try {
			const billingAddress = {
				givenName: fields.firstName,
				surname: fields.lastName,
				locality: fields.city,
				streetAddress: fields.street,
				postalCode: fields.zip,
				countryCodeAlpha2: fields.country
			};

			const { email } = profile;

			const response = await braintree.requestPaymentMethod({
				billingAddress,
				email,
				amount: subscriptionPrice
			});

			if (response.error) {
				throw new PaymentException({
					errors: [response.error]
				});
			}

			const paymentMethodData = {
				nonce: response.nonce,
				company_name: fields.companyName,
				vat_number: fields.vatNumber,
				billing_address: {
					first_name: fields.firstName,
					last_name: fields.lastName,
					city: fields.city,
					country: fields.country,
					line1: fields.street,
					state: fields.state,
					zip: fields.zip
				}
			};

			await api.billing.paymentMethod.post(token, paymentMethodData);

			commit(`user/${BANNER_TRIAL_ENDING_HIDDEN}`, null, { root: true });

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Payment successfully made',
				{ root: true }
			);
			dispatch(BILLING_NAVIGATE);
		} catch (exception) {
			if (exception.errors) {
				dispatch(
					ERRORS_SHOW,
					exception.errors
				);
			} else {
				logger.error(exception);
			}

			commit(BUTTON_ENABLE, true);
		}
	},

	[ERRORS_SHOW]({
		commit,
		state: { fields }
	}, errors) {
		const mappedErrors = errors
			.map(error => {
				let errorObject = error;

				if (error.name === 'BraintreeError') {
					errorObject = normalizeBraintreeError(error);
				}

				return errorObject;
			});

		const { generalErrors, contextualErrors } = groupErrors(
			mappedErrors, Object.keys(fields)
		);

		commit(GENERAL_ERRORS, generalErrors);
		commit(FIELD_ERRORS, contextualErrors);
	},

	[BILLING_NAVIGATE]: () => {
		router.push('/account/billing');
	}
};
