import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { PersonsException } from './helpers';

/**
 * Get paginated list of persons or data of single person specified by Person's id.
 * @param {Object} params
 * @param {String} params.token - User's JWT authorization token.
 * @param {Number} params.limit - Limit the number of persons.
 * @param {Number} params.page - Number of the page.
 * @param {String} params.personId
 * @param {String} params.search
 * @param {String} params.filterId
 * @param {String} params.segmentId
 */
async function httpGet({
	token,
	personId,
	limit,
	page,
	search,
	filterId,
	segmentId
}) {
	let url;

	if (personId) {
		url = new URL(endpoints.personsId(personId));
	} else {
		url = new URL(endpoints.persons);
		const searchParams = new URLSearchParams({
			sortBy: 'lastEventDate',
			sortOrder: 'desc'
		});

		if (limit) {
			searchParams.set('limit', limit);
		}

		if (page) {
			searchParams.set('page', page);
		}

		if (search) {
			searchParams.set('search', search);
		}

		if (filterId) {
			searchParams.set('filter', filterId);
		}

		if (segmentId) {
			searchParams.set('filter', segmentId);
		}

		url.search = searchParams.toString();
	}

	const result = await request.get(url.toString(), token);

	if (!result.success) {
		return Promise.reject(new PersonsException(result));
	}

	const { data, pagination } = result;

	return {
		data,
		pagination
	};
}

export {
	httpGet
};
