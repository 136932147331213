import { endpoints } from 'api/endpoints';
import { BaseException } from 'utils/error';

import { TYPES } from './types';

/**
 * @param {String} type
 * @return {Boolean}
 */
function isValidAutomationType(type) {
	return Object.values(TYPES).includes(type);
}

/**
 * @class
 */
class AutomationsException extends BaseException {
	/**
	 * @param {{ errors: Object[]}} result
	 */
	constructor(result) {
		super(result);
		this.name = 'AutomationsException';
	}
}

/**
 * Helper function to put together an url like
 * /v1/automations/456f78765f456f7?type=pipedrive_to_mailchimp.
 * @param {String} id - ID of an Automation.
 * @param {String} type - Type of an Automation.
 */
function getAutomationUrl(id, type) {
	let url = new window.URL(endpoints.automations);
	const searchParams = new window.URLSearchParams();

	if (id) {
		url = new window.URL(`${endpoints.automations}/${id}`);
	}

	if (type) {
		if (isValidAutomationType(type)) {
			searchParams.append('type', type);
			url.search = searchParams.toString();
		} else {
			throw new Error(`Invalid automation type: ${type}`);
		}
	}

	return url.toString();
}

/**
 * @param {String} value
 * @return {Booolean}
 */
function isValidFilterId(value) {
	let isValid = false;

	if (typeof value === 'number') {
		isValid = true;
	}

	return isValid;
}

/**
 * @param {String} value
 * @return {Booolean}
 */
function isValidListId(value) {
	let isValid = false;

	if (typeof value === 'string') {
		isValid = true;
	}

	return isValid;
}

export {
	TYPES,
	AutomationsException,
	getAutomationUrl,
	isValidFilterId,
	isValidListId
};
