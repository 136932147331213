import { httpGet } from './httpGet';
import { httpPut } from './httpPut';
import { events } from './events';
import { imports } from './imports';
import { leadscore } from './leadscore';

export const persons = {
	get: httpGet,
	put: httpPut,
	events,
	imports,
	leadscore
};
