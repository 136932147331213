/**
 * @constant {{
 * UPDATE: String,
 * SHUTDOWN: String
 * }}
 */
const Operations = {
	UPDATE: 'update',
	SHOW: 'show',
	SHOW_NEW_MESSAGE: 'showNewMessage',
	SHUTDOWN: 'shutdown'
};

/**
 * @constant {Number}
 */
const RADIX = 10;

const showChatWindow = () => {
	if (!window.Intercom) {
		return;
	}

	window.Intercom(Operations.SHOW);
};

const showNewMessageChatWindow = () => {
	if (!window.Intercom) {
		return;
	}

	window.Intercom(Operations.SHOW_NEW_MESSAGE);
};

/**
 * @return {Number}
 */
const getTimesamp = () => parseInt((new Date()).getTime() / 1000, RADIX);

/**
 * Identify user for Intercom.
 * @param {String} user
 */
const updateUser = user => {
	if (!window.Intercom) {
		return;
	}

	window.Intercom(Operations.UPDATE, user);
};

/**
 * Close Intercom chat.
 */
const shutDown = () => {
	if (!window.Intercom) {
		return;
	}

	window.Intercom(Operations.SHUTDOWN);
	// window.Intercom('shutdown') does not work when it's invoked before the IC widget is fully loaded
	// The line below ensures that the IC widget is not visible even if shutDown was called
	// before the widget was fully loaded
	window.Intercom(Operations.UPDATE, {
		hide_default_launcher: true
	});
};

/**
 * @param {Object} params
 * @param {String} params.email
 * @return {void}
 */
const trackPageChange = ({ email }) => {
	if (!window.Intercom) {
		return;
	}

	window.Intercom(Operations.UPDATE, { email, last_request_at: getTimesamp() });
};

export {
	updateUser,
	showChatWindow,
	showNewMessageChatWindow,
	shutDown,
	trackPageChange
};
