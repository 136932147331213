export const APPS = 'APPS';
export const CONNECTIONS = 'CONNECTIONS';
export const ERRORS = 'ERRORS';
export const UPGRADE_DIALOG_VISIBILITY = 'UPGRADE_DIALOG_VISIBILITY';
export const CONTACT_SYNC_CONFIRMATION_DIALOG_VISIBILITY = 'CONTACT_SYNC_CONFIRMATION_DIALOG_VISIBILITY';
export const ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_VISIBILITY = 'ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_VISIBILITY';
export const ENGAGEMENT_SYNC_UPGRADE_DIALOG_VISIBILITY = 'ENGAGEMENT_SYNC_UPGRADE_DIALOG_VISIBILITY';
export const SPLIT_CONTACT_SYNC_CONFIRMATION_DIALOG_VISIBILITY = 'SPLIT_CONTACT_SYNC_CONFIRMATION_DIALOG_VISIBILITY';
export const OPTIONS = 'OPTIONS';
export const CONTACT_CREATION_RULES = 'CONTACT_CREATION_RULES';
export const TWO_WAY_SYNC_ERRORS_VISIBILITY = 'TWO_WAY_SYNC_ERRORS_VISIBILITY';
export const ENGAGEMENT_SYNC_VALIDATION_ERRORS_VISIBILITY = 'ENGAGEMENT_SYNC_VALIDATION_ERRORS_VISIBILITY';
