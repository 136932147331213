import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import getInitialState from './state';
import payment from './payment';

export default {
	namespaced: true,
	state: getInitialState(),
	actions,
	mutations,
	getters,
	modules: {
		payment
	}
};
