import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { EmailsException } from './helpers';

/**
 * Delete an email of a sequence.
 * @param {String} token - User's JWT authorization token.
 * @param {String} sequenceId - ID of the sequence.
 * @param {String} emailId - ID of the email.
 * @return {Promise<Object>}
 */
async function httpDelete(
	token,
	sequenceId,
	emailId
) {
	const url = endpoints.sequenceEmailCampaignsIdEmailsId(sequenceId, emailId);
	const result = await request.delete(url, token);

	if (!result.success) {
		return Promise.reject(new EmailsException(result));
	}

	return result.data;
}

export {
	httpDelete
};
