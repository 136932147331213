export const CONTACTS_NAVIGATE = 'CONTACTS_NAVIGATE';
export const OPTIONS_GET = 'OPTIONS_GET';
export const SEGMENT_SAVE = 'SEGMENT_SAVE';
export const SEGMENT_GET = 'SEGMENT_GET';
export const SEGMENT_CREATE = 'SEGMENT_CREATE';
export const SEGMENT_UPDATE = 'SEGMENT_UPDATE';
export const SEGMENT_DELETE = 'SEGMENT_DELETE';
export const SEGMENT_DISCARD = 'SEGMENT_DISCARD';
export const CONDITION_SET_EDIT = 'CONDITION_SET_EDIT';
export const CONDITION_CREATE = 'CONDITION_CREATE';
export const CONDITION_EDIT = 'CONDITION_EDIT';
export const CONDITION_DELETE = 'CONDITION_DELETE';
export const SEGMENT_CREATE_NAVIGATE = 'SEGMENT_CREATE_NAVIGATE';
