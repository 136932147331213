/**
 * Get Pipedrive to Mailchimp automation's options:
 * - available Pipedrive filters
 * - available Mailchimp lists
 * - Pipedrive Person fields
 * - Pipedrive Org fields.
 */
export const GET_OPTIONS = 'GET_OPTIONS';

/** Get a Pipedrive to Mailchimp automation from backend. */
export const GET_AUTOMATION = 'GET_AUTOMATION';

/** Create a new Pipedrive to Mailchimp automation. */
export const POST_AUTOMATION = 'POST_AUTOMATION';

/** Save changes to a Pipedrive to Mailchimp automation. */
export const PUT_AUTOMATION = 'PUT_AUTOMATION';

/** Select Pipedrive filter. */
export const SELECT_FILTER = 'SELECT_FILTER';

/** Select Mailchimp list. */
export const SELECT_LIST = 'SELECT_LIST';

/** Mark specifed personfields as selected. */
export const SELECT_PERSONFIELDS = 'SELECT_PERSONFIELDS';

/** Mark specified orgFields as selected. */
export const SELECT_ORGFIELDS = 'SELECT_ORGFIELDS';

/** Schedule sync for automation. */
export const POST_SCHEDULE_SYNC = 'POST_SCHEDULE_SYNC';

/** Trigger sync and set automation as active. */
export const SCHEDULE_SYNC = 'SCHEDULE_SYNC';

/** Delete the automation. */
export const DELETE = 'DELETE';
