//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_CONFIRM = 'confirm';
const EVENT_CANCEL = 'cancel';

export default {
	props: {
		isVisible: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		onConfirm() {
			this.$emit(EVENT_CONFIRM);
		},
		onCancel() {
			this.$emit(EVENT_CANCEL);
		}
	}
};

