//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	props: {
		errors: {
			type: Array,
			default: () => []
		},
		icon: {
			type: String,
			default: undefined
		},
		className: {
			type: String,
			default: undefined
		},
		type: {
			type: String,
			default: 'error'
		},
		outlined: {
			type: Boolean,
			default: true
		},
		transition: {
			type: String,
			default: 'slide-y-transition'
		}
	}
};
