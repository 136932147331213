import { MutationTypes } from './mutationTypes';

export const mutations = {
	[MutationTypes.CHANNEL_REPORT_OPTIONS](state, channelReportOptions) {
		state.channelReportOptions = channelReportOptions;
	},

	[MutationTypes.CHANNEL_REPORT](state, channelReport) {
		state.channelReport = channelReport;
	},

	[MutationTypes.CHANNEL_REPORT_PERIOD](state, selectedPeriod) {
		state.selectedPeriod = selectedPeriod;
	},

	[MutationTypes.FEATURES](state, features) {
		state.features = features;
	}
};
