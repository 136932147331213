//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const EVENT_REFRESH_FIELDS = 'refresh:fields';

export default {
	props: {
		isRefreshFieldsInProgress: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		refreshFields() {
			this.$emit(EVENT_REFRESH_FIELDS);
		}
	}
};
