//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_CLOSE = 'close';

export default {
	props: {
		isVisible: {
			type: Boolean,
			required: true
		}
	},

	data: () => ({
		calendlyUrl: 'https://calendly.com/outfunnel-markus/research?embed_type=Inline&hide_event_type_details=1&hide_gdpr_banner=1'
	}),

	methods: {
		onClose() {
			this.$emit(EVENT_CLOSE);
		}
	}
};

