import {
	EmailHealthStatuses,
	MINIMUM_EMAIL_HEALTH_SENT_COUNT
} from 'utils/constants/email-health';

import {
	openRate,
	clickRate,
	bounceRate,
	unsubscribeRate
} from './engagement-rate';

import {
	isOpenRateGood,
	isOpenRateBad,
	isClickRateGood,
	isClickRateBad,
	isBounceRateGood,
	isBounceRateBad,
	isUnsubscribeRateGood,
	isUnsubscribeRateBad
} from './engagement-rate-evaluation';

/**
 * @param {{ emailStatistics }} state
 * @return {Boolean}
 */
const isEmailHealthVisible = ({ emailStatistics }) =>
	emailStatistics.sent >= MINIMUM_EMAIL_HEALTH_SENT_COUNT;

/**
 * @param {Object} state
 * @param {Object} getters
 * @return {String}
 */
const emailHealthStatus = (state, getters) => {
	const goodEvaluations = [
		getters.isOpenRateGood,
		getters.isClickRateGood,
		getters.isBounceRateGood,
		getters.isUnsubscribeRateGood
	];

	const badEvaluations = [
		getters.isOpenRateBad,
		getters.isClickRateBad,
		getters.isBounceRateBad,
		getters.isUnsubscribeRateBad
	];

	if (goodEvaluations.every(Boolean)) {
		return EmailHealthStatuses.GOOD;
	}

	if (badEvaluations.some(Boolean)) {
		return EmailHealthStatuses.BAD;
	}

	return EmailHealthStatuses.FAIR;
};

export const getters = {
	openRate,
	clickRate,
	bounceRate,
	unsubscribeRate,
	isOpenRateGood,
	isOpenRateBad,
	isClickRateGood,
	isClickRateBad,
	isBounceRateGood,
	isBounceRateBad,
	isUnsubscribeRateGood,
	isUnsubscribeRateBad,
	isEmailHealthVisible,
	emailHealthStatus
};
