import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { PauseException } from './helpers';

/**
 * Pause a sequence.
 * @param {String} token - User's JWT authorization token.
 * @param {String} sequenceId - ID of the sequence.
 */
async function httpPost(token, sequenceId) {
	const url = endpoints.sequenceEmailCampaignsIdPause(sequenceId);
	const result = await request.post(url, null, token);

	if (!result.success) {
		return Promise.reject(new PauseException(result));
	}

	return result.data;
}

export {
	httpPost
};
