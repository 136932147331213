//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


/**
 * @constant {String}
 */
const EVENT_CLOSE = 'close';

export default {
	props: {
		isVisible: {
			type: Boolean,
			required: true
		}
	},

	methods: {
		close() {
			this.$emit(EVENT_CLOSE);
		}
	}
};

