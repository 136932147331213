import {
	PERSONS,
	SEARCH,
	GENERAL_ERRORS,
	RESET,
	PAGINATION,
	DIALOG_CONTACTS_IMPORT_VISIBILITY,
	DIALOG_CONTACTS_IMPORT_LOADING,
	DIALOG_CONTACTS_IMPORT_SELECT_FILE,
	DIALOG_CONTACTS_IMPORT_RESET,
	DIALOG_CONTACTS_IMPORT_SEGMENT_NAME,
	DIALOG_CONTACTS_IMPORT_OPT_IN,
	DIALOG_CONTACTS_IMPORT_OPT_IN_ERROR,
	SEGMENT_ID,
	SEGMENTS,
	DIALOG_CONTACTS_IMPORT_DELIMITER
} from './mutationTypes';

import getInitialState from './state';

export default {
	[PERSONS](state, persons) {
		state.persons = persons;
	},

	[SEARCH](state, search) {
		state.search = search;
	},

	[PAGINATION](state, pagination) {
		state.pagination = pagination;
	},

	[SEGMENT_ID](state, segmentId) {
		state.segmentId = segmentId;
	},

	[SEGMENTS](state, segments) {
		state.segments = segments;
		state.segmentName = '';
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	},

	[DIALOG_CONTACTS_IMPORT_VISIBILITY](state, isVisible) {
		state.isDialogContactsImportVisible = isVisible;
	},

	[DIALOG_CONTACTS_IMPORT_LOADING](state, isLoading) {
		state.isLoading = isLoading;
	},

	[DIALOG_CONTACTS_IMPORT_SELECT_FILE](state, file) {
		state.selectedFile = file;
	},

	[DIALOG_CONTACTS_IMPORT_SEGMENT_NAME](state, segmentName) {
		state.segmentName = segmentName;
	},

	[DIALOG_CONTACTS_IMPORT_DELIMITER](state, delimiter) {
		state.delimiter = delimiter;
	},

	[DIALOG_CONTACTS_IMPORT_OPT_IN](state, isOptInConfirmed) {
		state.isOptInConfirmed = isOptInConfirmed;

		if (isOptInConfirmed) {
			state.isOptInError = false;
		}
	},

	[DIALOG_CONTACTS_IMPORT_OPT_IN_ERROR](state, isError) {
		state.isOptInError = isError;
	},

	[DIALOG_CONTACTS_IMPORT_RESET](state) {
		state.isLoading = false;
		state.isDialogContactsImportVisible = false;
		state.isOptInConfirmed = false;
		state.isOptInError = false;
		state.segmentName = '';
		state.selectedFile = {};
		state.delimiter = ',';
	}
};
