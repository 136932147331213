import getInitialState from './state';

import {
	CAMPAIGN_ID,
	CAMPAIGN,
	CONVERSION,
	CSV_DOWNLOAD_URL,
	GENERAL_ERRORS,
	SORT_ORDER,
	SORT_BY,
	PAGINATION,
	RECIPIENTS,
	RESET
} from './mutationTypes';

export default {
	[CAMPAIGN_ID](state, campaignId) {
		state.campaignId = campaignId;
	},

	[CAMPAIGN](state, campaign) {
		state.campaign = campaign;
	},

	[RECIPIENTS](state, recipients) {
		state.recipients = recipients;
	},

	[CONVERSION](state, conversion) {
		state.conversion = conversion;
	},

	[CSV_DOWNLOAD_URL](state, csvDownloadUrl) {
		state.csvDownloadUrl = csvDownloadUrl;
	},

	[SORT_ORDER](state, sortOrder) {
		state.sortOrder = sortOrder;
	},

	[SORT_BY](state, sortBy) {
		state.sortBy = sortBy;
	},

	[PAGINATION](state, pagination) {
		state.pagination = pagination;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	}
};
