import router from 'router';
import { api } from 'api';
import logger from 'utils/logger';

import ROUTE_NAMES from 'router/route-names';

import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import {
	LIST_NAVIGATE,
	OPTIONS_GET,
	SETTINGS_GET,
	FIELDS_EDIT,
	UNSUBSCRIBE_FIELD_SELECT,
	UNSUBSCRIBE_OPTION_FIELD_SELECT
} from './actionTypes';
import {
	OPTIONS,
	SETTINGS,
	FIELDS,
	ERRORS,
	UNSUBSCRIBE_FIELD,
	UNSUBSCRIBE_FIELD_OPTION
} from './mutationTypes';

export default {
	[LIST_NAVIGATE]() {
		router.push({
			name: ROUTE_NAMES.AUTOMATIONS
		});
	},

	[OPTIONS_GET]: async ({ commit, rootState: { token } }) => {
		try {
			const options = await api.user.settings.emailEngagement.options.get(token);

			commit(OPTIONS, options);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[SETTINGS_GET]: async ({ commit, rootState: { token } }) => {
		try {
			const settings = await api.user.settings.emailEngagement.get(token);

			if (settings.unsubscribeFieldTarget) {
				commit(
					UNSUBSCRIBE_FIELD,
					settings.unsubscribeFieldTarget.unsubscribeFieldId
				);
				commit(
					UNSUBSCRIBE_FIELD_OPTION,
					settings.unsubscribeFieldTarget.unsubscribeFieldOptionId
				);
			}

			commit(SETTINGS, settings);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [FIELDS_EDIT]({
		commit,
		dispatch,
		rootState: { token }
	}, fields) {
		commit(FIELDS, fields);

		try {
			commit(ERRORS, []);
			const updatedSettings = await api.user.settings.emailEngagement.put(
				token, fields
			);

			commit(SETTINGS, updatedSettings);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Settings saved',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[UNSUBSCRIBE_FIELD_SELECT]: async ({ dispatch, commit, state: { options } }, fieldId) => {
		const { options: unsubscribeFieldTarget } = options.unsubscribeFieldTarget;
		const selectedField = unsubscribeFieldTarget.find(field => field.key === fieldId);

		const engagementSettings = {
			unsubscribeFieldTarget: {
				isTemplate: selectedField.isTemplate,
				unsubscribeFieldId: selectedField.key,
				unsubscribeFieldOptionId: selectedField.options[0].id
			}
		};

		commit(UNSUBSCRIBE_FIELD, fieldId);
		commit(UNSUBSCRIBE_FIELD_OPTION, selectedField.options[0].id);

		await dispatch(FIELDS_EDIT, engagementSettings);
		if (selectedField.isTemplate) {
			await dispatch(OPTIONS_GET);
			await dispatch(SETTINGS_GET);
		}
	},

	[UNSUBSCRIBE_OPTION_FIELD_SELECT]: async ({ state, dispatch, commit }, optionId) => {
		if (optionId) {
			commit(UNSUBSCRIBE_FIELD_OPTION, optionId);
			const engagementSettings = {
				unsubscribeFieldTarget: {
					unsubscribeFieldId: state.unsubscribeFieldTarget.unsubscribeFieldId,
					unsubscribeFieldOptionId: optionId
				}
			};

			dispatch(FIELDS_EDIT, engagementSettings);
		}
	}
};
