export const STEPS = {
	EMAILS: 'EMAILS',
	SETTINGS: 'SETTINGS'
};

export const EDITOR_MODE = {
	CREATE: 'CREATE',
	EDIT: 'EDIT'
};

export const RECIPIENTS_TYPES = {
	ALL: 'All',
	TAGS: 'Tags',
	CONTACT_TYPE: 'Contact type',
	LEAD_STATUS: 'Lead status',
	OUTFUNNEL: 'outfunnel'
};

export const SEGMENT_TYPES = {
	PIPEDRIVE: 'pipedrive',
	COPPER: 'copper',
	OUTFUNNEL: 'outfunnel'
};

const getInitialState = () => ({
	mode: EDITOR_MODE.CREATE,
	step: STEPS.EMAILS,
	sequenceOptions: {},
	emailOptions: {},
	emailEngagementSettings: {},
	emailScheduleSettings: {},
	emailScheduleSettingsOptions: {},
	sequence: {},
	sequenceEmails: [],
	generalErrors: [],
	fieldErrors: {
		name: null,
		filter: null
	},
	invalidEmailDelays: [],
	testSequenceId: null,
	testEmailId: null,
	testEmailAddresses: [],
	testEmailErrors: [],
	isDialogTestEmailVisible: false,
	isDialogConfirmationVisible: false,
	isDialogSendingLimitsVisible: false,
	areMergeFieldsReplaced: true,
	recipientsType: null,
	segmentType: null
});

export default getInitialState;
