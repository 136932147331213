//
//
//
//
//
//


export default {
	props: {
		index: {
			type: Number,
			required: true,
			default: 0
		},
		planId: {
			type: String,
			required: true
		}
	}
};

