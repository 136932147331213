/* eslint-disable no-underscore-dangle */

module.exports = {
	/**
	 * @param {String} key
	 * @return {String|null}
	 */
	getConfValue: key => {
		let value = null;

		if (window.__outfunnel_app_config__[key] !== undefined) {
			value = window.__outfunnel_app_config__[key];
		}

		return value;
	}
};
