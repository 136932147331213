import logger from 'utils/logger';

/**
 * @constant {String}
 */
const SITE_KEY = process.env.RECAPTCHA_SITE_KEY;

/**
 * @constant {String}
 */
const CAPTCHA_SCRIPT_URL = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;

/**
 * Wait Captcha library to be ready.
 * @return {Promise}
 */
const waitCaptchaToBeReady = async () => new Promise(resolve => {
	window.grecaptcha.ready(resolve);
});

/**
 * Load Captcha script.
 */
export const loadCaptcha = () => {
	const captchaScript = document.createElement('script');

	captchaScript.type = 'text/javascript';
	captchaScript.async = true;
	captchaScript.src = CAPTCHA_SCRIPT_URL;

	const [firstScript] = document.getElementsByTagName('script');

	firstScript.parentNode.insertBefore(captchaScript, firstScript);
};

/**
 * Send action to captcha API.
 */
export const getCaptchaToken = async (action = 'submit') => {
	await waitCaptchaToBeReady();

	try {
		return window.grecaptcha.execute(SITE_KEY, { action });
	} catch (err) {
		logger.error('Failed to get captcha token');

		return null;
	}
};
