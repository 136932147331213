export const CAMPAIGN_ID = 'CAMPAIGN_ID';
export const CAMPAIGN = 'CAMPAIGN';
export const RECIPIENTS = 'RECIPIENTS';
export const CONVERSION = 'CONVERSION';
export const CSV_DOWNLOAD_URL = 'CSV_DOWNLOAD_URL';
export const SORT_ORDER = 'SORT_ORDER';
export const SORT_BY = 'SORT_BY';
export const PAGINATION = 'PAGINATION';
export const GENERAL_ERRORS = 'GENERAL_ERRORS';
export const RESET = 'RESET';
