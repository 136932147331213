/**
 * @return {String}
 */
export function getEnv() {
	return process.env.NODE_ENV || 'development';
}

/**
 * @return {Boolean}
 */
export function isProductionEnv() {
	return getEnv() === 'production';
}

/**
 * @return {Boolen}
 */
export function isDevelopmentEnv() {
	return getEnv() === 'development';
}
