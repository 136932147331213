let isScriptLoaded = false;

/**
 * Load PaperForm script.
 */
export const loadPaperformScript = () => {
	if (isScriptLoaded) {
		return;
	}

	const paperformScript = document.createElement('script');

	paperformScript.type = 'text/javascript';
	paperformScript.async = true;
	paperformScript.src = 'https://paperform.co/__embed';

	/**
	 * Script loading callback.
	 */
	paperformScript.onload = () => {
		isScriptLoaded = true;
	};

	const firstScript = document.getElementsByTagName('script')['0'];

	firstScript.parentNode.insertBefore(paperformScript, firstScript);
};
