//
//
//
//
//
//
//
//
//
//
//


import BaseStepper from 'components/BaseStepper.vue';
import { STEPS } from 'store/modules/campaigns/sequence/edit/state';

const EVENT_NAVIGATE = 'navigate';
const EVENT_ACTIVATE = 'activate';

export default {
	components: {
		BaseStepper
	},

	props: {
		currentStep: {
			type: String,
			required: true
		},

		isNextStepDisabled: {
			type: Boolean,
			default: false
		}
	},

	data: () => {
		const steps = [
			{
				id: STEPS.EMAILS,
				label: 'Emails',
				step: 1
			},
			{
				id: STEPS.SETTINGS,
				label: 'Settings',
				step: 2
			}
		];

		const actions = [
			{
				id: STEPS.EMAILS,
				label: 'Settings',
				type: 'navigate',
				target: STEPS.SETTINGS
			},
			{
				id: STEPS.SETTINGS,
				label: 'Activate sequence',
				type: 'confirm'
			}
		];

		return {
			steps,
			actions
		};
	},

	methods: {
		navigate(step) {
			this.$emit(EVENT_NAVIGATE, step);
		},

		activate() {
			this.$emit(EVENT_ACTIVATE);
		}
	}
};

