import actions from './actions';
import mutations from './mutations';
import getInitialState from './state';
import getters from './getters';

export const moduleEngagementRecording = {
	namespaced: true,
	state: getInitialState(),
	actions,
	mutations,
	getters
};
