import logger from 'utils/logger';
import { api } from 'api';
import router from 'router';
import ROUTE_NAMES from 'router/route-names';

import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import {
	MODE,
	OPTIONS,
	SEGMENT,
	CONDITION_SET,
	CONDITION,
	GENERAL_ERRORS,
	RESET,
	SEGMENT_SAVE_LOADING
} from './mutationTypes';

import {
	CONTACTS_NAVIGATE,
	OPTIONS_GET,
	SEGMENT_SAVE,
	SEGMENT_GET,
	SEGMENT_CREATE,
	SEGMENT_UPDATE,
	SEGMENT_DELETE,
	SEGMENT_DISCARD,
	CONDITION_SET_EDIT,
	CONDITION_CREATE,
	CONDITION_EDIT,
	CONDITION_DELETE,
	SEGMENT_CREATE_NAVIGATE
} from './actionTypes';

import { SEGMENT_MODE } from './state';

export const actions = {
	[CONTACTS_NAVIGATE]: ({
		state: { segment }
	}) => {
		router.push({
			name: ROUTE_NAMES.CONTACTS,
			query: {
				segment: segment._id
			}
		});
	},

	[OPTIONS_GET]: async ({
		rootState: { token },
		commit
	}) => {
		try {
			const options = await api.segments.options.get(token);

			commit(OPTIONS, options);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[SEGMENT_GET]: async ({
		rootState: { token },
		commit
	}, segmentId) => {
		try {
			const segment = await api.segments.get(token, segmentId);

			commit(SEGMENT, segment);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[SEGMENT_SAVE]: async ({
		dispatch,
		state
	}) => {
		if (state.mode === SEGMENT_MODE.CREATE) {
			dispatch(SEGMENT_CREATE);
		} else if (state.mode === SEGMENT_MODE.EDIT) {
			dispatch(SEGMENT_UPDATE);
		}
	},

	[SEGMENT_CREATE]: async ({
		rootState: { token },
		state,
		commit,
		dispatch
	}) => {
		commit(SEGMENT_SAVE_LOADING, true);
		commit(GENERAL_ERRORS, []);

		try {
			const segment = await api.segments.post(token, state.segment);

			commit(SEGMENT, segment);

			router.push({
				name: ROUTE_NAMES.SEGMENTS_EDIT,
				params: {
					id: segment._id
				}
			});

			commit(MODE, SEGMENT_MODE.EDIT);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Segment created',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		} finally {
			commit(SEGMENT_SAVE_LOADING, false);
		}
	},

	[SEGMENT_UPDATE]: async ({
		rootState: { token },
		state,
		commit,
		dispatch
	}) => {
		commit(SEGMENT_SAVE_LOADING, true);
		commit(GENERAL_ERRORS, []);

		try {
			const segment = await api.segments.put(token, state.segment._id, state.segment);

			commit(SEGMENT, segment);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Segment saved',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		} finally {
			commit(SEGMENT_SAVE_LOADING, false);
		}
	},

	[SEGMENT_DELETE]: async ({
		rootState: { token },
		state,
		commit,
		dispatch
	}) => {
		commit(GENERAL_ERRORS, []);

		try {
			await api.segments.delete(token, state.segment._id);

			commit(RESET);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Segment deleted',
				{ root: true }
			);

			dispatch(CONTACTS_NAVIGATE);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[SEGMENT_DISCARD]: ({ dispatch }) => {
		dispatch(CONTACTS_NAVIGATE);
	},

	[CONDITION_SET_EDIT]: ({ commit, state }, conditionSetUpdates) => {
		const conditionSet = state.segment.conditionSets[0];
		const updatedConditionSet = { ...conditionSet, ...conditionSetUpdates };

		commit(CONDITION_SET, updatedConditionSet);
	},

	[CONDITION_CREATE]: ({ commit }) => {
		commit(CONDITION, { conditionSetIndex: 0, condition: {} });
	},

	[CONDITION_EDIT]: ({ commit, state }, { conditionIndex, conditionField, value }) => {
		const condition = state.segment.conditionSets[0].conditions[conditionIndex];

		commit(CONDITION, {
			conditionSetIndex: 0,
			conditionIndex,
			condition: {
				...condition,
				[conditionField]: value
			}
		});
	},

	[CONDITION_DELETE]: ({ commit }, conditionIndex) => {
		commit(CONDITION, { conditionSetIndex: 0, conditionIndex, condition: null });
	},

	[SEGMENT_CREATE_NAVIGATE]: () => {
		router.push({
			name: ROUTE_NAMES.SEGMENTS_CREATE
		});
	}
};
