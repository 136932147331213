import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { OptionsException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.token
 * @param {String} params.listId
 * @param {Boolean=} params.refreshfields
 * @return {Promise<Object>}
 */
const httpGet = async ({ token, listId, refreshfields = false }) => {
	const url = new URL(endpoints.userListsIdOptions(listId));

	if (refreshfields) {
		const searchParams = new URLSearchParams({
			refreshfields
		});

		url.search = searchParams.toString();
	}

	const result = await request.get(url.toString(), token);

	if (!result.success) {
		return Promise.reject(new OptionsException(result));
	}

	return result.data;
};

export {
	httpGet
};
