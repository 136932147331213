import { MODE } from '../types';

const getInitialState = () => ({
	mode: MODE.EDIT,
	status: {
		message: '',
		type: ''
	},
	options: {},
	selected: {
		pipelineId: 0,
		stageId: 0,
		unsubscribeFieldId: 0,
		unsubscribeFieldOptionId: 0
	},
	automation: {}
});

export default getInitialState;
