import { api } from 'api';
import logger from 'utils/logger';
import utils from 'utils';

import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import {
	SETTINGS_GET,
	COPY_EMAIL
} from './actionTypes';
import {
	ERRORS,
	SETTINGS
} from './mutationTypes';

export const actions = {
	/**
	 * Get settings for Reply Tracking.
	 */
	[SETTINGS_GET]: async ({ commit, rootState: { token } }) => {
		try {
			const replyTrackingSettings = await api.user.settings.email.replyTracking.get(token);

			commit(SETTINGS, replyTrackingSettings);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * Copy selected email to clipboard and show notification.
	 */
	[COPY_EMAIL]({ dispatch }) {
		utils.copySelectedText();

		dispatch(
			`notifications/${SHOW_NOTIFICATION}`,
			'Reply tracking forwarding email copied to clipboard',
			{ root: true }
		);
	}
};
