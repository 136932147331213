import { groupErrors } from 'utils/error';

import getInitialState from './state';
import {
	OPTIONS,
	SETTINGS,
	COUNTERS,
	FIELDS,
	VISIBLE_FIELDS,
	ERRORS,
	RESET
} from './mutationTypes';

export default {
	[OPTIONS](state, options) {
		state.options = options;
	},

	[SETTINGS](state, settings) {
		state.settings = settings;
	},

	[COUNTERS](state, counters) {
		state.counters = counters;
	},

	[FIELDS](state, fields) {
		Object.assign(state.settings, fields);
	},

	[VISIBLE_FIELDS](state, fields) {
		state.visibleFields = fields;
	},

	[ERRORS](state, errors) {
		const { visibleFields } = state;
		const { generalErrors, contextualErrors } = groupErrors(
			errors, visibleFields
		);

		contextualErrors.forEach(error => {
			state.fieldErrors[error.context] = error.message;
		});

		state.generalErrors = generalErrors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	}
};
