import { api } from 'api';
import router from 'router';
import logger from 'utils/logger';

import ROUTE_NAMES from 'router/route-names';

import {
	CAMPAIGN,
	GENERAL_ERRORS
} from './mutationTypes';

import {
	CAMPAIGN_REPORT_NAVIGATE,
	CAMPAIGN_GET
} from './actionTypes';

export default {
	[CAMPAIGN_REPORT_NAVIGATE]({ state: { campaignId } }) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_REPORT,
			params: {
				id: campaignId
			}
		});
	},

	async [CAMPAIGN_GET]({
		commit,
		state: { campaignId },
		rootState: { token }
	}) {
		try {
			const { data } = await api.singleEmailCampaigns.get({
				token,
				campaignId
			});

			commit(CAMPAIGN, data);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
