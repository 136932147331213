//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_CONFIRM = 'confirm';
const EVENT_CANCEL = 'cancel';
const EVENT_INSERT_FILE = 'insert:file';
const EVENT_EDIT_SEGMENT_NAME = 'edit:segmentName';
const EVENT_EDIT_OPT_IN = 'edit:opt-in';
const EVENT_EDIT_DELIMITER = 'edit:delimiter';

export default {
	props: {
		isVisible: {
			type: Boolean,
			default: false
		},
		isLoading: {
			type: Boolean
		},
		isOptInConfirmed: {
			type: Boolean,
			default: false
		},
		isOptInError: {
			type: Boolean,
			default: false
		},
		isPrimaryButtonDisabled: {
			type: Boolean
		},
		isSecondaryButtonDisabled: {
			type: Boolean
		},
		supportedFileTypes: {
			type: Array,
			required: true
		},
		fileName: {
			type: String,
			default: ''
		},
		segmentName: {
			type: String,
			default: ''
		},
		delimiter: {
			type: String,
			default: ','
		}
	},

	data: () => ({
		learnMoreLink: 'https://support.outfunnel.com/en/articles/3871530-how-to-import-contacts-to-outfunnel'
	}),

	computed: {
		errorMessage() {
			if (this.isOptInError) {
				return 'You can only add contacts that have given you explicit permission to email them';
			}

			return null;
		}
	},

	methods: {
		onEditSegmentName(name) {
			this.$emit(EVENT_EDIT_SEGMENT_NAME, name);
		},
		onConfirm() {
			this.$emit(EVENT_CONFIRM);
		},
		onCancel() {
			this.$emit(EVENT_CANCEL);
		},

		/**
		 * Open browser's native file selection dialog.
		 */
		chooseFile() {
			this.$refs.fileInput.click();
		},

		/**
		 * Emit insert:file event with path to file
		 * when user selects a file.
		 * @param {InputEvent} inputEvent
		 * @param {EventTarget} inputEvent.target
		 * @param {Array} inputEvent.target.files List of files user selected. User should be able
		 * to select only one file.
		 */
		onFileSelected({ target: { files } }) {
			const file = files[0];

			this.$emit(EVENT_INSERT_FILE, file);
			this.$refs.fileInput.value = '';
		},

		onChangeOptIn(value) {
			this.$emit(EVENT_EDIT_OPT_IN, value);
		},

		onChangeDelimiter(value) {
			this.$emit(EVENT_EDIT_DELIMITER, value);
		}
	}
};

