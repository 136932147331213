import {
	OpenRateThresholds,
	ClickRateTresholds,
	BounceRateThesholds,
	UnsubscribeRateThresholds
} from 'utils/constants/email-health';

/**
 * @param {Object} state
 * @param {{ openRate }} getters
 * @return {Boolean}
 */
export const isOpenRateGood = (state, { openRate }) => openRate >= OpenRateThresholds.GOOD;

/**
 * @param {Object} state
 * @param {{ openRate }} getters
 * @return {Boolean}
 */
export const isOpenRateBad = (state, { openRate }) => openRate < OpenRateThresholds.FAIR;

/**
 * @param {Object} state
 * @param {{ clickRate }} getters
 * @return {Boolean}
 */
export const isClickRateGood = (state, { clickRate }) => clickRate >= ClickRateTresholds.GOOD;

/**
 * @param {Object} state
 * @param {{ clickRate }} getters
 * @return {Boolean}
 */
export const isClickRateBad = (state, { clickRate }) => clickRate < ClickRateTresholds.FAIR;

/**
 * @param {Object} state
 * @param {{ bounceRate }} getters
 * @return {Boolean}
 */
export const isBounceRateGood = (state, { bounceRate }) => bounceRate <= BounceRateThesholds.GOOD;

/**
 * @param {Object} state
 * @param {{ bounceRate }} getters
 * @return {Boolean}
 */
export const isBounceRateBad = (state, { bounceRate }) => bounceRate > BounceRateThesholds.FAIR;

/**
 * @param {Object} state
 * @param {{ unsubscribeRate }} getters
 * @return {Boolean}
 */
export const isUnsubscribeRateGood = (state, { unsubscribeRate }) =>
	unsubscribeRate <= UnsubscribeRateThresholds.GOOD;

/**
 * @param {Object} state
 * @param {{ unsubscribeRate }} getters
 * @return {Boolean}
 */
export const isUnsubscribeRateBad = (state, { unsubscribeRate }) =>
	unsubscribeRate > UnsubscribeRateThresholds.FAIR;
