import {
	LISTS,
	LIST,
	OPTIONS,
	NEW_FIELD_VISIBILITY,
	MAPPINGS,
	GENERAL_ERRORS,
	RESET
} from './mutationTypes';

import getInitialState from './state';

export default {
	[LISTS](state, lists) {
		state.lists = lists;
	},

	[LIST](state, list) {
		state.list = list;
	},

	[OPTIONS](state, options) {
		state.options = options;
	},

	[MAPPINGS](state, mappings) {
		state.mappings = mappings;
	},

	[NEW_FIELD_VISIBILITY](state, isNewFieldVisible) {
		state.isNewFieldVisible = isNewFieldVisible;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	}
};
