import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { getSignupTrackingData, getSignupIntents } from 'utils/tracking';
import { UsersException } from './helpers';

/**
 * Create new user account.
 * @param {Object} formData User data collected from Register page.
 * @return {Promise<String>}
 */
async function httpPost(formData) {
	const result = await request.post(endpoints.users, {
		...formData,
		signupTracking: getSignupTrackingData(),
		intents: getSignupIntents()
	});

	if (!result.success) {
		return Promise.reject(new UsersException(result));
	}

	return result.data.token;
}

export {
	httpPost
};
