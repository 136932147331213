export const GET_USER = 'GET_USER';
export const CHANGE_NAME = 'CHANGE_NAME';
export const CHANGE_COMPANY = 'CHANGE_COMPANY';
export const CHANGE_TIMEZONE = 'CHANGE_TIMEZONE';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CHANGE_TIPS = 'CHANGE_TIPS';
export const USAGE_GET = 'USAGE_GET';
export const ANALYTICS_IDENTIFY = 'ANALYTICS_IDENTIFY';
export const ANALYTICS_INTEGRATION_ADDED = 'ANALYTICS_INTEGRATION_ADDED';
export const ANALYTICS_INTEGRATION_REMOVED = 'ANALYTICS_INTEGRATION_REMOVED';
export const ANALYTICS_SIGNUP_IDENTIFY = 'ANALYTICS_SIGNUP_IDENTIFY';
export const CONFIGURE_VIEWS = 'CONFIGURE_VIEWS';
export const FEATURES_GET = 'FEATURES_GET';
