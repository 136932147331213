import { moduleSchedule } from './schedule';
import { moduleReplyTracking } from './replyTracking';
import { moduleDomains } from './domains';
import { moduleFooter } from './footer';
import { moduleEngagementRecording } from './engagementRecording';

export default {
	namespaced: true,
	modules: {
		moduleSchedule,
		moduleReplyTracking,
		moduleDomains,
		moduleFooter,
		moduleEngagementRecording
	}
};
