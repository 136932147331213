//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { capitalize } from 'utils/formatting';
import ROUTE_NAMES from 'router/route-names';
import { ContactSources } from 'utils/constants';

const EVENT_SEGMENT_CHANGED = 'change-segment';
/**
 * @constant {String}
 */
const SEGMENT_SUPPORT_URL = 'https://support.outfunnel.com/en/articles/3498829-how-to-segment-lists-with-outfunnel';

export default {
	props: {
		segments: {
			type: Array,
			required: true
		},
		segmentTypes: {
			type: Array,
			required: true
		},
		isSelectionDisabled: {
			type: Boolean,
			required: false,
			default: false
		},
		selectedSegment: {
			type: [String, Number],
			required: false,
			default: undefined
		},
		isContactSourceConnected: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({
		selectedSegmentType: undefined,
		popoverInformation: {
			[ContactSources.PIPEDRIVE]: {
				text: 'Use segments you’ve defined with Pipedrive people filters.',
				link: SEGMENT_SUPPORT_URL
			},
			[ContactSources.HUBSPOT]: {
				text: 'Use segments you’ve defined with HubSpot contact lists.',
				link: SEGMENT_SUPPORT_URL
			},
			[ContactSources.COPPER]: {
				text: 'Use segments you’ve defined with Copper tags, contact types or lead statuses.',
				link: SEGMENT_SUPPORT_URL
			},
			[ContactSources.OUTFUNNEL]: {
				text: 'Use segments you’ve defined in Outfunnel.',
				link: 'https://support.outfunnel.com/en/articles/4161671-how-to-create-event-based-segments'
			}
		}
	}),
	computed: {
		availableSegments() {
			if (!this.segmentTypes.length) {
				return this.segments;
			}

			const [type, entity] = this.selectedSegmentTypeWithFallback.split('-');
			const segmentsHaveEntities = this.segments.some(
				segment => segment.entity && segment.type !== 'outfunnel'
			);

			if (entity && segmentsHaveEntities) {
				return this.segments.filter(
					segment =>
						(segment.source === type
					|| segment.type === type)
					&& segment.entity === entity
				);
			}

			return this.segments.filter(
				segment => segment.source === type || segment.type === type
			);
		},

		selectedSegmentTypeWithFallback() {
			if (this.selectedSegmentType) {
				return this.selectedSegmentType;
			}

			if (this.selectedSegment) {
				return `${this.selectedSegmentObjectType || this.selectedSegmentObjectSource}-${this.selectedSegmentObjectEntity}`;
			}

			const firstSegment = this.segmentTypes
				&& this.segmentTypes.length
				&& this.segmentTypes[0];

			return `${firstSegment && firstSegment.value}-${firstSegment.entity}`;
		},

		segmentTypeLinkRoute() {
			if (this.selectedSegmentTypeWithFallback === ContactSources.OUTFUNNEL) {
				return { name: ROUTE_NAMES.CONTACTS };
			}

			return { name: ROUTE_NAMES.ACCOUNT_INTEGRATIONS };
		},

		segmentTypeLinkText() {
			if (this.selectedSegmentTypeWithFallback === ContactSources.OUTFUNNEL) {
				return 'Import contacts';
			}

			if (this.selectedSegmentTypeWithFallback === 'outfunnel-contact') {
				return 'Connect a CRM to select recipients';
			}

			return `Connect ${capitalize(this.selectedSegmentTypeWithFallback)} to select recipients`;
		},

		selectorLabel() {
			const selectedSegmentTypeOption = this.segmentTypes.find(
				segmentType => segmentType.value === this.selectedSegmentTypeWithFallback
			);

			if (!selectedSegmentTypeOption) {
				return 'Select a segment';
			}

			return `Select ${selectedSegmentTypeOption.text}`;
		},

		selectedSegmentObjectSource() {
			const selectedSegmentObject = this.segments.find(segment => segment.id === this.selectedSegment);

			if (!selectedSegmentObject) {
				return null;
			}

			return selectedSegmentObject.source;
		},

		selectedSegmentObjectType() {
			const selectedSegmentObject = this.segments.find(segment => segment.id === this.selectedSegment);

			if (!selectedSegmentObject) {
				return null;
			}

			return selectedSegmentObject.type;
		},

		selectedSegmentObjectEntity() {
			const selectedSegmentObject = this.segments.find(segment => segment.id === this.selectedSegment);

			if (!selectedSegmentObject) {
				return null;
			}

			return selectedSegmentObject.entity || 'contact';
		},

		availableSegmentsSource() {
			return this.availableSegments && this.availableSegments.length && this.availableSegments[0].source;
		},

		selectedSourcePopover() {
			const selectedSourcePopover = this.popoverInformation[this.selectedSegmentTypeWithFallback];

			if (selectedSourcePopover) {
				return selectedSourcePopover;
			}

			return this.popoverInformation[this.availableSegmentsSource];
		},

		popoverText() {
			if (!this.selectedSourcePopover) {
				return null;
			}

			return this.selectedSourcePopover.text;
		},

		popoverLink() {
			if (!this.selectedSourcePopover) {
				return null;
			}

			return this.selectedSourcePopover.link;
		},

		isSegmentTypeSelectorVisible() {
			return this.segmentTypes.length > 1;
		}
	},
	methods: {
		onChangeSegment(segmentId) {
			this.$emit(EVENT_SEGMENT_CHANGED, segmentId);
		},

		onChangeSegmentType(segmentType) {
			this.selectedSegmentType = segmentType;
		}
	}
};

