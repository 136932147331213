import { httpGet } from './httpGet';
import { httpPost } from './httpPost';
import { httpPut } from './httpPut';
import { httpDelete } from './httpDelete';

import { options } from './options';
import { emails } from './emails';
import { queue } from './queue';
import { start } from './start';
import { pause } from './pause';
import { duplicate } from './duplicate';

export const sequenceEmailCampaigns = {
	get: httpGet,
	post: httpPost,
	put: httpPut,
	delete: httpDelete,
	options,
	emails,
	queue,
	start,
	pause,
	duplicate
};
