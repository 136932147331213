import {
	SETTINGS,
	ERRORS
} from './mutationTypes';

/**
 * Mutation definitions of the replyTracking store module.
 */
export const mutations = {
	[SETTINGS](state, settings) {
		state.settings = settings;
	},

	[ERRORS](state, errors) {
		state.errors = errors;
	}
};
