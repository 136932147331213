import { api } from 'api';
import router from 'router';
import utils from 'utils';
import logger from 'utils/logger';

import ROUTE_NAMES from 'router/route-names';

import {
	EMAIL,
	RECIPIENTS,
	CSV_DOWNLOAD_URL,
	SORT_ORDER,
	SORT_BY,
	GENERAL_ERRORS,
	PAGINATION
} from './mutationTypes';

import {
	SEQUENCE_NAVIGATE,
	RECIPIENT_NAVIGATE,
	EMAIL_GET,
	RECIPIENTS_GET,
	RECIPIENTS_SORT
} from './actionTypes';

const RECIPIENTS_PER_PAGE = 20;

export default {
	[SEQUENCE_NAVIGATE]({ state: { sequenceId } }) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_REPORT,
			params: {
				id: sequenceId
			}
		});
	},

	[RECIPIENT_NAVIGATE]({ state: { sequenceId, emailId } }, recipientId) {
		router.push({
			name: ROUTE_NAMES.CONTACTS_PROFILE,
			params: {
				id: recipientId
			},
			query: {
				sequence: sequenceId,
				email: emailId
			}
		});
	},

	async [EMAIL_GET]({
		commit,
		state: { sequenceId, emailId },
		rootState: { token }
	}) {
		try {
			const email = await api.sequenceEmailCampaigns.emails.get(token, sequenceId, emailId);

			commit(EMAIL, email);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [RECIPIENTS_GET]({
		commit,
		state: {
			emailId, sequenceId, pagination, sortOrder, sortBy
		},
		rootState: { token }
	}, page = 1) {
		if (pagination.currentPage === page) {
			return;
		}

		try {
			const recipientsData = await api.sequenceEmailCampaigns.emails.recipients.get(
				token,
				sequenceId,
				emailId,
				RECIPIENTS_PER_PAGE,
				page,
				sortBy,
				sortOrder
			);

			commit(RECIPIENTS, recipientsData.data);
			commit(CSV_DOWNLOAD_URL, recipientsData.downloadUrl);
			commit(PAGINATION, recipientsData.pagination);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [RECIPIENTS_SORT]({
		commit,
		state: {
			emailId, sequenceId, pagination, sortBy, sortOrder
		},
		rootState: { token }
	}, newSortBy) {
		let newSortOrder = sortOrder;
		const isSameSortBy = sortBy === newSortBy;

		if (isSameSortBy) {
			newSortOrder = utils.reverseSortOrder(sortOrder);
		}

		try {
			const recipientsData = await api.sequenceEmailCampaigns.emails.recipients.get(
				token,
				sequenceId,
				emailId,
				RECIPIENTS_PER_PAGE,
				pagination.currentPage,
				newSortBy,
				newSortOrder
			);

			commit(SORT_BY, newSortBy);
			commit(SORT_ORDER, newSortOrder);
			commit(RECIPIENTS, recipientsData.data);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
