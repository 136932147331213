import { BaseException } from 'utils/error';

/**
 * @class
 */
class TestException extends BaseException {
	/**
	 * @param {{ errors: Object[]}} result
	 */
	constructor(result) {
		super(result);
		this.name = 'TestException';
	}
}

export {
	TestException
};
