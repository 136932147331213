import { EntityTypes } from 'utils/constants';

const PIPEDRIVE_TYPES = {
	TEXT: 'text',
	VARCHAR: 'varchar',
	VARCHAR_AUTO: 'varchar_auto',
	DATE: 'date',
	DOUBLE: 'double',
	INT: 'int',
	ADDRESS: 'address',
	PHONE: 'phone',
	ENUM: 'enum'
};

const PipedriveDisplayTypes = Object.freeze({
	enum: 'single option',
	varchar: 'text',
	varchar_auto: 'autocomplete',
	double: 'numerical',
	set: 'multiple options',
	phone: 'phone',
	address: 'address',
	monetary: 'monetary',
	date: 'date'
});

const EXCLUDED_PIPEDRIVE_FIELDS_BY_NAME = ['name', 'email', 'visible to', 'organization'];

const fieldTypeMappings = {
	text: [
		PIPEDRIVE_TYPES.TEXT,
		PIPEDRIVE_TYPES.VARCHAR,
		PIPEDRIVE_TYPES.VARCHAR_AUTO
	],
	zip: [
		PIPEDRIVE_TYPES.TEXT
	],
	phone: [
		PIPEDRIVE_TYPES.TEXT,
		PIPEDRIVE_TYPES.PHONE
	],
	url: [
		PIPEDRIVE_TYPES.TEXT,
		PIPEDRIVE_TYPES.VARCHAR
	],
	number: [
		PIPEDRIVE_TYPES.DOUBLE,
		PIPEDRIVE_TYPES.INT
	],
	radio: [
		PIPEDRIVE_TYPES.TEXT,
		PIPEDRIVE_TYPES.VARCHAR,
		PIPEDRIVE_TYPES.ENUM
	],
	dropdown: [
		PIPEDRIVE_TYPES.TEXT,
		PIPEDRIVE_TYPES.VARCHAR,
		PIPEDRIVE_TYPES.ENUM
	],
	date: [
		PIPEDRIVE_TYPES.DATE,
		PIPEDRIVE_TYPES.TEXT,
		PIPEDRIVE_TYPES.VARCHAR
	],
	birthday: [
		PIPEDRIVE_TYPES.VARCHAR,
		PIPEDRIVE_TYPES.TEXT
	],
	address: [
		PIPEDRIVE_TYPES.ADDRESS
	]
};

const isUnmappedSourceField = (mappings, sourceField) => {
	const sources = mappings.map(mapping => mapping.source);

	return !sources.includes(sourceField.tag);
};

const isUnmappedDestinationField = (mappings, destinationField) => {
	const destinations = mappings.map(mapping => mapping.id);

	return !destinations.includes(destinationField.id);
};

const getDisplayType = type => {
	let displayType = type;

	if (PipedriveDisplayTypes[type]) {
		displayType = PipedriveDisplayTypes[type];
	}

	return displayType;
};

const shouldDisplayField = field => field.entityType === EntityTypes.ORGANIZATION
	|| !EXCLUDED_PIPEDRIVE_FIELDS_BY_NAME.includes(field.name.toLowerCase());

export default {
	matchingDestinationFields: ({ options, mappings }) => (type, selectedDestination) => {
		const matchingTypes = fieldTypeMappings[type];

		if (matchingTypes) {
			return options.crmFields
				.filter(shouldDisplayField)
				.map(destinationField => ({
					...destinationField,
					displayType: getDisplayType(destinationField.type)
				}))
				.map(destinationField => {
					const destField = destinationField;

					if (!isUnmappedDestinationField(mappings, destField)
						&& selectedDestination !== destField.id
					) {
						destField.disabled = true;
					}

					return destField;
				})
				.filter(crmField => matchingTypes.includes(crmField.type));
		}

		return options.crmFields;
	},

	unmappedSourceFields: ({ options, mappings }) => options
		.mergeFields
		.filter(sourceField => isUnmappedSourceField(mappings, sourceField)),

	unmappedDestinationFields: ({ options, mappings }) => options
		.crmFields
		.filter(destinationField => isUnmappedDestinationField(mappings, destinationField))
};
