//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ConnectorUpgradeIllustration from '../images/connector-upgrade-illustration.svg';

/**
 * @constant {String}
 */
const EVENT_CLOSE = 'close';
/**
 * @constant {String}
 */
const EVENT_NAVIGATE_BILLING = 'navigate:billing';

/**
 * @constant {String}
 */
const PRICING_PAGE = 'https://outfunnel.com/pricing/';

export default {
	components: {
		ConnectorUpgradeIllustration
	},
	props: {
		isVisible: {
			type: Boolean,
			required: false
		},
		contactSource: {
			type: String,
			required: false,
			default: ''
		},
		engagementEventSource: {
			type: String,
			required: false,
			default: ''
		}
	},

	data: () => ({
		links: {
			activecampaign: {
				pipedrive: 'https://support.outfunnel.com/en/articles/5126662-2-way-pipedrive-activecampaign-integration',
				hubspot: 'https://support.outfunnel.com/en/articles/5046859-2-way-hubspot-activecampaign-integration',
				copper: 'https://support.outfunnel.com/en/articles/5127007-2-way-copper-activecampaign-integration',
				airtable: 'https://support.outfunnel.com/en/articles/5537656-2-way-airtable-activecampaign-integration',
				salesforce: PRICING_PAGE
			},
			mailchimp: {
				pipedrive: 'https://support.outfunnel.com/en/articles/5216792-2-way-pipedrive-mailchimp-integration',
				hubspot: 'https://support.outfunnel.com/en/articles/4376897-2-way-hubspot-mailchimp-integration',
				copper: 'https://support.outfunnel.com/en/articles/3557293-2-way-copper-mailchimp-integration',
				airtable: 'https://support.outfunnel.com/en/articles/5537650-2-way-airtable-mailchimp-integration',
				salesforce: PRICING_PAGE
			},
			calendly: 'https://support.outfunnel.com/en/articles/5741443-calendly-crm-connection'
		}
	}),

	computed: {
		learnMoreLink() {
			if (this.engagementEventSource === 'calendly') {
				return this.links.calendly;
			}

			if (!this.links[this.engagementEventSource]) {
				return PRICING_PAGE;
			}

			return this.links[this.engagementEventSource][this.contactSource] ?? PRICING_PAGE;
		}
	},

	methods: {
		close() {
			this.$emit(EVENT_CLOSE);
		},
		onNavigateBilling() {
			this.$emit(EVENT_NAVIGATE_BILLING);
		}
	}
};

