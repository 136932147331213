import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { ContactCreationRulesException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {String=} params.contactCreationRuleId
 * @return {String}
 */
const getUrl = ({ connectionId, contactCreationRuleId }) => {
	if (contactCreationRuleId) {
		return endpoints.connectionsIdContactCreationRulesId(connectionId, contactCreationRuleId);
	}

	return endpoints.connectionsIdContactCreationRules(connectionId);
};

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {String} params.contactCreationRuleId
 * @param {String} params.token
 * @return {Promise<Object[]>}
 */
export const httpGet = async ({ connectionId, contactCreationRuleId, token }) => {
	const url = getUrl({ connectionId, contactCreationRuleId });

	const response = await request.get(url, token);

	if (!response.success) {
		return Promise.reject(new ContactCreationRulesException(response));
	}

	return response.data;
};
