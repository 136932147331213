import dropin from 'braintree-web-drop-in';
import logger from 'utils/logger';

/**
 * Braintree wrapper.
 */
class Braintree {
	/**
	 * Initialize braintree.
	 */
	constructor() {
		this.threeDS = true;
	}

	/**
	 * @param {Object} params
	 * @param {String} params.braintreeClientToken
	 * @param {String} params.ipAddress
	 * @param {String} params.customerUserAgent
	 * @param {String} params.container
	 * @param {Boolean} params.disableThreeDSecure
	 * @return {Promise<void>}
	 */
	async create({
		braintreeClientToken,
		ipAddress,
		customerUserAgent,
		container,
		disableThreeDSecure
	}) {
		this.ipAddress = ipAddress;
		this.customerUserAgent = customerUserAgent;
		this.container = container;
		this.braintreeClientToken = braintreeClientToken;
		this.threeDS = !disableThreeDSecure;
		this.disableThreeDSecure = disableThreeDSecure;

		const options = {
			authorization: this.braintreeClientToken,
			container: this.container,
			threeDSecure: !disableThreeDSecure
		};

		this.braintreeInstance = await dropin.create(options);
	}

	/**
	 *
	 * @param {Object} params
	 * @param {Object} params.billingAddress
	 * @param {String} params.email
	 * @param {Number} params.amount
	 * @return {Promise<Object>}
	 */
	async requestPaymentMethod({ billingAddress, email, amount }) {
		const options = {};

		if (this.threeDS) {
			options.threeDSecure = {
				amount,
				billingAddress,
				email,
				additionalInformation: {
					ipAddress: this.ipAddress,
					userAgent: this.customerUserAgent
				}
			};
		}

		try {
			const response = await this.braintreeInstance.requestPaymentMethod(
				options
			);

			/*
			 * @hack: Enable 3DS in case previous
			 * error was not related to 3DS support
			 */
			this.threeDS = !this.disableThreeDSecure;

			return response;
		} catch (error) {
			logger.error(error);

			await this.braintreeInstance.teardown();

			/*
			 * @hack: Disable 3DS to try reprocess payment
			 * in case error was about lacking 3DS support
			 */
			this.threeDS = false;

			this.braintreeInstance = await dropin.create({
				authorization: this.braintreeClientToken,
				container: this.container
			});

			return { error };
		}
	}
}

export default new Braintree();
