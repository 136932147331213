//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

	props: {
		isEmailToolIntegrationEnabled: {
			type: Boolean,
			default: true
		}
	},
	data: () => ({
		supportedFormsUrl: 'https://support.outfunnel.com/en/articles/3245436-how-to-use-website-visitor-tracking',
		learnMoreUrl: 'https://support.outfunnel.com/en/articles/3245436-how-to-use-website-visitor-tracking'
	}),

	computed: {
		noEmailsSentText() {
			if (this.isEmailToolIntegrationEnabled) {
				return 'You have not yet sent any email campaigns from Outfunnel or Mailchimp,'
					+ ' so we haven\'t been able to match your contacts to (otherwise anonymous) website visitors.';
			}

			return 'You have not yet sent any email campaigns from Outfunnel, '
				+ ' so we haven\'t been able to match your contacts to (otherwise anonymous) website visitors.';
		}
	}
};

