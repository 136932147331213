import { isString } from 'utils/types';
import { MODE } from '../types';

const getDefaultUserId = ({ users }) => {
	let userId = 0;

	if (users && users.length && users[0].id) {
		userId = users[0].id;
	}

	return userId;
};

export default {
	isCreateMode(state) {
		return (state.mode === MODE.CREATE);
	},

	isEditMode(state) {
		return (state.mode === MODE.EDIT);
	},

	isDealCreatingEnabled({ automation }) {
		if (automation) {
			return (automation.dealOpen || automation.dealClick);
		}
	},

	listId({ automation }) {
		const { list } = automation;
		let listId;

		if (list && isString(list.id)) {
			listId = list.id;
		}

		return listId;
	},

	listName({ automation }) {
		const { list } = automation;
		let listName = 'All lists';

		if (list && list.name) {
			listName = list.name;
		}

		return listName;
	},

	/**
	 * Get Pipedrive user ID for option given by optionKey.
	 * @param {Object} state - MailchimpToPipedrive store state.
	 * @param {Object} state.options - MC to PD options.
	 * @param {Object} state.automation - MC to PD automation loaded from backend.
	 * @return {Function} - A getter that accepts an optionKey argument.
	 */
	owner: ({ options, automation }) => (
		/**
		 * Find owner for option with specified ID.
		 * @param {String} optionKey - Key of the option that has an owner.
		 * @return {Number} - ID of the owner Pipedrive user.
		 */
		optionKey => {
			const owner = automation[optionKey];
			let ownerId = 0;

			if (owner && owner.id) {
				ownerId = owner.id;
			} else {
				ownerId = getDefaultUserId(options);
			}

			return ownerId;
		}
	),

	pipelineId({ options, automation }) {
		const { pipelines } = options;
		const { stage } = automation;
		let pipelineId = 0;

		if (pipelines && pipelines.length && stage) {
			const selectedPipeline = pipelines.find(pipeline => {
				const selectedStage = pipeline.stages.find(pipelineStage => pipelineStage.id === stage.id);

				return (selectedStage !== undefined);
			});

			pipelineId = selectedPipeline.id;
		}

		return pipelineId;
	},

	stageId({ automation }) {
		const { stage } = automation;
		let stageId = 0;

		if (stage) {
			stageId = stage.id;
		}

		return stageId;
	},

	stages({ options, selected }) {
		const { pipelines } = options;
		let stages = [];

		if (pipelines) {
			const selectedPipeline = pipelines.find(pipeline => (pipeline.id === selected.pipelineId));

			if (selectedPipeline) {
				({ stages } = selectedPipeline);
			}
		}

		return stages;
	},

	unsubscribeFieldId({ options, automation }) {
		const { fieldUnsubscribeTarget } = automation;
		const { personSetFields } = options;
		let fieldId = 0;

		if (fieldUnsubscribeTarget && fieldUnsubscribeTarget.field) {
			fieldId = fieldUnsubscribeTarget.field;
		} else if (personSetFields && personSetFields[0].id) {
			fieldId = personSetFields[0].id;
		}

		return fieldId;
	},

	unsubscribeFieldOptions({ options, selected }) {
		const { personSetFields } = options;
		let fieldOptions = [];

		if (personSetFields) {
			const unsubscribeField = personSetFields
				.find(field => (field.id === selected.unsubscribeFieldId));

			if (unsubscribeField) {
				fieldOptions = unsubscribeField.options;
			}
		}

		return fieldOptions;
	}
};
