//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const EVENT_CONFIRM = 'confirm';
const EVENT_CLOSE = 'close';
const EVENT_COPY = 'copy';

export default {
	props: {
		isVisible: {
			type: Boolean,
			default: false
		},
		pluginName: {
			type: String,
			required: true
		},
		trackingId: {
			type: String,
			required: true
		},
		accountEmail: {
			type: String,
			required: true
		},
		apiKey: {
			type: String,
			required: true
		},
		errors: {
			type: Array,
			default: null
		}
	},

	data: () => ({
		ofPluginUrl: 'https://wordpress.org/plugins/outfunnel/'
	}),

	computed: {
		dialogTitle() {
			return `Configure ${this.pluginName}`;
		}
	},

	methods: {
		confirm() {
			this.$emit(EVENT_CONFIRM);
		},
		close() {
			this.$emit(EVENT_CLOSE);
		},
		copy(itemName) {
			this.$emit(EVENT_COPY, itemName);
		},
		onTrackingIdFocus(event) {
			event.target.select();

			this.copy('Tracking ID');
		},
		onEmailFocus(event) {
			event.target.select();

			this.copy('Email');
		},
		onApiKeyFocus(event) {
			event.target.select();

			this.copy('API Key');
		}
	}
};

