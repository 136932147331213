import { httpGet } from './httpGet';
import { httpPost } from './httpPost';
import { httpPut } from './httpPut';
import { httpDelete } from './httpDelete';

import { options } from './options';
import { send } from './send';
import { test } from './test';
import { duplicate } from './duplicate';
import { recipients } from './recipients';
import { conversion } from './conversion';

export const singleEmailCampaigns = {
	get: httpGet,
	post: httpPost,
	put: httpPut,
	delete: httpDelete,
	options,
	send,
	test,
	duplicate,
	recipients,
	conversion
};
