//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_JOIN_BETA = 'join:beta';

export default {
	props: {
		isBetaJoined: {
			type: Boolean,
			default: false
		},
		betaJoinedMessage: {
			type: String,
			default: ''
		}
	},

	methods: {
		joinBeta() {
			this.$emit(EVENT_JOIN_BETA);
		}
	}
};

