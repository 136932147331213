//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ContactSyncArrow from 'images/contact-sync-arrow.svg';
import AppIcon from './AppIcon.vue';

export default {
	components: {
		AppIcon,
		ContactSyncArrow
	},
	props: {
		connection: {
			type: Object,
			required: true
		},
		contactSyncEventsCount: {
			type: Number,
			default: 0
		},
		contactSyncEventsUpdatedCount: {
			type: Number,
			default: 0
		},
		contactSyncEventsCreatedCount: {
			type: Number,
			default: 0
		}
	},

	computed: {
	},

	methods: {
	}
};

