import getInitialState from './state';

import {
	CAMPAIGN_ID,
	CAMPAIGN,
	GENERAL_ERRORS,
	RESET
} from './mutationTypes';

export default {
	[CAMPAIGN_ID](state, campaignId) {
		state.campaignId = campaignId;
	},

	[CAMPAIGN](state, campaign) {
		state.campaign = campaign;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	}
};
