import {
	RECIPIENTS_TYPES,
	SEGMENT_TYPES
} from 'store/modules/campaigns/sequence/edit/state';

export function getRecipientsType(filter) {
	if ([SEGMENT_TYPES.OUTFUNNEL, SEGMENT_TYPES.PIPEDRIVE].includes(filter.source)) {
		return filter.source;
	}

	let type = RECIPIENTS_TYPES.TAGS;

	if (filter.type === 'lead') {
		type = RECIPIENTS_TYPES.LEAD_STATUS;
	} else if (filter.type === 'contact') {
		type = RECIPIENTS_TYPES.CONTACT_TYPE;
	} else if (filter.id === 0) {
		type = RECIPIENTS_TYPES.ALL;
	}

	return type;
}
