//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { createNamespacedHelpers } from 'vuex';
import { RESET } from '../store/modules/notifications/mutationTypes';

const {
	mapState
} = createNamespacedHelpers('notifications');

export default {
	computed: {
		...mapState([
			'isVisible',
			'message'
		])
	},

	methods: {
		close() {
			this.$store.commit(`notifications/${RESET}`, { root: true });
		}
	}
};

