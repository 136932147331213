import {
	RECIPIENTS_TYPES
} from 'store/modules/automations/copperToMailchimp/state';

export function getRecipientsType(filter) {
	let type = RECIPIENTS_TYPES.TAGS;

	if (filter.type === 'lead') {
		type = RECIPIENTS_TYPES.LEAD_STATUS;
	} else if (filter.type === 'contact') {
		type = RECIPIENTS_TYPES.CONTACT_TYPE;
	} else if (filter.id === 0) {
		type = RECIPIENTS_TYPES.ALL;
	}

	return type;
}
