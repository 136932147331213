import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { FlagsException } from './helpers';

/**
 * Add new flag to user.
 * @param {String} token - JWT Authorization token.
 * @param {String} name - Name of the flag to be added.
 * @return {Promise<Object>}
 */
async function httpPost(token, name) {
	const result = await request.post(`${endpoints.userFlags}/${name}`, null, token);

	if (!result.success) {
		return Promise.reject(new FlagsException(result));
	}

	return result.data;
}

export {
	httpPost
};
