import { api } from 'api';
import { Flags } from 'store/modules/user/helpers';
import { GET_USER } from 'store/modules/user/actionTypes';
import logger from 'utils/logger';
import ROUTE_NAMES from 'router/route-names';
import router from 'router';
import {
	COUNTERS,
	CHANNEL_REPORT,
	CHANNEL_REPORT_OPTIONS,
	CHANNEL_REPORT_PERIOD,
	GENERAL_ERRORS
} from './mutationTypes';

import {
	COUNTERS_GET,
	CHANNEL_REPORT_GET,
	CHANNEL_REPORT_OPTIONS_GET,
	CHANNEL_REPORT_PERIOD_EDIT,
	WEBTRACKING_INFOBOX_TOGGLE,
	BILLING_NAVIGATE
} from './actionTypes';

export const actions = {
	[COUNTERS_GET]: async ({ rootState: { token }, commit }) => {
		try {
			const counters = await api.tracking.counters.get(token);

			commit(COUNTERS, counters);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CHANNEL_REPORT_GET]: async ({ state, rootState: { token }, commit }) => {
		try {
			const channelReport = await api.tracking.channelReport.get({
				token,
				period: state.period
			});

			commit(CHANNEL_REPORT, channelReport);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CHANNEL_REPORT_OPTIONS_GET]: async ({ rootState: { token }, commit }) => {
		try {
			const channelReportOptions = await api.tracking.channelReport.options.get(token);

			commit(CHANNEL_REPORT_OPTIONS, channelReportOptions);

			const { period } = channelReportOptions;

			if (period && period.default) {
				commit(CHANNEL_REPORT_PERIOD, period.default);
			}
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CHANNEL_REPORT_PERIOD_EDIT]: async ({ commit, dispatch }, period) => {
		try {
			commit(CHANNEL_REPORT_PERIOD, period);

			dispatch(CHANNEL_REPORT_GET);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[WEBTRACKING_INFOBOX_TOGGLE]: async ({
		rootState: { token, user },
		dispatch,
		commit
	}) => {
		try {
			if (user.profile.flags.includes(Flags.WEB_TRACKING_MSG)) {
				await api.user.flags.delete(token, Flags.WEB_TRACKING_MSG);
			} else {
				await api.user.flags.post(token, Flags.WEB_TRACKING_MSG);
			}

			dispatch(`user/${GET_USER}`, null, { root: true });
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[BILLING_NAVIGATE]: async () => {
		router.push({ name: ROUTE_NAMES.ACCOUNT_BILLING });
	}
};
