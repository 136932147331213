import utils from 'utils';
import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import { VALUE_COPY } from './actionTypes';

export const actions = {

	[VALUE_COPY]: ({ dispatch }) => {
		utils.copySelectedText();

		dispatch(
			`notifications/${SHOW_NOTIFICATION}`,
			'Link copied to clipboard',
			{ root: true }
		);
	}
};
