import { BaseException } from 'utils/error';

/**
 * @class
 */
class EmailSettingsFooterException extends BaseException {
	/**
	 * @param {{
	 * success: Boolean,
	 * status: Number,
	 * errors: Object[]
	 * }} result
	 */
	constructor(result) {
		super(result);
		this.name = 'EmailSettingsFooterException';
	}
}

export {
	EmailSettingsFooterException
};
