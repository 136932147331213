import { getConfValue } from 'utils/appconfig';
import urljoin from 'url-join';
import { Providers } from './constants';

/**
 * @param {String} action
 * @return {String}
 */
export const getPipedriveOauthDialogUrl = (action = 'default') => {
	const { clientId, oauthUrl, redirectUrl } = getConfValue(Providers.PIPEDRIVE);

	const oauthDialogUrl = new URL(urljoin(oauthUrl, 'authorize'));

	oauthDialogUrl.searchParams.set('client_id', clientId);
	oauthDialogUrl.searchParams.set('redirect_uri', redirectUrl);
	oauthDialogUrl.searchParams.set('response_type', 'code');
	oauthDialogUrl.searchParams.set('state', action);

	return oauthDialogUrl.href;
};
