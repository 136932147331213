import { Flags } from 'store/modules/user/helpers';

export default {
	isInfoboxVisible(state, otherGetters, { user }) {
		return !user.profile.flags.includes(Flags.WEB_TRACKING_MSG);
	},

	isTrackingActive({ counters }) {
		return counters.totalEvents > 0;
	}
};
