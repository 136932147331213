//
//
//
//
//
//


import { STATUS_TYPE } from '../store/modules/automations/types';

export default {
	props: {
		status: {
			type: Object,
			required: true
		}
	},

	computed: {
		color() {
			if (this.status.type === STATUS_TYPE.FAILED) {
				return 'red--text';
			}

			return 'primary--text';
		}
	}
};

