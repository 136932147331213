import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { FlagsException } from './helpers';

/**
 * Delete an user flag.
 * @param {String} token - JWT Authorization token.
 * @param {String} name - Name of the flag to be deleted.
 * @return {Promise<Object>}
 */
async function httpDelete(token, name) {
	const result = await request.delete(`${endpoints.userFlags}/${name}`, token);

	if (!result.success) {
		return Promise.reject(new FlagsException(result));
	}

	return result.data;
}

export {
	httpDelete
};
