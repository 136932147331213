import posthog from 'posthog-js';
import { getIsLoggedInAsUser } from 'utils/storage';

export const initializePosthog = () => {
	if (process.env.SERVICE_ENV === 'development') {
		return;
	}

	posthog.init(
		process.env.POSTHOG_API_KEY,
		{ api_host: process.env.POSTHOG_API_HOST }
	);
};

export const identify = ({ userId, properties }) => {
	if (getIsLoggedInAsUser()) {
		return;
	}

	posthog.identify(userId, properties);
};

export const track = ({ event, properties }) => {
	if (getIsLoggedInAsUser()) {
		return;
	}

	posthog.capture(event, properties);
};
