/**
 * @constant {{
 * CACHE_CONTROL: String
 * }}
 */
const Headers = Object.freeze({
	CACHE_CONTROL: 'cache-control'
});

module.exports = Headers;
