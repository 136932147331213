export const CONFIRM = 'CONFIRM';
export const DIALOG_TEST_EMAIL_SHOW = 'DIALOG_TEST_EMAIL_SHOW';
export const DIALOG_TEST_EMAIL_EDIT = 'DIALOG_TEST_EMAIL_EDIT';
export const DIALOG_TEST_EMAIL_REPLACE_MERGE_FIELDS = 'DIALOG_TEST_EMAIL_REPLACE_MERGE_FIELDS';
export const DIALOG_TEST_EMAIL_SEND = 'DIALOG_TEST_EMAIL_SEND';
export const DIALOG_TEST_EMAIL_CLOSE = 'DIALOG_TEST_EMAIL_CLOSE';
export const DIALOG_CONFIRMATION_ACTIVATE = 'DIALOG_CONFIRMATION_ACTIVATE';
export const DIALOG_CONFIRMATION_CLOSE = 'DIALOG_CONFIRMATION_CLOSE';
export const DIALOG_SENDING_LIMITS_SHOW = 'DIALOG_SENDING_LIMITS_SHOW';
export const DIALOG_SENDING_LIMITS_CLOSE = 'DIALOG_SENDING_LIMITS_CLOSE';
export const EMAIL_OPTIONS_GET = 'EMAIL_OPTIONS_GET';
export const EMAIL_ADD = 'EMAIL_ADD';
export const EMAIL_EDIT = 'EMAIL_EDIT';
export const EMAIL_DELETE = 'EMAIL_DELETE';
export const EMAIL_ENGAGEMENT_SETTINGS_GET = 'EMAIL_ENGAGEMENT_SETTINGS_GET';
export const EMAIL_SCHEDULE_SETTINGS_GET = 'EMAIL_SCHEDULE_SETTINGS_GET';
export const EMAIL_SCHEDULE_SETTINGS_OPTIONS_GET = 'EMAIL_SCHEDULE_SETTINGS_OPTIONS_GET';
export const ERRORS_SHOW = 'ERRORS_SHOW';
export const FIELDS_EDIT = 'FIELDS_EDIT';
export const INFOBOX_DELIVERABILITY_DISMISS = 'INFOBOX_DELIVERABILITY_DISMISS';
export const LIST_NAVIGATE = 'LIST_NAVIGATE';
export const SEQUENCE_GET = 'SEQUENCE_GET';
export const SEQUENCE_OPTIONS_GET = 'SEQUENCE_OPTIONS_GET';
export const STEP_NAVIGATE = 'STEP_NAVIGATE';
export const TRIGGER_EDIT = 'TRIGGER_EDIT';
