//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { getLocaleFormattedDate } from 'utils/formatting';
import { IntlFormatOptions } from 'utils/constants';

export default {
	props: {
		allEventsCount: {
			type: Number,
			required: true
		},
		allErrorsCount: {
			type: Number,
			required: true
		},
		latestEventAt: {
			type: String,
			default: null
		},
		locale: {
			type: String,
			default: 'en-US'
		}
	},

	computed: {
		formattedLatestEventAt() {
			if (!this.latestEventAt) {
				return 'No events synced';
			}

			return getLocaleFormattedDate(
				this.latestEventAt,
				this.locale,
				{
					year: IntlFormatOptions.NUMERIC,
					month: IntlFormatOptions.NUMERIC,
					day: IntlFormatOptions.NUMERIC,
					hour: IntlFormatOptions.NUMERIC,
					minute: IntlFormatOptions.NUMERIC
				}
			);
		}
	}
};

