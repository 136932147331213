import getInitialState from './state';
import {
	FOOTER,
	ERRORS,
	OPTIONS,
	RESET,
	IMAGE
} from './mutationTypes';

export default {
	[FOOTER](state, footer) {
		state.footer = footer;
	},

	[ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[OPTIONS](state, options) {
		state.options = options;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	},

	[IMAGE](state, { uploadId, imageUrl }) {
		state.uploadedImages = {
			...state.uploadedImages,
			[uploadId]: imageUrl
		};
	}
};
