import { endpoints } from 'api/endpoints';

import { request } from 'utils/request';

import { EventsException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.token
 * @param {String} params.personId
 * @param {Number} params.limit
 * @param {Number} params.page
 * @return {Promise<{events: Object[], pagination: Object}>}
 */
async function httpGet({
	token,
	personId,
	limit,
	page
}) {
	const url = new URL(endpoints.personsIdEvents(personId));
	const searchParams = new URLSearchParams();

	searchParams.set('sortBy', 'created');
	searchParams.set('sortOrder', 'desc');

	if (limit) {
		searchParams.set('limit', limit);
	}

	if (page) {
		searchParams.set('page', page);
	}

	url.search = searchParams.toString();

	const result = await request.get(url.toString(), token);

	if (!result.success) {
		return Promise.reject(new EventsException(result));
	}

	const { data: events, pagination } = result;

	return {
		events,
		pagination
	};
}

export {
	httpGet
};
