export const WELCOME_DISMISS = 'WELCOME_DISMISS';
export const NAVIGATE_DASHBOARD = 'NAVIGATE_DASHBOARD';
export const CAMPAIGNS_GET = 'CAMPAIGNS_GET';
export const CAMPAIGN_CREATE = 'CAMPAIGN_CREATE';
export const CAMPAIGN_EDIT = 'CAMPAIGN_EDIT';
export const CAMPAIGN_DUPLICATE = 'CAMPAIGN_DUPLICATE';
export const CAMPAIGN_DELETE = 'CAMPAIGN_DELETE';
export const CAMPAIGN_REPORT_VIEW = 'CAMPAIGN_REPORT_VIEW';
export const EMAIL_VIEW = 'EMAIL_VIEW';
export const OPTIONS_GET = 'OPTIONS_GET';
