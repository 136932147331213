import { getFormattedPercentage } from 'utils/formatting';

export default {
	openedPercentage() {
		return counters => getFormattedPercentage(counters.opened, counters.queued);
	},

	clickedPercentage() {
		return counters => getFormattedPercentage(counters.clicked, counters.queued);
	},

	repliedPercentage() {
		return counters => getFormattedPercentage(counters.replied, counters.queued);
	}
};
