import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { LeadScoringSettingsException } from './helpers';

/**
 * Create lead scoring settings.
 * @param {String} token - JWT Authorization token.
 * @param {Object} settings - User lead scoring settings.
 * @return {Promise<Object>}
 */
async function httpPost(token, settings) {
	const result = await request.post(endpoints.userSettings.leadScoring, settings, token);

	if (!result.success) {
		return Promise.reject(new LeadScoringSettingsException(result));
	}

	return result.data;
}

export {
	httpPost
};
