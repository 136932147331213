import {
	APPS,
	GENERAL_ERRORS,
	CONNECTION_CONTACT_SOURCE,
	CONNECTION_ENGAGEMENT_SOURCE,
	CONNECTION_DIALOG_RESEARCH_VISIBILITY,
	APP_AUTH_DIALOG_VISIBILITY,
	APP_AUTH_APP_ID,
	APP_AUTH_DATA,
	APP_AUTH_ACCOUNT_ID,
	APP_AUTH_API_KEY,
	APP_AUTH_ERRORS,
	WP_PLUGIN_AUTH_DIALOG_VISIBILITY,
	WP_PLUGIN_AUTH_PLUGIN_NAME,
	WP_PLUGIN_AUTH_ERRORS,
	DIALOG_SUGGEST_APP_VISIBILITY,
	DIALOG_HUBSPOT_MKT_CONTACT_SYNC_VISIBILITY,
	RESET,
	CONTACT_SYNC_LEARN_MORE_DIALOG_VISIBILITY,
	ENGAGEMENT_SYNC_LEARN_MORE_DIALOG_VISIBILITY
} from './mutationTypes';

import { getInitialState } from './state';

export const mutations = {
	[APPS](state, apps) {
		state.apps = apps;
	},

	[GENERAL_ERRORS](state, errors) {
		state.errors = errors;
	},

	[CONNECTION_CONTACT_SOURCE](state, app) {
		state.connectionContactSource = app;
	},

	[CONNECTION_ENGAGEMENT_SOURCE](state, app) {
		state.connectionEngagementSource = app;
	},

	[CONNECTION_DIALOG_RESEARCH_VISIBILITY](state, isVisible) {
		state.isConnectionDialogResearchVisible = isVisible;
	},

	[DIALOG_SUGGEST_APP_VISIBILITY](state, isVisible) {
		state.isDialogSuggestAppVisible = isVisible;
	},

	[APP_AUTH_APP_ID](state, appId) {
		state.appAuthAppId = appId;
	},

	[APP_AUTH_DATA](state, authData) {
		state.appAuthData = { ...state.appAuthData, ...authData };
	},

	[APP_AUTH_DIALOG_VISIBILITY](state, isVisible) {
		state.isAppAuthDialogVisible = isVisible;
	},

	[APP_AUTH_ACCOUNT_ID](state, accountId) {
		state.appAuthAccountId = accountId;
	},

	[APP_AUTH_API_KEY](state, apiKey) {
		state.appAuthApiKey = apiKey;
	},

	[APP_AUTH_ERRORS](state, errors) {
		state.appAuthErrors = errors;
	},

	[WP_PLUGIN_AUTH_DIALOG_VISIBILITY](state, isVisible) {
		state.isWpPluginAuthDialogVisible = isVisible;
	},

	[WP_PLUGIN_AUTH_PLUGIN_NAME](state, name) {
		state.wpPluginAuthPluginName = name;
	},

	[WP_PLUGIN_AUTH_ERRORS](state, errors) {
		state.wpPluginAuthErrors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	},

	[DIALOG_HUBSPOT_MKT_CONTACT_SYNC_VISIBILITY](state, isVisible) {
		state.isDialogHubspotMktContactSyncVisible = isVisible;
	},
	[CONTACT_SYNC_LEARN_MORE_DIALOG_VISIBILITY](state, isVisible) {
		state.isContactSyncLearnMoreDialogVisible = isVisible;
	},

	[ENGAGEMENT_SYNC_LEARN_MORE_DIALOG_VISIBILITY](state, isVisible) {
		state.isEngagementSyncLearnMoreDialogVisible = isVisible;
	}
};
