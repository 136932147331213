
export const APPS = 'APPS';
export const GENERAL_ERRORS = 'GENERAL_ERRORS';
export const CONNECTION_ENGAGEMENT_SOURCE = 'CONNECTION_ENGAGEMENT_SOURCE';
export const CONNECTION_CONTACT_SOURCE = 'CONNECTION_CONTACT_SOURCE';
export const CONNECTION_DIALOG_RESEARCH_VISIBILITY = 'CONNECTION_DIALOG_RESEARCH_VISIBILITY';
export const APP_AUTH_DIALOG_VISIBILITY = 'APP_AUTH_DIALOG_VISIBILITY';
export const DIALOG_SUGGEST_APP_VISIBILITY = 'DIALOG_SUGGEST_APP_VISIBILITY';
export const DIALOG_HUBSPOT_MKT_CONTACT_SYNC_VISIBILITY = 'DIALOG_HUBSPOT_MKT_CONTACT_SYNC_VISIBILITY';

export const APP_AUTH_APP_ID = 'APP_AUTH_APP_ID';
export const APP_AUTH_DATA = 'APP_AUTH_DATA';
export const APP_AUTH_ACCOUNT_ID = 'APP_AUTH_ACCOUNT_ID';
export const APP_AUTH_API_KEY = 'APP_AUTH_API_KEY';
export const APP_AUTH_ERRORS = 'APP_AUTH_ERRORS';

export const WP_PLUGIN_AUTH_DIALOG_VISIBILITY = 'WP_PLUGIN_AUTH_DIALOG_VISIBILITY';
export const WP_PLUGIN_AUTH_PLUGIN_NAME = 'WP_PLUGIN_AUTH_PLUGIN_NAME';
export const WP_PLUGIN_AUTH_ERRORS = 'WP_PLUGIN_AUTH_ERRORS';
export const RESET = 'RESET';

export const CONTACT_SYNC_LEARN_MORE_DIALOG_VISIBILITY = 'CONTACT_SYNC_LEARN_MORE_DIALOG_VISIBILITY';
export const ENGAGEMENT_SYNC_LEARN_MORE_DIALOG_VISIBILITY = 'ENGAGEMENT_SYNC_LEARN_MORE_DIALOG_VISIBILITY';
