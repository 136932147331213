//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { getLocaleFormattedDate } from 'utils/formatting';
import { IntlFormatOptions } from 'utils/constants';
import { EventNames, track } from 'utils/analytics';

const EVENT_SELECT_PAGE = 'select:page';

export default {
	props: {
		errorFeed: {
			type: Array,
			required: true
		},
		locale: {
			type: String,
			required: true
		},
		timezone: {
			type: String,
			required: true
		},
		pagination: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		learnMoreLink: 'https://support.outfunnel.com/en/articles/3245428-common-reasons-data-is-not-syncing-as-expected'
	}),

	computed: {
	},

	methods: {
		localizeDate(date) {
			return getLocaleFormattedDate(
				date,
				this.locale,
				{
					day: IntlFormatOptions.TWO_DIGIT,
					month: IntlFormatOptions.TWO_DIGIT,
					year: IntlFormatOptions.TWO_DIGIT,
					hour: IntlFormatOptions.NUMERIC,
					minute: IntlFormatOptions.NUMERIC,
					second: IntlFormatOptions.NUMERIC,
					timeZone: this.timezone
				}
			);
		},

		onSelectPage(page) {
			track({ event: EventNames.SELECT_FEED_PAGE, properties: { page } });

			this.$emit(EVENT_SELECT_PAGE, page);
		}
	}
};

