import { request } from 'utils/request';
import { getAutomationUrl, AutomationsException } from './helpers';

/**
 * Update an Automation.
 * @param {String} token - JWT Authorization token.
 * @param {String} automationId - ID of the automation to update.
 * @param {Object} data - Fields to be updated.
 */
async function httpPut(token, automationId, data) {
	const url = getAutomationUrl(automationId);
	const result = await request.put(url, data, token);

	if (!result.success) {
		return Promise.reject(new AutomationsException(result));
	}

	return result.data;
}

export {
	httpPut
};
