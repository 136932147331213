export const MODE = 'MODE';
export const STATUS = 'STATUS';
export const OPTIONS = 'OPTIONS';
export const AUTOMATION = 'AUTOMATION';
export const OPTION = 'OPTION';
export const ERRORS = 'ERRORS';
export const GENERAL_ERRORS = 'GENERAL_ERRORS';
export const FIELD_ERRORS = 'FIELD_ERRORS';
export const FIELD_ERRORS_CLEAR = 'FIELD_ERRORS_CLEAR';
export const RESET = 'RESET';
export const LIST = 'LIST';
export const FILTER = 'FILTER';
export const SELECTED_PERSONFIELDS = 'SELECTED_PERSONFIELDS';
export const SELECTED_ORGFIELDS = 'SELECTED_ORGFIELDS';
export const RECIPIENTS_TYPE = 'RECIPIENTS_TYPE';
export const VISIBLE_FIELDS = 'VISIBLE_FIELDS';
