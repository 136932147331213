//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	inheritAttrs: false,
	props: {
		sortable: {
			type: Boolean,
			default: true
		},
		sorted: {
			type: Boolean,
			default: false
		},
		sortOrder: {
			type: String,
			default: 'desc'
		}
	},
	computed: {
		isDescending() {
			return this.sortOrder === 'desc';
		},
		classes() {
			const classes = [];

			if (this.sortable) {
				classes.push('link');
			}

			if (this.sorted) {
				classes.push('sorted');
			}

			return classes.join(' ');
		}
	}
};

