/* eslint-disable max-lines */
import router from 'router';
import { api } from 'api';
import logger from 'utils/logger';
import { Features } from 'utils/constants';

import ROUTE_NAMES from 'router/route-names';

import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import {
	PLAN_SELECT
} from 'store/modules/account/billing/actionTypes';
import {
	getLeadScoreInstructionBoxState,
	setLeadScoreInstructionBoxState
} from 'utils/storage';
import {
	BILLING_NAVIGATE,
	ADD_RULE_ERRORS,
	ADD_CRM_RULE_ERRORS,
	ADD_CRM_FIELD_RULE_ERRORS,
	COUNTERS_GET,
	LIST_NAVIGATE,
	MOUNT,
	MOUNT_DASHBOARD,
	OPTIONS_GET,
	SETTINGS_GET,
	FIELDS_EDIT,
	RULES_EDIT,
	PLAN_UPGRADE,
	ACTIVITY_TYPES_GET,
	LEAD_SCORE_INSTRUCTION_BOX_GET,
	LEAD_SCORE_INSTRUCTION_BOX_EDIT,
	CONTACTS_FIELDS_GET
} from './actionTypes';
import {
	COUNTERS,
	CRM_RULE_ERRORS,
	CRM_FIELD_ERRORS,
	LOADING,
	LEAD_SCORE_RULES_LIMIT,
	OPTIONS,
	SETTINGS,
	RULES,
	ADD_RULE,
	FIELDS,
	ERRORS,
	RULE_ERRORS,
	ACTIVITY_TYPES,
	LEAD_SCORE_CRM_ACTIVITIES_ENABLED,
	LEAD_SCORE_INSTRUCTION_BOX,
	CONTACTS_FIELDS
} from './mutationTypes';

const USER_IS_FEATURES_ENABLED = 'user/isFeatureEnabled';

const RULES_NOTIFICATION_MESSAGE = 'Lead score rule updated. Existing scores will be updated within 10 minutes.';

const InstructionBoxToggleValues = Object.freeze({
	OPEN: 'open',
	CLOSED: 'closed'
});

const getNotificationMessage = ({ leadScoreSettings }) => {
	if (!leadScoreSettings?.active) {
		return 'Settings updated';
	}

	return RULES_NOTIFICATION_MESSAGE;
};

export default {
	[BILLING_NAVIGATE]: async () => {
		router.push({ name: ROUTE_NAMES.ACCOUNT_BILLING });
	},

	[COUNTERS_GET]: async ({ rootState: { token }, commit }) => {
		try {
			const counters = await api.tracking.counters.get(token);

			commit(COUNTERS, counters);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [ADD_RULE_ERRORS]({
		commit
	}, errors) {
		commit(RULE_ERRORS, errors);
	},

	async [ADD_CRM_RULE_ERRORS]({
		commit
	}, errors) {
		commit(CRM_RULE_ERRORS, errors);
	},

	async [ADD_CRM_FIELD_RULE_ERRORS]({
		commit
	}, errors) {
		commit(CRM_FIELD_ERRORS, errors);
	},

	[LIST_NAVIGATE]() {
		router.push({
			name: ROUTE_NAMES.AUTOMATIONS
		});
	},

	[MOUNT]: async ({ dispatch, rootGetters }) => {
		const areFeaturesLoaded = rootGetters['user/areFeaturesLoaded'];

		if (!areFeaturesLoaded) {
			await dispatch('user/FEATURES_GET', null, { root: true });
		}

		const isFeatureEnabled = rootGetters[USER_IS_FEATURES_ENABLED](Features.LEAD_SCORING);
		const areActiveContactPricingPlansEnabled = rootGetters['user/areActiveContactPricingPlansEnabled'];

		if (!isFeatureEnabled && !areActiveContactPricingPlansEnabled) {
			return;
		}

		router.push({ name: ROUTE_NAMES.LEAD_SCORING_DASHBOARD });
	},

	[MOUNT_DASHBOARD]: async ({ commit, dispatch, rootGetters }) => {
		const isUserFetched = rootGetters['user/isUserFetched'];

		if (!isUserFetched) {
			await dispatch('user/GET_USER', null, { root: true });
		}

		const areFeaturesLoaded = rootGetters['user/areFeaturesLoaded'];

		if (!areFeaturesLoaded) {
			await dispatch('user/FEATURES_GET', null, { root: true });
		}

		const isFeatureEnabled = rootGetters[USER_IS_FEATURES_ENABLED](Features.LEAD_SCORING);
		const areActiveContactPricingPlansEnabled = rootGetters['user/areActiveContactPricingPlansEnabled'];

		const isCrmActivitiesLeadScoringRulesForOldPlansEnabled = rootGetters['user/isCrmActivitiesLeadScoringRulesForOldPlansEnabled'];
		const crmActivitiesFeatureEnabled = rootGetters[USER_IS_FEATURES_ENABLED](
			Features.CRM_ACTIVITIES
		);

		// eslint-disable-next-line max-len
		const leadScoringCrmActivitiesEnabled = crmActivitiesFeatureEnabled || isCrmActivitiesLeadScoringRulesForOldPlansEnabled;

		if (!isFeatureEnabled && !areActiveContactPricingPlansEnabled) {
			router.replace({ name: ROUTE_NAMES.LEAD_SCORING });

			return;
		}

		const leadScoreRulesLimit = rootGetters['user/getLeadScoreRulesLimit'](
			Features.LEAD_SCORING
		);

		commit(LEAD_SCORE_RULES_LIMIT, leadScoreRulesLimit);
		commit(LEAD_SCORE_CRM_ACTIVITIES_ENABLED, leadScoringCrmActivitiesEnabled);

		dispatch(OPTIONS_GET);
		dispatch(COUNTERS_GET);
		dispatch(ACTIVITY_TYPES_GET);
		dispatch(LEAD_SCORE_INSTRUCTION_BOX_GET);
		dispatch(CONTACTS_FIELDS_GET);
		dispatch(SETTINGS_GET);
	},

	[OPTIONS_GET]: async ({ commit, rootState: { token } }) => {
		try {
			const options = await api.user.settings.leadScoring.options.get(token);

			commit(OPTIONS, options);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[SETTINGS_GET]: async ({ commit, rootState: { token } }) => {
		try {
			commit(LOADING, true);
			const settings = await api.user.settings.leadScoring.get(token);

			commit(LOADING, false);
			commit(SETTINGS, settings);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [FIELDS_EDIT]({
		commit,
		dispatch,
		rootState: { token }
	}, fields) {
		commit(FIELDS, fields);

		try {
			commit(ERRORS, []);
			const updatedSettings = await api.user.settings.leadScoring.put(
				token, fields
			);

			commit(SETTINGS, updatedSettings);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				getNotificationMessage({ leadScoreSettings: updatedSettings }),
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [RULES_EDIT]({
		commit,
		dispatch,
		rootState: { token }
	}, rule) {
		try {
			commit(ERRORS, []);
			commit(RULE_ERRORS, []);
			commit(CRM_RULE_ERRORS, []);
			commit(CRM_FIELD_ERRORS, []);
			commit(LOADING, true);
			const updatedSettings = await api.user.settings.leadScoring.put(
				token, {
					rules: [rule]
				}
			);

			commit(SETTINGS, updatedSettings);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				getNotificationMessage({ leadScoreSettings: updatedSettings }),
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		} finally {
			commit(LOADING, false);
		}
	},

	async [ADD_RULE]({
		commit,
		dispatch,
		rootState: { token }
	}, rule, rules) {
		commit(ADD_RULE, rule);
		commit(RULES, rules);

		try {
			commit(ERRORS, []);
			commit(LOADING, true);
			const updatedSettings = await api.user.settings.leadScoring.put(
				token, {
					rules: [rule]
				}
			);

			commit(SETTINGS, updatedSettings);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				getNotificationMessage({ leadScoreSettings: updatedSettings }),
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		} finally {
			commit(LOADING, false);
		}
	},

	async [PLAN_UPGRADE]({
		dispatch,
		rootState,
		rootGetters
	}) {
		const requiredPlanId = rootGetters['user/requiredPlanForFeature'](Features.LEAD_SCORING);
		const requiredPlan = rootState.account.billing.plans.find(plan => plan.id === requiredPlanId);

		await dispatch(`account/billing/${PLAN_SELECT}`, requiredPlan._id, { root: true });

		const isFeatureEnabled = rootGetters[USER_IS_FEATURES_ENABLED](Features.LEAD_SCORING);

		if (!isFeatureEnabled) {
			return;
		}

		router.push({ name: ROUTE_NAMES.LEAD_SCORING_DASHBOARD });

		dispatch(
			`notifications/${SHOW_NOTIFICATION}`,
			`You are now on ${requiredPlan.name} plan`,
			{ root: true }
		);
	},

	[ACTIVITY_TYPES_GET]: async ({ commit, rootState: { token }, rootGetters }) => {
		const crmLeadScoringEnabled = rootGetters['user/isCrmActivitiesLeadScoringEnabled'];
		const source = rootGetters['user/activeContactSourceId'];
		const isValidActivitySourceConnected = rootGetters['user/isValidActivitySourceConnected'];

		const isValid = [crmLeadScoringEnabled, source, isValidActivitySourceConnected].every(Boolean);

		if (!isValid) {
			return commit(ACTIVITY_TYPES, []);
		}

		try {
			const types = await api.user.settings.leadScoring.options.activityTypes.get(token, source);

			commit(ACTIVITY_TYPES, types);
		} catch (exception) {
			if (exception.errors && crmLeadScoringEnabled) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONTACTS_FIELDS_GET]: async ({ commit, rootState: { token }, rootGetters }) => {
		const crmLeadScoringEnabled = rootGetters['user/isCrmActivitiesLeadScoringEnabled'];
		const source = rootGetters['user/activeContactSourceId'];

		if (!crmLeadScoringEnabled || !source) {
			return commit(CONTACTS_FIELDS, []);
		}

		try {
			const fields = await api.user.settings.leadScoring.options.contacts.fields.get({
				token,
				source
			});

			commit(CONTACTS_FIELDS, fields);
		} catch (exception) {
			if (exception.errors && crmLeadScoringEnabled) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[LEAD_SCORE_INSTRUCTION_BOX_EDIT]: async ({
		commit
	}, state) => {
		setLeadScoreInstructionBoxState(state);

		return commit(LEAD_SCORE_INSTRUCTION_BOX, state);
	},

	[LEAD_SCORE_INSTRUCTION_BOX_GET]: async ({
		commit
	}) => {
		const state = getLeadScoreInstructionBoxState();

		if (!state) {
			commit(LEAD_SCORE_INSTRUCTION_BOX, InstructionBoxToggleValues.OPEN);
			setLeadScoreInstructionBoxState(InstructionBoxToggleValues.OPEN);

			return;
		}

		return commit(LEAD_SCORE_INSTRUCTION_BOX, state);
	}
};
