import { capitalize } from 'utils/formatting';
import utils from 'utils';
import { Providers } from './constants';
import { getPipedriveOauthDialogUrl } from './pipedrive';
import { getMailchimpOauthDialogUrl } from './mailchimp';
import { getHubspotMktOauthDialogUrl, getHubspotOauthDialogUrl } from './hubspot';
import { getWixOauthDialogUrl } from './wix';
import { getFacebookAdsOauthDialogUrl, getFacebookOauthDialogUrl } from './facebook';
import { getCalendlyOauthDialogUrl } from './calendly';
import { getSalesforceOauthDialogUrl } from './salesforce';
import { getGoogleOauthDialogUrl } from './google';
import { getLinkedinOauthDialogUrl } from './linkedin';
import { getKlaviyoOauthDialogUrl } from './klaviyo';
import { getMondayOauthDialogUrl } from './monday';
import { getGetresponseDialogUrl } from './getresponse';
import { getConstantcontactOauthDialogUrl } from './constantcontact';
import { getGooglesheetsOauthDialogUrl } from './googlesheets';

/**
 * @param {Object} params
 * @param {String} params.provider OAuth service provider.
 * @param {String=} params.token OAuth flow token.
 * @param {String} params.action
 * @return {String}
 */
function getOauthUrl(
	{
		provider, token, action, pkceCodeChallenge = ''
	}
) {
	switch (provider) {
		case Providers.PIPEDRIVE:
			return getPipedriveOauthDialogUrl(action);
		case Providers.MAILCHIMP:
			return getMailchimpOauthDialogUrl();
		case Providers.HUBSPOT:
			return getHubspotOauthDialogUrl(action);
		case Providers.HUBSPOT_MARKETING:
			return getHubspotMktOauthDialogUrl();
		case Providers.WIX:
			return getWixOauthDialogUrl(token);
		case Providers.FACEBOOK:
			return getFacebookOauthDialogUrl();
		case Providers.FACEBOOK_ADS:
			return getFacebookAdsOauthDialogUrl();
		case Providers.CALENDLY:
			return getCalendlyOauthDialogUrl();
		case Providers.SALESFORCE:
			return getSalesforceOauthDialogUrl();
		case Providers.GOOGLE:
			return getGoogleOauthDialogUrl(action);
		case Providers.LINKEDIN:
			return getLinkedinOauthDialogUrl();
		case Providers.KLAVIYO:
			return getKlaviyoOauthDialogUrl(pkceCodeChallenge);
		case Providers.MONDAY:
			return getMondayOauthDialogUrl();
		case Providers.GETRESPONSE:
			return getGetresponseDialogUrl();
		case Providers.CONSTANTCONTACT:
			return getConstantcontactOauthDialogUrl();
		case Providers.GOOGLESHEETS:
			return getGooglesheetsOauthDialogUrl();
		default:
			return null;
	}
}

/**
 * @param {Object} params
 * @param {String} params.provider - OAuth service provider.
 * @param {String=} params.token - Optional token.
 * @param {String} params.action
 */
export function openOauthLoginWindow({
	provider, token, action, pkceCodeChallenge = ''
}) {
	const oauthLoginUrl = getOauthUrl({
		provider, token, action, pkceCodeChallenge
	});

	utils.openPopupWindow(oauthLoginUrl);
}

/**
 * Send authorization code from OAuth login window to parent window.
 * @param {Object} params OAuth login options.
 * @param {String} params.provider - OAuth service provider.
 * @param {String} params.code - OAuth authorization code.
 * @param {Boolean} params.close - Whether popup window should be closed automatically.
 * @param {String} params.state
 */
export function postOauthCodeToMainWindow({
	provider,
	code,
	close = true,
	state = ''
}) {
	utils.postMessage({
		type: 'oauth',
		provider,
		code,
		state
	});

	if (close) {
		window.close();
	}
}

/**
 * @param {Object} params
 * @param {Object[]} params.exception  - Exception object array.
 * @param {String} params.engagementEventSource
 * @return {{ error: String, message: String}}
 */
export function getInvalidOauthTokenError({ exception, engagementEventSource }) {
	const invalidOauthTokenError = exception.errors.find(err => err.error === 'invalid_oauth_token');

	if (invalidOauthTokenError) {
		return {
			...invalidOauthTokenError,
			message: `${capitalize(engagementEventSource)} access token has been revoked. <a href="/integrations">Please reconnect ${capitalize(engagementEventSource)}</a>`
		};
	}

	return null;
}
