import utils from 'utils';

/**
 * Single email campaign field names.
 * @type {{
 * FROM_OWNER: String
 * }}
 */
const FieldNames = Object.freeze({
	FROM_OWNER: 'fromOwner'
});

export default {
	/**
	 * Get user name.
	 * @param {Object} state
	 * @param {import('vuex').GetterTree} getters
	 * @param {Object} rootState
	 * @param {Object} rootState.user
	 */
	userName(state, getters, { user }) {
		return user.profile && user.profile.name;
	},

	/**
	 * Get all available CRM filters.
	 * @param {Object} state - Single email campaign module state.
	 * @param {Array} state.options
	 * @return {Array} Options - array of CRM filters.
	 */
	filterOptions({ options }) {
		let filterOptions = [];

		if (options && options.filter) {
			filterOptions = options.filter.options;
		}

		return filterOptions;
	},

	/**
	 * Get filters of type 'contact' from available CRM filters.
	 * @param {Object} state - Single email campaign module state.
	 * @param {Array} state.options - Single email campaign form options.
	 * @return {Array} Options - array of 'contact' type CRM filters.
	 */
	contactTypeFilters({ options }) {
		let filters = [];

		if (options && options.filter) {
			filters = options.filter.options.filter(({ type }) => type === 'contact');
		}

		return filters;
	},

	/**
	 * Get filters of type 'lead' from available CRM filters.
	 * @param {Object} state - Single email campaign module state.
	 * @param {Array} state.options - Single email campaign form options.
	 * @return {Array} Options - array of 'lead' type CRM filters.
	 */
	leadStatusFilters({ options }) {
		let filters = [];

		if (options && options.filter) {
			filters = options.filter.options.filter(({ type }) => type === 'lead');
		}

		return filters;
	},

	/**
	 * Get filters of type 'tag' from available CRM filters.
	 * @param {Object} state - Single email campaign module state.
	 * @param {Array} state.options - Single email campaign form options.
	 * @return {Array} Options - array of 'tag' type CRM filters.
	 */
	tagFilters({ options }) {
		let filters = [];

		if (options && options.filter) {
			filters = options.filter.options.filter(({ type }) => type === 'tag');
		}

		return filters;
	},

	/**
	 * Get events configured to record notes.
	 * @param {Object} state
	 * @param {Object} state.emailEngagementSettings
	 */
	noteEngagementEvents({ emailEngagementSettings }) {
		const keys = [
			'sentNote',
			'openNote',
			'clickNote',
			'bounceNote',
			'unsubscribeNote'
		];

		return Object.entries(emailEngagementSettings)
			.filter(([key, value]) => value && keys.includes(key))
			.map(([key]) => key.replace('Note', ''));
	},

	/**
	 * Get events configured to record activities.
	 * @param {Object} state
	 * @param {Object} state.emailEngagementSettings
	 */
	activityEngagementEvents({ emailEngagementSettings }) {
		const keys = [
			'sentActivity',
			'openActivity',
			'clickActivity',
			'bounceActivity',
			'unsubscribeActivity',
			'replyActivity'
		];

		return Object.entries(emailEngagementSettings)
			.filter(([key, value]) => value && keys.includes(key))
			.map(([key]) => key.replace('Activity', ''));
	},

	/**
	 * Get fromOwner value.
	 * @param {Object} state
	 * @param {Array} state.options
	 * @param {Object} state.campaign
	 * @return {Boolean}
	 */
	fromOwnerValue({ options, campaign }) {
		return utils.getValueWithFallback({
			object: campaign,
			options,
			field: FieldNames.FROM_OWNER
		});
	},

	segmentTypeOptions({ options }) {
		if (!options || !options.segmentTypes) {
			return [];
		}

		return options.segmentTypes.options;
	}
};
