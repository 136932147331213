export const APP_CREATED = 'APP_CREATED';

/**
 * App is mounted to DOM.
 */
export const APP_MOUNTED = 'APP_MOUNTED';

/**
 * Log user out from the app.
 */

export const LOGOUT = 'LOGOUT';
