const crypto = require('crypto');

const RANDOM_BYTES_SIZE = 128;

const generateCodeVerifier = () => {
	const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
	const randomValues = crypto.randomBytes(RANDOM_BYTES_SIZE);

	return Array.from(randomValues, byte => charset[byte % charset.length]).join('');
};

const base64URLEncode = buffer => buffer.toString('base64')
	.replace(/\+/g, '-')
	.replace(/\//g, '_')
	.replace(/=+$/, '');

const sha256 = buffer => crypto.createHash('sha256').update(buffer).digest();

export const generatePkceCodes = () => {
	const verifier = generateCodeVerifier();
	const challenge = base64URLEncode(sha256(verifier));

	return {
		codeVerifier: verifier,
		codeChallenge: challenge
	};
};
