import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { EmailScheduleSettingsException } from './helpers';

/**
 * Delete email schedule settings.
 * @param {String} token - JWT Authorization token.
 * @return {Promise<Object>}
 */
async function httpDelete(token) {
	const result = await request.delete(endpoints.userSettings.email.schedule, token);

	if (!result.success) {
		return Promise.reject(new EmailScheduleSettingsException(result));
	}

	return result.data;
}

export {
	httpDelete
};
