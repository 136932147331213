/**
 * @constant {{
 * STARTER: String,
 * GROWTH: String,
 * LITE: 'LITE
 * }}
 */
const Plans = {
	STARTER: 'starter',
	GROWTH: 'growth',
	LITE: 'lite'
};

/**
 * @constant {{
 * STARTER: Object,
 * GROWTH: Object,
 * }}
 */
const DOWNNGRADE_DIALOG_CONTENT_MAP = {
	[Plans.GROWTH]: {
		[Plans.LITE]: {
			mainContent:
			'will limit app connections to web forms and lead ads. Any other app connections will be paused',
			secondaryContent:
			'You will also lose access to Campaigns, Web tracking and Lead scoring. All active email sequences will be paused.'
		},
		[Plans.STARTER]: {
			mainContent:
			'will remove access to Campaigns and all active sequences will be paused.',
			secondaryContent:
			''
		}
	},
	[Plans.STARTER]: {
		[Plans.LITE]: {
			mainContent:
			'will limit app connections to web forms and lead ads. Any other app connections will be paused.',
			secondaryContent:
			'You will also lose access to Web tracking and Lead scoring.'
		}
	}
};

/**
 *
 * @param {Object} plan
 * @return {String}
 */
function getPlanName(plan) {
	if (plan?.group) {
		return plan.group;
	}

	return plan?.name ?? '';
}

/**
 *
 * @param {Object} currentPlan
 * @param {Object} selectedPlan
 * @return {Object}|| null.
 */
function getDowngradeDialogContent(currentPlan, selectedPlan) {
	return DOWNNGRADE_DIALOG_CONTENT_MAP?.[
		getPlanName(currentPlan).toLowerCase()
	]?.[getPlanName(selectedPlan).toLowerCase()] ?? {
		mainContent: '',
		secondaryContent: ''
	};
}

export {
	getDowngradeDialogContent,
	getPlanName
};
