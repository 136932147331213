import utils from 'utils';
import {
	getCookie,
	setCookie,
	getSignupSource
} from './helpers';

/**
 * @return {{
 * source: String,
 * referrer: String,
 * landingPageUrl: String,
 * utmSource: String,
 * utmMedium: String,
 * utmCampaign: String
 * }}
 */
const getSignupTrackingData = () => ({
	source: getCookie('signup_source'),
	referrer: getCookie('referrer'),
	landingPageUrl: getCookie('landing_page_url'),
	utmSource: getCookie('utm_source'),
	utmMedium: getCookie('utm_medium'),
	utmCampaign: getCookie('utm_campaign'),
	googleClientId: getCookie('google_client_id'),
	googleClickId: getCookie('google_click_id'),
	googleCookieExpiryDate: getCookie('google_cookie_expiry_date')
});

/**
 * @param {String} rawUrl
 * @return {Boolean}
 */
const isUrlFromOutfunnelDomain = rawUrl => {
	if (!rawUrl) {
		return false;
	}

	const { hostname } = new URL(rawUrl);

	return hostname.endsWith('outfunnel.com');
};

/**
 * Store tracking data to cookies.
 */
const storeTrackingData = () => {
	if (utils.isPopupWindow()) {
		return;
	}

	const { referrer } = document;

	if (!isUrlFromOutfunnelDomain(referrer)) {
		setCookie('referrer', referrer);
	}

	if (!getCookie('signup_source')) {
		return;
	}

	const params = (new URL(document.location.href)).searchParams;
	const signupSource = getSignupSource();
	const utmSource = params.get('utm_source');
	const utmMedium = params.get('utm_medium');
	const utmCampaign = params.get('utm_campaign');
	const landingPageUrl = document.location.href;

	setCookie('signup_source', signupSource);
	setCookie('utm_source', utmSource);
	setCookie('utm_medium', utmMedium);
	setCookie('utm_campaign', utmCampaign);
	setCookie('landing_page_url', landingPageUrl);
};

/**
 * @return {Array}
 */
const getSignupIntents = () => {
	let intents = [];
	const intent = getCookie('intent');

	if (intent) {
		intents = [intent];
	}

	return intents;
};

export {
	getSignupTrackingData,
	storeTrackingData,
	getSignupIntents
};
