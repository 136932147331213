const TYPES = {
	PIPEDRIVE_TO_MAILCHIMP: 'pipedrive_to_mailchimp',
	MAILCHIMP_TO_PIPEDRIVE: 'mailchimp_to_pipedrive',
	MAILCHIMP_TO_COPPER: 'mailchimp_to_copper',
	COPPER_TO_MAILCHIMP: 'copper_to_mailchimp',
	MAILCHIMP_TO_HUBSPOT: 'mailchimp_to_hubspot',
	HUBSPOT_TO_MAILCHIMP: 'hubspot_to_mailchimp'
};

export {
	TYPES
};
