//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { isNumber } from 'utils/types';

const DAY_IN_MS = 24 * 60 * 60 * 1000;
const HOUR_IN_MS = 60 * 60 * 1000;

const EVENT_EDIT_DELAY = 'edit:delay';

export default {
	props: {
		emailId: {
			type: String,
			required: true
		},
		emailIndex: {
			type: Number,
			required: true
		},
		trigger: {
			type: Object,
			default: null
		},
		hasError: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		days: null,
		hours: null
	}),

	computed: {
		delay() {
			const { hours, days } = this;

			return (days * DAY_IN_MS + hours * HOUR_IN_MS);
		},

		isFirstEmail() {
			return (this.emailIndex === 0);
		}
	},

	watch: {
		trigger({ delay }) {
			if (isNumber(delay)) {
				this.delayChanged(delay);
			}
		}
	},

	mounted() {
		const { delay } = this.trigger;

		if (isNumber(delay)) {
			this.delayChanged(delay);
		}
	},

	methods: {
		editDays(days) {
			this.days = days;
			const { emailId, delay } = this;

			this.$emit(EVENT_EDIT_DELAY, { emailId, delay });
		},

		editHours(hours) {
			this.hours = hours;
			const { emailId, delay } = this;

			this.$emit(EVENT_EDIT_DELAY, { emailId, delay });
		},

		delayChanged(delay) {
			this.days = Math.floor(delay / DAY_IN_MS);
			let hoursInMs = delay;

			if (this.days > 0) {
				hoursInMs = delay % (this.days * DAY_IN_MS);
			}

			this.hours = hoursInMs / HOUR_IN_MS;
		}
	}
};

