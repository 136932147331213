import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { FieldMappingsException } from './helpers';

/**
 * @param {String} params
 * @param {String} params.connectionId
 * @param {String} params.token
 * @param {String} params.fieldMappingData
 * @return {Promise<Object>}
 */
export const httpPost = async ({ connectionId, token, fieldMappingData }) => {
	const response = await request.post(
		endpoints.connectionsIdFieldMappings(connectionId),
		fieldMappingData,
		token
	);

	if (!response.success) {
		return Promise.reject(new FieldMappingsException(response));
	}

	return response.data;
};
