import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { SyncException } from './helpers';

/**
 * Schedule automation sync.
 * @param {String} token - JWT Authorization token.
 * @param {String} type - Automation type.
 * @param {String} automationId - ID of the automation.
 * @return {Boolean} Success - returns true when successful.
 */
async function httpPost(token, type, automationId) {
	const url = `${endpoints.automationsSync}/${automationId}`;
	const data = { type };
	const result = await request.post(url, data, token);

	if (!result.success) {
		return Promise.reject(new SyncException(result));
	}

	return result.success;
}

export {
	httpPost
};
