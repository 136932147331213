//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	inheritAttrs: false,

	props: {
		depressed: {
			type: Boolean,
			default: true
		},
		rounded: {
			type: Boolean,
			default: true
		}
	},

	computed: {
		hasIcon() {
			return Boolean(this.$slots.icon);
		}
	}
};
