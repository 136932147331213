//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import EmailHealth from './EmailHealth.vue';
import NavBar from './NavBar.vue';

const EVENT_NAVIGATE_BACK = 'navigate:back';
const EVENT_EDIT_NAME = 'edit:name';

export default {
	components: {
		NavBar,
		EmailHealth
	},
	props: {
		name: {
			type: String,
			default: ''
		},
		nameError: {
			type: String,
			default: null
		},
		isEmailHealthVisible: {
			type: Boolean,
			default: false
		},
		emailHealthStatus: {
			type: String,
			default: null
		}
	},

	methods: {
		navigateBack() {
			this.$emit(EVENT_NAVIGATE_BACK);
		},
		editName(name) {
			this.$emit(EVENT_EDIT_NAME, name);
		}
	}
};

