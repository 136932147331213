import { OAUTH_CONNECT_START } from 'store/modules/account/integrations/actionTypes';
import { Providers } from 'utils/oauth/constants';
import router from 'router';
import ROUTE_NAMES from 'router/route-names';
import { WIX_INSTALL } from './actionTypes';

export const actions = {
	[WIX_INSTALL]: ({
		state,
		dispatch,
		rootState: { token }
	}) => {
		if (token) {
			dispatch(
				`account/integrations/${OAUTH_CONNECT_START}`,
				{ provider: Providers.WIX, wixToken: state.wixToken },
				{ root: true }
			);

			router.push(ROUTE_NAMES.CONNECTIONS_CREATE);
		} else {
			router.push(ROUTE_NAMES.REGISTER);
		}
	}
};
