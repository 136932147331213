import { groupErrors } from 'utils/error';
import getInitialState from './state';

import {
	MODE,
	STATUS,
	OPTIONS,
	LIST,
	FILTER,
	AUTOMATION,
	OPTION,
	SELECTED_PERSONFIELDS,
	SELECTED_ORGFIELDS,
	ERRORS,
	GENERAL_ERRORS,
	FIELD_ERRORS,
	FIELD_ERRORS_CLEAR,
	RECIPIENTS_TYPE,
	VISIBLE_FIELDS,
	RESET
} from './mutationTypes';

import { getRecipientsType } from './helpers';

export default {
	[MODE](state, mode) {
		state.mode = mode;
	},

	[STATUS](state, status) {
		state.status = status;
	},

	[OPTIONS](state, options) {
		state.options = options;
	},

	[AUTOMATION](state, automation) {
		state.automation = automation;

		if (automation.filter) {
			state.automation.filterData = automation.filter;
			state.automation.filter = automation.filter.id;
			state.recipientsType = getRecipientsType(automation.filterData);
		}
	},

	[OPTION](state, option) {
		state.automation = { ...state.automation, ...option };
	},

	[LIST](state, listId) {
		state.selected.list = listId;
	},

	[FILTER](state, filterId) {
		state.selected.filter = filterId;
	},

	[SELECTED_PERSONFIELDS](state, personFields) {
		state.selected.personFields = personFields;
	},

	[SELECTED_ORGFIELDS](state, orgFields) {
		state.selected.orgFields = orgFields;
	},

	[RECIPIENTS_TYPE](state, type) {
		state.recipientsType = type;
	},

	[ERRORS](state, errors) {
		const { visibleFields } = state;
		const { generalErrors, contextualErrors } = groupErrors(
			errors, visibleFields
		);

		contextualErrors.forEach(error => {
			state.fieldErrors[error.context] = error.message;
		});

		state.generalErrors = generalErrors;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[FIELD_ERRORS](state, errors) {
		errors.forEach(error => {
			state.fieldErrors[error.context] = error.message;
		});
	},

	[FIELD_ERRORS_CLEAR](state, field) {
		state.fieldErrors[field] = null;
	},

	[VISIBLE_FIELDS](state, fields) {
		state.visibleFields = fields;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	}
};
