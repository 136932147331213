import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { TriggerException } from './helpers';

/**
 * Create trigger for specified Sequence email.
 * @param {String} token - User's JWT authorization.
 * @param {String} sequenceId - ID of the sequence.
 * @param {String} emailId - ID of the email.
 * @param {Object} triggerData - Trigger object to be created.
 */
async function httpPost(
	token,
	sequenceId,
	emailId,
	triggerData
) {
	const url = endpoints.sequenceEmailCampaignsIdEmailsIdTrigger(sequenceId, emailId);
	const result = await request.post(url, triggerData, token);

	if (!result.success) {
		return Promise.reject(new TriggerException(result));
	}

	return result.data;
}

export {
	httpPost
};
