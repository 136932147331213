import { MODE } from '../types';

export default {
	isCreateMode(state) {
		return (state.mode === MODE.CREATE);
	},

	isEditMode(state) {
		return (state.mode === MODE.EDIT);
	},

	listId({ automation }) {
		const { list } = automation;

		if (list && list.id) {
			return list.id;
		}

		return '';
	},

	listName({ automation }) {
		const { list } = automation;
		let listName = 'All lists';

		if (list && list.name) {
			listName = list.name;
		}

		return listName;
	},

	filterName({ automation }) {
		const { filterData } = automation;
		let filterName = '';

		if (filterData && filterData.name) {
			filterName = filterData.name;
		}

		return filterName;
	},

	/**
	 * Get all available CRM filters.
	 * @param {Object} state - Automation module state.
	 * @param {Array} state.options
	 * @return {Array} Options - array of CRM filters.
	 */
	filterOptions({ options }) {
		let filterOptions = [];

		if (options && options.filters) {
			filterOptions = options.filters;
		}

		return filterOptions;
	},

	/**
	 * Get filters of type 'contact' from available CRM filters.
	 * @param {Object} state - Automation module state.
	 * @param {Array} state.options - Automation form options.
	 * @return {Array} Options - array of 'contact' type CRM filters.
	 */
	contactTypeFilters({ options }) {
		let filters = [];

		if (options && options.filters) {
			filters = options.filters.filter(({ type }) => type === 'contact');
		}

		return filters;
	},

	/**
	 * Get filters of type 'lead' from available CRM filters.
	 * @param {Object} state - Automation module state.
	 * @param {Array} state.options - Automation form options.
	 * @return {Array} Options - array of 'lead' type CRM filters.
	 */
	leadStatusFilters({ options }) {
		let filters = [];

		if (options && options.filters) {
			filters = options.filters.filter(({ type }) => type === 'lead');
		}

		return filters;
	},

	/**
	 * Get filters of type 'tag' from available CRM filters.
	 * @param {Object} state - Automation module state.
	 * @param {Array} state.options - Automation form options.
	 * @return {Array} Options - array of 'tag' type CRM filters.
	 */
	tagFilters({ options }) {
		let filters = [];

		if (options && options.filters) {
			filters = options.filters.filter(({ type }) => type === 'tag');
		}

		return filters;
	}
};
