import {
	RESET,
	MODE,
	SEQUENCE_ID,
	OPTIONS,
	EMAIL,
	DIALOG_EDITOR_HTML_VISIBILITY,
	DIALOG_CUSTOM_HTML_VISIBILITY,
	GENERAL_ERRORS,
	FIELD_ERRORS,
	FIELD_ERRORS_CLEAR,
	VISIBLE_FIELDS,
	FIELDS,
	REPLACE_MERGE_FIELDS,
	IMAGE
} from './mutationTypes';

import getInitialState from './state';

export default {
	[RESET](state) {
		Object.assign(state, getInitialState());
	},

	[MODE](state, mode) {
		state.mode = mode;
	},

	[SEQUENCE_ID](state, sequenceId) {
		state.sequenceId = sequenceId;
	},

	[OPTIONS](state, options) {
		state.options = options;
	},

	[EMAIL](state, email) {
		state.email = email;
	},

	[VISIBLE_FIELDS](state, fields) {
		state.visibleFields = fields;
	},

	[FIELDS](state, fields) {
		Object.assign(state.email, fields);
	},

	[DIALOG_EDITOR_HTML_VISIBILITY](state, isVisible) {
		state.isDialogEditorHtmlVisible = isVisible;
	},

	[DIALOG_CUSTOM_HTML_VISIBILITY](state, isVisible) {
		state.isDialogCustomHtmlVisible = isVisible;
	},

	[REPLACE_MERGE_FIELDS](state, areMergeFieldsReplaced) {
		state.areMergeFieldsReplaced = areMergeFieldsReplaced;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[FIELD_ERRORS](state, errors) {
		errors.forEach(error => {
			state.fieldErrors[error.context] = error.message;
		});
	},

	[FIELD_ERRORS_CLEAR](state, fields) {
		Object.keys(fields).forEach(fieldKey => {
			state.fieldErrors[fieldKey] = null;
		});
	},

	[IMAGE](state, { uploadId, imageUrl }) {
		state.uploadedImages = {
			...state.uploadedImages,
			[uploadId]: imageUrl
		};
	}
};
