//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
		title: {
			type: String,
			required: true
		},
		dismissable: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: 'infobox'
		}
	},

	methods: {
		dismiss() {
			this.$emit('dismiss');
		}
	}
};
