import { request } from 'utils/request';
import { getAutomationUrl, AutomationsException } from './helpers';

/**
 * Get list of specific type of automations.
 * @param {String} token - User's JWT authorization token.
 * @param {String} type - Automation's type.
 * @param {String} automationId - ID of specific automation.
 * @return {Array} - Array of automations.
 */
async function httpGet(token, type, automationId) {
	const url = getAutomationUrl(automationId, type);
	const result = await request.get(url, token);

	if (!result.success) {
		return Promise.reject(new AutomationsException(result));
	}

	return result.data;
}

export {
	httpGet
};
