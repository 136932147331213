import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { ChannelReportException } from './helpers';

/**
 * @param {Number} period
 * @return {String}
 */
const getChannelReportUrl = period => {
	const url = new URL(endpoints.trackingChannelReport);

	url.searchParams.append('period', period);

	return url.toString();
};

/**
 * @param {Object} params
 * @param {String} params.token
 * @param {Number} params.period
 * @return {Promise<Object>}
 */
export const httpGet = async ({ token, period }) => {
	const url = getChannelReportUrl(period);

	const response = await request.get(url, token);

	if (!response.success) {
		return Promise.reject(new ChannelReportException(response));
	}

	return response.data;
};
