import {
	SEQUENCES,
	OPTIONS,
	PAGINATION,
	GENERAL_ERRORS,
	RESET
} from './mutationTypes';

import getInitialState from './state';

export default {
	[OPTIONS](state, options) {
		state.options = options;
	},

	[SEQUENCES](state, sequences) {
		state.sequences = sequences;
	},

	[PAGINATION](state, pagination) {
		state.pagination = pagination;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	}
};
