export const CAMPAIGN_NAVIGATE = 'CAMPAIGN_NAVIGATE';
export const SEQUENCE_NAVIGATE = 'SEQUENCE_NAVIGATE';
export const EMAIL_NAVIGATE = 'EMAIL_NAVIGATE';
export const PERSON_LIST_NAVIGATE = 'PERSON_LIST_NAVIGATE';
export const PERSON_GET = 'PERSON_GET';
export const PERSON_UNSUBSCRIBE = 'PERSON_UNSUBSCRIBE';
export const PERSON_EVENTS_GET = 'PERSON_EVENTS_GET';
export const ERRORS_SHOW = 'ERRORS_SHOW';
export const DIALOG_PERSON_UNSUBSCRIBE_SHOW = 'DIALOG_PERSON_UNSUBSCRIBE_SHOW';
export const DIALOG_PERSON_UNSUBSCRIBE_HIDE = 'DIALOG_PERSON_UNSUBSCRIBE_HIDE';
export const DIALOG_PERSON_UNSUBSCRIBE_CONFIRM = 'DIALOG_PERSON_UNSUBSCRIBE_CONFIRM';
export const DIALOG_PERSON_LEAD_SCORE_RESET_CONFIRM = 'DIALOG_PERSON_LEAD_SCORE_RESET_CONFIRM';
export const DIALOG_PERSON_LEAD_SCORE_RESET_HIDE = 'DIALOG_PERSON_LEAD_SCORE_RESET_HIDE';
export const DIALOG_PERSON_LEAD_SCORE_RESET_SHOW = 'DIALOG_PERSON_LEAD_SCORE_RESET_SHOW';
export const PERSON_LEAD_SCORE_RESET = 'PERSON_LEAD_SCORE_RESET';
