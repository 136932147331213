import { MODE } from '../types';

const getInitialState = () => ({
	mode: MODE.EDIT,
	status: {
		message: '',
		type: ''
	},
	fields: {
		filters: [],
		lists: [],
		personFields: [],
		orgFields: []
	},
	selected: {
		filter: null,
		list: null,
		personFields: [],
		orgFields: []
	},
	automation: null
});

export default getInitialState;
