import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { OptionsException } from './helpers';

/**
 * Get Options of Sequence Email Campaigns.
 * @param {String} token - User's JWT authorization token.
 */
async function httpGet(token) {
	const result = await request.get(endpoints.sequenceEmailCampaignsOptions, token);

	if (!result.success) {
		return Promise.reject(new OptionsException(result));
	}

	return result.data;
}

export {
	httpGet
};
