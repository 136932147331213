export const EMAIL = 'EMAIL';
export const PASSWORD = 'PASSWORD';
export const IN_PROGRESS = 'IN_PROGRESS';
export const SUCCESS = 'SUCCESS';
export const FAILED = 'FAILED';
export const ERRORS = 'ERRORS';
export const FIELD_ERROR = 'FIELD_ERROR';
export const VISIBLE_FIELDS = 'VISIBLE_FIELDS';
export const RESET = 'RESET';
export const INTEGRATION_TO_CONNECT = 'INTEGRATION_TO_CONNECT';
