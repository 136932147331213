import { isString } from 'utils/types';
import { MODE } from '../types';

export default {
	isCreateMode(state) {
		return (state.mode === MODE.CREATE);
	},

	isEditMode(state) {
		return (state.mode === MODE.EDIT);
	},

	listId({ automation }) {
		const { list } = automation;
		let listId;

		if (list && isString(list.id)) {
			listId = list.id;
		}

		return listId;
	},

	listName({ automation }) {
		const { list } = automation;
		let listName = 'All lists';

		if (list && list.name) {
			listName = list.name;
		}

		return listName;
	}
};
