export const WELCOME_DISMISS = 'WELCOME_DISMISS';
export const DASHBOARD_NAVIGATE = 'DASHBOARD_NAVIGATE';
export const OPTIONS_GET = 'OPTIONS_GET';
export const SEQUENCES_GET = 'SEQUENCES_GET';
export const SEQUENCE_CREATE = 'SEQUENCE_CREATE';
export const SEQUENCE_REPORT = 'SEQUENCE_REPORT';
export const SEQUENCE_EDIT = 'SEQUENCE_EDIT';
export const SEQUENCE_DUPLICATE = 'SEQUENCE_DUPLICATE';
export const SEQUENCE_ACTIVATE = 'SEQUENCE_ACTIVATE';
export const SEQUENCE_PAUSE = 'SEQUENCE_PAUSE';
export const SEQUENCE_DELETE = 'SEQUENCE_DELETE';
