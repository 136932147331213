
export const EMAILS = 'EMAILS';
export const GENERAL_ERRORS = 'GENERAL_ERRORS';
export const PAGINATION = 'PAGINATION';
export const QUEUED_EMAILS = 'QUEUED_EMAILS';
export const RESET = 'RESET';
export const SEQUENCE_ID = 'SEQUENCE_ID';
export const SEQUENCE = 'SEQUENCE';
export const SORT_BY = 'SORT_BY';
export const SORT_ORDER = 'SORT_ORDER';
