export const DIALOG_TEST_EMAIL_VISIBILITY = 'DIALOG_TEST_EMAIL_VISIBILITY';
export const DIALOG_CONFIRMATION_VISIBILITY = 'DIALOG_CONFIRMATION_VISIBILITY';
export const DIALOG_SENDING_LIMITS_VISIBILITY = 'DIALOG_SENDING_LIMITS_VISIBILITY';
export const EMAIL_OPTIONS = 'EMAIL_OPTIONS';
export const EMAIL_ENGAGEMENT_SETTINGS = 'EMAIL_ENGAGEMENT_SETTINGS';
export const EMAIL_SCHEDULE_SETTINGS = 'EMAIL_SCHEDULE_SETTINGS';
export const EMAIL_SCHEDULE_SETTINGS_OPTIONS = 'EMAIL_SCHEDULE_SETTINGS_OPTIONS';
export const FIELDS = 'FIELDS';
export const FIELD_ERRORS = 'FIELD_ERRORS';
export const FIELD_ERRORS_CLEAR = 'FIELD_ERRORS_CLEAR';
export const GENERAL_ERRORS = 'GENERAL_ERRORS';
export const INVALID_EMAIL_DELAYS = 'INVALID_EMAIL_DELAYS';
export const MODE = 'MODE';
export const RESET = 'RESET';
export const RECIPIENTS_TYPE = 'RECIPIENTS_TYPE';
export const REPLACE_MERGE_FIELDS = 'REPLACE_MERGE_FIELDS';
export const SEGMENT_TYPE = 'SEGMENT_TYPE';
export const SEQUENCE = 'SEQUENCE';
export const SEQUENCE_OPTIONS = 'SEQUENCE_OPTIONS';
export const SEQUENCE_EMAILS = 'SEQUENCE_EMAILS';
export const STEP = 'STEP';
export const TEST_SEQUENCE_ID = 'TEST_SEQUENCE_ID';
export const TEST_EMAIL_ID = 'TEST_EMAIL_ID';
export const TEST_EMAIL_ADDRESSES = 'TEST_EMAIL_ADDRESSES';
export const TEST_EMAIL_ERRORS = 'TEST_EMAIL_ERRORS';
export const VISIBLE_FIELDS = 'VISIBLE_FIELDS';
