import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { ConnectionsOptionsException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.contactSource
 * @param {String} params.engagementSource
 * @param {String[]=} params.requestedData
 * @return {String}
 */
const getUrl = ({ contactSource, engagementSource, requestedData }) => {
	const url = new URL(endpoints.connectionsOptions);

	url.searchParams.append('contactSource', contactSource);
	url.searchParams.append('engagementSource', engagementSource);

	if (requestedData) {
		url.searchParams.append('requestedData', requestedData.join(','));
	}

	return url.href;
};

/**
 * @param {Object} params
 * @param {String} params.token
 * @param {String} params.contactSource
 * @param {String} params.engagementSource
 * @param {String[]=} params.requestedData
 * @param {Boolean=} params.setNoCacheHeader
 * @return {Promise<Object>}
 */
export const httpGet = async ({
	token, contactSource, engagementSource, requestedData, setNoCacheHeader
}) => {
	const url = getUrl({ contactSource, engagementSource, requestedData });

	const response = await request.get({ url, token, setNoCacheHeader });

	if (!response.success) {
		return Promise.reject(new ConnectionsOptionsException(response));
	}

	return response.data;
};
