import { Headers, CacheControlHeaderValues } from 'utils/constants';
import { METHODS, fetch } from './helpers';

/**
 * @param {{setNoCacheHeader?: Boolean}} params
 * @return {Object}
 */
const getHeaders = ({ setNoCacheHeader }) => {
	if (setNoCacheHeader) {
		return {
			[Headers.CACHE_CONTROL]: CacheControlHeaderValues.NO_CACHE
		};
	}

	return {};
};

/**
 * @param {{url: String, token: String, setNoCacheHeader?: Boolean} | String} params
 * @param {String=} token
 */
const httpGet = (params, token) => {
	// Most implementations use the url and token parameters in a non-named way
	// This implementation offers backwards compatibility meaning that we don't have to change the existing implementations.
	if (params.url && params.token) {
		return fetch({
			method: METHODS.GET,
			url: params.url,
			token: params.token,
			headers: getHeaders({ setNoCacheHeader: params.setNoCacheHeader })
		});
	}

	return fetch({
		method: METHODS.GET,
		url: params,
		token
	});
};

/**
 * @param {String} url
 * @param {Object} data
 * @param {String} token
 */
const httpPost = (url, data, token) => fetch({
	method: METHODS.POST,
	url,
	data,
	token
});

/**
 * @param {String} url
 * @param {Object} data
 * @param {String} token
 */
const httpPut = (url, data, token) => fetch({
	method: METHODS.PUT,
	url,
	data,
	token
});

/**
 * @param {String} url
 * @param {Object} data
 * @param {String} token
 * @return {Promise<Object>}
 */
const httpPatch = (url, data, token) => fetch({
	method: METHODS.PATCH,
	url,
	data,
	token
});

/**
 * @param {String} url
 * @param {String} token
 */
const httpDelete = (url, token, data = {}) => fetch({
	method: METHODS.DELETE,
	url,
	data,
	token
});

/**
 * @param {String} url
 * @param {Object} data
 * @param {String} token
 */
const httpPostFile = (url, data, token) => fetch({
	method: METHODS.POST,
	url,
	data,
	token,
	isFileUpload: true
});

export const request = {
	get: httpGet,
	post: httpPost,
	put: httpPut,
	patch: httpPatch,
	delete: httpDelete,
	postFile: httpPostFile
};
