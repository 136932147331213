import { endpoints } from 'api/endpoints';
import { BaseException } from 'utils/error';

/**
 * @class
 */
class OptionsException extends BaseException {
	/**
	 * @param {{errors: Object[]}} result
	 */
	constructor(result) {
		super(result);
		this.name = 'OptionsException';
	}
}

/**
 * @param {String} type
 * @return {String}
 */
const getUrlWithSearchParams = type => {
	const url = new window.URL(endpoints.automationsOptions);
	const searchParams = new window.URLSearchParams({ type });

	url.search = searchParams.toString();

	return url.toString();
};

export {
	OptionsException,
	getUrlWithSearchParams
};
