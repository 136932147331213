//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_DISMISS = 'dismiss';

export default {
	methods: {
		onDismiss() {
			this.$emit(EVENT_DISMISS);
		}
	}
};
