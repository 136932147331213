import { httpGet } from './httpGet';
import { httpPut } from './httpPut';
import { httpPost } from './httpPost';
import { httpDelete } from './httpDelete';
import { options } from './options';

export const webTracking = {
	get: httpGet,
	put: httpPut,
	post: httpPost,
	delete: httpDelete,
	options
};
