export const getters = {
	/**
	 * @param {Object} state
	 * @param {Object} otherGetters
	 * @param {{user: Object}} object
	 * @return {Boolean}
	 */
	isInfoboxVisible(state, otherGetters, { user }) {
		return !user.profile?.flags.includes('web_tracking_msg');
	},

	/**
	 * @param {Object} state
	 * @param {{totalEvents: Number}} state.counters
	 * @return {Boolean}
	 */
	isTrackingActive({ counters }) {
		return counters.totalEvents > 0;
	},

	/**
	 * @param {Object} state
	 * @param {{period?: Object}} state.options
	 * @return {{value: Number, text: String}[]}
	 */
	periodOptions({ options }) {
		if (!options || !options.period) {
			return [];
		}

		return options.period.options;
	},

	/**
	 * @param {Object} state
	 * @param {{period?: Number}} state.period
	 * @return {Number | null}
	 */
	periodValue({ period }) {
		return period;
	}
};
