/**
 * Merges mapping destination key and entity type into an unified key.
 * @param {Object} params
 * @param {String} params.key Original mapping destination key.
 * @param {String} params.entityType Mapping entity type.
 * @return {String}
 */
const mergeKeyAndEntityType = ({ key, entityType }) => `${key}.${entityType}`;

export { mergeKeyAndEntityType };
