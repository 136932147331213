import { EDITOR_MODE } from './state';

export default {
	fromAddress({ mode, options, email }) {
		if (mode === EDITOR_MODE.CREATE) {
			return options.fromAddress.default;
		}

		return email.fromAddress;
	},

	fromName({ mode, options, email }) {
		if (mode === EDITOR_MODE.CREATE) {
			return options.fromName.default;
		}

		return email.fromName;
	}
};
