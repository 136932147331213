const getInitialState = () => ({
	emailField: {
		value: '',
		errorMessage: ''
	},
	passwordField: {
		value: '',
		errorMessage: ''
	},
	generalErrors: [],
	fieldErrors: {},
	visibleFields: [],
	loginStatus: '',
	integrationToConnect: null
});

export default getInitialState;
