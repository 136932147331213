
/**
 * @constant {{
 * DRAFT: String,
 * ACTIVE: String,
 * PAUSED: String,
 * SYNCING: String
 * }}
 */
export const ConnectionStatuses = Object.freeze({
	DRAFT: 'draft',
	ACTIVE: 'active',
	PAUSED: 'paused',
	SYNCING: 'syncing'
});

/**
 * @constant {{
 * CONTACT_SYNC: String,
 * ENGAGEMENT_SYNC: String,
 * FORM_SYNC: String
 * }}
 */
export const ConnectionTypes = Object.freeze({
	CONTACT_SYNC: 'contactSync',
	ENGAGEMENT_SYNC: 'engagementSync',
	FORM_SYNC: 'formSync'
});
