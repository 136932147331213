import {
	APP_AUTH_APP_ID,
	APP_AUTH_DATA,
	APP_AUTH_DIALOG_VISIBILITY,
	APP_AUTH_ERRORS,
	DIALOG_CONTACTS_IMPORT_DELIMITER,
	DIALOG_CONTACTS_IMPORT_LOADING,
	DIALOG_CONTACTS_IMPORT_OPT_IN,
	DIALOG_CONTACTS_IMPORT_OPT_IN_ERROR,
	DIALOG_CONTACTS_IMPORT_RESET,
	DIALOG_CONTACTS_IMPORT_SEGMENT_NAME,
	DIALOG_CONTACTS_IMPORT_SELECTED_FILE,
	DIALOG_CONTACTS_IMPORT_VISIBILITY,
	GENERAL_ERRORS,
	OAUTH_APP_PKCE_CODE_VERIFIER,
	RESET,
	WP_PLUGIN_AUTH_DIALOG_VISIBILITY,
	WP_PLUGIN_AUTH_ERRORS,
	WP_PLUGIN_AUTH_PLUGIN_NAME
} from './mutationTypes';
import { getInitialState } from './state';

export default {
	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[DIALOG_CONTACTS_IMPORT_VISIBILITY](state, isVisible) {
		state.isDialogContactsImportVisible = isVisible;
	},

	[DIALOG_CONTACTS_IMPORT_LOADING](state, isLoading) {
		state.isLoading = isLoading;
	},

	[DIALOG_CONTACTS_IMPORT_SELECTED_FILE](state, file) {
		state.selectedFile = file;
	},

	[DIALOG_CONTACTS_IMPORT_SEGMENT_NAME](state, segmentName) {
		state.segmentName = segmentName;
	},

	[DIALOG_CONTACTS_IMPORT_OPT_IN](state, isOptInConfirmed) {
		state.isOptInConfirmed = isOptInConfirmed;

		if (isOptInConfirmed) {
			state.isOptInError = false;
		}
	},

	[DIALOG_CONTACTS_IMPORT_OPT_IN_ERROR](state, isError) {
		state.isOptInError = isError;
	},

	[DIALOG_CONTACTS_IMPORT_DELIMITER](state, delimiter) {
		state.delimiter = delimiter;
	},

	[DIALOG_CONTACTS_IMPORT_RESET](state) {
		state.isLoading = false;
		state.isDialogContactsImportVisible = false;
		state.isOptInConfirmed = false;
		state.isOptInError = false;
		state.segmentName = '';
		state.selectedFile = {};
		state.delimiter = ',';
	},

	[APP_AUTH_DATA](state, authData) {
		state.appAuthData = { ...state.appAuthData, ...authData };
	},

	[APP_AUTH_APP_ID](state, appId) {
		state.appAuthAppId = appId;
	},

	[APP_AUTH_DIALOG_VISIBILITY](state, isVisible) {
		state.isAppAuthDialogVisible = isVisible;
	},

	[APP_AUTH_ERRORS](state, errors) {
		state.appAuthErrors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	},

	[WP_PLUGIN_AUTH_DIALOG_VISIBILITY](state, isVisible) {
		state.isWpPluginAuthDialogVisible = isVisible;
	},

	[WP_PLUGIN_AUTH_PLUGIN_NAME](state, name) {
		state.wpPluginAuthPluginName = name;
	},

	[WP_PLUGIN_AUTH_ERRORS](state, errors) {
		state.wpPluginAuthErrors = errors;
	},
	[OAUTH_APP_PKCE_CODE_VERIFIER](state, codeVerifier) {
		state.oauthPkceCodeVerifier = codeVerifier;
	}

};
