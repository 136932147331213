import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { MappingsException } from './helpers';

/**
 * @param {String} token
 * @param {String} listId
 * @param {String} mappingId
 * @return {Promise<Object>}
 */
const httpDelete = async (token, listId, mappingId) => {
	const result = await request.delete(endpoints.userListsIdMappingsId(listId, mappingId), token);

	if (!result.success) {
		return Promise.reject(new MappingsException(result));
	}

	return result.data;
};

export {
	httpDelete
};
