//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	props: {
		status: {
			type: String,
			required: true
		}
	},

	computed: {
		isDraft() {
			return (this.status === 'draft');
		},

		isPaused() {
			return (this.status === 'paused');
		},

		isActive() {
			return (this.status === 'active');
		}
	}
};
