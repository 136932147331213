//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
		elevation: {
			type: String,
			default: '2'
		}
	}
};

