import Cookies from 'js-cookie';

const cookiePrefix = 'of.tracking.';

/**
 * @return {String}
 */
const getDomain = () => {
	const url = new URL(document.location.href);
	const { hostname } = url;

	return hostname;
};

/**
 * @return {String}
 */
const getCookieDomain = () => {
	const domain = getDomain();
	let cookieDomain = domain;

	if (domain.endsWith('outfunnel.com')) {
		cookieDomain = '.outfunnel.com';
	}

	return cookieDomain;
};

/**
 * @param {String} name
 * @return {String|undefined}
 */
const getCookie = name => {
	const cookieName = cookiePrefix + name;

	return Cookies.get(cookieName);
};

/**
 * @param {String} name
 * @param {String} value
 */
const setCookie = (name, value) => {
	const cookieOptions = {
		domain: getCookieDomain(),
		path: '/',
		expires: 365
	};
	const cookieName = cookiePrefix + name;

	if (value && !Cookies.get(cookieName)) {
		Cookies.set(cookieName, value, cookieOptions);
	}
};

/**
 * @return {String}
 */
const getSignupSource = () => {
	const params = (new URL(document.location.href)).searchParams;
	const oauthCode = params.get('code');
	const oauthState = params.get('state');
	let signupSource = 'app';
	const source = params.get('signup_source');

	if (oauthCode && oauthState) {
		signupSource = oauthState;
	} else if (oauthCode && !oauthState) {
		signupSource = 'pipedrive';
	} else if (source) {
		signupSource = source;
	}

	return signupSource;
};

export {
	getCookie,
	setCookie,
	getSignupSource
};
