import { request } from 'utils/request';
import { OptionsException, getUrlWithSearchParams } from './helpers';

/**
 * Get options of specified automation type.
 * @param {String} token - JWT token of the user.
 * @param {String} type - Automation type.
 */
async function httpGet(token, type) {
	const url = getUrlWithSearchParams(type);
	const result = await request.get(url, token);

	if (!result.success) {
		return Promise.reject(new OptionsException(result));
	}

	return result.data;
}

export {
	httpGet
};
