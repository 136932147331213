import getInitialState from './state';

import {
	MODE,
	STATUS,
	OPTIONS,
	AUTOMATION,
	OPTION,
	LIST,
	GENERAL_ERRORS,
	RESET
} from './mutationTypes';

export default {
	[MODE](state, mode) {
		state.mode = mode;
	},

	[STATUS](state, status) {
		state.status = status;
	},

	[OPTIONS](state, options) {
		state.options = options;
	},

	[AUTOMATION](state, automation) {
		state.automation = automation;
	},

	[OPTION](state, option) {
		state.automation = { ...state.automation, ...option };
	},

	[LIST](state, listId) {
		state.selected.list = listId;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	}
};
