import {
	COUNTERS,
	CHANNEL_REPORT,
	CHANNEL_REPORT_OPTIONS,
	CHANNEL_REPORT_PERIOD,
	GENERAL_ERRORS
} from './mutationTypes';

export const mutations = {
	[COUNTERS](state, counters) {
		state.counters = counters;
	},

	[CHANNEL_REPORT](state, channelReport) {
		state.channelReport = channelReport;
	},

	[CHANNEL_REPORT_OPTIONS](state, options) {
		state.options = options;
	},

	[CHANNEL_REPORT_PERIOD](state, period) {
		state.period = period;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	}
};
