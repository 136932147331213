const getInitialState = () => ({
	footer: {
		contentJSON: null,
		html: null
	},
	options: {
		mergeFields: {
			options: []
		}
	},
	uploadedImages: {},
	generalErrors: []
});

export default getInitialState;
