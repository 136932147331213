export const BILLING_NAVIGATE = 'BILLING_NAVIGATE';
export const ADD_RULE_ERRORS = 'ADD_RULE_ERRORS';
export const ADD_CRM_RULE_ERRORS = 'ADD_CRM_RULE_ERRORS';
export const ADD_CRM_FIELD_RULE_ERRORS = 'ADD_CRM_FIELD_RULE_ERRORS';
export const COUNTERS_GET = 'COUNTERS_GET';
export const LIST_NAVIGATE = 'LIST_NAVIGATE';
export const MOUNT = 'MOUNT';
export const MOUNT_DASHBOARD = 'MOUNT_DASHBOARD';
export const OPTIONS_GET = 'OPTIONS_GET';
export const SETTINGS_GET = 'SETTINGS_GET';
export const SETTINGS_PUT = 'SETTINGS_PUT';
export const FIELDS_EDIT = 'FIELDS_EDIT';
export const RULES_EDIT = 'RULES_EDIT';
export const ADD_RULE = 'ADD_RULE';
export const PLAN_UPGRADE = 'PLAN_UPGRADE';
export const ACTIVITY_TYPES_GET = 'ACTIVITY_TYPES_GET';
export const LEAD_SCORE_INSTRUCTION_BOX_GET = 'LEAD_SCORE_INSTRUCTION_BOX_GET';
export const LEAD_SCORE_INSTRUCTION_BOX_EDIT = 'LEAD_SCORE_INSTRUCTION_BOX_EDIT';
export const CONTACTS_FIELDS_GET = 'CONTACTS_FIELDS_GET';
