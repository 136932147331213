
export const getters = {
	connection({ connection }) {
		if (!connection) {
			return null;
		}

		return connection;
	},

	contacts({ contacts }) {
		return contacts;
	},

	connectionContactSource({ connection }) {
		if (!connection) {
			return '';
		}

		return connection.contactSource;
	}
};
