import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { FieldMappingsException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {String} params.token
 * @param {String} params.destinationSegmentId
 * @param {String=} params.source
 * @param {String=} params.destination
 * @return {Promise<Object>}
 */
export const httpGet = async ({
	connectionId,
	token,
	destinationSegmentId,
	source,
	destination
}) => {
	const url = new URL(endpoints.connectionsIdFieldMappings(connectionId));

	if (destinationSegmentId) {
		url.searchParams.append('destinationSegmentId', destinationSegmentId);
	}

	if (source) {
		url.searchParams.append('source', source);
	}

	if (destination) {
		url.searchParams.append('destination', destination);
	}

	const response = await request.get(url.href, token);

	if (!response.success) {
		return Promise.reject(new FieldMappingsException(response));
	}

	return response.data;
};
