import { MODE } from '../types';

const getInitialState = () => ({
	mode: MODE.EDIT,
	status: {
		message: '',
		type: ''
	},
	options: {},
	selected: {},
	automation: {},
	generalErrors: []
});

export default getInitialState;
