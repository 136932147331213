import {
	DOWNGRADE_DIALOG_VISIBILITY,
	UPGRADE_DIALOG_VISIBILITY,
	PLANS,
	INVOICES,
	SUBSCRIPTION,
	BILLING_ERRORS,
	PLAN_PREVIEWED,
	SELECTED_PLAN_TO_DOWNGRADE,
	SELECTED_PLAN_TO_UPGRADE,
	CONNECTIONS,
	ERRORS
} from './mutationTypes';

/**
 * Used for sorting plans.
 * @param {{group: String}} plan
 * @return {Number}
 */
const getGroupRank = plan => {
	if (plan.group === 'Professional') {
		return 2;
	}

	if (plan.group === 'Basic') {
		return 1;
	}

	return 0;
};

export default {
	[DOWNGRADE_DIALOG_VISIBILITY](state, isVisisble) {
		state.isDowngradeDialogVisible = isVisisble;
	},

	[UPGRADE_DIALOG_VISIBILITY](state, isVisisble) {
		state.isUpgradeDialogVisible = isVisisble;
	},

	[PLANS](state, plans) {
		const selectedPlan = plans.find(plan => plan.selected);

		const preparedPlans = plans.map((plan, index) => {
			const groupRank = getGroupRank(plan);

			if (!plan.is_tiered_plan) {
				return { ...plan, is_displayed: true, groupRank };
			}

			if (plan.selected) {
				return { ...plan, is_displayed: true, groupRank };
			}

			if (plan.group === selectedPlan.group) {
				return { ...plan, is_displayed: false, groupRank };
			}

			const indexOfFirstPlanInGroup = plans.findIndex(({ group }) => group === plan.group);

			if (indexOfFirstPlanInGroup === index) {
				return { ...plan, is_displayed: true, groupRank };
			}

			return { ...plan, is_displayed: false, groupRank };
		});

		state.plans = preparedPlans;
	},

	[INVOICES](state, invoices) {
		state.invoices = invoices;
	},

	[SUBSCRIPTION](state, subscription) {
		state.subscription = subscription;
	},

	[BILLING_ERRORS](state, errors) {
		state.billingErrors = errors;
	},

	[PLAN_PREVIEWED](state, planId) {
		const previewedPlan = state.plans.find(({ _id }) => _id === planId);

		state.plans = state.plans.map(plan => {
			if (plan.group === previewedPlan.group && plan._id !== previewedPlan._id) {
				return { ...plan, is_displayed: false };
			}

			if (!plan.is_tiered_plan || plan._id === previewedPlan._id) {
				return { ...plan, is_displayed: true };
			}

			return plan;
		});
	},

	[SELECTED_PLAN_TO_DOWNGRADE](state, plan) {
		state.selectedPlanToDowngrade = plan;
	},

	[SELECTED_PLAN_TO_UPGRADE](state, plan) {
		state.selectedPlanToUpgrade = plan;
	},

	[CONNECTIONS](state, connections) {
		state.connections = connections;
	},

	[ERRORS](state, errors) {
		state.errors = errors;
	}
};
