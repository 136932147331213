import { AppTypes } from 'utils/constants';
import {
	isSyncInProgress,
	getSyncProgress
} from './helpers';

export default {
	isSelectedAppPremium: ({ apps }, _getters, { user }) => appId => {
		const { premium, type } = apps.find(app => app.id === appId) ?? {};

		if (!premium) {
			return false;
		}

		const marketingAppsEnabled = user?.features?.connector?.marketingAppsEnabled ?? true;
		const premiumCrmAppsEnabled = user?.features?.connector?.premiumCrmAppsEnabled ?? true;

		if (!marketingAppsEnabled && type === AppTypes.MARKETING) {
			return true;
		}

		if (!premiumCrmAppsEnabled && type === AppTypes.CRM) {
			return true;
		}

		return false;
	},

	connections: ({ connections }) => connections.map(connection => ({
		...connection,
		isSyncInProgress: isSyncInProgress(connection),
		syncProgress: getSyncProgress(connection)
	}))
};
