import { BaseException } from 'utils/error';

/**
 * Exception subclass for /email-settings/domains endpoint.
 */
export class EmailSettingsDomainsException extends BaseException {
	/**
	 * @param {{
	 * success: Boolean,
	 * status: Number,
	 * errors: Object[]
	 * }} result
	 */
	constructor(result) {
		super(result);
		this.name = 'EmailSettingsDomainsException';
	}
}
