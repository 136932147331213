import range from 'lodash/range';
import { isUndefined } from 'utils/types';

/**
 * Settings fields.
 * @readonly
 * @enum {String}
 */
const SettingsFields = Object.freeze({
	ACTIVE: 'active',
	MONDAY: 'monday',
	TUESDAY: 'tuesday',
	WEDNESDAY: 'wednesday',
	THURSDAY: 'thursday',
	FRIDAY: 'friday',
	SATURDAY: 'saturday',
	SUNDAY: 'sunday',
	START_HOURS: 'startHours',
	START_MINUTES: 'startMinutes',
	END_HOURS: 'endHours',
	END_MINTUES: 'endMinutes'
});

const getValue = ({ field, options, settings }) => {
	if (!isUndefined(settings[field])) {
		return settings[field];
	}

	if (options[field] && !isUndefined(options[field].default)) {
		return options[field].default;
	}

	return null;
};

const getHoursOptions = ({ field, options, settings }) => {
	if (!options[field]) {
		return range(24);
	}

	const { min, max } = options[field];
	const value = getValue({ field, options, settings });

	return options[field].options.filter(option => {
		const isCurrentValue = option.value === value;
		const isMoreThanMin = option.value >= min;
		const isLessThanMax = option.value <= max;

		return (isMoreThanMin && isLessThanMax) || isCurrentValue;
	});
};

export default {
	/**
	 * Get active value.
	 * @param {Object} state
	 * @param {Object} state.options
	 * @param {Object} state.settings
	 * @return {Boolean}
	 */
	activeValue({ options, settings }) {
		return getValue({ options, settings, field: SettingsFields.ACTIVE });
	},

	/**
	 * Get monday value.
	 * @param {Object} state
	 * @param {Object} state.options
	 * @param {Object} state.settings
	 * @return {Boolean}
	 */
	mondayValue({ options, settings }) {
		return getValue({ options, settings, field: SettingsFields.MONDAY });
	},

	/**
	 * Get tuesday value.
	 * @param {Object} state
	 * @param {Object} state.options
	 * @param {Object} state.settings
	 * @return {Boolean}
	 */
	tuesdayValue({ options, settings }) {
		return getValue({ options, settings, field: SettingsFields.TUESDAY });
	},

	/**
	 * Get wednesday value.
	 * @param {Object} state
	 * @param {Object} state.options
	 * @param {Object} state.settings
	 * @return {Boolean}
	 */
	wednesdayValue({ options, settings }) {
		return getValue({ options, settings, field: SettingsFields.WEDNESDAY });
	},

	/**
	 * Get thursday value.
	 * @param {Object} state
	 * @param {Object} state.options
	 * @param {Object} state.settings
	 * @return {Boolean}
	 */
	thursdayValue({ options, settings }) {
		return getValue({ options, settings, field: SettingsFields.THURSDAY });
	},

	/**
	 * Get friday value.
	 * @param {Object} state
	 * @param {Object} state.options
	 * @param {Object} state.settings
	 * @return {Boolean}
	 */
	fridayValue({ options, settings }) {
		return getValue({ options, settings, field: SettingsFields.FRIDAY });
	},

	/**
	 * Get saturday value.
	 * @param {Object} state
	 * @param {Object} state.options
	 * @param {Object} state.settings
	 * @return {Boolean}
	 */
	saturdayValue({ options, settings }) {
		return getValue({ options, settings, field: SettingsFields.SATURDAY });
	},

	/**
	 * Get sunday value.
	 * @param {Object} state
	 * @param {Object} state.options
	 * @param {Object} state.settings
	 * @return {Boolean}
	 */
	sundayValue({ options, settings }) {
		return getValue({ options, settings, field: SettingsFields.SUNDAY });
	},

	/**
	 * Get startHours value.
	 * @param {Object} state
	 * @param {Object} state.options
	 * @param {Object} state.settings
	 * @return {Number}
	 */
	startHoursValue({ options, settings }) {
		return getValue({ options, settings, field: SettingsFields.START_HOURS });
	},

	/**
	 * Get startHours options.
	 * @param {Object} state
	 * @param {Object} state.options
	 * @param {Object} state.settings
	 * @return {Number}
	 */
	startHoursOptions({ options, settings }) {
		return getHoursOptions({ field: SettingsFields.START_HOURS, options, settings });
	},

	/**
	 * Get endHours value.
	 * @param {Object} state
	 * @param {Object} state.options
	 * @param {Object} state.settings
	 * @return {Number}
	 */
	endHoursValue({ options, settings }) {
		return getValue({ options, settings, field: SettingsFields.END_HOURS });
	},

	/**
	 * Get startHours options.
	 * @param {Object} state
	 * @param {Object} state.options
	 * @param {Object} state.settings
	 * @return {Number}
	 */
	endHoursOptions({ options, settings }) {
		return getHoursOptions({ field: SettingsFields.END_HOURS, options, settings });
	}
};
