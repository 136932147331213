import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { SequenceEmailCampaignsException } from './helpers';

/**
 * Get list of sequence email campaigns or get one sequence by ID.
 * @param {Object} params
 * @param {String} params.token - User's JWT authorization token.
 * @param {String=} params.sequenceId - ID of the sequence to get.
 * @param {Number=} params.limit
 * @param {Number=} params.page
 */
async function httpGet({
	token,
	sequenceId,
	limit,
	page
}) {
	let url = new URL(endpoints.sequenceEmailCampaigns);

	url.searchParams.append('sortBy', 'active');
	url.searchParams.append('sortOrder', 'desc');
	url.searchParams.append('sortBy', 'updated');
	url.searchParams.append('sortOrder', 'desc');

	if (limit) {
		url.searchParams.append('limit', limit);
	}

	if (page) {
		url.searchParams.set('page', page);
	}

	if (sequenceId) {
		url = new URL(endpoints.sequenceEmailCampaignsId(sequenceId));
	}

	const result = await request.get(url.toString(), token);

	if (!result.success) {
		return Promise.reject(new SequenceEmailCampaignsException(result));
	}

	const { data, pagination } = result;

	return {
		data,
		pagination
	};
}

export {
	httpGet
};
