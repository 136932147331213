import { getConfValue } from 'utils/appconfig';
import { Providers } from './constants';

const generateRandomString = () => crypto.randomUUID();

export const getGetresponseDialogUrl = () => {
	const {
		clientId,
		oauthUrl,
		redirectUrl
	} = getConfValue(Providers.GETRESPONSE);

	const oauthDialogUrl = new URL(oauthUrl);

	oauthDialogUrl.searchParams.set('client_id', clientId);
	oauthDialogUrl.searchParams.set('redirect_uri', redirectUrl);
	oauthDialogUrl.searchParams.set('response_type', 'code');
	oauthDialogUrl.searchParams.set('state', generateRandomString());

	return oauthDialogUrl.href;
};
