const getInitialState = () => ({
	plans: [],
	subscription: {},
	fields: {
		companyName: '',
		vatNumber: '',
		firstName: '',
		lastName: '',
		street: '',
		state: '',
		city: '',
		zip: '',
		country: 'US'
	},
	fieldErrors: {
		companyName: null,
		vatNumber: null,
		firstName: null,
		lastName: null,
		street: null,
		state: null,
		city: null,
		zip: null,
		country: null
	},
	generalErrors: [],
	isButtonEnabled: false
});

export default getInitialState;
