import { api } from 'api';
import logger from 'utils/logger';
import { MutationTypes } from './mutationTypes';
import { ActionTypes } from './actionTypes';

export const actions = {
	[ActionTypes.CHANNEL_REPORT_OPTIONS_GET]: async ({
		rootState: { token },
		commit
	}) => {
		try {
			const channelReportOptions = await api.tracking.channelReport.options.get(token);

			commit(MutationTypes.CHANNEL_REPORT_OPTIONS, channelReportOptions);
		} catch (exception) {
			if (exception.errors) {
				return commit(MutationTypes.GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ActionTypes.CHANNEL_REPORT_GET]: async ({
		state,
		rootState: { token },
		dispatch,
		commit
	}) => {
		await dispatch(ActionTypes.FEATURES_GET);

		if (!state.features?.webTracking?.enabled) {
			commit(MutationTypes.CHANNEL_REPORT, []);

			return;
		}

		try {
			const channelReport = await api.tracking.channelReport.get({
				token,
				period: state.selectedPeriod
			});

			commit(MutationTypes.CHANNEL_REPORT, channelReport);
		} catch (exception) {
			if (exception.errors) {
				return commit(MutationTypes.GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ActionTypes.CHANNEL_REPORT_PERIOD_SELECT]: async ({
		commit,
		dispatch
	}, selectedPeriod) => {
		commit(MutationTypes.CHANNEL_REPORT_PERIOD, selectedPeriod);

		dispatch(ActionTypes.CHANNEL_REPORT_GET);
	},

	[ActionTypes.FEATURES_GET]: async ({
		rootState: { token },
		commit
	}) => {
		try {
			const features = await api.user.features.get(token);

			commit(MutationTypes.FEATURES, features);
		} catch (exception) {
			if (exception.errors) {
				commit(MutationTypes.GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
