import { api } from 'api';
import logger from 'utils/logger';

import {
	COUNTERS,
	GENERAL_ERRORS
} from './mutationTypes';
import {
	COUNTERS_GET
} from './actionTypes';

export const actions = {

	[COUNTERS_GET]: async ({ rootState: { token }, commit }) => {
		try {
			const counters = await api.tracking.counters.get(token);

			commit(COUNTERS, counters);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
