import { api } from 'api';
import { TYPES } from 'api/automations';
import { isValidFilterId, isValidListId } from 'api/automations/helpers';
import utils from '../../../../utils';

import {
	SET_MODE,
	SET_STATUS,
	SET_OPTIONS,
	SET_FILTER,
	SET_LIST,
	SET_SELECTED_PERSONFIELDS,
	SET_SELECTED_ORGFIELDS,
	SET_AUTOMATION
} from './mutationTypes';

import SHOW_NOTIFICATION from '../../notifications/actionTypes';

import {
	GET_OPTIONS,
	GET_AUTOMATION,
	PUT_AUTOMATION,
	POST_AUTOMATION,
	POST_SCHEDULE_SYNC,
	SELECT_FILTER,
	SELECT_LIST,
	SELECT_PERSONFIELDS,
	SELECT_ORGFIELDS,
	SCHEDULE_SYNC,
	DELETE
} from './actionTypes';

import router from '../../../../router';
import { MODE, STATUS_TYPE } from '../types';
import logger from '../../../../utils/logger';

function isError404(errors = []) {
	const matchedErrors = errors.filter(error => (error.error === 'automation_id_invalid' || 'automation_not_found'));

	return (matchedErrors.length > 0);
}

export default {
	[GET_OPTIONS]: async ({ commit, state, rootState: { token } }) => {
		const options = await api.automations.options.get(token, TYPES.PIPEDRIVE_TO_MAILCHIMP);

		commit(SET_OPTIONS, options);

		// Pre-fill checkboxes when creating a new Automation
		if (state.mode === MODE.CREATE) {
			const preselectedPersonFields = options.personFields
				.filter(field => field.checked)
				.map(field => field.id);

			commit(SET_SELECTED_PERSONFIELDS, preselectedPersonFields);

			const preselectedOrgFields = options.orgFields
				.filter(field => field.checked)
				.map(field => field.id);

			commit(SET_SELECTED_ORGFIELDS, preselectedOrgFields);
		}
	},

	[GET_AUTOMATION]: async ({ commit, rootState: { token } }, id) => {
		try {
			const automation = await api.automations.get(token, TYPES.PIPEDRIVE_TO_MAILCHIMP, id);

			commit(SET_AUTOMATION, automation);
			commit(SET_FILTER, automation.filter.id);
			commit(SET_LIST, automation.list.id);

			const selectedPersonFields = automation.personFields
				.map(field => field.id);

			commit(SET_SELECTED_PERSONFIELDS, selectedPersonFields);

			const selectedOrgFields = automation.orgFields
				.map(field => field.id);

			commit(SET_SELECTED_ORGFIELDS, selectedOrgFields);
		} catch (exception) {
			if (exception.errors) {
				if (isError404(exception.errors)) {
					return router.replace('/404');
				}

				return router.replace('/500');
			}

			logger.error(exception);
		}
	},

	[PUT_AUTOMATION]: async ({ commit, state: { automation }, rootState: { token } }, changes) => {
		commit(SET_STATUS, { type: STATUS_TYPE.SAVING, message: 'Saving' });

		try {
			const updatedAutomation = await api.automations.put(token, automation._id, changes);

			commit(SET_AUTOMATION, updatedAutomation);
			let message = 'Saved';

			if (!automation.active && updatedAutomation.active) {
				message = 'Saved & queued sync';
			} else if (!updatedAutomation.active) {
				message = 'Draft saved';
			}

			commit(SET_STATUS, { type: STATUS_TYPE.SAVED, message });
		} catch (exception) {
			commit(SET_STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to save changes' });

			logger.error(exception);
		}
	},

	[POST_AUTOMATION]: async ({ commit, state, rootState: { token } }) => {
		commit(SET_STATUS, { type: STATUS_TYPE.SAVING, message: 'Saving' });
		const data = {
			type: TYPES.PIPEDRIVE_TO_MAILCHIMP,
			...state.selected
		};

		try {
			const automation = await api.automations.post(token, data);

			commit(SET_AUTOMATION, automation);
			commit(SET_MODE, MODE.EDIT);
			commit(SET_STATUS, { type: STATUS_TYPE.SAVED, message: 'Draft saved' });
			router.replace(automation._id);
		} catch (exception) {
			commit(SET_STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to create automation' });

			logger.error(exception);
		}
	},

	[SELECT_FILTER]: async ({ commit, dispatch, state: { selected, mode } }, filterId) => {
		if (mode === MODE.CREATE) {
			commit(SET_FILTER, filterId);

			const { filter, list } = selected;

			if (isValidFilterId(filter) && isValidListId(list)) {
				dispatch(POST_AUTOMATION);
			}
		} else {
			dispatch(PUT_AUTOMATION, { filter: filterId });
		}
	},

	[SELECT_LIST]: async ({ commit, dispatch, state: { selected, mode } }, listId) => {
		if (mode === MODE.CREATE) {
			commit(SET_LIST, listId);

			const { filter, list } = selected;

			if (isValidFilterId(filter) && isValidListId(list)) {
				dispatch(POST_AUTOMATION);
			}
		} else {
			dispatch(PUT_AUTOMATION, { list: listId });
		}
	},

	[SELECT_PERSONFIELDS]: ({ commit, state: { mode }, dispatch }, personFields) => {
		commit(SET_SELECTED_PERSONFIELDS, personFields);

		if (mode === MODE.EDIT) {
			dispatch(PUT_AUTOMATION, { personFields });
		}
	},

	[SELECT_ORGFIELDS]: ({ commit, state: { mode }, dispatch }, orgFields) => {
		commit(SET_SELECTED_ORGFIELDS, orgFields);

		if (mode === MODE.EDIT) {
			dispatch(PUT_AUTOMATION, { orgFields });
		}
	},

	[SCHEDULE_SYNC]: async ({ dispatch, state: { automation } }) => {
		if (automation && automation.active) {
			dispatch(POST_SCHEDULE_SYNC);
		} else if (automation) {
			dispatch(PUT_AUTOMATION, { active: true });
		} else {
			dispatch(POST_AUTOMATION, { active: true });
		}

		dispatch(
			`notifications/${SHOW_NOTIFICATION}`,
			'Sync is queued',
			{ root: true }
		);

		await utils.delay(1);
		router.push('/automations');
	},

	[POST_SCHEDULE_SYNC]: async ({ commit, state: { automation }, rootState: { token } }) => {
		commit(SET_STATUS, { type: STATUS_TYPE.SAVING, message: 'Scheduling sync' });

		try {
			const id = automation._id;
			const type = TYPES.PIPEDRIVE_TO_MAILCHIMP;

			await api.automations.sync.post(token, type, id);

			commit(SET_STATUS, { type: STATUS_TYPE.SAVED, message: 'Sync queued' });
		} catch (exception) {
			commit(SET_STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to queue sync' });

			logger.error(exception);
		}
	},

	[DELETE]: async ({ commit, state: { automation }, rootState: { token } }) => {
		try {
			commit(SET_STATUS, { type: STATUS_TYPE.DELETING, message: 'Deleting' });
			await api.automations.delete(token, automation._id);
			router.push('/automations');
		} catch (exception) {
			commit(SET_STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to delete automation' });

			logger.error(exception);
		}
	}
};
