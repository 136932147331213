import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { FeedException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {Number} params.page
 * @param {String} params.token
 * @return {Promise<Object[]>}
 */
export const httpGet = async ({ connectionId, page, token }) => {
	const url = new URL(endpoints.connectionsIdFeed(connectionId));
	const searchParams = new URLSearchParams({});

	if (page) {
		searchParams.set('page', page);
	}

	url.search = searchParams.toString();

	const response = await request.get(String(url), token);

	if (!response.success) {
		return Promise.reject(new FeedException(response));
	}

	return {
		data: response.data,
		pagination: response.pagination
	};
};
