import { getConfValue } from 'utils/appconfig';
import urljoin from 'url-join';
import { Providers } from './constants';

/**
 * @return {String}
 */
export const getMailchimpOauthDialogUrl = () => {
	const {
		clientId,
		oauthUrl,
		redirectUrl
	} = getConfValue(Providers.MAILCHIMP);

	const oauthDialogUrl = new URL(urljoin(oauthUrl, 'authorize'));

	oauthDialogUrl.searchParams.set('client_id', clientId);
	oauthDialogUrl.searchParams.set('redirect_uri', redirectUrl);
	oauthDialogUrl.searchParams.set('response_type', 'code');

	return oauthDialogUrl.href;
};
