import {
	COUNTERS,
	GENERAL_ERRORS
} from './mutationTypes';

export const mutations = {
	[COUNTERS](state, counters) {
		state.counters = counters;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	}
};
