import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { ImportsException } from './helpers';

/**
 * @param {String} token
 * @param {Object} data
 * @return {Promise<Object>}
 */
async function httpPost(token, data) {
	const result = await request.postFile(endpoints.personsImport, data, token);

	if (!result.success) {
		return Promise.reject(new ImportsException(result));
	}

	return result;
}

export {
	httpPost
};
