//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
	getLocaleFormattedDate,
	getFormattedPercentage
} from 'utils/formatting';

import CampaignTableStatusIcon from './CampaignTableStatusIcon.vue';
import TableMenu from './TableMenu.vue';

const MENU_ACTION_ID = {
	REPORT: 'REPORT',
	VIEW_EMAIL: 'VIEW_EMAIL',
	EDIT: 'EDIT',
	DUPLICATE: 'DUPLICATE',
	DELETE: 'DELETE'
};

const MENU_ACTION = {
	[MENU_ACTION_ID.REPORT]: {
		id: MENU_ACTION_ID.REPORT,
		title: 'Report',
		icon: 'insert_chart'
	},
	[MENU_ACTION_ID.VIEW_EMAIL]: {
		id: MENU_ACTION_ID.VIEW_EMAIL,
		title: 'View email',
		icon: 'remove_red_eye'
	},
	[MENU_ACTION_ID.EDIT]: {
		id: MENU_ACTION_ID.EDIT,
		title: 'Edit',
		icon: 'edit'
	},
	[MENU_ACTION_ID.DUPLICATE]: {
		id: MENU_ACTION_ID.DUPLICATE,
		title: 'Duplicate',
		icon: 'file_copy'
	},
	[MENU_ACTION_ID.DELETE]: {
		id: MENU_ACTION_ID.DELETE,
		title: 'Delete',
		icon: 'delete'
	}
};

const EVENT_VIEW_REPORT = 'report:campaign';
const EVENT_VIEW_EMAIL = 'view:email';
const EVENT_EDIT_CAMPAIGN = 'edit:campaign';
const EVENT_DUPLICATE_SEQUENCE = 'duplicate:campaign';
const EVENT_DELETE_CAMPAIGN = 'delete:campaign';

const mapMenuActionToEvent = menuAction => {
	const actionToEventMap = {
		[MENU_ACTION_ID.REPORT]: EVENT_VIEW_REPORT,
		[MENU_ACTION_ID.VIEW_EMAIL]: EVENT_VIEW_EMAIL,
		[MENU_ACTION_ID.EDIT]: EVENT_EDIT_CAMPAIGN,
		[MENU_ACTION_ID.DUPLICATE]: EVENT_DUPLICATE_SEQUENCE,
		[MENU_ACTION_ID.DELETE]: EVENT_DELETE_CAMPAIGN
	};

	return actionToEventMap[menuAction];
};

export default {
	components: {
		CampaignTableStatusIcon,
		TableMenu
	},

	props: {
		rows: {
			type: Array,
			default: null
		},
		locale: {
			type: String,
			default: 'en'
		}
	},

	methods: {
		campaignClicked(campaign) {
			if (campaign.active) {
				this.viewReport(campaign._id);
			} else {
				this.editCampaign(campaign._id);
			}
		},

		campaignDate(campaign) {
			const LAST_SAVED_TEXT = 'Last saved';
			const SENT_ON_TEXT = 'Sent';

			let date = campaign.updated || campaign.created;

			let text = LAST_SAVED_TEXT;

			if (campaign.active && campaign.sent) {
				date = campaign.sent;
				text = SENT_ON_TEXT;
			}

			return `${text} ${getLocaleFormattedDate(date, this.locale)}`;
		},

		menuActions(campaign) {
			const menuActions = [
				MENU_ACTION[MENU_ACTION_ID.DUPLICATE]
			];

			if (campaign.active) {
				menuActions.unshift(MENU_ACTION[MENU_ACTION_ID.VIEW_EMAIL]);
				menuActions.unshift(MENU_ACTION[MENU_ACTION_ID.REPORT]);
			} else {
				menuActions.unshift(MENU_ACTION[MENU_ACTION_ID.EDIT]);
			}

			menuActions.push(MENU_ACTION[MENU_ACTION_ID.DELETE]);

			return menuActions;
		},

		onMenuAction(action, campaignId) {
			const event = mapMenuActionToEvent(action);

			this.$emit(event, campaignId);
		},

		viewReport(campaignId) {
			this.$emit(EVENT_VIEW_REPORT, campaignId);
		},

		editCampaign(campaignId) {
			this.$emit(EVENT_EDIT_CAMPAIGN, campaignId);
		},

		openedPercentage({ opened, queued }) {
			return getFormattedPercentage(opened, queued);
		},

		clickedPercentage({ clicked, queued }) {
			return getFormattedPercentage(clicked, queued);
		},

		repliedPercentage({ replied, queued }) {
			return getFormattedPercentage(replied, queued);
		}
	}
};

