//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_CANCEL = 'cancel';
const EVENT_RESET_LEAD_SCORE = 'reset-lead-score';
const DEFAULT_LEARN_MORE_LINK = 'https://support.outfunnel.com/en/articles/6721561-how-to-reset-a-contact-s-lead-score';

export default {
	props: {
		isVisible: {
			type: Boolean,
			default: false
		},
		email: {
			type: String,
			required: true
		},
		learnMoreLink: {
			type: String,
			default: DEFAULT_LEARN_MORE_LINK
		}
	},

	methods: {
		/**
		 * Emit reset event.
		 */
		resetLeadScore() {
			this.$emit(EVENT_RESET_LEAD_SCORE);
		},
		/**
		 * Emit cancel event.
		 */
		cancel() {
			this.$emit(EVENT_CANCEL);
		}
	}
};

