import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { SegmentsException } from './helpers';

/**
 * Send POST request to /segments endpoint.
 * @param {String} token JWT token.
 * @param {Object} segmentData Segment data.
 * @return {Promise<{Object}>}
 */
export const httpPost = async (token, segmentData) => {
	const response = await request.post(endpoints.segments, segmentData, token);

	if (!response.success) {
		return Promise.reject(new SegmentsException(response));
	}

	return response.data;
};
