export const getters = {
	periodOptions({ channelReportOptions }) {
		if (!channelReportOptions) {
			return [];
		}

		return channelReportOptions.period?.options ?? [];
	},

	isWebTrackingEnabled(state) {
		return state.features?.webTracking?.enabled ?? false;
	}
};
