/* eslint-disable max-lines */
import { api } from 'api';
import logger from 'utils/logger';
import router from 'router';
import ROUTE_NAMES from 'router/route-names';
import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import { Features } from 'utils/constants';
import {
	ConnectionStatuses,
	ConnectionTypes
} from 'utils/constants/connections';
import { getInvalidOauthTokenError } from 'utils/oauth';
import {
	APPS,
	CONNECTIONS,
	ERRORS,
	UPGRADE_DIALOG_VISIBILITY,
	CONTACT_SYNC_CONFIRMATION_DIALOG_VISIBILITY,
	ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_VISIBILITY,
	SPLIT_CONTACT_SYNC_CONFIRMATION_DIALOG_VISIBILITY,
	ENGAGEMENT_SYNC_UPGRADE_DIALOG_VISIBILITY,
	OPTIONS,
	CONTACT_CREATION_RULES,
	TWO_WAY_SYNC_ERRORS_VISIBILITY,
	ENGAGEMENT_SYNC_VALIDATION_ERRORS_VISIBILITY
} from './mutationTypes';
import {
	APPS_GET,
	BILLING_NAVIGATE,
	CONNECTIONS_GET,
	CONNECTIONS_SYNC_PROGRESS_UPDATE,
	CONNECTION_EDIT,
	CONNECTION_ACTIVATE,
	CONNECTION_REQUEST_SYNC,
	CONNECTION_SHOW_STATUS,
	FEATURES_GET,
	UPGRADE_DIALOG_CLOSE,
	UPGRADE_DIALOG_OPEN,
	CONTACT_SYNC_CONFIRMATION_DIALOG_CLOSE,
	CONTACT_SYNC_CONFIRMATION_DIALOG_OPEN,
	ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_CLOSE,
	ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_OPEN,
	SPLIT_CONTACT_SYNC_CONFIRMATION_DIALOG_CLOSE,
	SPLIT_CONTACT_SYNC_CONFIRMATION_DIALOG_OPEN,
	ENGAGEMENT_SYNC_UPGRADE_DIALOG_CLOSE,
	ENGAGEMENT_SYNC_UPGRADE_DIALOG_OPEN,
	OPTIONS_GET,
	CONTACT_CREATION_RULES_GET,
	SHOW_TWO_WAY_SYNC_ERRORS,
	HIDE_TWO_WAY_SYNC_ERRORS,
	SHOW_ENGAGEMENT_SYNC_VALIDATION_ERRORS,
	HIDE_ENGAGEMENT_SYNC_VALIDATION_ERRORS
} from './actionTypes';

import { isContactSyncTabVisible, trackConnectionManualSync } from './helpers';

export const actions = {

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [APPS_GET]({ commit, rootState: { token } }) {
		try {
			const apps = await api.apps.get(token);

			commit(APPS, apps);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[BILLING_NAVIGATE]: async () => {
		router.push({ name: ROUTE_NAMES.ACCOUNT_BILLING });
	},

	[CONNECTIONS_GET]: async ({
		commit,
		rootState: { token }
	}) => {
		try {
			const connections = await api.connections.get({ token });

			commit(CONNECTIONS, connections);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONNECTIONS_SYNC_PROGRESS_UPDATE]: async ({
		commit,
		state: { connections }
	}) => {
		commit(CONNECTIONS, connections.map(connection => ({
			...connection,
			syncStatusUpdatedAt: new Date()
		})));
	},

	[CONNECTION_EDIT]: async (context, connection) => {
		if (isContactSyncTabVisible(connection)) {
			return router.push({
				name: ROUTE_NAMES.CONNECTIONS_EDIT_CONTACT_SYNC,
				params: {
					id: connection._id
				}
			});
		}

		return router.push({
			name: ROUTE_NAMES.CONNECTIONS_EDIT_ENGAGEMENT_SYNC,
			params: {
				id: connection._id
			}
		});
	},

	[CONNECTION_SHOW_STATUS]: async ({ state }, connectionId) => {
		const connection = state.connections.find(({ _id }) => _id === connectionId);

		if (connection.isContactSyncStatusEnabled) {
			router.push({
				name: ROUTE_NAMES.CONNECTIONS_CONTACT_SYNC_STATUS,
				params: {
					id: connectionId
				}
			});

			return;
		}

		if (connection.type === ConnectionTypes.FORM_SYNC) {
			router.push({
				name: ROUTE_NAMES.CONNECTIONS_FORM_STATUS,
				params: {
					id: connectionId
				}
			});

			return;
		}

		router.push({
			name: ROUTE_NAMES.CONNECTIONS_EDIT_STATUS,
			params: {
				id: connectionId
			}
		});
	},

	[CONNECTION_ACTIVATE]: async ({
		commit,
		dispatch,
		rootState: { token }
	}, { connectionId, isRecentEngagementSyncEnabled }) => {
		const connectionData = {
			status: 'active'
		};

		const notification = {
			message: 'Connection activated. Waiting for new events'
		};

		if (isRecentEngagementSyncEnabled) {
			connectionData.includeRecentEngagements = true;

			notification.message = 'Connection activated. Sync starts within 5 minutes';
		}

		try {
			await api.connections.patch({
				connectionId,
				token,
				connectionData
			});

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				notification.message,
				{ root: true }
			);
			dispatch(CONNECTIONS_GET);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONNECTION_REQUEST_SYNC]: async ({
		rootState: { token },
		state,
		commit,
		dispatch
	}, connectionId) => {
		const connectionData = {
			status: ConnectionStatuses.SYNCING
		};

		try {
			await api.connections.patch({
				connectionId,
				token,
				connectionData
			});

			const { connections } = state;

			const updatedConnections = connections.map(connection => {
				if (connection._id !== connectionId) {
					return connection;
				}

				trackConnectionManualSync(connection, 'card');

				return { ...connection, syncStartedAt: new Date(), isSyncInProgress: true };
			});

			commit(CONNECTIONS, updatedConnections);

			await dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Sync started and will complete within 5 minutes',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[FEATURES_GET]: async ({ dispatch, rootGetters }) => {
		const areFeaturesLoaded = rootGetters['user/areFeaturesLoaded'];

		if (!areFeaturesLoaded) {
			await dispatch('user/FEATURES_GET', null, { root: true });
		}

		return rootGetters['user/isFeatureEnabled'](Features.CONNECTOR);
	},

	/**
	 * Close campaign send confirmation dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	[UPGRADE_DIALOG_OPEN]({ commit }) {
		commit(UPGRADE_DIALOG_VISIBILITY, true);
	},

	/**
	 * Close campaign send confirmation dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	[UPGRADE_DIALOG_CLOSE]({ commit }) {
		commit(UPGRADE_DIALOG_VISIBILITY, false);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[CONTACT_SYNC_CONFIRMATION_DIALOG_OPEN]({ commit }) {
		commit(CONTACT_SYNC_CONFIRMATION_DIALOG_VISIBILITY, true);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[CONTACT_SYNC_CONFIRMATION_DIALOG_CLOSE]({ commit }) {
		commit(CONTACT_SYNC_CONFIRMATION_DIALOG_VISIBILITY, false);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[SPLIT_CONTACT_SYNC_CONFIRMATION_DIALOG_OPEN]({ commit }) {
		commit(SPLIT_CONTACT_SYNC_CONFIRMATION_DIALOG_VISIBILITY, true);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[SPLIT_CONTACT_SYNC_CONFIRMATION_DIALOG_CLOSE]({ commit }) {
		commit(SPLIT_CONTACT_SYNC_CONFIRMATION_DIALOG_VISIBILITY, false);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_OPEN]({ commit }) {
		commit(ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_VISIBILITY, true);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_CLOSE]({ commit }) {
		commit(ENGAGEMENT_SYNC_CONFIRMATION_DIALOG_VISIBILITY, false);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[ENGAGEMENT_SYNC_UPGRADE_DIALOG_OPEN]({ commit }) {
		commit(ENGAGEMENT_SYNC_UPGRADE_DIALOG_VISIBILITY, true);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[ENGAGEMENT_SYNC_UPGRADE_DIALOG_CLOSE]({ commit }) {
		commit(ENGAGEMENT_SYNC_UPGRADE_DIALOG_VISIBILITY, false);
	},

	[OPTIONS_GET]: async ({
		commit,
		state,
		rootState: { token }
	}, connection) => {
		const { contactSource, engagementEventSource } = connection;
		const optionsLabel = `${contactSource}-${engagementEventSource}`;

		if (state.options[optionsLabel]) {
			return;
		}

		try {
			const response = await api.connections.options.get({
				token,
				contactSource,
				engagementSource: engagementEventSource,
				setNoCacheHeader: false
			});

			const options = {
				...state.options,
				[optionsLabel]: response
			};

			commit(OPTIONS, options);
		} catch (exception) {
			const invalidOauthTokenError = getInvalidOauthTokenError({
				exception,
				engagementEventSource
			});

			if (invalidOauthTokenError) {
				return commit(ERRORS, [invalidOauthTokenError]);
			}

			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CONTACT_CREATION_RULES_GET]: async ({
		commit,
		state,
		rootState: { token }
	}, { _id }) => {
		if (state.contactCreationRules[_id]) {
			return;
		}

		try {
			const contactCreationRules = await api.connections.contactCreationRules.get({
				connectionId: _id,
				token
			});

			const rules = {
				...state.contactCreationRules,
				[_id]: contactCreationRules
			};

			commit(CONTACT_CREATION_RULES, rules);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[SHOW_TWO_WAY_SYNC_ERRORS]({ commit }) {
		commit(TWO_WAY_SYNC_ERRORS_VISIBILITY, true);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[HIDE_TWO_WAY_SYNC_ERRORS]({ commit }) {
		commit(TWO_WAY_SYNC_ERRORS_VISIBILITY, false);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[SHOW_ENGAGEMENT_SYNC_VALIDATION_ERRORS]({ commit }) {
		commit(ENGAGEMENT_SYNC_VALIDATION_ERRORS_VISIBILITY, true);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[HIDE_ENGAGEMENT_SYNC_VALIDATION_ERRORS]({ commit }) {
		commit(ENGAGEMENT_SYNC_VALIDATION_ERRORS_VISIBILITY, false);
	}
};
