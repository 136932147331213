import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { getInitialState } from './state';

export const moduleStatus = {
	namespaced: true,
	state: getInitialState(),
	mutations,
	actions,
	getters
};
