export const MODE = {
	CREATE: 'create',
	EDIT: 'edit'
};

export const STATUS_TYPE = {
	NOT_SAVED: 'not_saved',
	SAVING: 'saving',
	SAVED: 'saved',
	DELETING: 'deleting',
	FAILED: 'failed'
};
