//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { AlertUnauthorized } from 'components';

export default {
	components: {
		AlertUnauthorized
	},

	props: {
		// @docs: https://vuetifyjs.com/en/api/v-skeleton-loader/#props
		loaderType: {
			type: String,
			default: 'article'
		},
		dataLoaded: {
			type: Boolean,
			default: true
		},
		authorized: {
			type: Boolean,
			default: true
		},
		errors: {
			type: Array,
			default: () => []
		}
	}
};
