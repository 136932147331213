//
//
//
//
//
//
//
//
//
//
//
//


const EXTRA_PADDING = 30;

export default {
	props: {
		html: {
			type: String,
			default: ''
		}
	},

	data: () => ({
		height: '100%'
	}),

	methods: {
		onIframeLoad() {
			const iframeContentHeight = this.$refs.iframe.contentWindow.document.body.scrollHeight;

			this.height = `${iframeContentHeight + EXTRA_PADDING}px`;
		},
		getStyle() {
			return `height: ${this.height}`;
		}
	}
};

