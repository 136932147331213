export default {
	webVisits({ person: { counters } }) {
		return counters?.webVisits ?? 0;
	},

	emailOpens({ person: { counters } }) {
		return counters?.emailOpens ?? 0;
	},

	emailClicks({ person: { counters } }) {
		return counters?.emailClicks ?? 0;
	},

	openDeals({ person: { counters } }) {
		return counters?.openDeals ?? 0;
	},

	pageViews({ person: { counters } }) {
		return counters?.pageViews ?? 0;
	}
};
