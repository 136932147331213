export const SEQUENCE_NAVIGATE = 'SEQUENCE_NAVIGATE';
export const OPTIONS_GET = 'OPTIONS_GET';
export const FIELDS_INITIALIZE = 'FIELDS_INITIALIZE';
export const EMAIL_GET = 'EMAIL_GET';
export const FIELDS_EDIT = 'FIELDS_EDIT';
export const DIALOG_EDITOR_HTML_SHOW = 'DIALOG_EDITOR_HTML_SHOW';
export const DIALOG_EDITOR_HTML_CONFIRM = 'DIALOG_EDITOR_HTML_CONFIRM';
export const DIALOG_EDITOR_HTML_CANCEL = 'DIALOG_EDITOR_HTML_CANCEL';
export const DIALOG_CUSTOM_HTML_SHOW = 'DIALOG_CUSTOM_HTML_SHOW';
export const DIALOG_CUSTOM_HTML_CONFIRM = 'DIALOG_CUSTOM_HTML_CONFIRM';
export const DIALOG_CUSTOM_HTML_CANCEL = 'DIALOG_CUSTOM_HTML_CANCEL';
export const ERRORS_SHOW = 'ERRORS_SHOW';
export const IMAGE_UPLOAD = 'IMAGE_UPLOAD';
