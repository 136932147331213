export const LISTS_GET = 'LISTS_GET';
export const LIST_SELECT = 'LIST_SELECT';
export const FIELD_ADD = 'FIELD_ADD';
export const MAPPINGS_GET = 'MAPPINGS_GET';
export const OPTIONS_GET = 'OPTIONS_GET';
export const SOURCE_FIELD_UPDATE = 'SOURCE_FIELD_UPDATE';
export const DESTINATION_FIELD_UPDATE = 'DESTINATION_FIELD_UPDATE';
export const MAPPING_CREATE = 'MAPPING_CREATE';
export const MAPPING_UPDATE = 'MAPPING_UPDATE';
export const MAPPING_DELETE = 'MAPPING_DELETE';
export const MAPPING_CANCEL = 'MAPPING_CANCEL';
