export const STEPS = {
	BASICS: 'BASICS',
	COMPOSE: 'COMPOSE',
	REVIEW: 'REVIEW'
};

export const EDITOR_MODE = {
	CREATE: 'CREATE',
	EDIT: 'EDIT'
};

export const RECIPIENTS_TYPES = {
	ALL: 'All',
	TAGS: 'Tags',
	CONTACT_TYPE: 'Contact type',
	LEAD_STATUS: 'Lead status'
};

export const SEGMENT_TYPES = {
	PIPEDRIVE: 'pipedrive',
	COPPER: 'copper',
	OUTFUNNEL: 'outfunnel'
};

const getInitialState = () => ({
	isFeatureEnabled: false,
	mode: EDITOR_MODE.CREATE,
	step: STEPS.BASICS,
	options: {},
	campaign: {
		name: undefined,
		fromAddress: '',
		fromName: '',
		filter: undefined,
		filterData: {
			id: 0,
			name: ''
		},
		subject: '',
		preHeader: '',
		delta: {},
		contentJSON: null,
		html: ''
	},
	emailEngagementSettings: {},
	visibleFields: [],
	generalErrors: [],
	fieldErrors: {
		name: null,
		filter: null,
		fromAddress: null,
		fromName: null,
		subject: null,
		html: null,
		preHeader: null
	},
	isDialogConfirmationVisible: false,
	isDialogTestEmailVisible: false,
	isDialogEditorHtmlVisible: false,
	isDialogCustomHtmlVisible: false,
	isDialogSendingLimitsVisible: false,
	testEmails: [],
	testEmailErrors: [],
	areMergeFieldsReplaced: true,
	recipientsType: null,
	segmentType: null,
	contactsCount: 0,
	uploadedImages: {}
});

export default getInitialState;
