/**
 * Verify user email against API.
 */

export const VERIFY_USER_EMAIL = 'VERIFY_USER_EMAIL';

/**
 * Store query parameters on page load.
 */

export const STORE_QUERY_PARAMS = 'STORE_QUERY_PARAMS';
