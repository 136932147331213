//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
		rows: {
			type: Array,
			default: () => []
		},
		placeholderText: {
			type: String,
			default: 'No data to show'
		},
		clickableRows: {
			type: Boolean,
			default: true
		},
		dataLoaded: {
			type: Boolean,
			default: true
		}
	},

	computed: {
		rowClasses() {
			if (this.clickableRows) {
				return 'base-table-row base-table-row--clickable';
			}

			return 'base-table-row';
		},

		isDataMissing() {
			return !this.rows || !this.rows.length;
		},

		hasNoDataSlot() {
			return !!this.$scopedSlots['no-data'];
		}
	}
};

