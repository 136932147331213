import {
	CONNECTION_ID,
	CONNECTION,
	CONNECTION_STATUS,
	PRIMARY_RULE_STATUS,
	SECONDARY_RULE_STATUS,
	CONTACTS,
	PAGINATION,
	ERRORS,
	CURRENT_PAGE,
	DOWNLOAD_URL,
	SORT_BY_FIELD,
	SORT_ORDER,
	CONTACT_STATUS,
	SEARCH_TERM,
	INTERVAL_ID,
	CONTACT_SYNC_RULES,
	OPTIONS
} from './mutationTypes';

export const mutations = {
	[CONNECTION_ID](state, connectionId) {
		state.connectionId = connectionId;
	},

	[CONNECTION](state, connection) {
		state.connection = connection;
	},

	[CONNECTION_STATUS](state, status) {
		state.connectionStatus = status;
	},

	[PRIMARY_RULE_STATUS](state, status) {
		state.primaryRuleStatus = status;
	},

	[SECONDARY_RULE_STATUS](state, status) {
		state.secondaryRuleStatus = status;
	},

	[CONTACTS](state, contacts) {
		state.contacts = contacts;
	},

	[CURRENT_PAGE](state, currentPage) {
		state.pagination.currentPage = currentPage;
	},

	[PAGINATION](state, pagination) {
		state.pagination = pagination;
	},

	[DOWNLOAD_URL](state, downloadUrl) {
		state.downloadUrl = downloadUrl;
	},

	[ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[SORT_BY_FIELD](state, sortByField) {
		state.sortByField = sortByField;
	},

	[SORT_ORDER](state, sortOrder) {
		state.sortOrder = sortOrder;
	},

	[CONTACT_STATUS](state, contactStatus) {
		state.contactStatus = contactStatus;
	},

	[SEARCH_TERM](state, searchTerm) {
		state.searchTerm = searchTerm;
	},

	[INTERVAL_ID](state, intervalId) {
		state.intervalId = intervalId;
	},

	[CONTACT_SYNC_RULES](state, contactSyncRules) {
		state.contactSyncRules = contactSyncRules;
	},

	[OPTIONS](state, options) {
		state.options = options;
	}
};
