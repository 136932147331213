import { MutationTypes } from './mutationTypes';

export const mutations = {
	[MutationTypes.INSTRUCTIONS_VISIBLE](state, isVisible) {
		state.areInstructionsVisible = isVisible;
	},

	[MutationTypes.VISITS](state, visits) {
		state.visits = visits;
	},

	[MutationTypes.PAGINATION](state, pagination) {
		state.pagination = pagination;
	},

	[MutationTypes.FEATURES](state, features) {
		state.features = features;
	},

	[MutationTypes.COUNTERS](state, counters) {
		state.counters = counters;
	}
};
