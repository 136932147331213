import { api } from 'api';
import router from 'router';
import logger from 'utils/logger';
import * as liveChat from 'utils/live-chat';
import Sentry from 'utils/sentry';
import { getSignupTrackingData } from 'utils/tracking';
import { EventNames, identify, track } from 'utils/analytics';
import {
	identify as posthogIdentify,
	track as posthogTrack
} from 'utils/analytics/posthog';
import {
	removeToken
} from '../../../utils/storage';
import {
	isAppConnectorRouteVisible,
	isCampaignsRouteVisible,
	isLeadScoringRouteVisible,
	isEmailSettingVisible,
	isContactsRouteVisible
} from './helpers';

import {
	REMOVE_TOKEN
} from '../../mutationTypes';

import {
	GOT_USER,
	USAGE,
	VIEWS,
	FEATURES
} from './mutationTypes';

import SHOW_NOTIFICATION from '../notifications/actionTypes';

import {
	GET_USER,
	CONFIGURE_VIEWS,
	CHANGE_NAME,
	CHANGE_COMPANY,
	CHANGE_TIMEZONE,
	CHANGE_LOCALE,
	CHANGE_TIPS,
	USAGE_GET,
	ANALYTICS_IDENTIFY,
	ANALYTICS_INTEGRATION_ADDED,
	ANALYTICS_INTEGRATION_REMOVED,
	ANALYTICS_SIGNUP_IDENTIFY,
	FEATURES_GET
} from './actionTypes';

export default {
	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[CONFIGURE_VIEWS]({
		commit, getters, state
	}) {
		try {
			commit(VIEWS, {
				views: {
					navigation: {
						appConnector: isAppConnectorRouteVisible({ getters }),
						appConnectorCreate: isAppConnectorRouteVisible({ getters }),
						campaigns: isCampaignsRouteVisible({ getters }),
						contacts: isContactsRouteVisible({ getters }),
						web: true,
						leadScoring: isLeadScoringRouteVisible({ getters }),
						help: true,
						account: true
					},
					settings: {
						profile: true,
						billing: true,
						integrations: true,
						emailSetting: isEmailSettingVisible({ getters })
					}
				}
			});
		} catch (exception) {
			commit(VIEWS, { views: state.views });
			logger.error(exception);
		}
	},
	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [GET_USER]({
		commit, dispatch, rootState
	}) {
		try {
			const user = await api.user.get(rootState.token);

			commit(GOT_USER, { user });
			const userData = {
				email: user.email,
				name: user.name,
				user_id: user.id,
				user_hash: user.intercomHash
			};

			dispatch(CONFIGURE_VIEWS);

			liveChat.updateUser(userData);

			Sentry.configureScope(scope => {
				scope.setUser({ id: user.id });
			});
		} catch (exception) {
			removeToken();
			commit(REMOVE_TOKEN, null, { root: true });
			logger.error(exception);
			// Redirecting to login page with router.go in order to ensure that the Vuex state is cleared properly
			router.go('/login');
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [CHANGE_NAME]({ commit, dispatch, rootState: { token } }, name) {
		const user = await api.user.put(token, { name });

		commit(GOT_USER, { user });

		dispatch(
			`notifications/${SHOW_NOTIFICATION}`,
			'Name changed',
			{ root: true }
		);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [CHANGE_COMPANY]({ commit, dispatch, rootState: { token } }, company) {
		const user = await api.user.put(token, { company });

		commit(GOT_USER, { user });

		dispatch(
			`notifications/${SHOW_NOTIFICATION}`,
			'Company name changed',
			{ root: true }
		);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [CHANGE_TIMEZONE]({ commit, dispatch, rootState: { token } }, timezone) {
		const user = await api.user.put(token, { tz: timezone });

		commit(GOT_USER, { user });

		dispatch(
			`notifications/${SHOW_NOTIFICATION}`,
			'Timezone changed',
			{ root: true }
		);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [CHANGE_LOCALE]({ commit, dispatch, rootState: { token } }, locale) {
		const user = await api.user.put(token, { locale });

		commit(GOT_USER, { user });

		dispatch(
			`notifications/${SHOW_NOTIFICATION}`,
			'Locale changed',
			{ root: true }
		);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [CHANGE_TIPS]({ commit, dispatch, rootState: { token } }, areTipsEnabled) {
		const user = await api.user.put(token, { tips: areTipsEnabled });

		commit(GOT_USER, { user });

		if (areTipsEnabled) {
			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'You are now subscribed to Outfunnel newsletter',
				{ root: true }
			);
		} else {
			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'You are now unsubscribed from Outfunnel newsletter',
				{ root: true }
			);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [USAGE_GET]({ commit, rootState: { token } }) {
		const usage = await api.user.usage.get(token);

		commit(USAGE, usage);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[ANALYTICS_IDENTIFY]: ({
		state: { profile }
	}, traits) => {
		if (profile) {
			const { name, email, company } = profile;

			identify({
				userId: profile.id,
				traits: {
					...traits, name, email, company
				}
			});

			posthogIdentify({
				userId: profile.id,
				properties: {
					...traits,
					name,
					email,
					company
				}
			});
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[ANALYTICS_SIGNUP_IDENTIFY]: ({
		state: { profile },
		rootState: { account }
	}, signupAuthType) => {
		const {
			id,
			email,
			name,
			company,
			created
		} = profile;
		const {
			trial_start,
			trial_end,
			plan_id,
			status
		} = account.billing.subscription;

		const signupTrackingData = getSignupTrackingData();

		identify({
			userId: id,
			traits: {
				email,
				name,
				company,
				signupAuthType,
				userCreatedAt: created,
				// eslint-disable-next-line camelcase
				trialStartDate: trial_start,
				// eslint-disable-next-line camelcase
				trialEndDate: trial_end,
				// eslint-disable-next-line camelcase
				signupPlan: plan_id,
				referralUrl: signupTrackingData.referrer,
				source: signupTrackingData.source,
				medium: signupTrackingData.utmSource,
				campaign: signupTrackingData.utmCampaign,
				subscriptionStatus: status,
				leadScoringCrmSync: 'disabled'
			}
		});

		posthogIdentify({
			userId: id,
			properties: {
				email,
				name,
				company,
				signupAuthType,
				userCreatedAt: created,
				// eslint-disable-next-line camelcase
				trialStartDate: trial_start,
				// eslint-disable-next-line camelcase
				trialEndDate: trial_end,
				// eslint-disable-next-line camelcase
				signupPlan: plan_id,
				referralUrl: signupTrackingData.referrer,
				source: signupTrackingData.source,
				medium: signupTrackingData.utmSource,
				campaign: signupTrackingData.utmCampaign,
				subscriptionStatus: status,
				leadScoringCrmSync: 'disabled'
			}
		});
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	// eslint-disable-next-line no-empty-pattern
	[ANALYTICS_INTEGRATION_ADDED]: ({ }, signupAuthType) => {
		track({
			event: EventNames.INTEGRATION_ADDED,
			properties: {
				appsSlug: signupAuthType,
				authType: 'sign up'
			}
		});

		posthogTrack({
			event: EventNames.INTEGRATION_ADDED,
			properties: {
				appsSlug: signupAuthType,
				authType: 'sign up'
			}
		});
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	// eslint-disable-next-line no-empty-pattern
	[ANALYTICS_INTEGRATION_REMOVED]: ({ }, appSlug) => {
		track({
			event: EventNames.INTEGRATION_REMOVED,
			properties: {
				appsSlug: appSlug
			}
		});

		posthogTrack({
			event: EventNames.INTEGRATION_REMOVED,
			properties: {
				appsSlug: appSlug
			}
		});
	},

	[FEATURES_GET]: async ({
		commit,
		rootState
	}) => {
		try {
			const features = await api.user.features.get(rootState.token);

			commit(FEATURES, features);
		} catch (exception) {
			if (exception.errors) {
				logger.error(exception);
			}
		}
	}
};
