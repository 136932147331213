import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { getSignupTrackingData, getSignupIntents } from 'utils/tracking';
import { OauthCodeException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.code
 * @param {String} params.provider
 * @param {String=} params.signupCoupon
 * @return {Promise<{token: String, isNewUser: Boolean}>}
 */
export const httpPost = async ({ code, provider, signupCoupon }) => {
	const requestBody = {
		code,
		provider,
		signupTracking: getSignupTrackingData(),
		intents: getSignupIntents()
	};

	if (signupCoupon) {
		requestBody.signupCoupon = signupCoupon;
	}

	const result = await request.post(endpoints.auth.oauthCode, requestBody);

	if (!result.success) {
		return Promise.reject(new OauthCodeException(result));
	}

	return result.data;
};
