import { groupErrors } from 'utils/error';
import {
	CONNECTIONS_COUNT,
	LAST_SYNC_STATS,
	LEAD_SCORE_SETTINGS,
	WEB_TRACKING_COUNTERS,
	ERRORS
} from './mutationTypes';

export default {

	[CONNECTIONS_COUNT](state, connectionsCount) {
		state.connectionsCount = connectionsCount;
	},

	[LAST_SYNC_STATS](state, lastSyncStats) {
		state.lastSyncStats = lastSyncStats;
	},

	[LEAD_SCORE_SETTINGS](state, leadScoreSettings) {
		state.leadScoreSettings = leadScoreSettings;
	},

	[WEB_TRACKING_COUNTERS](state, webTrackingCounters) {
		state.webTrackingCounters = webTrackingCounters;
	},

	[ERRORS](state, errors) {
		const { visibleFields } = state;
		const { generalErrors, contextualErrors } = groupErrors(
			errors, visibleFields
		);

		contextualErrors.forEach(error => {
			state.fieldErrors[error.context] = error.message;
		});

		state.generalErrors = generalErrors;
	}
};
