//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_DISMISS = 'dismiss';

export default {
	data: () => ({
		learnLink: 'https://outfunnel.com/marketing-automation'
	}),
	methods: {
		onDismiss() {
			this.$emit(EVENT_DISMISS);
		}
	}
};
