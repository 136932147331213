export const PLANS = 'PLANS';
export const SUBSCRIPTION = 'SUBSCRIPTION';

export const GENERAL_ERRORS = 'GENERAL_ERRORS';

export const BUTTON_ENABLE = 'BUTTON_ENABLE';

export const FIELDS = 'FIELDS';
export const FIELD_ERRORS = 'FIELD_ERRORS';
export const FIELD_ERRORS_CLEAR = 'FIELD_ERRORS_CLEAR';

export const RESET = 'RESET';
