import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { ConnectionsException } from './helpers';

/**
 * @param {String=} id
 * @return {String}
 */
const getUrl = id => {
	if (id) {
		return endpoints.connectionsId(id);
	}

	return endpoints.connections;
};

/**
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.token
 * @return {Promise<Object>}
 */
export const httpGet = async ({ id, token }) => {
	const url = getUrl(id);
	const response = await request.get(url, token);

	if (!response.success) {
		return Promise.reject(new ConnectionsException(response));
	}

	return response.data;
};
