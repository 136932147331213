import {
	PLANS,
	SUBSCRIPTION,
	GENERAL_ERRORS,
	BUTTON_ENABLE,
	FIELDS,
	FIELD_ERRORS,
	FIELD_ERRORS_CLEAR,
	RESET
} from './mutationTypes';

import getInitialState from './state';

export default {
	[PLANS](state, plans) {
		state.plans = plans;
	},

	[SUBSCRIPTION](state, subscription) {
		state.subscription = subscription;
	},

	[FIELDS](state, fields) {
		Object.assign(state.fields, fields);
	},

	[FIELD_ERRORS](state, errors) {
		errors.forEach(error => {
			state.fieldErrors[error.context] = error.message;
		});
	},

	[FIELD_ERRORS_CLEAR](state, fields) {
		Object.keys(fields).forEach(fieldKey => {
			state.fieldErrors[fieldKey] = null;
		});
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[BUTTON_ENABLE](state, isEnabled) {
		state.isButtonEnabled = isEnabled;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	}
};
