//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IntegrationIcon from './IntegrationIcon.vue';
import BaseButton from './BaseButton.vue';

export default {
	components: {
		IntegrationIcon,
		BaseButton
	},

	props: {
		integration: {
			type: String,
			required: true
		}
	}
};

