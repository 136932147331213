export const OPTIONS = 'OPTIONS';
export const FIELD_MAPPINGS_OPTIONS = 'FIELD_MAPPINGS_OPTIONS';
export const SEGMENT_FIELD_MAPPINGS_OPTIONS = 'SEGMENT_FIELD_MAPPINGS_OPTIONS';
export const ERRORS = 'ERRORS';
export const CONNECTION = 'CONNECTION';
export const SOURCE_SEGMENT = 'SOURCE_SEGMENT';
export const DESTINATION_SEGMENT = 'DESTINATION_SEGMENT';
export const ENGAGEMENT_EVENT_SEGMENT = 'ENGAGEMENT_EVENT_SEGMENT';
export const CONTACT_SYNC_RULES = 'CONTACT_SYNC_RULES';
export const CONTACT_CREATION_RULES = 'CONTACT_CREATION_RULES';
export const CONTACT_CREATION_RULE_OPTIONS = 'CONTACT_CREATION_RULE_OPTIONS';
export const FIELD_MAPPINGS = 'FIELD_MAPPINGS';
export const REVERSED_FIELD_MAPPINGS = 'REVERSED_FIELD_MAPPINGS';
export const UNIFIED_FIELD_MAPPINGS = 'UNIFIED_FIELD_MAPPINGS';
export const ENGAGEMENT_SYNC_RULES = 'ENGAGEMENT_SYNC_RULES';
export const ENGAGEMENT_SYNC_RULE_OPTIONS = 'ENGAGEMENT_SYNC_RULE_OPTIONS';
export const RESET = 'RESET';
export const VIEW = 'VIEW';
export const STATS = 'STATS';
export const FEED = 'FEED';
export const FEED_PAGE = 'FEED_PAGE';
export const FEED_PAGE_COUNT = 'FEED_PAGE_COUNT';
export const CONNECTION_STATUS_PERIOD = 'CONNECTION_STATUS_PERIOD';
export const CONNECTION_STATUS_OPTIONS = 'CONNECTION_STATUS_OPTIONS';
export const ENGAGEMENT_SYNC_UPGRADE_DIALOG_VISIBILITY = 'ENGAGEMENT_SYNC_UPGRADE_DIALOG_VISIBILITY';
export const MAP_REQUIRED_FIELDS_DIALOG_VISIBILITY = 'MAP_REQUIRED_FIELDS_DIALOG_VISIBILITY';
export const DIALOG_SYNC_RECENT_ENGAGEMENTS_VISIBILITY = 'DIALOG_SYNC_RECENT_ENGAGEMENTS_VISIBILITY';
export const SET_REFRESH_FIELDS_IN_PROGRESS = 'SET_REFRESH_FIELDS_IN_PROGRESS';
