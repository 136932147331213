//
//
//
//
//
//
//

import { createNamespacedHelpers } from 'vuex';

import ROUTE_NAMES from 'router/route-names';
import Navigation from './Navigation.vue';

const userHelpers = createNamespacedHelpers('user');

const APP_CONNECTOR_LABEL = 'App connector';
const APP_CONNECTOR_ICON = 'mdi-sync';

export default {
	components: {
		Navigation
	},

	computed: {
		...userHelpers.mapGetters([
			'areAutomationsDisabled',
			'areSingleEmailCampaignsEnabled',
			'isCrmConnected',
			'locales',
			'isPipedriveConnected',
			'views',
			'isBillingVisible',
			'isIntegrationsVisible',
			'isConnectorEnabled',
			'isConnectorEnabledForPipedrive',
			'isConnectorLiteEnabled',
			'isDashboardEnabled',
			'isEmailEngagementSettingsSupported'
		]),

		// eslint-disable-next-line max-lines-per-function
		routes() {
			const routes = [];

			if (this.views?.navigation?.appConnectorCreate) {
				routes.push({
					name: this.locales?.navigation?.appConnectorCreate ?? 'Create connection',
					icon: 'mdi-plus',
					route: { name: ROUTE_NAMES.CONNECTIONS_CREATE }
				});
			}

			if (this.views?.navigation?.appConnector) {
				routes.push({
					name: this.locales?.navigation?.appConnector ?? APP_CONNECTOR_LABEL,
					icon: APP_CONNECTOR_ICON,
					route: { name: ROUTE_NAMES.CONNECTIONS }
				});
			}

			if (this.views?.navigation?.campaigns) {
				routes.push({
					name: this.locales?.navigation?.campaigns ?? 'Campaigns',
					icon: 'mdi-email-outline',
					route: { name: ROUTE_NAMES.CAMPAIGNS },
					group: 'campaigns',
					subLinks: this.campaignsSublinks
				});
			}

			if (this.views?.navigation?.contacts) {
				routes.push({
					name: this.locales?.navigation?.contacts ?? 'Contacts',
					icon: 'mdi-account-outline',
					route: { name: ROUTE_NAMES.CONTACTS }
				});
			}

			routes.push({
				name: this.locales?.navigation?.web ?? 'Web tracking',
				icon: 'mdi-web',
				route: { name: ROUTE_NAMES.WEB_TRACKING_IDENTIFIED_VISITORS },
				group: 'new-web-tracking',
				subLinks: [
					{
						name: 'Identified visitors',
						route: { name: ROUTE_NAMES.WEB_TRACKING_IDENTIFIED_VISITORS }
					},
					{
						name: 'Channel report',
						route: { name: ROUTE_NAMES.WEB_TRACKING_CHANNEL_REPORT_NEW }
					},
					{
						name: 'Workflows',
						route: { name: ROUTE_NAMES.WEB_TRACKING_WORKFLOWS }
					},
					{
						name: 'Create tracking link',
						route: { name: ROUTE_NAMES.WEB_TRACKING_LINKS }
					},
					{
						name: 'Web tracking setup',
						route: { name: ROUTE_NAMES.WEB_TRACKING_SETUP }
					}
				]
			});

			if (this.views?.navigation?.leadScoring) {
				routes.push({
					name: this.locales?.navigation?.leadScoring ?? 'Lead scoring',
					icon: 'mdi-creation-outline',
					route: { name: ROUTE_NAMES.LEAD_SCORING },
					group: 'lead-scoring',
					subLinks: [
						{
							name: 'Scoring rules',
							route: { name: ROUTE_NAMES.LEAD_SCORING_DASHBOARD }
						},
						{
							name: 'Workflows',
							route: { name: ROUTE_NAMES.LEAD_SCORING_WORKFLOWS }
						}
					]
				});
			}

			if (this.isIntegrationsVisible) {
				routes.push({
					name: this.locales?.navigation?.integrations ?? 'Apps',
					icon: 'mdi-view-grid-plus-outline',
					route: { name: ROUTE_NAMES.INTEGRATIONS },
					placement: 'secondary'
				});
			}

			routes.push({
				name: this.locales?.navigation?.help ?? 'Help',
				icon: 'mdi-help-circle-outline',
				isExternalLink: true,
				route: 'https://support.outfunnel.com/en/',
				placement: 'secondary'
			});

			routes.push({
				name: this.locales?.navigation?.account ?? 'Account',
				icon: 'mdi-account-circle-outline',
				route: this.accountRoute,
				placement: 'secondary',
				group: 'account',
				subLinks: [
					{
						name: 'Billing',
						route: { name: ROUTE_NAMES.ACCOUNT_BILLING }
					},
					{
						name: 'Profile',
						route: { name: ROUTE_NAMES.ACCOUNT_PROFILE }
					}

				]
			});

			return routes;
		},

		accountRoute() {
			if (this.isBillingVisible) {
				return { name: ROUTE_NAMES.ACCOUNT_BILLING };
			}

			return { name: ROUTE_NAMES.ACCOUNT_USAGE };
		},

		logoRoute() {
			if (!this.isConnectorLiteEnabled && this.isDashboardEnabled) {
				return { name: ROUTE_NAMES.DASHBOARD };
			}

			if (this.isConnectorEnabled && !this.isConnectorLiteEnabled) {
				return { name: ROUTE_NAMES.CONNECTIONS };
			}

			return { name: ROUTE_NAMES.CAMPAIGNS };
		},

		campaignsSublinks() {
			const sublinks = [
				{
					name: 'Sequences',
					route: { name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE }
				},
				{
					name: 'Sending schedule',
					route: { name: ROUTE_NAMES.EMAIL_SETTINGS }
				},
				{
					name: 'Reply tracking',
					route: { name: ROUTE_NAMES.EMAIL_SETTINGS_REPLY_TRACKING }
				},
				{
					name: 'Verified domains',
					route: { name: ROUTE_NAMES.EMAIL_SETTINGS_DOMAINS }
				},
				{
					name: 'Footer',
					route: { name: ROUTE_NAMES.EMAIL_SETTINGS_FOOTER }
				},
				{
					name: 'Email health',
					route: { name: ROUTE_NAMES.EMAIL_SETTINGS_EMAIL_HEALTH }
				}
			];

			if (this.areSingleEmailCampaignsEnabled) {
				sublinks.splice(1, 0, {
					name: 'Single Emails',
					route: { name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL }
				});
			}

			if (this.isEmailEngagementSettingsSupported) {
				sublinks.push({
					name: 'Event recording',
					route: { name: ROUTE_NAMES.EMAIL_SETTINGS_ENGAGEMENT_RECORDING }
				});
			}

			return sublinks;
		}
	}
};

