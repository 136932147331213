//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
	getLocaleFormattedDate,
	getFormattedPercentage
} from 'utils/formatting';

import SequenceTableStatusIcon from './SequenceTableStatusIcon.vue';
import TableMenu from './TableMenu.vue';

const MENU_ACTION_ID = {
	REPORT: 'REPORT',
	EDIT: 'EDIT',
	DUPLICATE: 'DUPLICATE',
	ACTIVATE: 'ACTIVATE',
	PAUSE: 'PAUSE',
	DELETE: 'DELETE'
};

const MENU_ACTION = {
	[MENU_ACTION_ID.REPORT]: {
		id: MENU_ACTION_ID.REPORT,
		title: 'Report',
		icon: 'insert_chart'
	},
	[MENU_ACTION_ID.EDIT]: {
		id: MENU_ACTION_ID.EDIT,
		title: 'Edit',
		icon: 'edit'
	},
	[MENU_ACTION_ID.DUPLICATE]: {
		id: MENU_ACTION_ID.DUPLICATE,
		title: 'Duplicate',
		icon: 'file_copy'
	},
	[MENU_ACTION_ID.ACTIVATE]: {
		id: MENU_ACTION_ID.ACTIVATE,
		title: 'Activate',
		icon: 'play_arrow'
	},
	[MENU_ACTION_ID.PAUSE]: {
		id: MENU_ACTION_ID.PAUSE,
		title: 'Pause',
		icon: 'pause'
	},
	[MENU_ACTION_ID.DELETE]: {
		id: MENU_ACTION_ID.DELETE,
		title: 'Delete',
		icon: 'delete'
	}
};

const STATUS = {
	ACTIVE: 'active',
	PAUSED: 'paused',
	DRAFT: 'draft'
};

const EVENT_VIEW_SEQUENCE_REPORT = 'report:sequence';
const EVENT_EDIT_SEQUENCE = 'edit:sequence';
const EVENT_DUPLICATE_SEQUENCE = 'duplicate:sequence';
const EVENT_ACTIVATE_SEQUENCE = 'activate:sequence';
const EVENT_PAUSE_SEQUENCE = 'pause:sequence';
const EVENT_DELETE_SEQUENCE = 'delete:sequence';

const mapMenuActionToEvent = menuAction => {
	const actionToEventMap = {
		[MENU_ACTION_ID.REPORT]: EVENT_VIEW_SEQUENCE_REPORT,
		[MENU_ACTION_ID.EDIT]: EVENT_EDIT_SEQUENCE,
		[MENU_ACTION_ID.DUPLICATE]: EVENT_DUPLICATE_SEQUENCE,
		[MENU_ACTION_ID.ACTIVATE]: EVENT_ACTIVATE_SEQUENCE,
		[MENU_ACTION_ID.PAUSE]: EVENT_PAUSE_SEQUENCE,
		[MENU_ACTION_ID.DELETE]: EVENT_DELETE_SEQUENCE
	};

	return actionToEventMap[menuAction];
};

export default {
	components: {
		SequenceTableStatusIcon,
		TableMenu
	},

	props: {
		rows: {
			type: Array,
			default: null
		},
		locale: {
			type: String,
			default: 'en'
		}
	},

	methods: {
		menuActions(sequence) {
			const menuActions = [
				MENU_ACTION[MENU_ACTION_ID.EDIT],
				MENU_ACTION[MENU_ACTION_ID.DUPLICATE]
			];

			if (sequence.status !== STATUS.DRAFT) {
				menuActions.unshift(MENU_ACTION[MENU_ACTION_ID.REPORT]);
			}

			if (sequence.status === STATUS.ACTIVE) {
				menuActions.push(MENU_ACTION[MENU_ACTION_ID.PAUSE]);
			} else {
				menuActions.push(MENU_ACTION[MENU_ACTION_ID.ACTIVATE]);
			}

			menuActions.push(MENU_ACTION[MENU_ACTION_ID.DELETE]);

			return menuActions;
		},

		onMenuAction(action, sequenceId) {
			const event = mapMenuActionToEvent(action);

			this.$emit(event, sequenceId);
		},

		sequenceClicked(sequence) {
			if (sequence.status === STATUS.DRAFT) {
				this.$emit(EVENT_EDIT_SEQUENCE, sequence._id);
			} else {
				this.$emit(EVENT_VIEW_SEQUENCE_REPORT, sequence._id);
			}
		},

		dateText(sequence) {
			const STATUS_TEXT = {
				[STATUS.ACTIVE]: 'Activated',
				[STATUS.PAUSED]: 'Paused',
				[STATUS.DRAFT]: 'Draft saved'
			};

			let text = 'Last saved on';
			const date = sequence.updated || sequence.created;

			if (STATUS_TEXT[sequence.status]) {
				text = STATUS_TEXT[sequence.status];
			}

			return `${text} ${getLocaleFormattedDate(date, this.locale)}`;
		},

		sentCount({ counters: { queued } }) {
			return queued;
		},

		openedPercentage({ counters: { opened, queued } }) {
			return getFormattedPercentage(opened, queued);
		},

		clickedPercentage({ counters: { clicked, queued } }) {
			return getFormattedPercentage(clicked, queued);
		},

		repliedPercentage({ counters: { replied, queued } }) {
			return getFormattedPercentage(replied, queued);
		},

		isDraft(sequence) {
			return sequence.status === STATUS.DRAFT;
		}
	}
};

