const getInitialState = () => ({
	connectionsCount: 0,
	lastSyncStats: {
		latestEventAt: null,
		allEventsCount: 0
	},
	leadScoreSettings: {
		active: false,
		leadsScored: 0
	},
	webTrackingCounters: {
		totalEvents: 0,
		identifiedContacts: 0
	},
	generalErrors: [],
	fieldErrors: []
});

export default getInitialState;
