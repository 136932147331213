import { httpGet } from './httpGet';
import { httpPost } from './httpPost';
import { httpPut } from './httpPut';
import { httpDelete } from './httpDelete';

import { options } from './options';
import { trigger } from './trigger';
import { test } from './test';
import { recipients } from './recipients';

export const emails = {
	get: httpGet,
	post: httpPost,
	put: httpPut,
	delete: httpDelete,
	options,
	trigger,
	test,
	recipients
};
