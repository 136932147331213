import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { StatusException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {String} params.source
 * @param {String} params.destination
 * @param {String} params.token
 * @param {Boolean=} params.setNoCacheHeader
 * @return {Promise<Object>}
 */
export const httpGet = async ({
	connectionId,
	source,
	destination,
	token,
	setNoCacheHeader
}) => {
	const url = new URL(endpoints.connectionsStatus(connectionId));

	if (source) {
		url.searchParams.set('source', source);
	}

	if (destination) {
		url.searchParams.set('destination', destination);
	}

	const response = await request.get({ url: url.href, token, setNoCacheHeader });

	if (!response.success) {
		return Promise.reject(new StatusException(response));
	}

	return response.data;
};
