//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_SAVE = 'save';
const EVENT_CLOSE = 'close';
const EVENT_CHANGE_AUTH_DATA = 'change:auth-data';

export default {
	props: {
		isVisible: {
			type: Boolean,
			default: false
		},
		authData: {
			type: Object,
			default: () => ({})
		},
		errors: {
			type: Array,
			default: () => ([])
		}
	},
	data: () => ({
		firstField: {
			id: 'apiKey',
			label: 'API Key'
		},
		secondField: {
			id: 'baseId',
			label: 'Airtable Base ID'
		},
		thirdField: {
			id: 'tableName',
			label: 'Airtable table name'
		},
		airtableSupportLink: 'https://support.outfunnel.com/en/articles/5537650-2-way-airtable-mailchimp-integration#h_5cf669dcae'
	}),
	methods: {
		onSave() {
			this.$emit(EVENT_SAVE);
		},

		onClose() {
			this.$emit(EVENT_CLOSE);
		},

		onChangeAuthData(authData) {
			this.$emit(EVENT_CHANGE_AUTH_DATA, authData);
		}
	}
};

