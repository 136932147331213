import { api } from 'api';
import logger from 'utils/logger';
import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import { mergeKeyAndEntityType } from './helpers';

import {
	LISTS,
	GENERAL_ERRORS,
	LIST,
	OPTIONS,
	MAPPINGS,
	NEW_FIELD_VISIBILITY
} from './mutationTypes';

import {
	LISTS_GET,
	LIST_SELECT,
	FIELD_ADD,
	MAPPINGS_GET,
	OPTIONS_GET,
	SOURCE_FIELD_UPDATE,
	DESTINATION_FIELD_UPDATE,
	MAPPING_CREATE,
	MAPPING_UPDATE,
	MAPPING_DELETE,
	MAPPING_CANCEL
} from './actionTypes';

export default {
	async [LISTS_GET]({ commit, rootState: { token } }) {
		try {
			const lists = await api.user.lists.get(token);

			commit(LISTS, lists);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [LIST_SELECT]({ commit, dispatch }, list) {
		commit(LIST, list);

		dispatch(OPTIONS_GET, { list });
		dispatch(MAPPINGS_GET, list);
	},

	async [OPTIONS_GET]({ commit, dispatch, rootState: { token } }, { list, refreshfields }) {
		try {
			const options = await api.user.lists.options.get({ token, listId: list, refreshfields });

			const crmFields = options.crmFields.map(option => ({
				...option,
				id: mergeKeyAndEntityType({
					key: option.key,
					entityType: option.entityType
				})
			}));

			if (refreshfields) {
				dispatch(
					`notifications/${SHOW_NOTIFICATION}`,
					'Fields refreshed',
					{ root: true }
				);
			}

			commit(OPTIONS, { ...options, crmFields });
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [MAPPINGS_GET]({ commit, rootState: { token } }, list) {
		try {
			const mappings = await api.user.lists.mappings.get(token, list);

			const convertedMappings = mappings.map(mapping => (
				{
					...mapping,
					id: mergeKeyAndEntityType(
						{
							key: mapping.destination,
							entityType: mapping.destinationEntityType
						}
					)
				}
			));

			commit(MAPPINGS, convertedMappings);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[FIELD_ADD]({ commit }) {
		commit(NEW_FIELD_VISIBILITY, true);
	},

	async [SOURCE_FIELD_UPDATE]({ state, dispatch }, { mappingId, value }) {
		const sourceField = state.options.mergeFields.find(({ tag }) => tag === value);
		const mapping = {
			type: 'mailchimp_to_pipedrive',
			source: sourceField.tag,
			sourceType: sourceField.type
		};

		if (mappingId) {
			dispatch(MAPPING_UPDATE, { mappingId, mapping });
		} else {
			dispatch(MAPPING_CREATE, mapping);
		}
	},

	async [DESTINATION_FIELD_UPDATE]({ state, dispatch }, { mappingId, value }) {
		const destinationField = state.options.crmFields.find(({ id }) => id === value);
		const mapping = {
			type: 'mailchimp_to_pipedrive',
			destination: destinationField.key,
			destinationType: destinationField.type,
			destinationEntityType: destinationField.entityType
		};

		if (mappingId) {
			dispatch(MAPPING_UPDATE, { mappingId, mapping });
		} else {
			dispatch(MAPPING_CREATE, mapping);
		}
	},

	async [MAPPING_CREATE]({
		dispatch,
		commit,
		state,
		rootState: { token }
	}, mapping) {
		try {
			await api.user.lists.mappings.post(token, state.list, mapping);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Created new field mapping',
				{ root: true }
			);

			commit(NEW_FIELD_VISIBILITY, false);
			dispatch(MAPPINGS_GET, state.list);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}
		}
	},

	async [MAPPING_UPDATE]({
		dispatch,
		commit,
		state,
		rootState: { token }
	}, { mappingId, mapping }) {
		try {
			await api.user.lists.mappings.put(token, state.list, mappingId, mapping);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Field mapping updated',
				{ root: true }
			);

			dispatch(MAPPINGS_GET, state.list);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [MAPPING_DELETE]({
		state,
		rootState: { token },
		commit,
		dispatch
	}, mappingId) {
		try {
			await api.user.lists.mappings.delete(token, state.list, mappingId);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Field mapping removed',
				{ root: true }
			);
			dispatch(MAPPINGS_GET, state.list);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[MAPPING_CANCEL]({
		commit
	}) {
		commit(NEW_FIELD_VISIBILITY, false);
	}
};
