import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { SingleEmailCampaignsException } from './helpers';

/**
 * Get list of single email campaigns or specific single email campaign.
 * @param {Object} params
 * @param {String} params.token - User's JWT authorization token.
 * @param {String=} params.campaignId - ID of the campaign to get.
 * @param {Number=} params.limit
 * @param {Number=} params.page
 */
async function httpGet({
	token,
	campaignId,
	limit,
	page
}) {
	let url = new URL(endpoints.singleEmailCampaigns);

	url.searchParams.append('sortBy', 'active');
	url.searchParams.append('sortOrder', 'asc');
	url.searchParams.append('sortBy', 'updated');
	url.searchParams.append('sortOrder', 'desc');

	if (limit) {
		url.searchParams.append('limit', limit);
	}

	if (page) {
		url.searchParams.set('page', page);
	}

	if (campaignId) {
		url = endpoints.singleEmailCampaignsId(campaignId);
	}

	const result = await request.get(url, token);

	if (!result.success) {
		return Promise.reject(new SingleEmailCampaignsException(result));
	}

	const { data, pagination } = result;

	return {
		data,
		pagination
	};
}

export {
	httpGet
};
