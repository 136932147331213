import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { IntegrationsException } from './helpers';

/**
 * @param {String} token
 * @param {String} integration
 * @param {Object} data
 * @return {Promise<Object>}
 */
async function httpPost(token, integration, data) {
	const result = await request.post(endpoints.integrations, { integration, ...data }, token);

	if (!result.success) {
		return Promise.reject(new IntegrationsException(result));
	}

	return result;
}

export {
	httpPost
};
