import { isString } from 'utils/types';

export const getters = {
	listId({ automation }) {
		const { list } = automation;

		if (list && isString(list.id)) {
			return list.id;
		}
	},

	listName({ automation }) {
		const { list } = automation;

		if (list && list.name) {
			return list.name;
		}

		return 'All lists';
	}
};
