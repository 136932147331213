import {
	GOT_USER,
	USAGE,
	BANNER_TRIAL_ENDING_HIDDEN,
	VIEWS,
	FEATURES
} from './mutationTypes';

export default {
	[GOT_USER](state, { user }) {
		state.profile = user;
	},

	[USAGE](state, usage) {
		state.usage = usage;
	},

	[BANNER_TRIAL_ENDING_HIDDEN](state) {
		state.isBannerTrialEndingHidden = true;
	},

	[VIEWS](state, { views }) {
		state.views = views;
	},

	[FEATURES](state, features) {
		state.features = features;
	}
};
