import Sentry from 'utils/sentry';
import { reset } from 'utils/analytics';

import {
	REMOVE_TOKEN,
	REMOVE_LOGGED_IN_AS_USER
} from './mutationTypes';

import {
	APP_CREATED,
	APP_MOUNTED,
	LOGOUT
} from './actionTypes';

import {
	LOGIN_USER_WITH_OAUTH
} from './modules/login/actionTypes';

import {
	REGISTER_USER_WITH_OAUTH
} from './modules/register/actionTypes';

import {
	GET_USER,
	USAGE_GET
} from './modules/user/actionTypes';

import {
	OAUTH_CONNECT
} from './modules/account/integrations/actionTypes';

import {
	SUBSCRIPTION_GET,
	PLANS_GET
} from './modules/account/billing/actionTypes';

import {
	removeToken,
	removeIsLoggedInAsUser
} from '../utils/storage';

import { storeTrackingData } from '../utils/tracking';
import * as liveChat from '../utils/live-chat';

/**
 * @param {Object} params
 * @param {Boolean} params.isUserLoggedIn
 * @param {String} params.oauthState
 * @return {String}
 */
const getOauthAction = ({ isUserLoggedIn, oauthState }) => {
	if (isUserLoggedIn) {
		return `account/integrations/${OAUTH_CONNECT}`;
	}

	if (oauthState === 'register') {
		return `register/${REGISTER_USER_WITH_OAUTH}`;
	}

	return `login/${LOGIN_USER_WITH_OAUTH}`;
};

export default {
	[APP_CREATED]: ({ dispatch, state }) => {
		storeTrackingData();

		if (state.token) {
			dispatch(`user/${GET_USER}`);
			dispatch(`user/${USAGE_GET}`);
			dispatch(`account/billing/${SUBSCRIPTION_GET}`, null, { root: true });
			dispatch(`account/billing/${PLANS_GET}`, null, { root: true });
		}
	},

	[APP_MOUNTED]: ({ dispatch, state }) => {
		window.addEventListener('message', ({
			data: {
				type, provider, code, state: oauthState
			}
		}) => {
			if (type === 'oauth') {
				const isUserLoggedIn = state.token !== null;
				const nextAction = getOauthAction({
					isUserLoggedIn,
					oauthState
				});

				dispatch(nextAction, { provider, code, state: oauthState });
			}
		});
	},

	[LOGOUT]: ({ commit }) => {
		liveChat.shutDown();
		removeIsLoggedInAsUser();
		commit(REMOVE_LOGGED_IN_AS_USER);
		reset();
		removeToken();
		commit(REMOVE_TOKEN);

		Sentry.configureScope(scope => scope.clear());

		// Redirecting to login page in order to ensure that the Vuex state is cleared properly
		window.location = '/login';
	}
};
