import { api } from 'api';
import logger from 'utils/logger';

import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';

import {
	VERIFICATION_EMAIL_RESEND
} from './actionTypes';

import {
	GENERAL_ERRORS
} from './mutationTypes';

export default {
	async [VERIFICATION_EMAIL_RESEND]({ commit, dispatch }, email) {
		try {
			await api.auth.emailVerificationResend.post(email);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Verification email resent',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
