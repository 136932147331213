import {
	DOMAIN,
	GENERAL_ERRORS
} from './mutationTypes';

export const mutations = {
	[DOMAIN](state, domain) {
		state.domain = domain;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	}
};
