import { mutations } from './mutations';
import { actions } from './actions';
import { getInitialState } from './state';

export const moduleWixInstall = {
	namespaced: true,
	mutations,
	actions,
	state: getInitialState()
};
