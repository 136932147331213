import { getConfValue } from 'utils/appconfig';
import { Providers } from './constants';

/**
 * @return {String}
 */
export const getLinkedinOauthDialogUrl = () => {
	const {
		clientId, oauthUrl, redirectUrl, scope
	} = getConfValue(Providers.LINKEDIN);

	const oauthDialogUrl = new URL(oauthUrl);

	oauthDialogUrl.searchParams.set('client_id', clientId);
	oauthDialogUrl.searchParams.set('redirect_uri', redirectUrl);
	oauthDialogUrl.searchParams.set('response_type', 'code');
	oauthDialogUrl.searchParams.set('scope', scope);

	return oauthDialogUrl.href;
};
