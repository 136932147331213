import { api } from 'api';
import { TYPES } from 'api/automations';
import utils from '../../../../utils';

import {
	SET_MODE,
	SET_STATUS,
	SET_AUTOMATION,
	SET_OPTIONS,
	SET_LIST,
	SET_OPTION,
	SET_PIPELINE,
	SET_UNSUBSCRIBE_FIELD,
	SET_UNSUBSCRIBE_FIELD_OPTION
} from './mutationTypes';

import SHOW_NOTIFICATION from '../../notifications/actionTypes';

import {
	GET_OPTIONS,
	GET_AUTOMATION,
	PUT_AUTOMATION,
	POST_AUTOMATION,
	SCHEDULE_SYNC,
	POST_SCHEDULE_SYNC,
	DELETE,
	SELECT_LIST,
	CHANGE_OPTION,
	SELECT_PIPELINE,
	SELECT_STAGE,
	SELECT_DEAL_OPEN_OWNER,
	SELECT_DEAL_CLICK_OWNER,
	SELECT_MEMBER_SUBSCRIBE_OWNER,
	SELECT_UNSUBSCRIBE_FIELD,
	SELECT_UNSUBSCRIBE_FIELD_OPTION
} from './actionTypes';

import { MODE, STATUS_TYPE } from '../types';
import router from '../../../../router';
import logger from '../../../../utils/logger';

const NEWSLETTER_STATUS_FIELD = 'Subscribed to campaigns';

/**
 * Get default personSetField to be updated when user unsubscribes.
 * When NEWSLETTER_STATUS_FIELD has not been created yet,
 * then first field is "Create new field" and second field should be selected as default.
 * When it is already created, then first field should be selected instead.
 * @param {Array} personSetFields - Array of personSetFields.
 */
function getDefaultPersonSetField(personSetFields) {
	let defaultField = null;
	const hasNewsletterStatusField = Boolean(
		personSetFields.find(field => field.name === NEWSLETTER_STATUS_FIELD)
	);

	const [firstField, secondField] = personSetFields;

	if (hasNewsletterStatusField && firstField) {
		defaultField = firstField;
	} else if (secondField) {
		defaultField = secondField;
	}

	return defaultField;
}

export default {
	[GET_OPTIONS]: async ({
		commit, state, rootState: { token }
	}) => {
		const options = await api.automations.options.get(token, TYPES.MAILCHIMP_TO_PIPEDRIVE);

		commit(SET_OPTIONS, options);

		// In create mode, default values need to be populated
		if (state.mode === MODE.CREATE) {
			const booleanOptions = Object.entries(options).filter(field => typeof field[1] === 'boolean');

			booleanOptions.forEach(([key, value]) => {
				commit(SET_OPTION, { [key]: value });
			});

			if (options.personSetFields) {
				const defaultField = getDefaultPersonSetField(options.personSetFields);
				const [firstOption] = defaultField.options;

				commit(SET_UNSUBSCRIBE_FIELD, defaultField.id);
				commit(SET_UNSUBSCRIBE_FIELD_OPTION, firstOption.id);
			}
		}
	},

	[GET_AUTOMATION]: async ({ commit, rootState: { token } }, id) => {
		const automation = await api.automations.get(token, TYPES.MAILCHIMP_TO_PIPEDRIVE, id);

		commit(SET_AUTOMATION, automation);

		if (automation.fieldUnsubscribeTarget) {
			commit(SET_UNSUBSCRIBE_FIELD, automation.fieldUnsubscribeTarget.field);
			commit(SET_UNSUBSCRIBE_FIELD_OPTION, automation.fieldUnsubscribeTarget.option);
		}

		if (automation.stage && automation.stage.pipeline_id) {
			commit(SET_PIPELINE, automation.stage.pipeline_id);
		}
	},

	[PUT_AUTOMATION]: async ({
		dispatch, commit, state: { automation }, rootState: { token }
	}, changes) => {
		commit(SET_STATUS, { type: STATUS_TYPE.SAVING, message: 'Saving' });

		try {
			const updatedAutomation = await api.automations.put(token, automation._id, changes);

			commit(SET_AUTOMATION, updatedAutomation);
			let message = 'Saved';

			if (!automation.active && updatedAutomation.active) {
				message = 'Saved and queued sync';
			} else if (!updatedAutomation.active) {
				message = 'Draft saved';
			}

			if (updatedAutomation.fieldUnsubscribeTarget) {
				commit(SET_UNSUBSCRIBE_FIELD, updatedAutomation.fieldUnsubscribeTarget.field);
				commit(SET_UNSUBSCRIBE_FIELD_OPTION, updatedAutomation.fieldUnsubscribeTarget.option);
			}

			if (changes.fieldUnsubscribeTarget && changes.fieldUnsubscribeTarget.field === 0) {
				dispatch(GET_OPTIONS);
			}

			commit(SET_STATUS, { type: STATUS_TYPE.SAVED, message });
		} catch (exception) {
			commit(SET_STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to save changes' });

			logger.error(exception);
		}
	},

	[POST_AUTOMATION]: async ({ commit, rootState: { token } }, options) => {
		commit(SET_STATUS, { type: STATUS_TYPE.SAVING, message: 'Saving' });
		const data = {
			type: TYPES.MAILCHIMP_TO_PIPEDRIVE,
			...options
		};

		try {
			const automation = await api.automations.post(token, data);

			commit(SET_AUTOMATION, automation);
			commit(SET_MODE, MODE.EDIT);
			commit(SET_STATUS, { type: STATUS_TYPE.SAVED, message: 'Draft saved' });

			if (automation.fieldUnsubscribeTarget) {
				commit(SET_UNSUBSCRIBE_FIELD, automation.fieldUnsubscribeTarget.field);
				commit(SET_UNSUBSCRIBE_FIELD_OPTION, automation.fieldUnsubscribeTarget.option);
			}

			router.replace(automation._id);
		} catch (exception) {
			commit(SET_STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to create automation' });

			logger.error(exception);
		}
	},

	[SCHEDULE_SYNC]: async ({ dispatch, state: { automation } }) => {
		if (automation && automation.active) {
			dispatch(POST_SCHEDULE_SYNC);
		} else if (automation) {
			dispatch(PUT_AUTOMATION, { active: true });
		} else {
			dispatch(POST_AUTOMATION, { active: true });
		}

		dispatch(
			`notifications/${SHOW_NOTIFICATION}`,
			'Sync is queued',
			{ root: true }
		);

		await utils.delay(1);
		router.push('/automations');
	},

	[POST_SCHEDULE_SYNC]: async ({ commit, state: { automation }, rootState: { token } }) => {
		commit(SET_STATUS, { type: STATUS_TYPE.SAVING, message: 'Scheduling sync' });

		try {
			const id = automation._id;
			const type = TYPES.MAILCHIMP_TO_PIPEDRIVE;

			await api.automations.sync.post(token, type, id);

			commit(SET_STATUS, { type: STATUS_TYPE.SAVED, message: 'Sync queued' });
		} catch (exception) {
			commit(SET_STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to queue sync' });

			logger.error(exception);
		}
	},

	[DELETE]: async ({ commit, state: { automation }, rootState: { token } }) => {
		try {
			commit(SET_STATUS, { type: STATUS_TYPE.DELETING, message: 'Deleting' });
			await api.automations.delete(token, automation._id);
			router.push('/automations');
		} catch (exception) {
			commit(SET_STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to delete automation' });

			logger.error(exception);
		}
	},

	/**
	 * CHANGE_OPTION action is dispatched when user checks/unchecks a checkbox
	 * in the automation settings. Some settings need to have additional settings with them.
	 */
	[CHANGE_OPTION]: ({ commit, dispatch, state: { mode, automation, options } }, option) => {
		commit(SET_OPTION, option);
		const automationData = { ...option };

		const [defaultUser] = options.users;

		if (option.dealOpen && !automation.dealOpenOwner) {
			automationData.dealOpenOwner = defaultUser.id;
		}

		if (option.dealClick && !automation.dealClickOwner) {
			automationData.dealClickOwner = defaultUser.id;
		}

		if (option.memberSubscribe && !automation.memberSubscribeOwner) {
			automationData.memberSubscribeOwner = defaultUser.id;
		}

		if (mode === MODE.CREATE) {
			dispatch(POST_AUTOMATION, automationData);
		} else {
			dispatch(PUT_AUTOMATION, automationData);
		}
	},

	[SELECT_LIST]: ({ commit, dispatch, state: { mode } }, listId) => {
		commit(SET_LIST, listId);

		const automationData = { list: listId };

		if (mode === MODE.CREATE) {
			dispatch(POST_AUTOMATION, automationData);
		} else {
			dispatch(PUT_AUTOMATION, automationData);
		}
	},

	[SELECT_PIPELINE]: ({ dispatch, commit, state: { mode, options } }, pipelineId) => {
		commit(SET_PIPELINE, pipelineId);

		const selectedPipeline = options.pipelines.find(pipeline => pipeline.id === pipelineId);
		const automationdata = { stage: selectedPipeline.stages[0].id };

		if (mode === MODE.CREATE) {
			dispatch(POST_AUTOMATION, automationdata);
		} else {
			dispatch(PUT_AUTOMATION, automationdata);
		}
	},

	[SELECT_STAGE]: ({ dispatch, state: { mode } }, stageId) => {
		const automationData = { stage: stageId };

		if (mode === MODE.CREATE) {
			dispatch(POST_AUTOMATION, automationData);
		} else {
			dispatch(PUT_AUTOMATION, automationData);
		}
	},

	[SELECT_DEAL_OPEN_OWNER]: ({ dispatch, state: { mode } }, ownerId) => {
		const automationData = { dealOpenOwner: ownerId };

		if (mode === MODE.CREATE) {
			dispatch(POST_AUTOMATION, automationData);
		} else {
			dispatch(PUT_AUTOMATION, automationData);
		}
	},

	[SELECT_DEAL_CLICK_OWNER]: ({ dispatch, state: { mode } }, ownerId) => {
		const automationData = { dealClickOwner: ownerId };

		if (mode === MODE.CREATE) {
			dispatch(POST_AUTOMATION, automationData);
		} else {
			dispatch(PUT_AUTOMATION, automationData);
		}
	},

	[SELECT_MEMBER_SUBSCRIBE_OWNER]: ({ dispatch, state: { mode } }, ownerId) => {
		const automationData = { memberSubscribeOwner: ownerId };

		if (mode === MODE.CREATE) {
			dispatch(POST_AUTOMATION, automationData);
		} else {
			dispatch(PUT_AUTOMATION, automationData);
		}
	},

	[SELECT_UNSUBSCRIBE_FIELD]: ({ dispatch, commit, state: { options } }, fieldId) => {
		commit(SET_UNSUBSCRIBE_FIELD, fieldId);

		const selectedField = options.personSetFields.find(field => field.id === fieldId);

		const automationData = {
			fieldUnsubscribeTarget: {
				target: 'person',
				field: selectedField.id,
				option: selectedField.options[0].id
			}
		};

		if (MODE === MODE.CREATE) {
			dispatch(POST_AUTOMATION, automationData);
		} else {
			dispatch(PUT_AUTOMATION, automationData);
		}
	},

	[SELECT_UNSUBSCRIBE_FIELD_OPTION]: ({ state, dispatch }, optionId) => {
		const data = {
			fieldUnsubscribeTarget: {
				target: 'person',
				field: state.selected.unsubscribeFieldId,
				option: optionId
			}
		};

		dispatch(PUT_AUTOMATION, data);
	}
};
