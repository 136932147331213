//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_SAVE = 'save';
const EVENT_CLOSE = 'close';
const EVENT_CHANGE_AUTH_DATA = 'change:auth-data';

export default {
	props: {
		isVisible: {
			type: Boolean,
			default: false
		},
		authData: {
			type: Object,
			default: () => ({})
		},
		errors: {
			type: Array,
			default: () => ([])
		}
	},
	data: () => ({
		firstField: {
			id: 'email',
			label: 'Creator Email'
		},
		secondField: {
			id: 'apiKey',
			label: 'API Key'
		},
		apiKeysLink: 'https://app.copper.com/companies/*/app#/settings/api-keys'
	}),
	methods: {
		onSave() {
			this.$emit(EVENT_SAVE);
		},

		onClose() {
			this.$emit(EVENT_CLOSE);
		},

		onChangeAuthData(authData) {
			this.$emit(EVENT_CHANGE_AUTH_DATA, authData);
		}
	}
};

