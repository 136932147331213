import { getConfValue } from 'utils/appconfig';
import { Providers } from './constants';

const generateRandomString = () => crypto.randomUUID();

export const getConstantcontactOauthDialogUrl = () => {
	const {
		clientId,
		oauthUrl,
		redirectUrl,
		scope
	} = getConfValue(Providers.CONSTANTCONTACT);

	const oauthDialogUrl = new URL(oauthUrl);

	oauthDialogUrl.searchParams.set('client_id', clientId);
	oauthDialogUrl.searchParams.set('redirect_uri', redirectUrl);
	oauthDialogUrl.searchParams.set('response_type', 'code');
	oauthDialogUrl.searchParams.set('state', generateRandomString());
	oauthDialogUrl.searchParams.set('scope', scope);

	return oauthDialogUrl.href;
};
