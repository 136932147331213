import { api } from 'api';
import router from 'router';
import logger from 'utils/logger';

import ROUTE_NAMES from 'router/route-names';

import {
	EMAIL,
	SEQUENCE,
	GENERAL_ERRORS
} from './mutationTypes';

import {
	SEQUENCE_EMAIL_NAVIGATE,
	SEQUENCE_GET,
	EMAIL_GET
} from './actionTypes';

export default {
	[SEQUENCE_EMAIL_NAVIGATE]({ state: { sequenceId, emailId } }) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_EMAILS_REPORT,
			params: {
				sequenceId,
				emailId
			}
		});
	},

	async [SEQUENCE_GET]({
		commit,
		state: { sequenceId },
		rootState: { token }
	}) {
		try {
			const { data } = await api.sequenceEmailCampaigns.get({
				token,
				sequenceId
			});

			commit(SEQUENCE, data);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [EMAIL_GET]({
		commit,
		state: { sequenceId, emailId },
		rootState: { token }
	}) {
		try {
			const email = await api.sequenceEmailCampaigns.emails.get(token, sequenceId, emailId);

			commit(EMAIL, email);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
