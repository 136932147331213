const getInitialState = () => ({
	options: {},
	settings: {},
	visibleFields: [],
	generalErrors: [],
	fieldErrors: {},
	unsubscribeFieldTarget: {
		unsubscribeFieldId: 0,
		unsubscribeFieldOptionId: 0
	}
});

export default getInitialState;
