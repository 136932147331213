import { mutations } from './mutations';
import { actions } from './actions';
import { getInitialState } from './state';

export const moduleStart = {
	namespaced: true,
	state: getInitialState(),
	mutations,
	actions
};
