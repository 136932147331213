//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ContactSourceNames } from 'utils/constants';

export default {
	inheritAttrs: false,
	props: {
		crm: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			pipedriveSupportLink: 'https://support.outfunnel.com/en/articles/3498963-how-to-use-outfunnel-s-lead-scoring-feature',
			copperSupportLink: 'https://support.outfunnel.com/en/articles/3557293-using-the-2-way-copper-mailchimp-integration',
			hubspotSupportLink: 'https://support.outfunnel.com/en/articles/4376897-using-the-2-way-hubspot-mailchimp-integration'
		};
	},
	computed: {
		title() {
			return `Seamless 2-way ${this.crmName} and Mailchimp integration.`;
		},
		crmName() {
			if (this.crm) {
				return this.crm;
			}

			return ContactSourceNames.CRM;
		},
		isPipedrive() {
			return this.crm === ContactSourceNames.PIPEDRIVE;
		},
		readMoreLink() {
			let link = '';

			if (this.crm === ContactSourceNames.PIPEDRIVE) {
				link = this.pipedriveSupportLink;
			} else if (this.crm === ContactSourceNames.COPPER) {
				link = this.copperSupportLink;
			} else if (this.crm === ContactSourceNames.HUBSPOT) {
				link = this.hubspotSupportLink;
			}

			return link;
		}
	}
};
