import mutations from './mutations';
import actions from './actions';
import getInitialState from './state';

import singleEmail from './singleEmail';
import sequence from './sequence';

export default {
	namespaced: true,
	state: getInitialState(),
	mutations,
	actions,
	modules: {
		singleEmail,
		sequence
	}
};
