import { moduleCreate } from './moduleCreate';
import { moduleEdit } from './moduleEdit';
import { moduleWelcome } from './moduleWelcome';
import { moduleStatus } from './moduleStatus';

import { mutations } from './mutations';
import { actions } from './actions';
import { getInitialState } from './state';
import getters from './getters';

export const moduleConnections = {
	namespaced: true,
	mutations,
	getters,
	actions,
	state: getInitialState(),
	modules: {
		moduleCreate,
		moduleEdit,
		moduleWelcome,
		moduleStatus
	}
};
