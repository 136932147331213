import { isEmail } from 'validator';
import { getCaptchaToken } from 'utils/captcha';
import { api } from 'api';
import logger from 'utils/logger';

import {
	SENT,
	ERRORS
} from './mutationTypes';

import { SEND_RESET_EMAIL } from './actionTypes';

export const actions = {
	async [SEND_RESET_EMAIL]({ state, commit }) {
		if (!isEmail(state.email)) {
			commit(ERRORS, [{
				context: 'email',
				message: 'Enter valid email address'
			}]);

			return;
		}

		try {
			const captchaToken = await getCaptchaToken('reset_password');

			await api.auth.passwordResetEmail.post({
				email: state.email,
				captchaToken
			});

			commit(SENT, true);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
			commit(ERRORS, [{ message: 'Unable to send out Password Reset email. Try again later' }]);
		}
	}
};
