// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Lato-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/Lato-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/Lato-Heavy.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face{font-family:Lato;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\");font-weight:300}@font-face{font-family:Lato;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\");font-weight:normal}@font-face{font-family:Lato;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:500}@font-face{font-family:Lato;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\");font-weight:700}::selection{background:#b5e1dd;color:inherit}.v-tab{letter-spacing:.04em}.grecaptcha-badge{display:none !important}", ""]);
// Exports
module.exports = exports;
