import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { ConnectionsException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {String} params.token
 * @param {Object} params.connectionData
 * @return {Promise<Object>}
 */
export const httpPatch = async ({ connectionId, token, connectionData }) => {
	const response = await request.patch(
		endpoints.connectionsId(connectionId),
		connectionData,
		token
	);

	if (!response.success) {
		return Promise.reject(new ConnectionsException(response));
	}

	return response.data;
};
