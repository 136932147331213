import { api } from 'api';
import { getCaptchaToken } from 'utils/captcha';
import logger from 'utils/logger';

import {
	CHANGED,
	ERRORS
} from './mutationTypes';

import {
	NEW_PASSWORD_SUBMIT
} from './actionTypes';

/**
 * @constant {String}
 */
const MIN_PASSWORD_LENGTH = 8;

export const actions = {
	async [NEW_PASSWORD_SUBMIT]({ state, commit }) {
		const {
			email,
			token,
			password,
			passwordConfirmation
		} = state;

		if (password.length < MIN_PASSWORD_LENGTH) {
			return commit(ERRORS, [{
				context: 'password',
				message: `Password must be at least ${MIN_PASSWORD_LENGTH} characters long.`
			}]);
		}

		if (password !== passwordConfirmation) {
			return commit(ERRORS, [{
				context: 'password',
				message: 'Passwords do not match.'
			}]);
		}

		try {
			const captchaToken = await getCaptchaToken('reset_password_create');

			await api.auth.passwordChange.post({
				email,
				token,
				password,
				captchaToken
			});

			commit(CHANGED, true);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
			commit(ERRORS, [{
				message: 'We are unable to change your password. Try again later.'
			}]);
		}
	}
};
