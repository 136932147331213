import getInitialState from './state';

import {
	GENERAL_ERRORS,
	RESET
} from './mutationTypes';

export default {
	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	}
};
