//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_SELECT_ALL = 'select:all';

export default {
	props: {
		selectedListItems: {
			type: Array,
			default: () => []
		},
		listItems: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		areAllListItemsSelected() {
			return this.listItems.length === this.selectedListItems.length;
		},

		icon() {
			if (this.areAllListItemsSelected) {
				return 'mdi-checkbox-marked';
			}

			if (this.selectedListItems.length) {
				return 'mdi-minus-box';
			}

			return 'mdi-checkbox-blank-outline';
		}
	},
	methods: {
		selectAllListItems() {
			const listItems = this.areAllListItemsSelected ? [] : this.listItems;

			this.$emit(EVENT_SELECT_ALL, listItems);
		}
	}
};
