const getInitialState = () => ({
	fields: {
		email: {
			value: '',
			error: null
		}
	},
	errors: [],
	sent: false
});

export default getInitialState;
