import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { EmailSettingsFooterException } from './helpers';

/**
 * Update email footer.
 * @param {Object} params
 * @param {String} params.token - JWT Authorization token.
 * @param {String} params.footer - User email footer.
 * @return {Promise<Object>}
 */
async function httpPut({ token, footer }) {
	const result = await request.put(endpoints.userSettings.email.footer, { footer }, token);

	if (!result.success) {
		return Promise.reject(new EmailSettingsFooterException(result));
	}

	return result.data;
}

export {
	httpPut
};
