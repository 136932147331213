import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { StatsException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {Number=} params.period
 * @return {String}
 */
const getStatsUrl = ({ connectionId, period }) => {
	const url = new URL(endpoints.connectionsIdStats(connectionId));

	if (period) {
		url.searchParams.append('period', period);
	}

	return url.toString();
};

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {Number=} params.period
 * @param {String} params.token
 * @return {Promise<Object[]>}
 */
export const httpGet = async ({ connectionId, period, token }) => {
	const url = getStatsUrl({ connectionId, period });

	const response = await request.get(url, token);

	if (!response.success) {
		return Promise.reject(new StatsException(response));
	}

	return response.data;
};
