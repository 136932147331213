import {
	DIALOG_CONFIRMATION_VISIBILITY,
	DIALOG_SENDING_LIMITS_VISIBILITY,
	DIALOG_TEST_EMAIL_VISIBILITY,
	EMAIL_ENGAGEMENT_SETTINGS,
	EMAIL_OPTIONS,
	EMAIL_SCHEDULE_SETTINGS,
	EMAIL_SCHEDULE_SETTINGS_OPTIONS,
	FIELDS,
	FIELD_ERRORS,
	FIELD_ERRORS_CLEAR,
	GENERAL_ERRORS,
	INVALID_EMAIL_DELAYS,
	MODE,
	RESET,
	RECIPIENTS_TYPE,
	REPLACE_MERGE_FIELDS,
	SEGMENT_TYPE,
	SEQUENCE,
	SEQUENCE_OPTIONS,
	SEQUENCE_EMAILS,
	STEP,
	TEST_SEQUENCE_ID,
	TEST_EMAIL_ID,
	TEST_EMAIL_ADDRESSES,
	TEST_EMAIL_ERRORS,
	VISIBLE_FIELDS
} from './mutationTypes';

import getInitialState from './state';
import { isValidSequenceEmail, getValidationErrorMessage } from './helpers/validation';
import { getRecipientsType } from './helpers';

export default {
	[MODE](state, mode) {
		state.mode = mode;
	},

	[STEP](state, step) {
		state.step = step;
	},

	[SEQUENCE_OPTIONS](state, options) {
		state.sequenceOptions = options;
	},

	[EMAIL_OPTIONS](state, options) {
		state.emailOptions = options;
	},

	[SEQUENCE](state, sequence) {
		state.sequence = sequence;

		if (sequence.filter) {
			state.sequence.filterData = sequence.filter;
			state.sequence.filter = sequence.filter.id;
			state.recipientsType = getRecipientsType(sequence.filterData);
		}
	},

	[SEQUENCE_EMAILS](state, emails) {
		state.sequenceEmails = emails.map(email => ({
			...email,
			isValid: isValidSequenceEmail(email, state.emailOptions),
			validationErrorMessage: getValidationErrorMessage(email, state.emailOptions)
		}));
	},

	[EMAIL_ENGAGEMENT_SETTINGS](state, settings) {
		state.emailEngagementSettings = settings;
	},

	[EMAIL_SCHEDULE_SETTINGS](state, settings) {
		state.emailScheduleSettings = settings;
	},

	[EMAIL_SCHEDULE_SETTINGS_OPTIONS](state, settings) {
		state.emailScheduleSettingsOptions = settings;
	},

	[FIELDS](state, fields) {
		Object.assign(state.sequence, fields);
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[FIELD_ERRORS](state, errors) {
		errors.forEach(error => {
			state.fieldErrors[error.context] = error.message;
		});
	},

	[FIELD_ERRORS_CLEAR](state, fields) {
		Object.keys(fields).forEach(fieldKey => {
			state.fieldErrors[fieldKey] = null;
		});
	},

	[INVALID_EMAIL_DELAYS](state, emailIds) {
		state.invalidEmailDelays = emailIds;
	},

	[VISIBLE_FIELDS](state, fields) {
		state.visibleFields = fields;
	},

	[DIALOG_TEST_EMAIL_VISIBILITY](state, isVisible) {
		state.isDialogTestEmailVisible = isVisible;
	},

	[TEST_SEQUENCE_ID](state, sequenceId) {
		state.testSequenceId = sequenceId;
	},

	[TEST_EMAIL_ID](state, emailId) {
		state.testEmailId = emailId;
	},

	[TEST_EMAIL_ADDRESSES](state, emailAddresses) {
		state.testEmailAddresses = emailAddresses;
	},

	[TEST_EMAIL_ERRORS](state, errors) {
		state.testEmailErrors = errors;
	},

	[DIALOG_CONFIRMATION_VISIBILITY](state, isVisible) {
		state.isDialogConfirmationVisible = isVisible;
	},

	[DIALOG_SENDING_LIMITS_VISIBILITY](state, isVisible) {
		state.isDialogSendingLimitsVisible = isVisible;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	},

	[RECIPIENTS_TYPE](state, type) {
		state.recipientsType = type;
	},

	[SEGMENT_TYPE](state, type) {
		state.segmentType = type;
	},

	[REPLACE_MERGE_FIELDS](state, areMergeFieldsReplaced) {
		state.areMergeFieldsReplaced = areMergeFieldsReplaced;
	}
};
