import {
	SET_TOKEN,
	REMOVE_TOKEN,
	SET_LOGGED_IN_AS_USER,
	REMOVE_LOGGED_IN_AS_USER,
	REGISTER_REQUEST
} from './mutationTypes';

export default {
	[SET_TOKEN](state, { token }) {
		state.token = token;
	},

	[REMOVE_TOKEN](state) {
		state.token = null;
	},

	[SET_LOGGED_IN_AS_USER](state) {
		state.isLoggedInAsUser = true;
	},

	[REMOVE_LOGGED_IN_AS_USER](state) {
		state.isLoggedInAsUser = null;
	},

	[REGISTER_REQUEST](state) {
		state.registerStatus = 'in_progress';
	}
};
