import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import getInitialState from './state';

import edit from './edit';
import report from './report';
import emailReport from './emailReport';
import emailPreview from './emailPreview';

export default {
	namespaced: true,
	state: getInitialState(),
	mutations,
	actions,
	getters,
	modules: {
		edit,
		report,
		emailReport,
		emailPreview
	}
};
