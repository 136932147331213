//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createNamespacedHelpers } from 'vuex';

import {
	TheFooter,
	TheHeader,
	TheNavigation,
	TheNotifications
} from 'components';

import { APP_MOUNTED, APP_CREATED } from 'store/actionTypes';
import { ANALYTICS_IDENTIFY } from 'store/modules/user/actionTypes';

import * as liveChat from './utils/live-chat';

const userHelpers = createNamespacedHelpers('user');

const App = {
	components: {
		TheHeader,
		TheFooter,
		TheNavigation,
		TheNotifications
	},

	data: () => ({
		activeRoute: null
	}),

	watch: {
		/**
		 * Watch route changes.
		 */
		$route(route) {
			this.activeRoute = route;

			const timestamp = Math.floor(Date.now() / 1000);

			liveChat.updateUser({
				last_request_at: timestamp
			});

			if (!route.meta.public) {
				this.ANALYTICS_IDENTIFY();
			}
		},

		isLiveChatEnabled(newValue) {
			if (newValue) {
				return;
			}

			liveChat.shutDown();
		}
	},

	computed: {
		...userHelpers.mapGetters([
			'isUserFetched',
			'isLiveChatEnabled',
			'isInternalUser'
		]),

		isFooterVisible() {
			return this.activeRoute?.name !== 'register';
		}
	},

	/**
	 * Created lifecycle hook.
	 */
	created() {
		this.$store.dispatch(APP_CREATED);
	},

	/**
	 * Mounted lifecycle hook.
	 */
	mounted() {
		this.$store.dispatch(APP_MOUNTED);
	},

	methods: {
		...userHelpers.mapActions([
			ANALYTICS_IDENTIFY
		])
	}
};

export default App;

