//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_ACTION = 'action';

export default {
	props: {
		actions: {
			type: Array,
			required: true,
			validator: value => {
				let isValid = true;

				value.forEach(item => {
					if (isValid) {
						isValid = item.id && item.title;
					}
				});

				return isValid;
			}
		},
		target: {
			type: String,
			required: true
		}
	},

	methods: {
		menuClick(action, id) {
			this.$emit(EVENT_ACTION, action, id);
		}
	}
};

