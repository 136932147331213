import { BaseException } from 'utils/error';

/**
 * @class
 */
class InvoicesException extends BaseException {
	/**
	 * @param {{errors: Object[]}} result
	 */
	constructor(result) {
		super(result);
		this.name = 'InvoicesException';
	}
}

export {
	InvoicesException
};
