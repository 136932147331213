import { MODE } from '../types';

export const RECIPIENTS_TYPES = {
	ALL: 'All',
	TAGS: 'Tags',
	CONTACT_TYPE: 'Contact type',
	LEAD_STATUS: 'Lead status'
};

const getInitialState = () => ({
	mode: MODE.EDIT,
	status: {
		message: '',
		type: ''
	},
	options: {
		filters: [],
		lists: [],
		personFields: [],
		orgFields: []
	},
	selected: {
		filter: null,
		list: null,
		personFields: [],
		orgFields: []
	},
	automation: {
		filter: undefined,
		filterData: {
			id: 0,
			name: ''
		}
	},
	recipientsType: RECIPIENTS_TYPES.TAGS,
	generalErrors: [],
	fieldErrors: {
		list: null
	},
	visibleFields: []
});

export default getInitialState;
