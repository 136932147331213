import {
	MESSAGE,
	VISIBILITY,
	RESET
} from './mutationTypes';
import getInitialState from './state';

export default {
	[MESSAGE](state, message) {
		state.message = message;
	},

	[VISIBILITY](state, isVisible) {
		state.isVisible = isVisible;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	}
};
