/** Remove billing errors. */
export const BILLING_ERRORS_CLEAR = 'BILLING_ERRORS_CLEAR';

/** Close Subscription Dialog. */
export const DOWNGRADE_DIALOG_CLOSE = 'DOWNGRADE_DIALOG_CLOSE';

/** Open Subscription Dialog. */
export const UPGRADE_DIALOG_OPEN = 'UPGRADE_DIALOG_OPEN';

/** Close Subscription Dialog. */
export const UPGRADE_DIALOG_CLOSE = 'UPGRADE_DIALOG_CLOSE';

/** Open Subscription Dialog. */
export const DOWNGRADE_DIALOG_OPEN = 'DOWNGRADE_DIALOG_OPEN';

/** Get available subscription plans. */
export const PLANS_GET = 'PLANS_GET';

/** Get info about subscription. */
export const SUBSCRIPTION_GET = 'SUBSCRIPTION_GET';

/** Get list of invoices. */
export const INVOICES_GET = 'INVOICES_GET';

/** Select another Plan. */
export const PLAN_SELECT = 'PLAN_SELECT';

/** Cancel active subscription. */
export const SUBSCRIPTION_CANCEL = 'SUBSCRIPTION_CANCEL';

/** Add or change payment method. */
export const PAYMENT_METHOD_EDIT = 'PAYMENT_METHOD_EDIT';

export const PLAN_PREVIEW = 'PLAN_PREVIEW';

export const PLAN_DOWNGRADE = 'PLAN_DOWNGRADE';

export const PLAN_UPGRADE = 'PLAN_UPGRADE';

/** Find and set the selected downgrade plan. */
export const PLAN_TO_DOWNGRADE = 'PLAN_TO_DOWNGRADE';

/** Find and set the selected upgrade plan. */
export const PLAN_TO_UPGRADE = 'PLAN_TO_UPGRADE';

export const CONNECTIONS_GET = 'CONNECTIONS_GET';
