import ROUTE_NAMES from 'router/route-names';
import router from 'router';
import {
	APP_CONNECT
} from './actionTypes';

export const actions = {
	[APP_CONNECT]: () => {
		router.push({ name: ROUTE_NAMES.ACCOUNT_INTEGRATIONS });
	}
};
