import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { EngagementSyncRuleOptionsException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.token
 * @param {String} params.connectionId
 * @param {Boolean} params.setNoCacheHeader
 * @return {Promise<Object>}
 */
export const httpGet = async ({ token, connectionId, setNoCacheHeader }) => {
	const url = endpoints.connectionsIdEngagementSyncRuleOptions(connectionId);
	const response = await request.get({ url, token, setNoCacheHeader });

	if (!response.success) {
		return Promise.reject(new EngagementSyncRuleOptionsException(response));
	}

	return response.data;
};
