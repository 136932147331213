import { getConfValue } from 'utils/appconfig';
import { Providers } from './constants';

// https://dev.wix.com/api/rest/getting-started/authentication#getting-started_authentication_step-2-app-sends-users-to-authorize-the-app

/**
 * @param {String} token
 * @return {String}
 */
export const getWixOauthDialogUrl = token => {
	const {
		installUrl,
		clientId,
		redirectUrl
	} = getConfValue(Providers.WIX);

	const oauthDialogUrl = new URL(installUrl);

	oauthDialogUrl.searchParams.set('appId', clientId);
	oauthDialogUrl.searchParams.set('redirectUrl', redirectUrl);
	oauthDialogUrl.searchParams.set('state', 'app');

	if (token) {
		oauthDialogUrl.searchParams.set('token', token);
	}

	return oauthDialogUrl.href;
};
