import getInitialState from './state';

import {
	SET_MODE,
	SET_STATUS,
	SET_OPTIONS,
	SET_AUTOMATION,
	SET_OPTION,
	SET_LIST,
	SET_PIPELINE,
	SET_UNSUBSCRIBE_FIELD,
	RESET_STATE,
	SET_UNSUBSCRIBE_FIELD_OPTION
} from './mutationTypes';

export default {
	[SET_MODE](state, mode) {
		state.mode = mode;
	},

	[SET_STATUS](state, status) {
		state.status = status;
	},

	[SET_OPTIONS](state, options) {
		state.options = options;
	},

	[SET_AUTOMATION](state, automation) {
		state.automation = automation;
	},

	[SET_OPTION](state, option) {
		state.automation = { ...state.automation, ...option };
	},

	[SET_LIST](state, listId) {
		state.selected.list = listId;
	},

	[SET_PIPELINE](state, pipelineId) {
		state.selected.pipelineId = pipelineId;
	},

	[SET_UNSUBSCRIBE_FIELD](state, fieldId) {
		state.selected.unsubscribeFieldId = fieldId;
	},

	[SET_UNSUBSCRIBE_FIELD_OPTION](state, optionId) {
		state.selected.unsubscribeFieldOptionId = optionId;
	},

	[RESET_STATE](state) {
		Object.assign(state, getInitialState());
	}
};
