//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_CLOSE = 'close';

export default {
	props: {
		formId: {
			type: String,
			required: true
		},
		prefillFields: {
			type: Object,
			default: () => ({})
		}
	},

	computed: {
		prefillParams() {
			return String(new URLSearchParams(this.prefillFields));
		}
	},

	methods: {
		onClose() {
			this.$emit(EVENT_CLOSE);
		}
	}
};

