import { isEmail } from 'validator';
import { api } from 'api';

import SEND_PASSWORDLESS_EMAIL from './actionTypes';

import {
	SET_SENT,
	SET_ERRORS
} from './mutationTypes';

import logger from '../../../utils/logger';

export default {
	[SEND_PASSWORDLESS_EMAIL]: async ({ commit, state }) => {
		if (!isEmail(state.fields.email.value)) {
			return commit(SET_ERRORS, [{ message: 'Enter valid email address', context: 'email' }]);
		}

		try {
			await api.auth.passwordlessEmail.post(state.fields.email.value);

			commit(SET_SENT);
		} catch (exception) {
			if (exception.errors) {
				return commit(SET_ERRORS, exception.errors);
			}

			commit(SET_ERRORS, [{ message: 'Unable to send out Passwordless login email. Try again later.' }]);
			logger.error(exception);
		}
	}
};
