const getInitialState = () => ({
	persons: null,
	segments: [],
	pagination: {
		currentPage: 0
	},
	segmentId: null,
	isDialogContactsImportVisible: false,
	isLoading: false,
	isOptInConfirmed: false,
	isOptInError: false,
	selectedFile: {},
	segmentName: '',
	delimiter: ','
});

export default getInitialState;
