import { getFormattedPercentage } from 'utils/formatting';

export default {
	campaignOpenedPercentage() {
		return campaign => getFormattedPercentage(campaign.opened, campaign.queued);
	},

	campaignClickedPercentage() {
		return campaign => getFormattedPercentage(campaign.clicked, campaign.queued);
	},

	campaignRepliedPercentage() {
		return campaign => getFormattedPercentage(campaign.replied, campaign.queued);
	}
};
