export const DOWNGRADE_DIALOG_VISIBILITY = 'DOWNGRADE_DIALOG_VISIBILITY';
export const UPGRADE_DIALOG_VISIBILITY = 'UPGRADE_DIALOG_VISIBILITY';
export const INVOICES = 'INVOICES';
export const PLANS = 'PLANS';
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const BILLING_ERRORS = 'BILLING_ERRORS';
export const PLAN_PREVIEWED = 'PLAN_PREVIEWED';
export const SELECTED_PLAN_TO_DOWNGRADE = 'SELECTED_PLAN_TO_DOWNGRADE';
export const SELECTED_PLAN_TO_UPGRADE = 'SELECTED_PLAN_TO_UPGRADE';
export const CONNECTIONS = 'CONNECTIONS';
export const ERRORS = 'ERRORS';
