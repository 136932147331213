import {
	SET_IN_PROGRESS,
	SET_ERROR,
	SET_SUCCESS,
	SET_EMAIL,
	SET_TOKEN
} from './mutationTypes';

export default {
	[SET_IN_PROGRESS](state, value) {
		state.error = null;
		state.success = false;
		state.inProgress = value;
	},
	[SET_ERROR](state, value) {
		state.success = false;
		state.inProgress = false;
		state.error = value;
	},
	[SET_SUCCESS](state, value) {
		state.inProgress = false;
		state.error = null;
		state.success = value;
	},
	[SET_EMAIL](state, value) {
		state.email = value;
	},
	[SET_TOKEN](state, value) {
		state.token = value;
	}
};
