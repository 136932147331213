import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { MappingsException } from './helpers';

/**
 *
 * @param {String} token
 * @param {String} listId
 * @param {Object} mapping
 * @return {Promise<Object>}
 */
const httpPost = async (token, listId, mapping) => {
	const result = await request.post(endpoints.userListsIdMappings(listId), mapping, token);

	if (!result.success) {
		return Promise.reject(new MappingsException(result));
	}

	return result.data;
};

export {
	httpPost
};
