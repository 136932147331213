import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { ConnectionsException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.token
 * @param {String} params.connectionId
 * @return {Promise<Object>}
 */
export const httpDelete = async ({ token, connectionId }) => {
	const response = await request.delete(endpoints.connectionsId(connectionId), token);

	if (!response.success) {
		return Promise.reject(new ConnectionsException(response));
	}

	return true;
};
