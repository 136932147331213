import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { AutomationsException } from './helpers';

/**
 * Create new automation.
 * @param {String} token - JWT Authorization token.
 * @param {Object} data - Automation data.
 * @return {Object} Automation - returns created automation.
 */
async function httpPost(token, data) {
	const result = await request.post(endpoints.automations, data, token);

	if (!result.success) {
		return Promise.reject(new AutomationsException(result));
	}

	return result.data;
}

export {
	httpPost
};
