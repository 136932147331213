//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import EmailContentWrapper from './EmailContentWrapper.vue';

export default {
	components: {
		EmailContentWrapper
	},
	props: {
		fromName: {
			type: String,
			default: ''
		},
		fromAddress: {
			type: String,
			default: ''
		},
		recipientName: {
			type: String,
			default: ''
		},
		subject: {
			type: String,
			default: ''
		},
		preHeader: {
			type: String,
			default: ''
		},
		html: {
			type: String,
			default: ''
		},
		isCustomHtml: {
			type: Boolean,
			default: false
		}
	}
};

