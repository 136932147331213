const getInitialState = () => ({
	options: {},
	sequences: [],
	pagination: {
		currentPage: 0
	},
	generalErrors: []
});

export default getInitialState;
