import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { PasswordlessVerifyException } from './helpers';

/**
 * Log in using passwordless token.
 * @param {String} email
 * @param {String} token
 */
async function httpPost(email, token) {
	const result = await request.post(endpoints.auth.passwordlessVerify, { email, token });

	if (!result.success) {
		return Promise.reject(new PasswordlessVerifyException(result));
	}

	return result.data;
}

export {
	httpPost
};
