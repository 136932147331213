import { isUndefined } from 'utils/types';
import { getInitialState } from './state';

import {
	MODE,
	OPTIONS,
	NAME,
	SEGMENT,
	CONDITION_SET,
	CONDITION,
	GENERAL_ERRORS,
	RESET,
	SEGMENT_SAVE_LOADING
} from './mutationTypes';

export const mutations = {
	[MODE](state, mode) {
		state.mode = mode;
	},

	[OPTIONS](state, options) {
		state.options = options;
	},

	[NAME](state, name) {
		state.segment.name = name;
	},

	[SEGMENT](state, segment) {
		state.segment = segment;
	},

	[CONDITION_SET](state, conditionSet) {
		state.segment.conditionSets[0].glue = conditionSet.glue;
	},

	[CONDITION](
		state,
		{
			conditionSetIndex,
			conditionIndex,
			condition
		}
	) {
		if (isUndefined(state.segment.conditionSets)) {
			state.segment.conditionSets = [];
		}

		if (state.segment.conditionSets.length === 0) {
			state.segment.conditionSets.push({
				conditions: []
			});
		}

		if (isUndefined(conditionIndex)) {
			return state.segment.conditionSets[conditionSetIndex].conditions.push(condition);
		}

		if (!condition) {
			return state.segment.conditionSets[conditionSetIndex].conditions.splice(conditionIndex, 1);
		}

		state.segment.conditionSets[conditionSetIndex].conditions[conditionIndex] = condition;
	},

	[GENERAL_ERRORS](state, errors) {
		state.errors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	},

	[SEGMENT_SAVE_LOADING](state, isLoading) {
		state.isSegmentSaveLoading = isLoading;
	}
};
