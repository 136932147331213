import { request } from 'utils/request';
import { getAutomationUrl, AutomationsException } from './helpers';

/**
 * Delete an Automation.
 * @param {String} token - JWT Authorization token.
 * @param {String} automationId - ID of the Automation to delete.
 */
async function httpDelete(token, automationId) {
	const url = getAutomationUrl(automationId);
	const result = await request.delete(url, token);

	if (!result.success) {
		return Promise.reject(new AutomationsException(result));
	}

	return result.data;
}

export {
	httpDelete
};
