import {
	FormEngagementSources,
	EmailEngagementSources,
	MeetingEngagementSources,
	AdSources,
	EngagementSourcesWithDestinationSegmentRestriction
} from 'utils/constants';
import {
	isSyncInProgress,
	getSyncProgress
} from './helpers';

export const getters = {
	getConnection({ connection }) {
		return connection;
	},

	connection({ connection }) {
		if (!connection) {
			return null;
		}

		return {
			...connection,
			isSyncInProgress: isSyncInProgress(connection),
			syncProgress: getSyncProgress(connection)
		};
	},

	areContactSyncRulesEnabled({ connection }) {
		return Boolean(connection.contactSyncRulesEnabled);
	},

	getEngagementEventSource({ connection }) {
		return connection?.engagementEventSource;
	},

	getContactSource({ connection }) {
		return connection?.contactSource;
	},

	isFormToolConnection({ connection }) {
		return Object
			.values(FormEngagementSources)
			.includes(connection?.engagementEventSource);
	},

	isMeetingAppConnection({ connection }) {
		return Object
			.values(MeetingEngagementSources)
			.includes(connection?.engagementEventSource);
	},

	isEmailToolConnection({ connection }) {
		return Boolean(Object.values(EmailEngagementSources)
			.includes(connection?.engagementEventSource));
	},

	isAdToolConnection({ connection }) {
		return Boolean(Object.values(AdSources)
			.includes(connection?.engagementEventSource));
	},

	/**
	 * @param {Object} state
	 * @param {{period?: Object}} state.connectionStatusOptions
	 * @return {{value: Number, text: String}[]}
	 */
	periodOptions({ connectionStatusOptions }) {
		if (!connectionStatusOptions || !connectionStatusOptions.period) {
			return [];
		}

		return connectionStatusOptions.period.options;
	},

	/**
	 * @param {Object} state
	 * @param {{period?: Number}} state.period
	 * @return {Number | null}
	 */
	periodValue({ period }) {
		return period;
	},

	/**
	 * @param {Object} state
	 * @param {{connection?: Object}} state.connection
	 * @return {Number | null}
	 */
	isDestinationSegmentRestrictionEnabled({ connection }) {
		return EngagementSourcesWithDestinationSegmentRestriction
			.some(engagementSource => engagementSource === connection.engagementEventSource);
	},

	/**
	 * @param {{{connection?: Object}}} engagementSyncRuleOptions
	 * @param {{{isAdToolConnection?: Boolean}}} isAdToolConnection
	 * @return {String}
	 */
	engagementEntity({ engagementSyncRuleOptions }, { isAdToolConnection }) {
		if (isAdToolConnection) {
			return 'audience';
		}

		const options = engagementSyncRuleOptions?.eventEntity?.options;
		const [engagementEntity] = Array.isArray(options) ? options : [];

		return engagementEntity ?? '';
	},

	contactSyncRules({ contactSyncRules = [], connection }) {
		return contactSyncRules.filter(
			rule => rule.source !== connection.engagementEventSource
		);
	},

	reversedContactSyncRule({ contactSyncRules, connection }) {
		if (!contactSyncRules.length) {
			return null;
		}

		const reversedContactSyncRule = contactSyncRules.find(
			rule => rule.source === connection.engagementEventSource
		);

		return reversedContactSyncRule ?? null;
	}
};
