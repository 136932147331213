/**
 * @param {Object} error
 * @return {Object}
 */
const getOriginalError = error => {
	let errorObject = error;
	const errorDetails = error && error.details;
	const originalError = errorDetails && errorDetails.originalError;

	if (originalError) {
		errorObject = getOriginalError(originalError);
	}

	return errorObject;
};

/**
 * @param {Object} error
 * @return {String}
 */
const getErrorMessage = error => {
	const errorObject = error.error || error;
	let message = 'Error processing card details. Please try again';

	if (errorObject.message) {
		({ message } = errorObject);
	} else if (errorObject.code) {
		message = `Error processing card details (code ${errorObject.code}). Please try again`;
	}

	return message;
};

/**
 * Normalize nested Braintree error.
 * @param {Object} error
 * @return {Object}
 */
export default function normalizeBraintreeError(error) {
	const originalError = getOriginalError(error);

	return {
		context: 'braintree',
		error: error.name,
		message: getErrorMessage(originalError)
	};
}
