import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { PasswordlessEmailException } from './helpers';

/**
 * Start passwordless login flow by sending out Passwordless email to user.
 * @param {String} email - Email address of user who wants to log in with magic link.
 * @return {Promise<Object>}
 */
async function httpPost(email) {
	const result = await request.post(endpoints.auth.passwordlessEmail, { email });

	if (!result.success) {
		return Promise.reject(new PasswordlessEmailException(result));
	}

	return result;
}

export {
	httpPost
};
