import router from 'router';
import { api } from 'api';
import { Flags } from 'store/modules/user/helpers';
import { GET_USER } from 'store/modules/user/actionTypes';
import logger from 'utils/logger';

import ROUTE_NAMES from 'router/route-names';

import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import {
	COUNTERS_GET,
	LIST_NAVIGATE,
	OPTIONS_GET,
	SETTINGS_GET,
	FIELDS_EDIT,
	WEBTRACKING_INFOBOX_TOGGLE
} from './actionTypes';
import {
	COUNTERS,
	OPTIONS,
	SETTINGS,
	FIELDS,
	ERRORS
} from './mutationTypes';

export default {
	[COUNTERS_GET]: async ({ rootState: { token }, commit }) => {
		try {
			const counters = await api.tracking.counters.get(token);

			commit(COUNTERS, counters);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},
	[LIST_NAVIGATE]() {
		router.push({
			name: ROUTE_NAMES.AUTOMATIONS
		});
	},

	[OPTIONS_GET]: async ({ commit, rootState: { token } }) => {
		const options = await api.user.settings.webTracking.options.get(token);

		commit(OPTIONS, options);
	},

	[SETTINGS_GET]: async ({ commit, rootState: { token } }) => {
		const settings = await api.user.settings.webTracking.get(token);

		commit(SETTINGS, settings);
	},

	async [FIELDS_EDIT]({
		commit,
		dispatch,
		rootState: { token }
	}, fields) {
		commit(FIELDS, fields);

		try {
			commit(ERRORS, []);
			const updatedSettings = await api.user.settings.webTracking.put(
				token, fields
			);

			commit(SETTINGS, updatedSettings);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Settings saved',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[WEBTRACKING_INFOBOX_TOGGLE]: async ({
		rootState: { token, user },
		dispatch,
		commit
	}) => {
		try {
			if (user.profile.flags.includes(Flags.WEB_TRACKING_MSG)) {
				await api.user.flags.delete(token, Flags.WEB_TRACKING_MSG);
			} else {
				await api.user.flags.post(token, Flags.WEB_TRACKING_MSG);
			}

			dispatch(`user/${GET_USER}`, null, { root: true });
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
