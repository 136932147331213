import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { SequenceEmailCampaignsException } from './helpers';

/**
 * Delete Sequence Email Campaign.
 * @param {String} token - User's JWT authorization token.
 * @param {String} id - ID of th Sequence.
 */
async function httpDelete(token, id) {
	const result = await request.delete(endpoints.sequenceEmailCampaignsId(id), token);

	if (!result.success) {
		return Promise.reject(new SequenceEmailCampaignsException(result));
	}

	return true;
}

export {
	httpDelete
};
