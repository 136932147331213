import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { LeadScoringSettingsException } from './helpers';

/**
 * Delete lead scoring settings.
 * @param {String} token - JWT Authorization token.
 * @return {Promise<Object>}
 */
async function httpDelete(token) {
	const result = await request.delete(endpoints.userSettings.leadScoring, token);

	if (!result.success) {
		return Promise.reject(new LeadScoringSettingsException(result));
	}

	return result.data;
}

export {
	httpDelete
};
