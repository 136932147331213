import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { LeadscoreException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.token
 * @param {String} params.personId
 * @param {Object} params.data
 * @return {Promise<Object>}
 */
async function httpPut({ token, personId, data }) {
	const url = new URL(endpoints.personsLeadScore(personId));

	const response = await request.put(url, data, token);

	if (!response.success) {
		return Promise.reject(new LeadscoreException(response));
	}

	return { data: response.data };
}

export {
	httpPut
};
