import moment from 'moment';
import { IntlFormatOptions } from 'utils/constants';

/**
 * @param {String} currencyCode
 * @return {String}
 */
export function getCurrencySymbol(currencyCode) {
	let currencySymbol = '€';

	if (currencyCode.toLowerCase() === 'usd') {
		currencySymbol = '$';
	}

	return currencySymbol;
}

/**
 * @param {Number} fraction
 * @param {Number} total
 * @return {String}
 */
export function getFormattedPercentage(fraction, total) {
	let formattedPercentage = 0;

	if (total > 0) {
		// eslint-disable-next-line no-magic-numbers
		const percentage = Number((fraction / total) * 100);
		const decimalDigits = Math.ceil(percentage % 1);

		formattedPercentage = percentage.toFixed(decimalDigits);
	}

	return formattedPercentage;
}

/**
 * @param {Number} fraction
 * @param {String} locale
 * @return {String}
 */
export function getLocaleFormattedPercentage(fraction, locale) {
	const option = {
		style: 'percent',
		minimumFractionDigits: 0,
		maximumFractionDigits: 1
	};

	const localeIdentifier = locale.replace('_', '-');
	const formatter = new Intl.NumberFormat(localeIdentifier, option);

	return formatter.format(fraction);
}

/**
 * Converts timestamp to the specified timezone in the specified locale.
 * @param {Date|String} date - Date to localize.
 * @param {String} locale - Specified locale.
 * @param {String=} format - Specified output format.
 * @return {String}
 */
export function getLocaleFormattedDate(date, locale, format = {
	day: IntlFormatOptions.TWO_DIGIT,
	month: IntlFormatOptions.TWO_DIGIT,
	year: IntlFormatOptions.TWO_DIGIT
}) {
	if (!date) {
		return '';
	}

	const momentDate = moment(date);

	if (!momentDate.isValid()) {
		return '';
	}

	const languageTag = locale.replace('_', '-');

	const formatter = new Intl.DateTimeFormat(languageTag, format);

	return formatter.format(momentDate.toDate());
}

/**
 * Converts amount number to specified locale currency aount.
 * @param {Number} amount - Amount to localize.
 * @param {String=} currency - Specified currency.
 * @param {String=} locale - Specified user locale.
 * @return {String}
 */
export function getLocaleFormattedAmount(amount, currency = 'USD', locale = 'en-US') {
	if (Number.isNaN(amount)) {
		return '';
	}

	const languageTag = locale.replace('_', '-');

	const formatter = new Intl.NumberFormat(languageTag, { style: 'currency', currency });

	return formatter.format(amount);
}

/**
 * Checks if value is valid date string.
 * @param {*} value Value that will be evaluated.
 * @return {Boolean} Returns if value is valid date string or not.
 */
export function isValidDateString(value) {
	return !Number.isNaN(Date.parse(value));
}

/**
 * Capitalizes string.
 * @param {String} value Value that will be capitalized.
 * @return {String}
 */
export function capitalize(value) {
	if (typeof value !== 'string') {
		return '';
	}

	return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}
