import { BaseException } from 'utils/error';
import { endpoints } from 'api/endpoints';

/**
 * Exception subclass for /lead-scoring/options endpoint.
 */
class LeadScoringSettingsOptionsException extends BaseException {
	/**
	 * @param {{
	 * success: Boolean,
	 * status: Number,
	 * errors: Object[]
	 * }} result
	 */
	constructor(result) {
		super(result);
		this.name = 'LeadScoringSettingsOptionsException';
	}
}

/**
 * @param {String} source
 * @return {String}
 */
const getActivityTypesUrlWithSearchParams = source => {
	const url = new window.URL(endpoints.userSettings.leadScoringOptionsActivityTypes);
	const searchParams = new window.URLSearchParams({ source });

	url.search = searchParams.toString();

	return url.toString();
};

export {
	LeadScoringSettingsOptionsException,
	getActivityTypesUrlWithSearchParams
};
