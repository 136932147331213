import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { EmailsException } from './helpers';

/**
 * Add an email to a sequence.
 * @param {String} token - User's JWT authorization token.
 * @param {String} sequenceId - ID of the sequence.
 * @param {Oject} data - New email's fields.
 * @return {Promise<Object>}
 */
async function httpPost(token, sequenceId, data) {
	const url = endpoints.sequenceEmailCampaignsIdEmails(sequenceId);
	const result = await request.post(url, data, token);

	if (!result.success) {
		return Promise.reject(new EmailsException(result));
	}

	return result.data;
}

export {
	httpPost
};
