import {
	OPTIONS,
	CONNECTION,
	SOURCE_SEGMENT,
	DESTINATION_SEGMENT,
	ENGAGEMENT_EVENT_SEGMENT,
	CONTACT_SYNC_RULES,
	CONTACT_CREATION_RULES,
	CONTACT_CREATION_RULE_OPTIONS,
	FIELD_MAPPINGS_OPTIONS,
	SEGMENT_FIELD_MAPPINGS_OPTIONS,
	FIELD_MAPPINGS,
	REVERSED_FIELD_MAPPINGS,
	UNIFIED_FIELD_MAPPINGS,
	ENGAGEMENT_SYNC_RULES,
	ENGAGEMENT_SYNC_RULE_OPTIONS,
	ERRORS,
	RESET,
	VIEW,
	STATS,
	FEED,
	FEED_PAGE,
	FEED_PAGE_COUNT,
	CONNECTION_STATUS_OPTIONS,
	CONNECTION_STATUS_PERIOD,
	ENGAGEMENT_SYNC_UPGRADE_DIALOG_VISIBILITY,
	MAP_REQUIRED_FIELDS_DIALOG_VISIBILITY,
	DIALOG_SYNC_RECENT_ENGAGEMENTS_VISIBILITY,
	SET_REFRESH_FIELDS_IN_PROGRESS
} from './mutationTypes';

import { getInitialState } from './state';

export const mutations = {
	[OPTIONS](state, options) {
		state.options = options;
	},

	[CONNECTION](state, connection) {
		state.connection = connection;
	},

	[SOURCE_SEGMENT](state, sourceSegment) {
		state.sourceSegment = sourceSegment;
	},

	[DESTINATION_SEGMENT](state, destinationSegment) {
		state.destinationSegment = destinationSegment;
	},

	[ENGAGEMENT_EVENT_SEGMENT](state, engagementEventSegment) {
		state.engagementEventSegment = engagementEventSegment;
	},

	[CONTACT_SYNC_RULES](state, contactSyncRules) {
		state.contactSyncRules = contactSyncRules;
	},

	[CONTACT_CREATION_RULES](state, contactCreationRules) {
		state.contactCreationRules = contactCreationRules;
	},

	[CONTACT_CREATION_RULE_OPTIONS](state, contactCreationRuleOptions) {
		state.contactCreationRuleOptions = contactCreationRuleOptions;
	},

	[FIELD_MAPPINGS_OPTIONS](state, fieldMappingsOptions) {
		state.fieldMappingsOptions = fieldMappingsOptions;
	},

	[SEGMENT_FIELD_MAPPINGS_OPTIONS](state, segmentFieldMappingsOptions) {
		state.segmentFieldMappingsOptions = segmentFieldMappingsOptions;
	},

	[FIELD_MAPPINGS](state, fieldMappings) {
		state.fieldMappings = fieldMappings;
	},

	[REVERSED_FIELD_MAPPINGS](state, reversedFieldMappings) {
		state.reversedFieldMappings = reversedFieldMappings;
	},

	[UNIFIED_FIELD_MAPPINGS](state, unifiedFieldMappings) {
		state.unifiedFieldMappings = unifiedFieldMappings;
	},

	[ENGAGEMENT_SYNC_RULES](state, engagementSyncRules) {
		state.engagementSyncRules = engagementSyncRules;
	},

	[ENGAGEMENT_SYNC_RULE_OPTIONS](state, engagementSyncRules) {
		state.engagementSyncRuleOptions = engagementSyncRules;
	},

	[ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	},

	[VIEW](state, { view }) {
		state.view = view;
	},

	[STATS](state, stats) {
		state.stats = stats;
	},

	[FEED](state, feed) {
		state.feed = feed;
	},

	[FEED_PAGE](state, page) {
		state.feedPagination.currentPage = page;
	},

	[FEED_PAGE_COUNT](state, pageCount) {
		state.feedPagination.pageCount = pageCount;
	},

	[CONNECTION_STATUS_OPTIONS](state, options) {
		state.connectionStatusOptions = options;
	},

	[CONNECTION_STATUS_PERIOD](state, period) {
		state.period = period;
	},

	[ENGAGEMENT_SYNC_UPGRADE_DIALOG_VISIBILITY](state, isVisisble) {
		state.isEngagementSyncUpgradeDialogOpen = isVisisble;
	},

	[MAP_REQUIRED_FIELDS_DIALOG_VISIBILITY](state, isVisisble) {
		state.isMapRequiredFieldsDialogOpen = isVisisble;
	},

	[DIALOG_SYNC_RECENT_ENGAGEMENTS_VISIBILITY](state, isVisible) {
		state.isDialogRecentEngagementsOpen = isVisible;
	},

	[SET_REFRESH_FIELDS_IN_PROGRESS](state, isRefreshFieldsInProgress) {
		state.isRefreshFieldsInProgress = isRefreshFieldsInProgress;
	}
};
