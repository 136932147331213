import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { ConnectionStatusOptionsException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {String} params.token
 * @param {Boolean=} params.setNoCacheHeader
 * @return {Promise<Object>}
 */
export const httpGet = async ({
	connectionId,
	token,
	setNoCacheHeader
}) => {
	const url = new URL(endpoints.connectionsIdConnectionStatusOptions(connectionId));

	const response = await request.get({ url: url.href, token, setNoCacheHeader });

	if (!response.success) {
		return Promise.reject(new ConnectionStatusOptionsException(response));
	}

	return response.data;
};
