export const LIST_NAVIGATE = 'LIST_NAVIGATE';
export const CONNECTION_CONTACTS_GET = 'CONNECTION_CONTACTS_GET';
export const CONNECTION_GET = 'CONNECTION_GET';
export const CONTACT_SYNC_RULES_GET = 'CONTACT_SYNC_RULES_GET';
export const CONNECTION_REQUEST_SYNC = 'CONNECTION_REQUEST_SYNC';
export const CONNECTION_STATUS_GET = 'CONNECTION_STATUS_GET';
export const CURRENT_PAGE_SET = 'CURRENT_PAGE_SET';
export const SORTING_CHANGE = 'SORTING_CHANGE';
export const SEARCH = 'SEARCH';
export const PAGE_SELECT = 'PAGE_SELECT';
export const MOUNTED = 'MOUNTED';
export const DESTROYED = 'DESTROYED';
export const NAVIGATE_EDIT = 'NAVIGATE_EDIT';
export const OPTIONS_GET = 'OPTIONS_GET';
