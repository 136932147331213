import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { SingleEmailCampaignsException } from './helpers';

/**
 * Update single email campaign.
 * @param {String} token - User's JWT authorization token.
 * @param {String} campaignId - ID of the campaign to be changed.
 * @param {Object} campaign - Updated fields for the campaign.
 */
async function httpPut(token, campaignId, campaign) {
	const url = endpoints.singleEmailCampaignsId(campaignId);
	const result = await request.put(url, campaign, token);

	if (!result.success) {
		return Promise.reject(new SingleEmailCampaignsException(result));
	}

	return result.data;
}

export {
	httpPut
};
