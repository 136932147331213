import { isUndefined } from 'utils/types';

const SettingsKeys = {
	SENT_ACTIVITY: 'sentActivity',
	SENT_TITLE_FIELD_UPDATE: 'sentTitleFieldUpdate',
	SENT_DATE_FIELD_UPDATE: 'sentDateFieldUpdate',
	SENT_NOTE: 'sentNote',
	OPEN_ACTIVITY: 'openActivity',
	OPEN_NOTE: 'openNote',
	CLICK_ACTIVITY: 'clickActivity',
	CLICK_NOTE: 'clickNote',
	REPLY_ACTIVITY: 'replyActivity',
	UNSUBSCRIBE_ACTIVITY: 'unsubscribeActivity',
	UNSUBSCRIBE_NOTE: 'unsubscribeNote',
	BOUNCE_ACTIVITY: 'bounceActivity',
	BOUNCE_NOTE: 'bounceNote',
	UNSUBSCRIBE_FIELD: 'unsubscribeField'
};

const getValue = (options, settings, key) => {
	if (!isUndefined(settings[key])) {
		return settings[key];
	}

	if (options[key] && !isUndefined(options[key].default)) {
		return options[key].default;
	}

	return false;
};

export default {
	sentActivityValue({ options, settings }) {
		return getValue(options, settings, SettingsKeys.SENT_ACTIVITY);
	},
	sentTitleFieldUpdateValue({ options, settings }) {
		return getValue(options, settings, SettingsKeys.SENT_TITLE_FIELD_UPDATE);
	},
	sentDateFieldUpdateValue({ options, settings }) {
		return getValue(options, settings, SettingsKeys.SENT_DATE_FIELD_UPDATE);
	},
	sentNoteValue({ options, settings }) {
		return getValue(options, settings, SettingsKeys.SENT_NOTE);
	},
	openActivityValue({ options, settings }) {
		return getValue(options, settings, SettingsKeys.OPEN_ACTIVITY);
	},
	openNoteValue({ options, settings }) {
		return getValue(options, settings, SettingsKeys.OPEN_NOTE);
	},
	clickActivityValue({ options, settings }) {
		return getValue(options, settings, SettingsKeys.CLICK_ACTIVITY);
	},
	replyActivityValue({ options, settings }) {
		return getValue(options, settings, SettingsKeys.REPLY_ACTIVITY);
	},
	clickNoteValue({ options, settings }) {
		return getValue(options, settings, SettingsKeys.CLICK_NOTE);
	},
	unsubscribeActivityValue({ options, settings }) {
		return getValue(options, settings, SettingsKeys.UNSUBSCRIBE_ACTIVITY);
	},
	unsubscribeNoteValue({ options, settings }) {
		return getValue(options, settings, SettingsKeys.UNSUBSCRIBE_NOTE);
	},
	unsubscribeFieldValue({ options, settings }) {
		return getValue(options, settings, SettingsKeys.UNSUBSCRIBE_FIELD);
	},
	bounceActivityValue({ options, settings }) {
		return getValue(options, settings, SettingsKeys.BOUNCE_ACTIVITY);
	},
	bounceNoteValue({ options, settings }) {
		return getValue(options, settings, SettingsKeys.BOUNCE_NOTE);
	},
	unsubscribeFieldOptions({ options, unsubscribeFieldTarget }) {
		const { options: unsubscribeFieldTargetOptions } = options.unsubscribeFieldTarget;

		if (!unsubscribeFieldTargetOptions) {
			return [];
		}

		const unsubscribeField = unsubscribeFieldTargetOptions
			.find(field => (field.key === unsubscribeFieldTarget.unsubscribeFieldId));

		if (!unsubscribeField) {
			return [];
		}

		return unsubscribeField.options || [];
	}
};
