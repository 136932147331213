import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { DuplicateException } from './helpers';

/**
 * Create a duplicate from an existing campaign.
 * @param {String} token - User's JWT authorization token.
 * @param {String} campaignId - ID of the campaign.
 */
async function httpPost(token, campaignId) {
	const url = endpoints.singleEmailCampaignsIdDuplicate(campaignId);
	const result = await request.post(url, null, token);

	if (!result.success) {
		return Promise.reject(new DuplicateException(result));
	}

	return result.data;
}

export {
	httpPost
};
