//
//
//
//
//
//
//
//
//
//
//


import BaseStepper from 'components/BaseStepper.vue';
import { STEPS } from 'store/modules/campaigns/singleEmail/edit/state';

const EVENT_NAVIGATE = 'navigate';
const EVENT_SEND = 'send';

export default {
	components: {
		BaseStepper
	},

	props: {
		currentStep: {
			type: String,
			required: true
		},

		isNextStepDisabled: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		steps: [
			{
				id: STEPS.BASICS,
				label: 'Basics',
				step: 1
			},
			{
				id: STEPS.COMPOSE,
				label: 'Compose',
				step: 2
			},
			{
				id: STEPS.REVIEW,
				label: 'Review',
				step: 3
			}
		],

		actions: [
			{
				id: STEPS.BASICS,
				label: 'Compose',
				type: 'navigate',
				target: STEPS.COMPOSE
			},
			{
				id: STEPS.COMPOSE,
				label: 'Review and settings',
				type: 'navigate',
				target: STEPS.REVIEW
			},
			{
				id: STEPS.REVIEW,
				label: 'Send campaign',
				type: 'confirm'
			}
		]
	}),

	methods: {
		navigate(step) {
			this.$emit(EVENT_NAVIGATE, step);
		},

		send() {
			this.$emit(EVENT_SEND);
		}
	}
};

