//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * @constant {String}
 */
const EVENT_CLOSE = 'close';
/**
 * @constant {String}
 */
const EVENT_NAVIGATE_CONTINUE = 'navigate:continue';

/**
 * @constant {String}
 */
const PRICING_PAGE = 'https://outfunnel.com/pricing/';

export default {
	props: {
		isVisible: {
			type: Boolean,
			required: true
		},
		headline: {
			type: String,
			required: true,
			default: ''
		},
		callToAction: {
			type: String,
			required: false,
			default: ''
		},
		content: {
			type: String,
			required: true,
			default: ''
		},
		actionBtnText: {
			type: String,
			required: false,
			default: 'Continue'
		},
		learnMoreLink: {
			type: String,
			required: true,
			default: PRICING_PAGE
		},
		isGoBackBtnVisible: {
			type: Boolean,
			required: false,
			default: true
		}
	},

	computed: {
		buttonClass() {
			if (this.isGoBackBtnVisible) {
				return 'd-flex justify-space-between';
			}

			return 'd-flex justify-center';
		}
	},

	methods: {
		close() {
			this.$emit(EVENT_CLOSE);
		},
		navigateContinue() {
			this.$emit(EVENT_NAVIGATE_CONTINUE);
		}
	}
};

