
import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { AppsException } from './helpers';

/**
 * @param {String} token - JWT authorization token.
 * @return {Promise<Object>}
 */
export const httpGet = async token => {
	const response = await request.get(endpoints.apps, token);

	if (!response.success) {
		return Promise.reject(new AppsException(response));
	}

	return response.data;
};
