import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { TriggerException } from './helpers';

/**
 * Get the trigger of the specified Sequence email.
 * @param {String} token - User's JWT authorization.
 * @param {String} sequenceId - ID of the sequence.
 * @param {String} emailId - ID of the email.
 */
async function httpGet(token, sequenceId, emailId) {
	const url = endpoints.sequenceEmailCampaignsIdEmailsIdTrigger(sequenceId, emailId);
	const result = await request.get(url, token);

	if (!result.success) {
		return Promise.reject(new TriggerException(result));
	}

	return result.data;
}

export {
	httpGet
};
