import { getConfValue } from 'utils/appconfig';
import { Providers } from './constants';

/**
 * @return {String}
 */
const getFacebookOauthDialogUrl = () => {
	const {
		clientId,
		oauthUrl,
		redirectUrl,
		scope
	} = getConfValue(Providers.FACEBOOK);

	const oauthDialogUrl = new URL(oauthUrl);

	oauthDialogUrl.searchParams.set('client_id', clientId);
	oauthDialogUrl.searchParams.set('redirect_uri', redirectUrl);
	oauthDialogUrl.searchParams.set('scope', scope);
	oauthDialogUrl.searchParams.set('state', Providers.FACEBOOK);

	return oauthDialogUrl.href;
};

/**
 * @return {String}
 */
const getFacebookAdsOauthDialogUrl = () => {
	const {
		clientId,
		oauthUrl,
		redirectUrl,
		scope
	} = getConfValue(Providers.FACEBOOK_ADS);

	const oauthDialogUrl = new URL(oauthUrl);

	oauthDialogUrl.searchParams.set('client_id', clientId);
	oauthDialogUrl.searchParams.set('redirect_uri', redirectUrl);
	oauthDialogUrl.searchParams.set('scope', scope);
	oauthDialogUrl.searchParams.set('state', Providers.FACEBOOK_ADS);

	return oauthDialogUrl.href;
};

export {
	getFacebookOauthDialogUrl,
	getFacebookAdsOauthDialogUrl
};
