import { BaseException } from 'utils/error';

/**
 * Exception subclass for /visits endpoint.
 */
class VisitsException extends BaseException {
	/**
	 * @param {{
	 * success: Boolean,
	 * status: Number,
	 * errors: Object[]
	 * }} result
	 */
	constructor(result) {
		super(result);
		this.name = 'VisitsException';
	}
}

export {
	VisitsException
};
