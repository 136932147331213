import mutations from './mutations';
import actions from './actions';

const state = {
	inProgress: false,
	success: false,
	error: null
};

export default {
	namespaced: true,
	state,
	actions,
	mutations
};
