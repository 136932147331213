import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { ConnectionsException } from './helpers';

/**
 *
 * @param {Object} params
 * @param {String} params.token
 * @param {Object} params.connectionData
 * @return {Promise<Object>}
 */
export const httpPost = async ({ token, connectionData }) => {
	const response = await request.post(
		endpoints.connections,
		connectionData,
		token
	);

	if (!response.success) {
		return Promise.reject(new ConnectionsException(response));
	}

	return response.data;
};
