import utils from 'utils';
import logger from 'utils/logger';
import { api } from 'api';
import router from 'router';

import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';

import {
	USAGE_GET,
	FEATURES_GET
} from '../../user/actionTypes';

import {
	DOWNGRADE_DIALOG_VISIBILITY,
	UPGRADE_DIALOG_VISIBILITY,
	PLANS,
	INVOICES,
	SUBSCRIPTION,
	BILLING_ERRORS,
	PLAN_PREVIEWED,
	SELECTED_PLAN_TO_DOWNGRADE,
	SELECTED_PLAN_TO_UPGRADE,
	CONNECTIONS,
	ERRORS
} from './mutationTypes';

import {
	BILLING_ERRORS_CLEAR,
	DOWNGRADE_DIALOG_CLOSE,
	DOWNGRADE_DIALOG_OPEN,
	UPGRADE_DIALOG_CLOSE,
	UPGRADE_DIALOG_OPEN,
	INVOICES_GET,
	PLANS_GET,
	SUBSCRIPTION_GET,
	PLAN_SELECT,
	PLAN_PREVIEW,
	SUBSCRIPTION_CANCEL,
	PAYMENT_METHOD_EDIT,
	PLAN_DOWNGRADE,
	PLAN_UPGRADE,
	PLAN_TO_DOWNGRADE,
	PLAN_TO_UPGRADE,
	CONNECTIONS_GET
} from './actionTypes';

const getCancellationSurveyUrl = ({ userId, email }) => `https://tfm0syzz.paperform.co?9b94v=${userId}&1hktd=${email}`;

export default {

	/**
	 * Close campaign send confirmation dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	[DOWNGRADE_DIALOG_CLOSE]({ commit }) {
		commit(DOWNGRADE_DIALOG_VISIBILITY, false);
	},

	/**
	 * Close campaign send confirmation dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	[DOWNGRADE_DIALOG_OPEN]({ commit }) {
		commit(DOWNGRADE_DIALOG_VISIBILITY, true);
	},

	/**
	 * Close campaign send confirmation dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	[UPGRADE_DIALOG_CLOSE]({ commit }) {
		commit(UPGRADE_DIALOG_VISIBILITY, false);
	},

	/**
	 * Close campaign send confirmation dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	[UPGRADE_DIALOG_OPEN]({ commit }) {
		commit(UPGRADE_DIALOG_VISIBILITY, true);
	},

	[PLANS_GET]: async ({ commit, rootState: { token } }) => {
		const plans = await api.billing.plans.get(token);

		commit(PLANS, plans);
	},

	[INVOICES_GET]: async ({ commit, rootState: { token } }) => {
		const invoices = await api.billing.invoices.get(token);

		commit(INVOICES, invoices);
	},

	[SUBSCRIPTION_GET]: async ({ commit, rootState: { token } }) => {
		const subscription = await api.billing.subscription.get(token);

		commit(SUBSCRIPTION, subscription);
	},

	[PLAN_SELECT]: async ({ commit, dispatch, rootState: { token } }, planId) => {
		commit(BILLING_ERRORS, []);

		try {
			const updatedSubscription = await api.billing.subscription.put(
				token,
				{ plan_id: planId }
			);

			commit(SUBSCRIPTION, updatedSubscription);

			const plansPromise = await api.billing.plans.get(token);
			const usagePromise = dispatch(`user/${USAGE_GET}`, null, { root: true });
			const featuresPromise = dispatch(`user/${FEATURES_GET}`, null, { root: true });

			const plans = await plansPromise;

			commit(PLANS, plans);

			await usagePromise;
			await featuresPromise;
		} catch (exception) {
			if (exception.errors) {
				return commit(BILLING_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[PLAN_PREVIEW]({ commit }, planId) {
		commit(PLAN_PREVIEWED, planId);
	},

	[PLAN_DOWNGRADE]: async ({
		commit, dispatch, rootState: { token }, getters
	}, planId) => {
		dispatch(BILLING_ERRORS_CLEAR);
		try {
			const updatedSubscription = await api.billing.subscription.put(
				token,
				{
					plan_id: planId,
					end_of_term: true
				}
			);

			commit(SUBSCRIPTION, updatedSubscription);

			const plans = await api.billing.plans.get(token);

			commit(PLANS, plans);

			dispatch('user/FEATURES_GET', null, { root: true });

			const selectedPlan = plans.find(plan => plan._id === planId);

			dispatch(`user/${USAGE_GET}`, null, { root: true });

			const message = (getters.isTrialActive) ? 'Plan has been downgraded effective immediately' : `Plan will be downgraded to ${selectedPlan.name} when the subscription renews on ${updatedSubscription.next_billing_at_formatted}`;

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				message,
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(BILLING_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[PLAN_UPGRADE]: async ({ commit, dispatch, rootState: { token } }, planId) => {
		dispatch(BILLING_ERRORS_CLEAR);

		try {
			const updatedSubscription = await api.billing.subscription.put(
				token,
				{ plan_id: planId }
			);

			commit(SUBSCRIPTION, updatedSubscription);

			const plans = await api.billing.plans.get(token);

			commit(PLANS, plans);

			dispatch('user/FEATURES_GET', null, { root: true });

			const selectedPlan = plans.find(plan => plan.selected);

			dispatch(`user/${USAGE_GET}`, null, { root: true });

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				`Upgraded to ${selectedPlan.name} plan`,
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(BILLING_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[SUBSCRIPTION_CANCEL]: async ({
		commit,
		rootState: { token, user }
	}) => {
		commit(BILLING_ERRORS, []);

		try {
			const updatedSubscription = await api.billing.subscription.put(
				token,
				{ cancel: true }
			);

			commit(SUBSCRIPTION, updatedSubscription);

			const plans = await api.billing.plans.get(token);

			commit(PLANS, plans);

			utils.redirectToUrl(getCancellationSurveyUrl({
				userId: user?.profile?.id,
				email: user?.profile?.email
			}));
		} catch (exception) {
			if (exception.errors) {
				return commit(BILLING_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[PAYMENT_METHOD_EDIT]: () => {
		router.push('/account/billing/payments');
	},

	[PLAN_TO_DOWNGRADE]: async ({ commit, state: { plans } }, planId) => {
		if (!planId) {
			commit(SELECTED_PLAN_TO_DOWNGRADE, null);

			return;
		}

		commit(SELECTED_PLAN_TO_DOWNGRADE, plans.find(plan => plan._id === planId));
	},

	[PLAN_TO_UPGRADE]: async ({ commit, state: { plans } }, planId) => {
		if (!planId) {
			commit(SELECTED_PLAN_TO_UPGRADE, null);

			return;
		}

		commit(SELECTED_PLAN_TO_UPGRADE, plans.find(plan => plan._id === planId));
	},

	[BILLING_ERRORS_CLEAR]: async ({ commit }) => {
		commit(BILLING_ERRORS, []);
	},

	[CONNECTIONS_GET]: async ({
		commit,
		rootState: { token }
	}) => {
		try {
			const connections = await api.connections.get({ token });

			commit(CONNECTIONS, connections);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
