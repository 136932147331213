import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { OptionsException } from './helpers';

/**
 * Get single email campaign field options.
 * @param {String} token - User's JWT authorization token.
 */
async function httpGet(token) {
	const result = await request.get(endpoints.singleEmailCampaignsOptions, token);

	if (!result.success) {
		return Promise.reject(new OptionsException(result));
	}

	return result.data;
}

export {
	httpGet
};
