import { httpGet } from './httpGet';
import { httpPost } from './httpPost';
import { httpPut } from './httpPut';
import { httpDelete } from './httpDelete';

import { options } from './options';

export const segments = {
	get: httpGet,
	post: httpPost,
	put: httpPut,
	delete: httpDelete,
	options
};
