import { api } from 'api';
import router from 'router';
import logger from 'utils/logger';

import ROUTE_NAMES from 'router/route-names';

import {
	GET_USER
} from '../../user/actionTypes';

import {
	OPTIONS,
	SEQUENCES,
	PAGINATION,
	GENERAL_ERRORS
} from './mutationTypes';

import {
	WELCOME_DISMISS,
	DASHBOARD_NAVIGATE,
	OPTIONS_GET,
	SEQUENCES_GET,
	SEQUENCE_CREATE,
	SEQUENCE_REPORT,
	SEQUENCE_EDIT,
	SEQUENCE_DUPLICATE,
	SEQUENCE_ACTIVATE,
	SEQUENCE_PAUSE,
	SEQUENCE_DELETE
} from './actionTypes';

import { Flags } from '../../user/helpers';
import SHOW_NOTIFICATION from '../../notifications/actionTypes';

const CAMPAIGNS_PER_PAGE = 30;

export default {
	async [WELCOME_DISMISS]({
		dispatch,
		rootState: { token }
	}) {
		await api.user.flags.post(token, Flags.CAMPAIGNS_SEQUENCE_WELCOME_MSG);
		dispatch(`user/${GET_USER}`, null, { root: true });
	},

	[DASHBOARD_NAVIGATE]() {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS
		});
	},

	async [OPTIONS_GET]({
		commit,
		rootState: { token }
	}) {
		try {
			const options = await api.sequenceEmailCampaigns.options.get(token);

			commit(OPTIONS, options);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [SEQUENCES_GET]({
		commit,
		rootState: { token }
	}, page = 1) {
		try {
			const { data, pagination } = await api.sequenceEmailCampaigns.get({
				token,
				limit: CAMPAIGNS_PER_PAGE,
				page
			});

			commit(SEQUENCES, data);
			commit(PAGINATION, pagination);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[SEQUENCE_CREATE]() {
		router.push({ name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_CREATE });
	},

	[SEQUENCE_REPORT](context, sequenceId) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_REPORT,
			params: {
				id: sequenceId
			}
		});
	},

	[SEQUENCE_EDIT](context, sequenceId) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_EMAILS,
			params: {
				id: sequenceId
			}
		});
	},

	async [SEQUENCE_DUPLICATE]({
		dispatch,
		commit,
		rootState: { token }
	}, sequenceId) {
		try {
			await api.sequenceEmailCampaigns.duplicate.post(token, sequenceId);
			dispatch(SEQUENCES_GET);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Sequence duplicated',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [SEQUENCE_ACTIVATE]({
		dispatch,
		commit,
		rootState: { token }
	}, sequenceId) {
		try {
			await api.sequenceEmailCampaigns.start.post(token, sequenceId);
			dispatch(SEQUENCES_GET);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Sequence activated',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [SEQUENCE_PAUSE]({
		dispatch,
		commit,
		rootState: { token }
	}, sequenceId) {
		try {
			await api.sequenceEmailCampaigns.pause.post(token, sequenceId);
			dispatch(SEQUENCES_GET);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Sequence paused',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [SEQUENCE_DELETE]({
		commit,
		dispatch,
		rootState: { token }
	}, sequenceId) {
		try {
			await api.sequenceEmailCampaigns.delete(token, sequenceId);
			dispatch(SEQUENCES_GET);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Sequence deleted',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
