import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { EmailVerificationResendException } from './helpers';

/**
 * Sends POST request to verification email resend API endpoint.
 * @param {String} email User email.
 * @return {Promise<Object>}
 */
async function httpPost(email) {
	const result = await request.post(endpoints.auth.emailVerificationResend, { email });

	if (!result.success) {
		return Promise.reject(new EmailVerificationResendException(result));
	}

	return result;
}

export {
	httpPost
};
