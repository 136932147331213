import ROUTE_NAMES from 'router/route-names';

export const webTrackingRoutes = [
	{
		path: '/new-web-tracking',
		name: ROUTE_NAMES.WEB_TRACKING_HOME,
		component: () => import('pages/web-tracking/WebTrackingPage.vue'),
		meta: {
			title: 'Web Tracking'
		},
		redirect: { name: ROUTE_NAMES.WEB_TRACKING_IDENTIFIED_VISITORS },
		children: [
			{
				path: 'identified-visitors',
				name: ROUTE_NAMES.WEB_TRACKING_IDENTIFIED_VISITORS,
				component: () => import('pages/web-tracking/sub-pages/identified-visitors/IdentifiedVisitorsSubPage.vue'),
				meta: {
					title: 'Identified visitors - Web Tracking'
				}
			},
			{
				path: 'channel-report',
				name: ROUTE_NAMES.WEB_TRACKING_CHANNEL_REPORT_NEW,
				component: () => import('pages/web-tracking/sub-pages/channel-report/ChannelReportSubPage.vue'),
				meta: {
					title: 'Channel report - Web Tracking'
				}
			},
			{
				path: 'workflows',
				name: ROUTE_NAMES.WEB_TRACKING_WORKFLOWS,
				component: () => import('pages/web-tracking/sub-pages/workflows/WorkflowsSubPage.vue'),
				meta: {
					title: 'Workflows - Web Tracking'
				}
			},
			{
				path: 'create-tracking-links',
				name: ROUTE_NAMES.WEB_TRACKING_LINKS,
				component: () => import('pages/web-tracking/sub-pages/create-tracking-links/CreateTrackingLinksSubPage.vue'),
				meta: {
					title: 'Create tracking links - Web Tracking'
				}
			},
			{
				path: 'setup',
				name: ROUTE_NAMES.WEB_TRACKING_SETUP,
				component: () => import('pages/web-tracking/sub-pages/setup/SetupSubPage.vue'),
				meta: {
					title: 'Setup - Web Tracking'
				}
			}
		]
	}
];
