export const SEGMENT_MODE = {
	CREATE: 'CREATE',
	EDIT: 'EDIT'
};

/**
 * Get initial state of edit module.
 * @return {Object}
 */
export const getInitialState = () => ({
	mode: SEGMENT_MODE.CREATE,
	options: {},
	segment: {
		imported: false,
		name: '',
		conditionSets: [
			{
				glue: 'and',
				conditions: []
			}
		]
	},
	errors: [],
	isSegmentSaveLoading: false
});
