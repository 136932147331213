export const MODE = 'MODE';
export const OPTIONS = 'OPTIONS';
export const NAME = 'NAME';
export const SEGMENT = 'SEGMENT';
export const CONDITION_SET = 'CONDITION_SET';
export const CONDITION = 'CONDITION';
export const CONDITION_DELETE = 'CONDITION_DELETE';
export const RESET = 'RESET';
export const GENERAL_ERRORS = 'GENERAL_ERRORS';
export const SEGMENT_SAVE_LOADING = 'SEGMENT_SAVE_LOADING';
