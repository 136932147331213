const getInitialState = () => ({
	sequenceId: null,
	emailId: null,
	email: {},
	recipients: null,
	csvDownloadUrl: '',
	sortBy: 'toName',
	sortOrder: 'asc',
	pagination: {
		currentPage: 0,
		pageCount: 0
	},
	generalErrors: []
});

export default getInitialState;
