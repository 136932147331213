import { api } from 'api';
import logger from 'utils/logger';
import throttle from 'lodash/throttle';

import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import {
	FOOTER_GET,
	FOOTER_EDIT,
	OPTIONS_GET,
	IMAGE_UPLOAD
} from './actionTypes';
import {
	FOOTER,
	ERRORS,
	OPTIONS,
	IMAGE
} from './mutationTypes';

/**
 * Minimum number of milliseconds
 * between save requests to API.
 * @constant {Number}
 */
const SAVING_INTERVAL = 3000;

export default {
	[FOOTER_GET]: async ({ commit, rootState: { token } }) => {
		try {
			const { footer } = await api.user.settings.email.footer.get(token);

			commit(FOOTER, footer);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [OPTIONS_GET]({ commit, rootState: { token } }) {
		try {
			const options = await api.user.settings.email.footer.options.get(token);

			commit(OPTIONS, options);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[FOOTER_EDIT]: throttle(async ({ commit, dispatch, rootState: { token } }, footer) => {
		try {
			await api.user.settings.email.footer.put({ token, footer });

			commit(ERRORS, []);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		} finally {
			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Footer saved',
				{ root: true }
			);
		}
	}, SAVING_INTERVAL, { trailing: true }),

	async [IMAGE_UPLOAD]({
		commit,
		rootState: { token }
	}, { imageFile, uploadId }) {
		try {
			const result = await api.content.upload.post(token, { file: imageFile });

			commit(IMAGE, { uploadId, imageUrl: result.data.location });
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
