//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	inheritAttrs: false
};

