import { api } from 'api';
import logger from 'utils/logger';
import utils from 'utils';
import { capitalize } from 'utils/formatting';
import { openOauthLoginWindow } from 'utils/oauth';
import { Providers } from 'utils/oauth/constants';
import { ANALYTICS_INTEGRATION_REMOVED, GET_USER } from 'store/modules/user/actionTypes';
import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import { Flags } from 'store/modules/user/helpers';
import router from 'router';
import ROUTE_NAMES from 'router/route-names';
import { AppLabels, Apps, EngagementSourceNames } from 'utils/constants';
import { getConfValue } from 'utils/appconfig';
import { generatePkceCodes } from 'utils/pkce';
import {
	APP_AUTH_DIALOG_HIDE,
	APP_AUTH_DIALOG_RESET,
	APP_AUTH_DIALOG_SAVE,
	APP_AUTH_DIALOG_SHOW,
	DIALOG_CONTACTS_IMPORT_CLOSE,
	DIALOG_CONTACTS_IMPORT_CONFIRM,
	DIALOG_CONTACTS_IMPORT_SHOW,
	DISCONNECT_INTEGRATION,
	INTEGRATION_SIGN_UP_SUCCESS,
	OAUTH_CONNECT,
	OAUTH_CONNECT_START,
	OAUTH_CONNECT_SUCCESS,
	OAUTH_CONNECT_FAILURE,
	VALUE_COPY,
	WIX_CONNECT_START,
	WP_PLUGIN_AUTH_DIALOG_CONFIRM,
	WP_PLUGIN_AUTH_DIALOG_HIDE,
	WP_PLUGIN_AUTH_DIALOG_RESET,
	WP_PLUGIN_AUTH_DIALOG_SHOW,
	ZENDESK_SIGNUP
} from './actionTypes';

import {
	APP_AUTH_APP_ID,
	APP_AUTH_DATA,
	APP_AUTH_DIALOG_VISIBILITY,
	APP_AUTH_ERRORS,
	DIALOG_CONTACTS_IMPORT_LOADING,
	DIALOG_CONTACTS_IMPORT_OPT_IN_ERROR,
	DIALOG_CONTACTS_IMPORT_RESET,
	DIALOG_CONTACTS_IMPORT_VISIBILITY,
	GENERAL_ERRORS,
	OAUTH_APP_PKCE_CODE_VERIFIER,
	WP_PLUGIN_AUTH_DIALOG_VISIBILITY,
	WP_PLUGIN_AUTH_ERRORS,
	WP_PLUGIN_AUTH_PLUGIN_NAME
} from './mutationTypes';

const WP_PLUGIN_AUTH_ERROR = {
	error: 'wp_plugin_auth_failed',
	message: 'Something went wrong. Please verify details. Contact support if the issue persists.',
	context: 'wp_plugin_auth'
};

export default {
	async [ZENDESK_SIGNUP]({ dispatch, commit, rootState: { token } }) {
		try {
			await api.user.flags.post(token, Flags.ZENDESK);
			dispatch(`user/${GET_USER}`, null, { root: true });
			dispatch(INTEGRATION_SIGN_UP_SUCCESS);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[WIX_CONNECT_START]() {
		const { inviteUrl } = getConfValue(Providers.WIX);

		window.location = inviteUrl;
	},

	[OAUTH_CONNECT_START]: async ({ commit }, { provider, wixToken }) => {
		const { codeChallenge, codeVerifier } = generatePkceCodes();

		commit(OAUTH_APP_PKCE_CODE_VERIFIER, codeVerifier);
		openOauthLoginWindow({
			provider, token: wixToken, pkceCodeChallenge: codeChallenge
		});
	},

	[OAUTH_CONNECT_SUCCESS]: async ({ commit, dispatch }, { provider }) => {
		commit(GENERAL_ERRORS, []);
		dispatch(
			`notifications/${SHOW_NOTIFICATION}`, `${AppLabels[provider]} integration successfully connected.`, { root: true }
		);
	},

	[OAUTH_CONNECT_FAILURE]: async ({ commit, dispatch }, { provider }) => {
		commit(GENERAL_ERRORS, []);
		dispatch(
			`notifications/${SHOW_NOTIFICATION}`,
			`Unable to connect ${capitalize(provider)}. Access to account was denied. Please try again.`,
			{ root: true }
		);
	},

	[INTEGRATION_SIGN_UP_SUCCESS]: async ({ commit, dispatch }) => {
		commit(GENERAL_ERRORS, []);
		dispatch(
			`notifications/${SHOW_NOTIFICATION}`, 'Thank you. We\'ll be in touch soon.', { root: true }
		);
	},

	async [OAUTH_CONNECT]({ rootState, dispatch, commit }, { provider, code, state }) {
		if (!code) {
			dispatch(OAUTH_CONNECT_FAILURE, { provider });

			return;
		}

		try {
			await api.integrations.post(rootState.token, provider, {
				code,
				state,
				oauthPkceCodeVerifier: rootState.account.integrations.oauthPkceCodeVerifier
			});
			dispatch(OAUTH_CONNECT_SUCCESS, { provider });
			dispatch(`user/${GET_USER}`, null, { root: true });
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[DIALOG_CONTACTS_IMPORT_SHOW]({ commit }) {
		commit(DIALOG_CONTACTS_IMPORT_VISIBILITY, true);
	},

	[DIALOG_CONTACTS_IMPORT_CLOSE]({ commit }) {
		commit(DIALOG_CONTACTS_IMPORT_RESET);
	},

	async [DIALOG_CONTACTS_IMPORT_CONFIRM]({
		rootState: { token },
		state: {
			selectedFile, segmentName, isOptInConfirmed, delimiter
		},
		commit,
		dispatch
	}) {
		if (!isOptInConfirmed) {
			return commit(DIALOG_CONTACTS_IMPORT_OPT_IN_ERROR, true);
		}

		commit(DIALOG_CONTACTS_IMPORT_LOADING, true);

		try {
			const { data, errors } = await api.persons.imports.post(token,
				{ file: selectedFile, segmentName, delimiter });

			if (errors) {
				commit(DIALOG_CONTACTS_IMPORT_LOADING, false);
				commit(DIALOG_CONTACTS_IMPORT_RESET);

				return commit(GENERAL_ERRORS, errors);
			}

			commit(DIALOG_CONTACTS_IMPORT_RESET);

			dispatch(`user/${GET_USER}`, null, { root: true });

			router.push({
				name: ROUTE_NAMES.CONTACTS,
				query: {
					segment: data.segmentId
				}
			});
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[APP_AUTH_DIALOG_SHOW]({ rootGetters, commit }, appId) {
		commit(APP_AUTH_APP_ID, appId);

		if (appId === Apps.COPPER) {
			commit(APP_AUTH_DATA, rootGetters['user/copperAuthData']);
		}

		if (appId === Apps.ACTIVECAMPAIGN) {
			commit(APP_AUTH_DATA, rootGetters['user/activecampaignAuthData']);
		}

		if (appId === Apps.AIRTABLE) {
			commit(APP_AUTH_DATA, rootGetters['user/airtableAuthData']);
		}

		if (appId === Apps.SENDINBLUE) {
			commit(APP_AUTH_DATA, rootGetters['user/sendinblueAuthData']);
		}

		if (appId === Apps.KLAVIYO) {
			commit(APP_AUTH_DATA, rootGetters['user/klaviyoAuthData']);
		}

		if (appId === Apps.MAILERLITE) {
			commit(APP_AUTH_DATA, rootGetters['user/mailerliteAuthData']);
		}

		commit(APP_AUTH_ERRORS, []);
		commit(APP_AUTH_DIALOG_VISIBILITY, true);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[APP_AUTH_DIALOG_HIDE]({ commit }) {
		commit(APP_AUTH_DIALOG_VISIBILITY, false);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [APP_AUTH_DIALOG_SAVE]({
		dispatch, commit, state, rootState: { token }
	}) {
		try {
			await api.integrations.post(token, state.appAuthAppId, state.appAuthData);

			dispatch(`user/${GET_USER}`, null, { root: true });
			dispatch(APP_AUTH_DIALOG_HIDE);
			dispatch(
				`notifications/${SHOW_NOTIFICATION}`, `${AppLabels[state.appAuthAppId]} authenticated`, { root: true }
			);

			dispatch(APP_AUTH_DIALOG_RESET);
		} catch (exception) {
			if (exception.errors) {
				return commit(APP_AUTH_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[APP_AUTH_DIALOG_RESET]({
		commit
	}) {
		commit(APP_AUTH_APP_ID, '');
		commit(APP_AUTH_DATA, { apiKey: '', accountName: '' });
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	async [WP_PLUGIN_AUTH_DIALOG_CONFIRM]({
		dispatch, commit, state, rootState: { token }
	}) {
		try {
			const options = await api.connections.options.get({
				token,
				contactSource: null,
				engagementSource: state.appAuthAppId
			});

			if (!options.engagementEventSourceSegments) {
				return commit(WP_PLUGIN_AUTH_ERRORS, [WP_PLUGIN_AUTH_ERROR]);
			}

			dispatch(WP_PLUGIN_AUTH_DIALOG_HIDE);
			dispatch(`user/${GET_USER}`, null, { root: true });
			dispatch(
				`notifications/${SHOW_NOTIFICATION}`, `${state.wpPluginAuthPluginName} configured`, { root: true }
			);
			dispatch(WP_PLUGIN_AUTH_DIALOG_RESET);
		} catch (exception) {
			if (exception.errors) {
				return commit(WP_PLUGIN_AUTH_ERRORS, [WP_PLUGIN_AUTH_ERROR]);
			}

			logger.error(exception);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[WP_PLUGIN_AUTH_DIALOG_SHOW]({ commit }, plugin) {
		commit(WP_PLUGIN_AUTH_PLUGIN_NAME, EngagementSourceNames[plugin]);
		commit(WP_PLUGIN_AUTH_ERRORS, []);
		commit(APP_AUTH_APP_ID, plugin);
		commit(WP_PLUGIN_AUTH_DIALOG_VISIBILITY, true);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[WP_PLUGIN_AUTH_DIALOG_HIDE]({ commit }) {
		commit(WP_PLUGIN_AUTH_DIALOG_VISIBILITY, false);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[WP_PLUGIN_AUTH_DIALOG_RESET]({
		commit
	}) {
		commit(WP_PLUGIN_AUTH_PLUGIN_NAME, '');
		commit(WP_PLUGIN_AUTH_ERRORS, []);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[VALUE_COPY]: ({ dispatch }, itemName) => {
		utils.copySelectedText();

		dispatch(
			`notifications/${SHOW_NOTIFICATION}`, `${itemName} copied to clipboard`, { root: true }
		);
	},

	/**
	 * @param {import('vuex').ActionContext} context
	 */
	[DISCONNECT_INTEGRATION]: async ({ dispatch, commit, rootState: { token } }, integration) => {
		try {
			await api.integrations.delete(token, integration);
			dispatch(`user/${ANALYTICS_INTEGRATION_REMOVED}`, integration, { root: true });
			dispatch(`user/${GET_USER}`, null, { root: true });
			dispatch(
				`notifications/${SHOW_NOTIFICATION}`, `${AppLabels[integration]} integration successfully disconnected`, { root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
