import isEqual from 'lodash/isEqual';

/**
 * Helper function for quill -> prosemirror migration.
 * @param {Object} campaignDelta
 * @param {Object} defaultDelta
 * @return {Boolean}
 */
export default function shouldLoadHtmlContent(
	campaignDelta,
	defaultDelta
) {
	return (!isEqual(defaultDelta, campaignDelta));
}
