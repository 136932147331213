import throttle from 'lodash/throttle';

import { api } from 'api';
import router from 'router';
import { groupErrors } from 'utils/error';
import logger from 'utils/logger';
import shouldLoadHtmlContent from 'utils/migration';

import ROUTE_NAMES from 'router/route-names';

import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';

import {
	OPTIONS,
	EMAIL,
	FIELDS,
	DIALOG_EDITOR_HTML_VISIBILITY,
	DIALOG_CUSTOM_HTML_VISIBILITY,
	GENERAL_ERRORS,
	FIELD_ERRORS,
	FIELD_ERRORS_CLEAR,
	IMAGE
} from './mutationTypes';

import {
	SEQUENCE_NAVIGATE,
	OPTIONS_GET,
	EMAIL_GET,
	FIELDS_INITIALIZE,
	FIELDS_EDIT,
	DIALOG_EDITOR_HTML_SHOW,
	DIALOG_EDITOR_HTML_CONFIRM,
	DIALOG_EDITOR_HTML_CANCEL,
	DIALOG_CUSTOM_HTML_SHOW,
	DIALOG_CUSTOM_HTML_CONFIRM,
	DIALOG_CUSTOM_HTML_CANCEL,
	ERRORS_SHOW,
	IMAGE_UPLOAD
} from './actionTypes';

const SAVING_INTERVAL = 3000;

/**
 * Emails actions.
 */
export default {
	[SEQUENCE_NAVIGATE]({
		state: { sequenceId }
	}) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_EMAILS,
			params: {
				id: sequenceId
			}
		});
	},

	async [OPTIONS_GET]({
		commit,
		dispatch,
		rootState: { token }
	}) {
		try {
			const options = await api.sequenceEmailCampaigns.emails.options.get(token);

			commit(OPTIONS, options);
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [EMAIL_GET]({
		commit,
		dispatch,
		state: { sequenceId, options },
		rootState: { token }
	}, emailId) {
		try {
			const email = await api.sequenceEmailCampaigns.emails.get(token, sequenceId, emailId);
			const defaultDelta = options.delta.default;

			if (shouldLoadHtmlContent(email.delta, defaultDelta)) {
				email.contentJSON = null;
			}

			commit(EMAIL, email);
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}
	},

	[FIELDS_INITIALIZE]({ commit }, options) {
		Object.entries(options).forEach(option => {
			const [key, value] = option;

			if (value.default) {
				commit(FIELDS, { [key]: value.default });
			}
		});
	},

	[FIELDS_EDIT]: throttle(async ({
		commit,
		dispatch,
		rootState: { token },
		state: { email, sequenceId }
	}, fields) => {
		commit(FIELDS, fields);

		commit(FIELD_ERRORS_CLEAR, fields);

		try {
			const updatedEmail = await api.sequenceEmailCampaigns.emails.put(
				token,
				sequenceId,
				email._id,
				fields
			);

			commit(EMAIL, updatedEmail);

			const message = email.active && !updatedEmail.active ? 'Sequence paused' : 'Email saved';

			dispatch(`notifications/${SHOW_NOTIFICATION}`, message, { root: true });
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}

			logger.error(exception);
		}
	}, SAVING_INTERVAL, { trailing: true }),

	/**
	 * Show confirmation dialog for switching editor to editor html mode.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_EDITOR_HTML_SHOW]({ commit }) {
		commit(DIALOG_EDITOR_HTML_VISIBILITY, true);
	},

	/**
	 * Switch sequence email to editor html and hide the confirmation dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	async [DIALOG_EDITOR_HTML_CONFIRM]({
		commit,
		dispatch,
		rootState: { token },
		state: { sequenceId, email }
	}) {
		try {
			const updatedEmail = await api.sequenceEmailCampaigns.emails.put(
				token,
				sequenceId,
				email._id,
				{ isCustomHtml: false }
			);

			commit(EMAIL, updatedEmail);
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}
		}

		commit(DIALOG_EDITOR_HTML_VISIBILITY, false);
	},

	/**
	 * Hide editor html confirmation dialog when switching is cancelled.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_EDITOR_HTML_CANCEL]({ commit }) {
		commit(DIALOG_EDITOR_HTML_VISIBILITY, false);
	},

	/**
	 * Show confirmation dialog for switching editor to custom html mode.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_CUSTOM_HTML_SHOW]({ commit }) {
		commit(DIALOG_CUSTOM_HTML_VISIBILITY, true);
	},

	/**
	 * Switch campaign to custom html and hide confirmation dialog.
	 * @param {import('vuex').ActionContext} context
	 */
	async [DIALOG_CUSTOM_HTML_CONFIRM]({
		commit,
		dispatch,
		rootState: { token },
		state: { sequenceId, email }
	}) {
		try {
			const updatedEmail = await api.sequenceEmailCampaigns.emails.put(
				token,
				sequenceId,
				email._id,
				{ isCustomHtml: true }
			);

			commit(EMAIL, updatedEmail);
		} catch (exception) {
			if (exception.errors) {
				return dispatch(ERRORS_SHOW, exception.errors);
			}
		}

		commit(DIALOG_CUSTOM_HTML_VISIBILITY, false);
	},

	/**
	 * Hide custom html confirmation dialog when switching is cancelled.
	 * @param {import('vuex').ActionContext} context
	 */
	[DIALOG_CUSTOM_HTML_CANCEL]({ commit }) {
		commit(DIALOG_CUSTOM_HTML_VISIBILITY, false);
	},

	[ERRORS_SHOW]({
		commit,
		state: { visibleFields }
	}, errors) {
		const { generalErrors, contextualErrors } = groupErrors(
			errors,
			visibleFields
		);

		commit(GENERAL_ERRORS, generalErrors);
		commit(FIELD_ERRORS, contextualErrors);
	},

	async [IMAGE_UPLOAD]({
		commit,
		rootState: { token }
	}, { imageFile, uploadId }) {
		try {
			const result = await api.content.upload.post(token, { file: imageFile });

			commit(IMAGE, { uploadId, imageUrl: result.data.location });
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
