import mutations from './mutations';
import actions from './actions';
import getInitialState from './state';

import pipedriveToMailchimp from './pipedriveToMailchimp';
import mailchimpToPipedrive from './mailchimpToPipedrive';
import mailchimpToCopper from './mailchimpToCopper';
import { mailchimpToHubspot } from './mailchimpToHubspot';
import copperToMailchimp from './copperToMailchimp';

export default {
	namespaced: true,
	state: getInitialState(),
	actions,
	mutations,
	modules: {
		pipedriveToMailchimp,
		mailchimpToPipedrive,
		mailchimpToCopper,
		mailchimpToHubspot,
		copperToMailchimp
	}
};
