import {
	COUNTERS,
	VISITS,
	EXPANDED,
	PAGINATION,
	GENERAL_ERRORS
} from './mutationTypes';

export const mutations = {
	[COUNTERS](state, counters) {
		state.counters = counters;
	},

	[VISITS](state, visits) {
		state.visits = visits.map(visit => ({
			...visit,
			expanded: false
		}));
	},

	[EXPANDED](state, index) {
		state.visits[index].expanded = !state.visits[index].expanded;
	},

	[PAGINATION](state, pagination) {
		state.pagination = pagination;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	}
};
