import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { QueueException } from './helpers';

/**
 * Get list of sequence email campaign emails or get one email by ID.
 * @param {String} token - User's JWT authorization.
 * @param {String} sequenceId - ID of the sequence.
 * @param {Number} limit - Limit the number of persons.
 * @param {Number} page - Number of the page.
 */
// eslint-disable-next-line max-params
async function httpGet(token, sequenceId, limit, page, sortBy, sortOrder) {
	const url = new URL(endpoints.sequenceEmailCampaignsQueue(sequenceId));
	const searchParams = new URLSearchParams({
		limit,
		page,
		sortBy,
		sortOrder
	});

	url.search = searchParams.toString();

	const result = await request.get(url, token);

	if (!result.success) {
		return Promise.reject(new QueueException(result));
	}

	return result;
}

export {
	httpGet
};
