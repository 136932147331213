//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { createNamespacedHelpers } from 'vuex';
import { LOGOUT } from 'store/actionTypes';

const { mapState } = createNamespacedHelpers('user');

export default {
	computed: {
		...mapState({
			email: state => state.profile && state.profile.email
		})
	},

	methods: {
		logout() {
			this.$store.dispatch(LOGOUT);
		}
	}
};

