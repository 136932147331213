const getInitialState = () => ({
	sequenceId: null,
	sequence: {},
	emails: null,
	queuedEmails: null,
	sortBy: 'sendTime',
	sortOrder: 'asc',
	pagination: {
		currentPage: 0,
		pageCount: 0
	},
	generalErrors: []
});

export default getInitialState;
