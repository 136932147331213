import logger from 'utils/logger';
import { api } from 'api';
import router from 'router';

import ROUTE_NAMES from 'router/route-names';

import {
	PERSON,
	EVENTS,
	PAGINATION,
	GENERAL_ERRORS,
	DIALOG_PERSON_UNSUBSCRIBE_VISIBILITY,
	DIALOG_PERSON_LEAD_SCORE_RESET_VISIBILITY
} from './mutationTypes';

import {
	CAMPAIGN_NAVIGATE,
	SEQUENCE_NAVIGATE,
	EMAIL_NAVIGATE,
	PERSON_LIST_NAVIGATE,
	PERSON_GET,
	PERSON_EVENTS_GET,
	PERSON_UNSUBSCRIBE,
	DIALOG_PERSON_UNSUBSCRIBE_SHOW,
	DIALOG_PERSON_UNSUBSCRIBE_HIDE,
	DIALOG_PERSON_UNSUBSCRIBE_CONFIRM,
	DIALOG_PERSON_LEAD_SCORE_RESET_CONFIRM,
	DIALOG_PERSON_LEAD_SCORE_RESET_HIDE,
	DIALOG_PERSON_LEAD_SCORE_RESET_SHOW,
	PERSON_LEAD_SCORE_RESET
} from './actionTypes';

const EVENTS_PER_PAGE = 20;

export default {
	/**
	 * @param {import('vuex').Store} actionContext
	 * @param {String} campaignId
	 */
	[CAMPAIGN_NAVIGATE](actionContext, campaignId) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_REPORT,
			params: {
				id: campaignId
			}
		});
	},

	/**
	 * @param {import('vuex').Store} actionContext
	 * @param {String} sequenceId
	 */
	[SEQUENCE_NAVIGATE](actionContext, sequenceId) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_REPORT,
			params: {
				id: sequenceId
			}
		});
	},

	/**
	 * @param {import('vuex').ActionContext} actionContext
	 * @param {Object} params
	 * @param {String} params.sequenceId
	 * @param {String} params.emailId
	 */
	[EMAIL_NAVIGATE](actionContext, { sequenceId, emailId }) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_EMAILS_REPORT,
			params: {
				sequenceId,
				emailId
			}
		});
	},

	/**
	 * Navigate to person list.
	 */
	[PERSON_LIST_NAVIGATE]() {
		router.push({
			name: ROUTE_NAMES.CONTACTS
		});
	},

	/**
	 * @param {import('vuex').ActionContext} actionContext
	 */
	async [PERSON_GET]({
		commit,
		state: { personId },
		rootState: { token }
	}) {
		try {
			const { data } = await api.persons.get({ token, personId });

			commit(PERSON, data);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} actionContext
	 * @param {Number} page
	 */
	async [PERSON_EVENTS_GET]({
		commit,
		state: {
			personId
		},
		rootState: { token }
	}, page = 1) {
		try {
			const { events, pagination } = await api.persons.events.get({
				token,
				personId,
				limit: EVENTS_PER_PAGE,
				page
			});

			commit(EVENTS, events);
			commit(PAGINATION, pagination);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} actionContext
	 */
	async [PERSON_UNSUBSCRIBE]({
		state: {
			personId
		},
		rootState: { token },
		commit,
		dispatch
	}) {
		try {
			const { data } = await api.persons.put({
				token,
				personId,
				person: { subscriptionStatus: 'unsubscribed' }
			});

			commit(PERSON, data);
			dispatch(PERSON_EVENTS_GET);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} actionContext
	 */
	async [PERSON_LEAD_SCORE_RESET]({
		state: {
			personId
		},
		rootState: { token },
		commit,
		dispatch
	}) {
		try {
			await api.persons.leadscore.put({
				token,
				personId,
				data: {}
			});

			dispatch(PERSON_GET);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	/**
	 * @param {import('vuex').ActionContext} actionContext
	 */
	[DIALOG_PERSON_UNSUBSCRIBE_SHOW]({ commit }) {
		commit(DIALOG_PERSON_UNSUBSCRIBE_VISIBILITY, true);
	},

	/**
	 * @param {import('vuex').ActionContext} actionContext
	 */
	[DIALOG_PERSON_UNSUBSCRIBE_HIDE]({ commit }) {
		commit(DIALOG_PERSON_UNSUBSCRIBE_VISIBILITY, false);
	},

	/**
	 * @param {import('vuex').ActionContext} actionContext
	 */
	async [DIALOG_PERSON_UNSUBSCRIBE_CONFIRM]({ commit, dispatch }) {
		commit(DIALOG_PERSON_UNSUBSCRIBE_VISIBILITY, false);

		dispatch(PERSON_UNSUBSCRIBE);
	},

	/**
	 * @param {import('vuex').ActionContext} actionContext
	 */
	[DIALOG_PERSON_LEAD_SCORE_RESET_CONFIRM]({ commit, dispatch }) {
		commit(DIALOG_PERSON_LEAD_SCORE_RESET_VISIBILITY, false);
		dispatch(PERSON_LEAD_SCORE_RESET);
	},

	/**
	 * @param {import('vuex').ActionContext} actionContext
	 */
	[DIALOG_PERSON_LEAD_SCORE_RESET_HIDE]({ commit }) {
		commit(DIALOG_PERSON_LEAD_SCORE_RESET_VISIBILITY, false);
	},

	/**
	 * @param {import('vuex').ActionContext} actionContext
	 */
	[DIALOG_PERSON_LEAD_SCORE_RESET_SHOW]({ commit }) {
		commit(DIALOG_PERSON_LEAD_SCORE_RESET_VISIBILITY, true);
	}
};
