import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { ContactCreationRulesException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {String} params.contactCreationRuleId
 * @param {String} params.token
 * @param {Object} params.ruleData
 * @return {Promise<Object>}
 */
export const httpPatch = async ({
	connectionId,
	contactCreationRuleId,
	token,
	ruleData
}) => {
	const response = await request.patch(
		endpoints.connectionsIdContactCreationRulesId(connectionId, contactCreationRuleId),
		ruleData,
		token
	);

	if (!response.success) {
		return Promise.reject(new ContactCreationRulesException(response));
	}

	return response.data;
};
