import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { ContactsException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {String} params.term
 * @param {String} params.status
 * @param {String} params.page
 * @param {String=} params.sortBy
 * @param {String=} params.sortOrder
 * @param {String} params.token
 * @param {Boolean=} params.setNoCacheHeader
 * @return {Promise<Object>}
 */
export const httpGet = async ({
	connectionId,
	term,
	status,
	page,
	sortBy,
	sortOrder,
	token,
	setNoCacheHeader
}) => {
	const url = new URL(endpoints.connectionsContacts(connectionId));

	if (term) {
		url.searchParams.append('term', term);
	}

	if (status) {
		url.searchParams.append('status', status);
	}

	if (page) {
		url.searchParams.append('page', page);
	}

	if (sortBy) {
		url.searchParams.append('sortBy', sortBy);
	}

	if (sortOrder) {
		url.searchParams.append('sortOrder', sortOrder);
	}

	const response = await request.get({ url: url.href, token, setNoCacheHeader });

	if (!response.success) {
		return Promise.reject(new ContactsException(response));
	}

	return response.data;
};
