import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { PasswordResetEmailException } from './helpers';

/**
 * Start password reset flow by sending out Password Reset email to user.
 * @param {Object} params
 * @param {String} params.email - Email address of user who has forgotten/lost their password.
 * @param {String} params.captchaToken
 * @return {Promise<Object>}
 */
export const httpPost = async ({ email, captchaToken }) => {
	const response = await request.post(endpoints.auth.passwordResetEmail, { email, captchaToken });

	if (!response.success) {
		return Promise.reject(new PasswordResetEmailException(response));
	}

	return response.data;
};
