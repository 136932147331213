//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_EDIT_EMAILS = 'edit:emails';
const EVENT_SEND = 'send';
const EVENT_CLOSE = 'close';
const EVENT_SET_REPLACE_MERGE_FIELDS = 'edit:replacemergefields';

export default {
	props: {
		isVisible: {
			type: Boolean,
			default: false
		},
		userEmail: {
			type: String,
			default: ''
		},
		errors: {
			type: Array,
			default: null
		},
		replaceFields: {
			type: Boolean,
			default: true
		},
		isReplaceFieldsDisabled: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		editTestEmails(emails) {
			this.$emit(EVENT_EDIT_EMAILS, emails);
		},
		send() {
			this.$emit(EVENT_SEND);
		},
		close() {
			this.$emit(EVENT_CLOSE);
		},
		onChangeReplaceMergeFields(isEnabled) {
			this.$emit(EVENT_SET_REPLACE_MERGE_FIELDS, isEnabled);
		}
	}
};

