export const REGISTER_USER_WITH_CREDENTIALS = 'REGISTER_USER_WITH_CREDENTIALS';
export const REGISTER_USER_WITH_OAUTH = 'REGISTER_USER_WITH_OAUTH';
export const START_OAUTH = 'START_OAUTH';
export const GET_SIGNUP_PLAN = 'GET_SIGNUP_PLAN';
export const STORE_SIGNUP_PLAN = 'STORE_SIGNUP_PLAN';
export const STORE_SIGNUP_COUPON = 'STORE_SIGNUP_COUPON';
export const GET_INTENT = 'GET_INTENT';
export const TERMS_CONFIRMATION_SHOW = 'TERMS_CONFIRMATION_SHOW';
export const TERMS_CONFIRMATION_HIDE = 'TERMS_CONFIRMATION_HIDE';
export const NAVIGATE_CONNECTIONS = 'NAVIGATE_CONNECTIONS';
export const NAVIGATE_HOME = 'NAVIGATE_HOME';
