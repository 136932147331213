import { getInitialState } from './state';
import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';

export const moduleChannelReport2 = {
	namespaced: true,
	state: getInitialState(),
	mutations,
	actions,
	getters
};
