import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { VisitsException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.token
 * @param {Number} params.limit
 * @param {Number} params.page
 * @return {Promise<Object>}
 */
async function httpGet({
	token,
	limit,
	page
}) {
	const url = new URL(endpoints.trackingVisits);

	const searchParams = new URLSearchParams({
		sortBy: 'lastVisitAt',
		sortOrder: 'desc'
	});

	if (limit) {
		searchParams.set('limit', limit);
	}

	if (page) {
		searchParams.set('page', page);
	}

	url.search = searchParams.toString();

	const result = await request.get(url.toString(), token);

	if (!result.success) {
		return Promise.reject(new VisitsException(result));
	}

	return result.data;
}

export {
	httpGet
};
