import { BaseException } from 'utils/error';

/**
 * @class
 */
export class ContactCreationRulesException extends BaseException {
	/**
	 * @param {{errors: Object[]}} response
	 */
	constructor(response) {
		super(response);
		this.name = 'ContactCreationRulesException';
	}
}
