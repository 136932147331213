//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { EmailHealthStatuses } from 'utils/constants/email-health';
import ROUTE_NAMES from 'router/route-names';

export default {
	props: {
		healthStatus: {
			type: String,
			required: true
		}
	},

	data: () => ({
		emailHealthRoute: { name: ROUTE_NAMES.ACCOUNT_EMAIL_SETTINGS_EMAIL_HEALTH }
	}),

	computed: {
		isHealthStatusGood() {
			return this.healthStatus === EmailHealthStatuses.GOOD;
		},
		isHealthStatusFair() {
			return this.healthStatus === EmailHealthStatuses.FAIR;
		},
		isHealthStatusBad() {
			return this.healthStatus === EmailHealthStatuses.BAD;
		}
	}
};

