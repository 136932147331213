import getInitialState from './state';

import {
	PIPEDRIVE_TO_MAILCHIMP_LIST,
	MAILCHIMP_TO_PIPEDRIVE_LIST,
	MAILCHIMP_TO_COPPER_LIST,
	COPPER_TO_MAILCHIMP_LIST,
	MAILCHIMP_TO_HUBSPOT_LIST,
	LEAD_SCORING_SETTINGS,
	WEB_TRACKING_SETTINGS,
	GENERAL_ERRORS,
	RESET
} from './mutationTypes';

export default {
	[PIPEDRIVE_TO_MAILCHIMP_LIST](state, automations) {
		state.pipedriveToMailchimpList = automations;
	},

	[MAILCHIMP_TO_PIPEDRIVE_LIST](state, automations) {
		state.mailchimpToPipedriveList = automations;
	},

	[MAILCHIMP_TO_COPPER_LIST](state, automations) {
		state.mailchimpToCopperList = automations;
	},

	[COPPER_TO_MAILCHIMP_LIST](state, automations) {
		state.copperToMailchimpList = automations;
	},

	[MAILCHIMP_TO_HUBSPOT_LIST](state, automations) {
		state.mailchimpToHubspotList = automations;
	},

	[LEAD_SCORING_SETTINGS](state, settings) {
		state.leadScoringSettings = settings;
	},

	[WEB_TRACKING_SETTINGS](state, settings) {
		state.webTrackingSettings = settings;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	}
};
