//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { getPlanName } from 'utils/billing/helpers';
import AccountBillingUpgradeIllustration from '../images/account-billing-upgrade-illustration.svg';

/**
 * @constant {String}
 */
const EVENT_CLOSE = 'close';
/**
 * @constant {String}
 */
const EVENT_UPGRADE_PLAN = 'upgrade:plan';

export default {
	components: {
		AccountBillingUpgradeIllustration
	},
	props: {
		isVisible: {
			type: Boolean,
			required: false,
			default: false
		},
		plan: {
			type: Object,
			required: false,
			default:
				() => ({
					name: '',
					_id: 1
				})
		}
	},

	data: () => ({
		learnMoreLink: 'https://outfunnel.com/pricing/'
	}),

	computed: {
		planName() {
			return getPlanName(this.plan);
		}
	},

	methods: {
		onClose() {
			this.$emit(EVENT_CLOSE);
		},
		onUpgradePlan() {
			if (this.plan?._id) {
				this.$emit(EVENT_UPGRADE_PLAN, this.plan._id);
			}
		},
		formattedPrice(price) {
			return Math.ceil(price);
		}
	}
};

