import { api } from 'api';
import router from 'router';
import utils from 'utils';
import logger from 'utils/logger';
import ROUTE_NAMES from 'router/route-names';
import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';

import {
	SEQUENCE,
	EMAILS,
	QUEUED_EMAILS,
	SORT_ORDER,
	SORT_BY,
	PAGINATION,
	GENERAL_ERRORS
} from './mutationTypes';

import {
	LIST_NAVIGATE,
	EMAIL_NAVIGATE,
	SEQUENCE_GET,
	RECIPIENT_NAVIGATE,
	QUEUED_EMAILS_GET,
	QUEUED_EMAILS_REFRESH,
	QUEUED_EMAILS_SORT,
	UPDATE_EMAIL_SEND_DATE
} from './actionTypes';

const EMAILS_PER_PAGE = 50;

export default {
	[LIST_NAVIGATE]() {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE
		});
	},

	[EMAIL_NAVIGATE]({ state: { sequenceId } }, emailId) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_EMAILS_REPORT,
			params: {
				sequenceId,
				emailId
			}
		});
	},

	[RECIPIENT_NAVIGATE]({ state: { sequenceId } }, recipientId) {
		router.push({
			name: ROUTE_NAMES.CONTACTS_PROFILE,
			params: {
				id: recipientId
			},
			query: {
				sequence: sequenceId
			}
		});
	},

	async [SEQUENCE_GET]({
		commit,
		state: { sequenceId },
		rootState: { token }
	}) {
		try {
			const { data } = await api.sequenceEmailCampaigns.get({
				token,
				sequenceId
			});

			commit(SEQUENCE, data);

			const emails = await api.sequenceEmailCampaigns.emails.get(token, sequenceId);

			commit(EMAILS, emails);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [QUEUED_EMAILS_GET]({
		commit,
		state: {
			sequenceId, pagination, sortBy, sortOrder
		},
		rootState: { token }
	}, page = 1) {
		if (pagination.currentPage === page) {
			return;
		}

		try {
			const response = await api.sequenceEmailCampaigns.queue.get(
				token,
				sequenceId,
				EMAILS_PER_PAGE,
				page,
				sortBy,
				sortOrder
			);

			commit(QUEUED_EMAILS, response.data);
			commit(PAGINATION, response.pagination);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [QUEUED_EMAILS_REFRESH]({
		commit,
		state: {
			sequenceId, sortBy, sortOrder
		},
		rootState: { token }
	}, page = 1) {
		try {
			const response = await api.sequenceEmailCampaigns.queue.get(
				token,
				sequenceId,
				EMAILS_PER_PAGE,
				page,
				sortBy,
				sortOrder
			);

			await commit(QUEUED_EMAILS, response.data);
			await commit(PAGINATION, response.pagination);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [QUEUED_EMAILS_SORT]({
		commit,
		state: {
			sequenceId, pagination, sortBy, sortOrder
		},
		rootState: { token }
	}, newSortBy) {
		let newSortOrder = sortOrder;
		const isSameSortBy = sortBy === newSortBy;

		if (isSameSortBy) {
			newSortOrder = utils.reverseSortOrder(sortOrder);
		}

		try {
			const response = await api.sequenceEmailCampaigns.queue.get(
				token,
				sequenceId,
				EMAILS_PER_PAGE,
				pagination.currentPage,
				newSortBy,
				newSortOrder
			);

			commit(SORT_BY, newSortBy);
			commit(SORT_ORDER, newSortOrder);

			commit(QUEUED_EMAILS, response.data);
			commit(PAGINATION, response.pagination);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [UPDATE_EMAIL_SEND_DATE]({
		commit,
		dispatch,
		rootState: { token }
	}, data) {
		try {
			await api.user.email.triggers.patch(token, data);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Email send date updated',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
