export const APPS_GET = 'APPS_GET';
export const APP_SELECT = 'APP_SELECT';
export const APP_PRESELECT = 'APP_PRESELECT';
export const CONNECTION_CREATE = 'CONNECTION_CREATE';
export const CONNECTION_CREATE_CONTACT_SYNC = 'CONNECTION_CREATE_CONTACT_SYNC';
export const CONNECTION_CREATE_ENGAGEMENT_SYNC = 'CONNECTION_CREATE_ENGAGEMENT_SYNC';
export const CONNECTION_DIALOG_RESEARCH_OPEN = 'CONNECTION_DIALOG_RESEARCH_OPEN';
export const CONNECTION_DIALOG_RESEARCH_CLOSE = 'CONNECTION_DIALOG_RESEARCH_CLOSE';
export const CONNECTION_ANALYTICS_TRACK = 'CONNECTION_ANALYTICS_TRACK';
export const CONTACT_SOURCE_AUTHENTICATE = 'CONTACT_SOURCE_AUTHENTICATE';
export const ENGAGEMENT_SOURCE_AUTHENTICATE = 'ENGAGEMENT_SOURCE_AUTHENTICATE';
export const FEATURES_GET = 'FEATURES_GET';
export const APP_AUTH_DIALOG_SHOW = 'APP_AUTH_DIALOG_SHOW';
export const APP_AUTH_DIALOG_HIDE = 'APP_AUTH_DIALOG_HIDE';
export const APP_AUTH_DIALOG_SAVE = 'APP_AUTH_DIALOG_SAVE';
export const APP_AUTH_DIALOG_RESET = 'APP_AUTH_DIALOG_RESET';
export const WP_PLUGIN_AUTH_DIALOG_CONFIRM = 'WP_PLUGIN_AUTH_DIALOG_CONFIRM';
export const WP_PLUGIN_AUTH_DIALOG_SHOW = 'WP_PLUGIN_AUTH_DIALOG_SHOW';
export const WP_PLUGIN_AUTH_DIALOG_HIDE = 'WP_PLUGIN_AUTH_DIALOG_HIDE';
export const WP_PLUGIN_AUTH_DIALOG_RESET = 'WP_PLUGIN_AUTH_DIALOG_RESET';
export const VALUE_COPY = 'VALUE_COPY';
export const DIALOG_SUGGEST_APP_SHOW = 'DIALOG_SUGGEST_APP_SHOW';
export const DIALOG_SUGGEST_APP_CLOSE = 'DIALOG_SUGGEST_APP_CLOSE';
export const DIALOG_HUBSPOT_MKT_CONTACT_SYNC_SHOW = 'DIALOG_HUBSPOT_MKT_CONTACT_SYNC_SHOW';
export const DIALOG_HUBSPOT_MKT_CONTACT_SYNC_CLOSE = 'DIALOG_HUBSPOT_MKT_CONTACT_SYNC_CLOSE';
export const CONTACT_SYNC_LEARN_MORE_DIALOG_CLOSE = 'CONTACT_SYNC_LEARN_MORE_DIALOG_CLOSE';
export const CONTACT_SYNC_LEARN_MORE_DIALOG_OPEN = 'CONTACT_SYNC_LEARN_MORE_DIALOG_OPEN';

export const ENGAGEMENT_SYNC_LEARN_MORE_DIALOG_CLOSE = 'ENGAGEMENT_SYNC_LEARN_MORE_DIALOG_CLOSE';
export const ENGAGEMENT_SYNC_LEARN_MORE_DIALOG_OPEN = 'ENGAGEMENT_SYNC_LEARN_MORE_DIALOG_OPEN';
