/* eslint-disable max-lines */
import { api } from 'api';
import router from 'router';
import logger from 'utils/logger';
import { Features } from 'utils/constants';

import ROUTE_NAMES from 'router/route-names';

import {
	PLAN_SELECT
} from 'store/modules/account/billing/actionTypes';
import {
	GET_USER
} from '../user/actionTypes';

import {
	CAMPAIGNS_OPTIONS,
	CAMPAIGNS,
	SEQUENCES,
	GENERAL_ERRORS,
	GENERAL_ERRORS_APPEND
} from './mutationTypes';

import {
	WELCOME_DISMISS,
	BILLING_NAVIGATE,
	CAMPAIGNS_OPTIONS_GET,
	CAMPAIGNS_GET,
	CAMPAIGN_CREATE,
	CAMPAIGN_EDIT,
	CAMPAIGN_DUPLICATE,
	CAMPAIGN_DELETE,
	CAMPAIGN_REPORT_VIEW,
	EMAIL_VIEW,
	MOUNT,
	SEQUENCES_GET,
	SEQUENCE_CREATE,
	SEQUENCE_REPORT,
	SEQUENCE_EDIT,
	SEQUENCE_DUPLICATE,
	SEQUENCE_ACTIVATE,
	SEQUENCE_PAUSE,
	SEQUENCE_DELETE,
	PLAN_UPGRADE
} from './actionTypes';

import { Flags } from '../user/helpers';
import SHOW_NOTIFICATION from '../notifications/actionTypes';

const CAMPAIGNS_COUNT = 5;
const SEQUENCES_COUNT = 5;

export default {
	async [WELCOME_DISMISS]({ dispatch, rootState: { token } }) {
		await api.user.flags.post(token, Flags.CAMPAIGNS_WELCOME_MSG);
		dispatch(`user/${GET_USER}`, null, { root: true });
	},

	[BILLING_NAVIGATE]: async () => {
		router.push({ name: ROUTE_NAMES.ACCOUNT_BILLING });
	},

	async [CAMPAIGNS_OPTIONS_GET]({
		commit,
		rootState: { token }
	}) {
		try {
			const options = await api.singleEmailCampaigns.options.get(token);

			commit(CAMPAIGNS_OPTIONS, options);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS_APPEND, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [CAMPAIGNS_GET]({
		commit,
		rootState: { token }
	}) {
		try {
			const { data } = await api.singleEmailCampaigns.get({
				token,
				limit: CAMPAIGNS_COUNT,
				page: 1
			});

			commit(CAMPAIGNS, data);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS_APPEND, exception.errors);
			}

			logger.error(exception);
		}
	},

	[CAMPAIGN_CREATE]() {
		router.push({ name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_CREATE });
	},

	[CAMPAIGN_EDIT](context, campaignId) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_BASICS,
			params: {
				id: campaignId
			}
		});
	},

	async [CAMPAIGN_DUPLICATE]({
		dispatch,
		commit,
		rootState: { token }
	}, campaignId) {
		try {
			await api.singleEmailCampaigns.duplicate.post(token, campaignId);
			dispatch(CAMPAIGNS_GET);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Campaign duplicated',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [CAMPAIGN_DELETE]({
		state,
		commit,
		dispatch,
		rootState: { token }
	}, campaignId) {
		try {
			const campaigns = state.campaigns.filter(campaign => campaign._id !== campaignId);

			await api.singleEmailCampaigns.delete(token, campaignId);

			commit(CAMPAIGNS, campaigns);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Campaign deleted',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.erros);
			}

			logger.error(exception);
		}
	},

	[CAMPAIGN_REPORT_VIEW](context, campaignId) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_REPORT,
			params: {
				id: campaignId
			}
		});
	},

	[EMAIL_VIEW](context, campaignId) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL_PREVIEW,
			params: {
				id: campaignId
			}
		});
	},

	[MOUNT]: async ({ dispatch, rootGetters }) => {
		const areFeaturesLoaded = rootGetters['user/areFeaturesLoaded'];

		if (!areFeaturesLoaded) {
			await dispatch('user/FEATURES_GET', null, { root: true });
		}

		dispatch('account/billing/PLANS_GET', null, { root: true });

		const isFeatureEnabled = rootGetters['user/isFeatureEnabled'](Features.CAMPAIGNS);

		if (!isFeatureEnabled) {
			return;
		}

		router.push({ name: ROUTE_NAMES.CAMPAIGNS_DASHBOARD });
	},

	async [SEQUENCES_GET]({
		commit,
		rootState: { token }
	}, { getAllSequences } = {}) {
		try {
			const sequencesCount = getAllSequences ? undefined : SEQUENCES_COUNT;
			const { data } = await api.sequenceEmailCampaigns.get({
				token,
				limit: sequencesCount,
				page: 1
			});

			commit(SEQUENCES, data);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[SEQUENCE_CREATE]() {
		router.push({ name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_CREATE });
	},

	[SEQUENCE_REPORT](context, sequenceId) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_REPORT,
			params: {
				id: sequenceId
			}
		});
	},

	[SEQUENCE_EDIT](context, sequenceId) {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SEQUENCE_EMAILS,
			params: {
				id: sequenceId
			}
		});
	},

	async [SEQUENCE_DUPLICATE]({
		dispatch,
		commit,
		rootState: { token }
	}, sequenceId) {
		try {
			await api.sequenceEmailCampaigns.duplicate.post(token, sequenceId);
			dispatch(SEQUENCES_GET);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Sequence duplicated',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [SEQUENCE_ACTIVATE]({
		dispatch,
		commit,
		rootState: { token }
	}, sequenceId) {
		try {
			await api.sequenceEmailCampaigns.start.post(token, sequenceId);
			dispatch(SEQUENCES_GET);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Sequence activated',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [SEQUENCE_PAUSE]({
		dispatch,
		commit,
		rootState: { token }
	}, sequenceId) {
		try {
			await api.sequenceEmailCampaigns.pause.post(token, sequenceId);
			dispatch(SEQUENCES_GET);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Sequence paused',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [SEQUENCE_DELETE]({
		commit,
		dispatch,
		rootState: { token }
	}, sequenceId) {
		try {
			await api.sequenceEmailCampaigns.delete(token, sequenceId);
			dispatch(SEQUENCES_GET);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Sequence deleted',
				{ root: true }
			);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [PLAN_UPGRADE]({
		dispatch,
		rootState,
		rootGetters
	}) {
		const requiredPlanId = rootGetters['user/requiredPlanForFeature'](Features.CAMPAIGNS);
		const requiredPlan = rootState.account.billing.plans.find(plan => plan.id === requiredPlanId);

		await dispatch(`account/billing/${PLAN_SELECT}`, requiredPlan._id, { root: true });

		const isFeatureEnabled = rootGetters['user/isFeatureEnabled'](Features.CAMPAIGNS);

		if (!isFeatureEnabled) {
			return;
		}

		router.push({ name: ROUTE_NAMES.CAMPAIGNS_DASHBOARD });

		dispatch(
			`notifications/${SHOW_NOTIFICATION}`,
			`You are now on ${requiredPlan.name} plan`,
			{ root: true }
		);
	}
};
