import logger from 'utils/logger';
import { api } from 'api';
import { EventNames, track } from 'utils/analytics';
import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';
import { MutationTypes } from './mutationTypes';
import { ActionTypes } from './actionTypes';

export const actions = {
	[ActionTypes.OPTIONS_GET]: async ({
		commit,
		rootState: { token }
	}) => {
		try {
			const options = await api.user.settings.webTracking.options.get(token);

			commit(MutationTypes.OPTIONS, options);
		} catch (exception) {
			if (exception.errors) {
				return commit(MutationTypes.ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ActionTypes.FEATURES_GET]: async ({
		rootState: { token },
		commit
	}) => {
		try {
			const features = await api.user.features.get(token);

			commit(MutationTypes.FEATURES, features);
		} catch (exception) {
			if (exception.errors) {
				commit(MutationTypes.GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ActionTypes.SETTINGS_GET]: async ({
		commit,
		rootState: { token }
	}) => {
		try {
			const settings = await api.user.settings.webTracking.get(token);

			commit(MutationTypes.SETTINGS, settings);
		} catch (exception) {
			if (exception.errors) {
				return commit(MutationTypes.ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ActionTypes.FIELDS_EDIT]: async ({
		commit,
		dispatch,
		rootState: { token }
	}, fields) => {
		try {
			commit(MutationTypes.ERRORS, []);

			const updatedSettings = await api.user.settings.webTracking.put(
				token, fields
			);

			commit(MutationTypes.SETTINGS, updatedSettings);

			dispatch(
				`notifications/${SHOW_NOTIFICATION}`,
				'Settings saved',
				{ root: true }
			);

			dispatch(ActionTypes.ANALYTICS_TRACK, fields);
		} catch (exception) {
			if (exception.errors) {
				return commit(MutationTypes.ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ActionTypes.ANALYTICS_TRACK]: (context, fields) => {
		const fieldToWorkflowMap = {
			activityVisit: 'activity sync',
			noteVisit: 'note sync',
			trackLeadSource: 'lead source sync',
			trackLeadReferrer: 'referrer sync',
			trackLandingPage: 'landing page sync'
		};

		const editedFieldNames = Object.keys(fields);

		editedFieldNames.forEach(fieldName => {
			const workflow = fieldToWorkflowMap[fieldName];

			if (!workflow) {
				return;
			}

			const status = fields[fieldName] ? 'enabled' : 'disabled';

			track({
				event: EventNames.WEB_TRACKING_WORKFLOW_CHANGED,
				properties: { status, workflow }
			});
		});
	}
};
