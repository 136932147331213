const getInitialState = () => ({
	person: {},
	events: [],
	pagination: {
		currentPage: 0
	},
	isDialogPersonUnsubscribeVisible: false,
	isDialogPersonLeadScoreResetVisible: false,
	generalErrors: []
});

export default getInitialState;
