import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { ConversionException } from './helpers';

/**
 * Get conversion of a single email campaign.
 * @param {Object} data
 * @param {String} data.token -  User's JWT authorization token.
 * @param {String} data.campaignId - ID of the campaign that was sent.
 */
async function httpGet({ token, campaignId }) {
	const url = new URL(endpoints.singleEmailCampaignsIdConversion(campaignId));

	const result = await request.get(url.toString(), token);

	if (!result.success) {
		return Promise.reject(new ConversionException(result));
	}

	return result.data;
}

export {
	httpGet
};
