import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { OptionsException } from './helpers';

/**
 * @param {String} token
 * @return {Promise<Object>}
 */
export const httpGet = async token => {
	const response = await request.get(endpoints.segmentsOptions, token);

	if (!response.success) {
		return Promise.reject(new OptionsException(response));
	}

	return response.data;
};
