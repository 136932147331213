export const WELCOME_DISMISS = 'WELCOME_DISMISS';
export const BILLING_NAVIGATE = 'BILLING_NAVIGATE';
export const CAMPAIGNS_OPTIONS_GET = 'CAMPAIGNS_OPTIONS_GET';
export const CAMPAIGNS_GET = 'CAMPAIGNS_GET';
export const CAMPAIGN_CREATE = 'CAMPAIGN_CREATE';
export const CAMPAIGN_EDIT = 'CAMPAIGN_EDIT';
export const CAMPAIGN_DUPLICATE = 'CAMPAIGN_DUPLICATE';
export const CAMPAIGN_DELETE = 'CAMPAIGN_DELETE';
export const CAMPAIGN_REPORT_VIEW = 'CAMPAIGN_REPORT_VIEW';
export const EMAIL_VIEW = 'EMAIL_VIEW';
export const MOUNT = 'MOUNT';
export const SEQUENCES_GET = 'SEQUENCES_GET';
export const SEQUENCE_CREATE = 'SEQUENCE_CREATE';
export const SEQUENCE_REPORT = 'SEQUENCE_REPORT';
export const SEQUENCE_EDIT = 'SEQUENCE_EDIT';
export const SEQUENCE_DUPLICATE = 'SEQUENCE_DUPLICATE';
export const SEQUENCE_ACTIVATE = 'SEQUENCE_ACTIVATE';
export const SEQUENCE_PAUSE = 'SEQUENCE_PAUSE';
export const SEQUENCE_DELETE = 'SEQUENCE_DELETE';
export const PLAN_UPGRADE = 'PLAN_UPGRADE';
