import { isUndefined } from 'utils/types';

import {
	FROM_ADDRESS_ERROR,
	FILTER_ERROR,
	FROM_NAME_ERROR,
	SUBJECT_ERROR,
	HTML_ERROR,
	OPPORTUNITY_FIELD_ERROR
} from './errors';

import { STEPS } from '../state';

const fields = [
	{
		name: 'fromAddress',
		step: STEPS.BASICS,
		isValid: fromAddress => Boolean(fromAddress && fromAddress.length),
		error: FROM_ADDRESS_ERROR
	},
	{
		name: 'filter',
		step: STEPS.BASICS,
		isValid: filter => !isUndefined(filter),
		error: FILTER_ERROR
	},
	{
		name: 'fromName',
		step: STEPS.BASICS,
		isValid: fromName => Boolean(fromName && fromName.length),
		error: FROM_NAME_ERROR
	},
	{
		name: 'subject',
		step: STEPS.COMPOSE,
		isValid: subject => Boolean(subject && subject.length),
		error: SUBJECT_ERROR
	},
	{
		name: 'html',
		step: STEPS.COMPOSE,
		isValid: html => html.includes('{{unsubscribe_url}}'),
		error: HTML_ERROR
	},
	{
		name: 'isDealFieldAllowed',
		step: STEPS.COMPOSE,
		isValid: isDealFieldAllowed => Boolean(isDealFieldAllowed),
		error: OPPORTUNITY_FIELD_ERROR
	}
];

function validateCampaignFields(campaign, step = STEPS.BASICS) {
	return fields
		.filter(field => {
			const isFieldInCurrentStep = (field.step === step);
			const isFieldInvalid = !field.isValid(campaign[field.name]);

			return (isFieldInCurrentStep && isFieldInvalid);
		}).map(field => field.error);
}

function validateCampaign(campaign) {
	return fields
		.filter(field => !field.isValid(campaign[field.name])).map(field => field.error);
}

export {
	validateCampaignFields,
	validateCampaign
};
