export const MODE = 'MODE';
export const STEP = 'STEP';
export const CAMPAIGN = 'CAMPAIGN';
export const EMAIL_ENGAGEMENT_SETTINGS = 'EMAIL_ENGAGEMENT_SETTINGS';
export const SET_OPTIONS = 'SET_OPTIONS';
export const GENERAL_ERRORS = 'GENERAL_ERRORS';
export const VISIBLE_FIELDS = 'VISIBLE_FIELDS';
export const FIELDS = 'FIELDS';
export const FIELD_ERRORS = 'FIELD_ERRORS';
export const FIELD_ERRORS_CLEAR = 'FIELD_ERRORS_CLEAR';
export const TEST_EMAILS = 'TEST_EMAILS';
export const TEST_EMAILS_ERRORS = 'TEST_EMAILS_ERRORS';
export const CONTACTS_COUNT = 'CONTACTS_COUNT';
export const DIALOG_CONFIRMATION_VISIBILITY = 'DIALOG_CONFIRMATION_VISIBILITY';
export const DIALOG_TEST_EMAIL_VISIBILITY = 'DIALOG_TEST_EMAIL_VISIBILITY';
export const DIALOG_EDITOR_HTML_VISIBILITY = 'DIALOG_EDITOR_HTML_VISIBILITY';
export const DIALOG_CUSTOM_HTML_VISIBILITY = 'DIALOG_CUSTOM_HTML_VIBILITY';
export const DIALOG_SENDING_LIMITS_VISIBILITY = 'DIALOG_SENDING_LIMITS_VISIBILITY';
export const RESET = 'RESET';
export const RECIPIENTS_TYPE = 'RECIPIENTS_TYPE';
export const SEGMENT_TYPE = 'SEGMENT_TYPE';
export const REPLACE_MERGE_FIELDS = 'REPLACE_MERGE_FIELDS';
export const IMAGE = 'IMAGE';
