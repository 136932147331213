//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_DISMISS = 'dismiss';

export default {
	props: {
		trialDaysRemaining: {
			type: Number,
			default: 0
		}
	},

	computed: {
		remainingDaysText() {
			let text;

			if (this.trialDaysRemaining === 0) {
				text = 'today';
			} else if (this.trialDaysRemaining === 1) {
				text = 'tomorrow';
			} else {
				text = `in ${this.trialDaysRemaining} days`;
			}

			return text;
		}
	},

	methods: {
		onDismiss() {
			this.$emit(EVENT_DISMISS);
		}
	}
};
