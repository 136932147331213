const getInitialState = () => ({
	options: {
		name: {
			default: ''
		},
		fromName: {
			default: ''
		},
		fromAddress: {
			default: ''
		},
		filter: {
			options: []
		},
		html: {
			default: ''
		},
		mergeFields: {
			options: []
		}
	},
	pagination: {
		currentPage: 0
	},
	errors: [],
	campaigns: null
});

export default getInitialState;
