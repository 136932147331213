//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_UPDATE = 'update';

export default {
	inheritAttrs: false,
	props: {
		destinationFields: {
			type: Array,
			default: () => []
		},
		mappingId: {
			type: String,
			default: null
		},
		value: {
			type: String,
			default: null
		}
	},

	methods: {
		onUpdate(value) {
			this.$emit(EVENT_UPDATE, { mappingId: this.mappingId, value });
		}
	}
};

