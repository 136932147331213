//
//
//
//
//
//
//
//
//
//

import { AlertLevels } from 'utils/constants';

export default {
	props: {
		type: {
			type: String,
			required: true
		}
	},

	computed: {
		color() {
			if (this.type === AlertLevels.SUCCESS) {
				return 'success';
			}

			if (this.type === AlertLevels.WARNING) {
				return 'orange';
			}

			if (this.type === AlertLevels.ERROR) {
				return 'error';
			}

			return 'grey darken-2';
		},

		icon() {
			if (this.type === AlertLevels.SUCCESS) {
				return 'check_circle';
			}

			if (this.type === AlertLevels.WARNING) {
				return 'warning';
			}

			if (this.type === AlertLevels.ERROR) {
				return 'error';
			}

			return 'warning';
		}
	}
};

