/** Get billing plans. */
export const PLANS_GET = 'PLANS_GET';

/** Load values for Payment Method's Billing Address. */
export const PAYMENT_METHOD_GET = 'PAYMENT_METHOD_GET';

/** Add Payment method. */
export const PAYMENT_METHOD_POST = 'PAYMENT_METHOD_POST';

/** Initialize Braintree Credit Card form. */
export const BRAINTREE_DROPIN_CREATE = 'BRAINTREE_DROPIN_CREATE';

/** Show general and field errors in UI. */
export const ERRORS_SHOW = 'ERRORS_SHOW';

/** Navigate back to main billing page. */
export const BILLING_NAVIGATE = 'BILLING_NAVIGATE';
