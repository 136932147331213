import utils from 'utils/index';
import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { EmailStatisticsException } from './helpers';

/**
 * @param {Object} params
 * @param {Date} params.start
 * @param {Date} params.end
 * @return {String}
 */
const getUserEmailStatisticsUrl = ({ start, end }) => {
	const url = new URL(endpoints.userEmailStatistics);

	url.searchParams.append('start', utils.getDateString(start));
	url.searchParams.append('end', utils.getDateString(end));

	return url.toString();
};

/**
 * @param {Object} params
 * @param {String} params.token
 * @param {Date} params.start
 * @param {Date} params.end
 * @return {Promise<Object>}
 */
async function httpGet({ token, start, end }) {
	const result = await request.get(getUserEmailStatisticsUrl({ start, end }), token);

	if (!result.success) {
		return Promise.reject(new EmailStatisticsException(result));
	}

	return result.data;
}

export {
	httpGet
};
