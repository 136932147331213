import {
	EDIT_EMAIL,
	SET_SENT,
	SET_ERRORS
} from './mutationTypes';

export default {
	// Mutations of /passwordless
	[EDIT_EMAIL](state, email) {
		state.fields.email.value = email;
		state.fields.email.error = null;
		state.errors = [];
	},

	[SET_ERRORS](state, errors) {
		const fieldKeys = Object.keys(state.fields);
		const generalErrors = [];

		errors.forEach(error => {
			if (fieldKeys.includes(error.context)) {
				state.fields[error.context].error = error.message;
			} else {
				generalErrors.push(error);
			}
		});

		state.errors = generalErrors;
	},

	[SET_SENT](state) {
		state.sent = true;
	}
};
