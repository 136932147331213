import { TYPES } from './helpers';
import { httpGet } from './httpGet';
import { httpPut } from './httpPut';
import { httpPost } from './httpPost';
import { httpDelete } from './httpDelete';

import { options } from './options';
import { sync } from './sync';

const automations = {
	get: httpGet,
	put: httpPut,
	post: httpPost,
	delete: httpDelete,
	options,
	sync
};

export {
	automations,
	TYPES
};
