import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { MappingsException } from './helpers';

/**
 * @param {String} token
 * @param {String} listId
 * @return {Promise<Object>}
 */
const httpGet = async (token, listId) => {
	const result = await request.get(endpoints.userListsIdMappings(listId), token);

	if (!result.success) {
		return Promise.reject(new MappingsException(result));
	}

	return result.data;
};

export {
	httpGet
};
