import { subDays } from 'date-fns';
import { api } from 'api';
import logger from 'utils/logger';
import { EMAIL_STATISTICS_PERIOD } from 'utils/constants/email-health';
import {
	EMAIL_STATISTICS_GET
} from './actionTypes';

import {
	EMAIL_STATISTICS,
	ERRORS
} from './mutationTypes';

export const actions = {
	[EMAIL_STATISTICS_GET]: async ({ commit, rootState: { token } }) => {
		try {
			const end = new Date();
			const start = subDays(end, EMAIL_STATISTICS_PERIOD);
			const { counts: emailStatistics } = await api.user
				.emailStatistics.get({ token, start, end });

			commit(EMAIL_STATISTICS, emailStatistics);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
