import { httpGet } from './httpGet';
import { httpPut } from './httpPut';
import { httpPost } from './httpPost';
import { httpDelete } from './httpDelete';

export const mappings = {
	get: httpGet,
	put: httpPut,
	post: httpPost,
	delete: httpDelete
};
