import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import getInitialState from './state';

export default {
	namespaced: true,
	state: getInitialState(),
	actions,
	mutations,
	getters
};
