const FIELD_ERRORS = {
	html_missing: 'Please add valid HTML',
	subject_missing: 'Please add a Subject',
	fromName_missing: 'Please add a From Name',
	fromEmail_missing: 'Please add a From Email',
	unsubscribe_field_missing: 'Email has to include an unsubscribe link (To fix, add {{unsubscribe_url}} to the footer)',
	html_size_invalid: 'Too large HTML. Must be less than 1 MB',
	opportunity_field_not_allowed: 'Opportunity merge fields can only be used with opportunity tags. To fix, please remove opportunity merge fields from the email or change the target segment to an opportunity tag.',
	default: 'Some fields are missing'
};

function getRequiredFields(emailOptions) {
	const isFieldRequired = ([, option]) => option.required;

	return Object
		.entries(emailOptions)
		.filter(isFieldRequired);
}

function areRequiredFieldsFilled(requiredFields, email) {
	return requiredFields.every(([fieldId]) => {
		if (fieldId === 'html') {
			return true;
		}

		return (email[fieldId] && email[fieldId].length);
	});
}

function getMissingFieldErrors(email, emailOptions) {
	const requiredFields = getRequiredFields(emailOptions);

	return requiredFields
		.filter(([fieldId]) => !email[fieldId] && fieldId !== 'html')
		.map(([fieldId]) => `${fieldId}_missing`);
}

function getFieldErrors(email, emailOptions) {
	const fieldErrors = getMissingFieldErrors(email, emailOptions);

	if (!email.hasUnsubscribeField) {
		fieldErrors.push('unsubscribe_field_missing');
	}

	if (!email.validHtmlSize) {
		fieldErrors.push('html_size_invalid');
	}

	if (!email.isDealFieldAllowed) {
		fieldErrors.push('opportunity_field_not_allowed');
	}

	return fieldErrors;
}

export function isValidSequenceEmail(email, emailOptions) {
	const requiredFields = getRequiredFields(emailOptions);

	return (
		areRequiredFieldsFilled(requiredFields, email)
		&& email.validHtml && email.isDealFieldAllowed
	);
}

export function getValidationErrorMessage(email, emailOptions) {
	const fieldErrors = getFieldErrors(email, emailOptions);
	let message = '';

	if (fieldErrors.length > 1) {
		message = 'Several required fields are missing';
	} else if (fieldErrors.length === 1) {
		message = FIELD_ERRORS[fieldErrors[0]] || FIELD_ERRORS.default;
	}

	return message;
}
