import { schedule } from './schedule';
import { replyTracking } from './reply-tracking';
import { domains } from './domains';
import { footer } from './footer';

export const email = {
	schedule,
	replyTracking,
	footer,
	domains
};
