import moment from 'moment';

import {
	SUBSCRIPTION_STATUS
} from 'api/billing/helpers';

import {
	getDowngradeDialogContent
} from 'utils/billing/helpers';
import { ConnectionStatuses } from 'utils/constants/connections';

const getters = {
	isTrialActive(state) {
		return state.subscription?.status === SUBSCRIPTION_STATUS.IN_TRIAL;
	},

	isTrialEnded(state) {
		return (state.subscription?.status === SUBSCRIPTION_STATUS.CANCELLED);
	},

	trialDaysRemaining({ subscription }) {
		return moment(subscription.trial_end).diff(moment(), 'days');
	},

	activePlan({ subscription }) {
		return subscription?.plan_id;
	},

	currentPlan({ subscription, plans }) {
		return plans.find(plan => plan.id === subscription?.plan_id);
	},

	isActiveContactsPlan({ subscription = {} }) {
		if (!subscription.plan_id) {
			return false;
		}

		const { plan_id: planId } = subscription;

		return planId.startsWith('basic') || planId.startsWith('professional');
	},

	downgradeDialogContent({ selectedPlanToDowngrade }, { currentPlan }) {
		return getDowngradeDialogContent(currentPlan, selectedPlanToDowngrade);
	},

	hasActiveConnection({ connections = [] }) {
		return connections.some(connection => connection.status === ConnectionStatuses.ACTIVE);
	}
};

export default getters;
