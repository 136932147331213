import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { EngagementSyncRulesException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {String} params.id
 * @param {String} params.token
 * @return {Promise<Object>}
 */
export const httpDelete = async ({
	connectionId,
	id,
	token
}) => {
	const response = await request.delete(
		endpoints.connectionsIdEngagementSyncRulesId(connectionId, id),
		token
	);

	if (!response.success) {
		return Promise.reject(new EngagementSyncRulesException(response));
	}

	return response.data;
};
