import { api } from 'api';
import logger from 'utils/logger';
import { EventNames, track } from 'utils/analytics';
import {
	CONNECTIONS_GET,
	LEAD_SCORE_SETTINGS_GET,
	WEB_TRACKING_COUNTERS_GET,
	ANALYTICS_TRACK_CTA
} from './actionTypes';
import {
	CONNECTIONS_COUNT,
	LAST_SYNC_STATS,
	LEAD_SCORE_SETTINGS,
	ERRORS,
	WEB_TRACKING_COUNTERS
} from './mutationTypes';

const isAfter = (date1, date2) => {
	if (!date1) {
		return false;
	}

	if (!date2) {
		return true;
	}

	return date1 > date2;
};

const calculateAllEventsCount = stats => {
	if (!stats?.counts) {
		return 0;
	}

	const { counts } = stats;

	const emailEngagementEventSentCount = counts.emailEngagementEventSentCount ?? 0;
	const emailEngagementEventOpenCount = counts.emailEngagementEventOpenCount ?? 0;
	const emailEngagementEventClickCount = counts.emailEngagementEventClickCount ?? 0;
	const emailEngagementEventBounceCount = counts.emailEngagementEventBounceCount ?? 0;
	const emailEngagementEventUnsubscribeCount = counts.emailEngagementEventUnsubscribeCount ?? 0;
	const formEventsCount = counts.formEventsCount ?? 0;
	const meetingEventsCount = counts.meetingEventsCount ?? 0;

	return emailEngagementEventSentCount
		+ emailEngagementEventOpenCount
		+ emailEngagementEventClickCount
		+ emailEngagementEventBounceCount
		+ emailEngagementEventUnsubscribeCount
		+ formEventsCount
		+ meetingEventsCount;
};

export default {
	[CONNECTIONS_GET]: async ({
		commit,
		rootState: { token }
	}) => {
		try {
			const connections = await api.connections.get({ token });

			if (!connections?.length) {
				return;
			}

			commit(CONNECTIONS_COUNT, connections?.length);

			const lastSyncStats = {
				latestEventAt: null,
				allEventsCount: 0
			};

			await Promise.all(connections.map(async connection => {
				const connectionStatusOptions = await api.connections.connectionStatus.options.get({
					connectionId: connection._id,
					token
				});

				const stats = await api.connections.stats.get({
					connectionId: connection._id,
					period: connectionStatusOptions?.period?.default,
					token
				});

				if (isAfter(stats?.latestEventAt, lastSyncStats.latestEventAt)) {
					lastSyncStats.latestEventAt = stats.latestEventAt;
				}

				const allEventsCount = calculateAllEventsCount(stats);

				lastSyncStats.allEventsCount += allEventsCount;
			}));

			commit(LAST_SYNC_STATS, lastSyncStats);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[LEAD_SCORE_SETTINGS_GET]: async ({ commit, rootState: { token } }) => {
		try {
			const settingsRaw = await api.user.settings.leadScoring.get(token);

			const settings = {
				active: settingsRaw.active,
				leadsScored: settingsRaw.counters?.total
			};

			commit(LEAD_SCORE_SETTINGS, settings);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[WEB_TRACKING_COUNTERS_GET]: async ({ rootState: { token }, commit }) => {
		try {
			const counters = await api.tracking.counters.get(token);

			commit(WEB_TRACKING_COUNTERS, counters);
		} catch (exception) {
			if (exception.errors) {
				return commit(ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ANALYTICS_TRACK_CTA]: (context, ctaAction) => {
		track({
			event: EventNames.DASHBOARD_CTA_CLICKED,
			properties: {
				clickedCta: ctaAction
			}
		});
	}
};
