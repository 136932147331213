import Sentry from 'utils/sentry';
import utils from '../index';
import logger from '../logger';

/**
 * @param {String} token
 * @return {String}
 */
function getAuthorizationHeader(token) {
	return `Bearer ${token}`;
}

export const METHODS = {
	GET: 'GET',
	PUT: 'PUT',
	PATCH: 'PATCH',
	POST: 'POST',
	DELETE: 'DELETE'
};

/**
 * Get request headers for JSON type.
 * @param {String} token - Authorization token.
 * @return {Object}
 */
const getJSONHeaders = token => {
	const headers = {
		'Content-Type': 'application/json',
		'X-Client-Name': 'webapp',
		'X-Client-Version': utils.getClientVersion()
	};

	if (token) {
		headers.Authorization = getAuthorizationHeader(token);
	}

	if (utils.isLoggedInAsUser()) {
		headers['X-Impersonated-Session'] = utils.isLoggedInAsUser();
	}

	return headers;
};

/**
 * Get request headers for File type.
 * @param {String} token - Authorization token.
 * @return {Object}
 */
const getFileHeaders = token => {
	const headers = {
		'X-Client-Name': 'webapp',
		'X-Client-Version': utils.getClientVersion()
	};

	if (token) {
		headers.Authorization = getAuthorizationHeader(token);
	}

	return headers;
};

/**
 * Get request body for JSON type.
 * @param {Object} requestBody
 * @return {Object}
 */
function getJSONBody(requestBody) {
	if (!requestBody) {
		return null;
	}

	let body = null;

	try {
		body = JSON.stringify(requestBody);
	} catch (exception) {
		logger.error('Failed to stringify request body data', exception);
	}

	return body;
}

const FILE_KEYS = ['fileSize', 'file'];

/**
 * Get request body for File type.
 * @param {Object} fileData
 * @param {{
 * size: Number
 * }} fileData.file
 * @return {Object}
 */
function getFileBody(fileData) {
	const body = new FormData();

	Object.keys(fileData)
		.filter(key => !FILE_KEYS.includes(key))
		.forEach(
			key => body.append(key, fileData[key])
		);

	const { file } = fileData;

	if (!file) {
		logger.error('No file provided');

		return body;
	}

	body.append('fileSize', file.size || 0);
	body.append('file', file);

	return body;
}

/**
 * @param {Object} params
 * @param {String} params.method
 * @param {String} params.url
 * @param {Object} params.requestBody
 * @param {String} params.token
 * @param {Boolean} params.isFileUpload
 * @param {Object} params.headers
 * @return {Request}
 */
function createRequest({
	method,
	url,
	requestBody,
	token,
	isFileUpload,
	headers
}) {
	if (isFileUpload) {
		return new window.Request(url, {
			method,
			headers: getFileHeaders(token),
			body: getFileBody(requestBody)
		});
	}

	return new window.Request(url, {
		method,
		headers: {
			...headers,
			...getJSONHeaders(token)
		},
		body: getJSONBody(requestBody)
	});
}

/**
 * Wrapper around native fetch to make authenticated HTTP requests
 * with JSON content type.
 * @param {Object} options
 * @param {String} options.method
 * @param {String} options.url
 * @param {Object} options.data
 * @param {String} options.token
 * @param {Boolean} options.isFileUpload
 * @param {Object} options.headers
 */
export async function fetch({
	method, url, data, token, isFileUpload = false, headers
}) {
	const request = createRequest({
		requestBody: data,
		method,
		url,
		token,
		isFileUpload,
		headers
	});

	let responseData = {};

	Sentry.addBreadcrumb({ message: `${method} ${url}` });

	try {
		const response = await window.fetch(request);

		responseData = response.json();
	} catch (err) {
		throw new Error(`Request failed: ${method} ${url} ${err}`);
	}

	return responseData;
}
