//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ROUTE_NAMES from 'router/route-names';

const EVENT_DISMISS = 'dismiss';

export default {
	data: () => ({
		contactsRoute: { name: ROUTE_NAMES.CONTACTS },
		accountIntegrationsRoute: { name: ROUTE_NAMES.ACCOUNT_INTEGRATIONS },
		// eslint-disable-next-line
		deliverabilityUrl: 'https://support.outfunnel.com/en/articles/3155138-how-to-improve-deliverability-by-adding-custom-domain-authentication-dkim-and-spf-for-outfunnel',
		sequencesUrl: 'https://support.outfunnel.com/en/articles/3245426-how-to-use-our-email-sequences-feature'
	}),

	methods: {
		onDismiss() {
			this.$emit(EVENT_DISMISS);
		}
	}
};
