//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_NAVIGATE = 'navigate';
const EVENT_CONFIRM = 'confirm';

export default {
	props: {
		steps: {
			type: Array,
			required: true
		},
		actions: {
			type: Array,
			required: true
		},
		currentStep: {
			type: String,
			required: true
		},
		isNextStepDisabled: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		currentAction() {
			return this.actions.find(action => (action.id === this.currentStep));
		}
	},

	methods: {
		isCurrentStep(id) {
			return (id === this.currentStep);
		},

		isStepDisabled(id) {
			const currentStep = this.steps.find(item => item.id === this.currentStep);
			const navigateStep = this.steps.find(item => item.id === id);
			const isNextStep = navigateStep.step > currentStep.step;

			return isNextStep && this.isNextStepDisabled;
		},

		navigate(id) {
			if (this.isStepDisabled(id) || this.isCurrentStep(id)) {
				return;
			}

			this.$emit(EVENT_NAVIGATE, id);
		},

		handleAction({ type, target }) {
			if (type === 'navigate') {
				this.navigate(target);
			} else if (type === 'confirm') {
				this.$emit(EVENT_CONFIRM);
			}
		}
	}
};

