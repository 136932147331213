import getInitialState from './state';

import {
	SEQUENCE_ID,
	SEQUENCE,
	EMAILS,
	QUEUED_EMAILS,
	SORT_ORDER,
	SORT_BY,
	PAGINATION,
	GENERAL_ERRORS,
	RESET
} from './mutationTypes';

export default {
	[SEQUENCE_ID](state, sequenceId) {
		state.sequenceId = sequenceId;
	},

	[SEQUENCE](state, sequence) {
		state.sequence = sequence;
	},

	[EMAILS](state, emails) {
		state.emails = emails;
	},

	[QUEUED_EMAILS](state, queuedEmails) {
		state.queuedEmails = queuedEmails;
	},

	[SORT_ORDER](state, sortOrder) {
		state.sortOrder = sortOrder;
	},

	[SORT_BY](state, sortBy) {
		state.sortBy = sortBy;
	},

	[PAGINATION](state, pagination) {
		state.pagination = pagination;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	}
};
