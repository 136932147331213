//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_RESEND_VERIFICATION_EMAIL = 'click:resend';

export default {
	inheritAttrs: false,
	props: {
		label: {
			type: String,
			default: 'Email'
		},
		name: {
			type: String,
			default: 'email'
		},
		value: {
			type: String,
			default: ''
		},
		isEmailVerified: {
			type: Boolean,
			default: false
		},
		verifiedTooltipText: {
			type: String,
			default: 'Email is verified'
		},
		nonVerifiedTooltipText: {
			type: String,
			default: 'Email is not verified'
		},
		resendButtonText: {
			type: String,
			default: 'Resend'
		},
		resendTooltipText: {
			type: String,
			default: 'Resend verification email'
		}
	},
	methods: {
		onClickResend() {
			this.$emit(EVENT_RESEND_VERIFICATION_EMAIL, this.value);
		}
	}
};
