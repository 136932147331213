import { BaseException } from 'utils/error';

/**
 * @class
 */
class BillingException extends BaseException {
	/**
	 * @param {{errors: Object[]}} result
	 */
	constructor(result) {
		super(result);
		this.name = 'BillingException';
	}
}

const NOT_SUBSCRIBED = 'not_subscribed';
const DELETED = 'deleted';
const CANCELLED = 'cancelled';
const IN_TRIAL = 'in_trial';
const ACTIVE = 'active';
const NON_RENEWING = 'non_renewing';
const UNKNOWN = 'unknown';

const SUBSCRIPTION_STATUS = {
	NOT_SUBSCRIBED,
	DELETED,
	CANCELLED,
	IN_TRIAL,
	ACTIVE,
	NON_RENEWING,
	UNKNOWN
};

/**
 * @param {String} subscription
 * @return {String}
 */
function getSubscriptionText(subscription) {
	let text = 'You don\'t have an active subscription';

	const statusMap = {
		[NOT_SUBSCRIBED]: 'You don\'t have an active subscription.',
		[DELETED]: 'Subscription is deleted.',
		[CANCELLED]: 'Subscription has been cancelled.',
		[IN_TRIAL]: 'Subscription is in Trial.',
		[ACTIVE]: 'Subscription is Active.',
		[NON_RENEWING]: 'Subscription is non-renewing.'
	};

	if (subscription.cancelled && subscription.status === IN_TRIAL) {
		text = 'Subscription has been scheduled for cancellation';
	} else if (statusMap[subscription.status]) {
		text = statusMap[subscription.status];
	}

	return text;
}

const getNextPayment = subscription => {
	if (subscription.status === SUBSCRIPTION_STATUS.ACTIVE) {
		return `Next billing: $${(subscription.upcoming_invoice_estimate / 100).toFixed(2)} (${subscription.next_billing_formatted})`;
	}

	if (subscription.status === SUBSCRIPTION_STATUS.IN_TRIAL) {
		return `End of trial: ${subscription.trial_end_formatted}`;
	}

	if (subscription.status === SUBSCRIPTION_STATUS.NON_RENEWING) {
		return `End of subscription: ${subscription.trial_end_formatted}`;
	}

	if (subscription.status === SUBSCRIPTION_STATUS.CANCELLED) {
		return `Subscription is active until ${subscription.active_until_formatted}`;
	}

	return '';
};

const cancelableStatuses = [
	IN_TRIAL,
	ACTIVE
];

/**
 * @param {String} status
 * @return {Boolean}
 */
function isCancelableSubscriptionStatus(status) {
	return cancelableStatuses.includes(status);
}

export {
	BillingException,
	SUBSCRIPTION_STATUS,
	getSubscriptionText,
	getNextPayment,
	isCancelableSubscriptionStatus
};
