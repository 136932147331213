//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


/**
 * @constant {{
 * UNVERIFIED: String,
 * VERIFIED: String,
 * FAILED: String
 * }}
 */
const VerificationStatuses = Object.freeze({
	UNVERIFIED: 'unverified',
	VERIFIED: 'verified',
	FAILED: 'failed'
});

export default {
	props: {
		verificationStatus: {
			type: String,
			default: 'unverified'
		}
	},

	computed: {
		isUnverified() {
			return this.verificationStatus === VerificationStatuses.UNVERIFIED;
		},

		isVerified() {
			return this.verificationStatus === VerificationStatuses.VERIFIED;
		},

		isVerificationFailed() {
			return this.verificationStatus === VerificationStatuses.FAILED;
		}

	}
};

