import { groupErrors } from 'utils/error';

import getInitialState from './state';
import {
	COUNTERS,
	CRM_RULE_ERRORS,
	LOADING,
	OPTIONS,
	SETTINGS,
	RULES,
	ADD_RULE,
	FIELDS,
	VISIBLE_FIELDS,
	ERRORS,
	RESET,
	RULE_ERRORS,
	CRM_FIELD_ERRORS,
	LEAD_SCORE_RULES_LIMIT,
	ACTIVITY_TYPES,
	LEAD_SCORE_CRM_ACTIVITIES_ENABLED,
	LEAD_SCORE_INSTRUCTION_BOX,
	CONTACTS_FIELDS
} from './mutationTypes';

export default {
	[COUNTERS](state, counters) {
		state.counters = counters;
	},

	[CRM_RULE_ERRORS](state, errors) {
		state.crmRuleErrors = errors;
	},

	[CRM_FIELD_ERRORS](state, errors) {
		state.crmFieldRuleErrors = errors;
	},

	[LOADING](state, isLoading) {
		state.isLoading = isLoading;
	},

	[LEAD_SCORE_RULES_LIMIT](state, leadScoreRulesLimit) {
		state.leadScoreRulesLimit = leadScoreRulesLimit;
	},

	[OPTIONS](state, options) {
		state.options = options;
	},

	[SETTINGS](state, settings) {
		state.settings = settings;
	},

	[RULES](state, rules) {
		Object.assign(state.settings, rules);
	},

	[ADD_RULE](state, rule) {
		state.settings.rules.push(rule);
	},

	[FIELDS](state, fields) {
		Object.assign(state.settings, fields);
	},

	[VISIBLE_FIELDS](state, fields) {
		state.visibleFields = fields;
	},

	[RULE_ERRORS](state, errors) {
		state.ruleErrors = errors;
	},

	[ERRORS](state, errors) {
		const { visibleFields } = state;
		const { generalErrors, contextualErrors } = groupErrors(
			errors, visibleFields
		);
		const hiddenContextualErrors = contextualErrors.filter(error => error.context !== 'active');

		contextualErrors.forEach(error => {
			state.fieldErrors[error.context] = error.message;
		});

		state.generalErrors = [...generalErrors, ...hiddenContextualErrors];
	},

	[RESET](state) {
		Object.assign(state, getInitialState());
	},

	[ACTIVITY_TYPES](state, activityTypes) {
		state.activityTypes = activityTypes;
	},

	[LEAD_SCORE_CRM_ACTIVITIES_ENABLED](state, leadScoringCrmActivitiesEnabled) {
		state.leadScoringCrmActivitiesEnabled = leadScoringCrmActivitiesEnabled;
	},

	[LEAD_SCORE_INSTRUCTION_BOX](state, leadScoreInstructionBoxState) {
		state.leadScoreInstructionBoxState = leadScoreInstructionBoxState;
	},

	[CONTACTS_FIELDS](state, contactsFields) {
		state.contactsFields = contactsFields;
	}
};
