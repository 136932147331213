//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
	ContactSourceNames,
	EngagementSourceNames,
	EngagementSourceSegmentNames
} from 'utils/constants';
import { isUndefined } from 'utils/types';
import RefreshListsButton from './RefreshListsButton.vue';

const EVENT_CHANGE_CONTACT_CREATION_RULE = 'change:contact-creation-rule';
const EVENT_DELETE_CONTACT_CREATION_RULE = 'delete:contact-creation-rule';
const EVENT_REFRESH_FIELDS = 'refresh:fields';

export default {
	components: {
		RefreshListsButton
	},
	props: {
		contactCreationRules: {
			type: Array,
			default: () => ([])
		},
		contactSource: {
			type: String,
			default: 'CRM'
		},
		engagementSource: {
			type: String,
			default: 'Email tool'
		},
		learnMoreLink: {
			type: String,
			required: true
		},
		sourceSegments: {
			type: Array,
			default: () => ([])
		},
		destinationUsers: {
			type: Array,
			default: () => ([])
		},
		isRefreshFieldsInProgress: {
			type: Boolean,
			default: false
		},
		isConnectorV3Enabled: {
			type: Boolean,
			required: true
		}
	},

	data: () => ({
		isEmptyRuleSelectorVisible: false,
		selectedSourceSegmentId: null,
		selectedDestinationUserId: -1
	}),

	computed: {
		contactSourceName() {
			return ContactSourceNames[this.contactSource.toUpperCase()];
		},

		engagementSourceName() {
			return EngagementSourceNames[this.engagementSource];
		},

		sourceSegmentLabel() {
			const contactSourceSegmentName = EngagementSourceSegmentNames[this.engagementSource];

			return `${this.engagementSourceName} ${contactSourceSegmentName}`;
		},

		destinationUsersLabel() {
			return 'New contact owner';
		},

		isRuleCreated() {
			if (this.isConnectorV3Enabled) {
				return false;
			}

			return Boolean(this.contactCreationRules.length);
		}
	},

	methods: {
		getSegmentText({ name, contactsCount }) {
			if (isUndefined(contactsCount) || contactsCount === 0) {
				return name;
			}

			return `${name} (${contactsCount})`;
		},

		getDestinationUserText({ name }) {
			return name;
		},

		onAddNewRule() {
			this.isEmptyRuleSelectorVisible = true;
		},

		onEditNewRuleSourceSegment({ segmentId }) {
			if (!this.selectedDestinationUserId) {
				this.selectedSourceSegmentId = segmentId;

				return;
			}

			const ruleData = {
				sourceSegmentId: segmentId,
				destinationUserId: this.selectedDestinationUserId
			};

			this.$emit(
				EVENT_CHANGE_CONTACT_CREATION_RULE,
				{ ruleData }
			);

			this.isEmptyRuleSelectorVisible = false;
		},

		onEditExistingRuleSourceSegment({ contactCreationRuleId, segmentId }) {
			const contactCreationRule = this.contactCreationRules.find(({ _id }) => _id === contactCreationRuleId);
			const ruleData = {
				sourceSegmentId: segmentId,
				destinationUserId: contactCreationRule.destinationUserId
			};

			this.$emit(
				EVENT_CHANGE_CONTACT_CREATION_RULE,
				{ contactCreationRuleId, ruleData }
			);
		},

		onEditNewRuleDestinationUser({ destinationUserId }) {
			if (!this.selectedSourceSegmentId) {
				this.selectedDestinationUserId = destinationUserId;

				return;
			}

			const ruleData = {
				sourceSegmentId: this.selectedSourceSegmentId,
				destinationUserId
			};

			this.$emit(
				EVENT_CHANGE_CONTACT_CREATION_RULE,
				{ ruleData }
			);

			this.isEmptyRuleSelectorVisible = false;
		},

		onEditExistingRuleDestinationUser({ contactCreationRuleId, destinationUserId }) {
			const contactCreationRule = this.contactCreationRules.find(({ _id }) => _id === contactCreationRuleId);
			const ruleData = {
				sourceSegmentId: contactCreationRule.sourceSegmentId,
				destinationUserId
			};

			this.$emit(
				EVENT_CHANGE_CONTACT_CREATION_RULE,
				{ contactCreationRuleId, ruleData }
			);
		},

		onDeleteRule({ contactCreationRuleId }) {
			this.$emit(
				EVENT_DELETE_CONTACT_CREATION_RULE,
				{ contactCreationRuleId }
			);
		},

		onDeleteNewRule() {
			this.isEmptyRuleSelectorVisible = false;
		},

		refreshFields() {
			this.$emit(EVENT_REFRESH_FIELDS);
		}
	}
};

