//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	props: {
		webTrackingActive: {
			type: Boolean,
			required: true
		}
	}
};
