//
//
//
//
//
//

import { mapErrorMessage } from 'utils/error';
import VRuntimeTemplate from 'v-runtime-template';

export default {
	components: {
		VRuntimeTemplate
	},
	props: {
		error: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		errorMessage() {
			return mapErrorMessage(this.error);
		}
	}
};
