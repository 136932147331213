import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { SubscriptionException } from './helpers';

/**
 * @param {String} token
 * @param {Object} subscription
 * @return {Promise<Object>}
 */
async function httpPut(token, subscription) {
	const result = await request.put(endpoints.billingSubscription, subscription, token);

	if (!result.success) {
		return Promise.reject(new SubscriptionException(result));
	}

	return result.data;
}

export {
	httpPut
};
