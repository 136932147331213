const getInitialState = () => ({
	pipedriveToMailchimpList: [],
	mailchimpToPipedriveList: [],
	copperToMailchimpList: [],
	mailchimpToCopperList: [],
	mailchimpToHubspotList: [],
	leadScoringSettings: {
		counters: {}
	},
	webTrackingSettings: {
		counters: {}
	},
	generalErrors: []
});

export default getInitialState;
