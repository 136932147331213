import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { SequenceEmailCampaignsException } from './helpers';

/**
 * Create new Sequence Email Campaign.
 * @param {String} token - User's JWT authorization token.
 * @param {Object} data - Fields of a new Sequence email campaign.
 */
async function httpPost(token, data) {
	const result = await request.post(endpoints.sequenceEmailCampaigns, data, token);

	if (!result.success) {
		return Promise.reject(new SequenceEmailCampaignsException(result));
	}

	return result.data;
}

export {
	httpPost
};
