import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { EngagementSyncRulesException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {String} params.token
 * @return {Promise<Object[]>}
 */
export const httpGet = async ({ connectionId, token }) => {
	const response = await request.get(
		endpoints.connectionsIdEngagementSyncRules(connectionId),
		token
	);

	if (!response.success) {
		return Promise.reject(new EngagementSyncRulesException(response));
	}

	return response.data;
};
