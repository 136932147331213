import { api } from 'api';
import { TYPES } from 'api/automations';
import { isBoolean } from 'utils/types';
import router from 'router';
import ROUTE_NAMES from 'router/route-names';
import logger from 'utils/logger';
import utils from 'utils';

import SHOW_NOTIFICATION from 'store/modules/notifications/actionTypes';

import {
	MODE,
	OPTIONS,
	AUTOMATION,
	LIST,
	OPTION,
	STATUS,
	GENERAL_ERRORS
} from './mutationTypes';

import {
	OPTIONS_GET,
	AUTOMATION_GET,
	AUTOMATION_POST,
	AUTOMATION_PUT,
	OPTION_CHANGE,
	LIST_SELECT,
	SCHEDULE_SYNC,
	POST_SCHEDULE_SYNC,
	DELETE
} from './actionTypes';

import {
	MODE as PAGE_MODE,
	STATUS_TYPE
} from '../types';

export const actions = {
	[OPTIONS_GET]: async ({
		commit,
		state,
		rootState: { token }
	}) => {
		try {
			const options = await api.automations.options.get(token, TYPES.MAILCHIMP_TO_HUBSPOT);

			commit(OPTIONS, options);

			if (state.mode === PAGE_MODE.CREATE) {
				const booleanOptions = Object
					.entries(options)
					.filter(field => isBoolean(field[1]));

				booleanOptions.forEach(([key, value]) => {
					commit(OPTION, { [key]: value });
				});
			}
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}
		}
	},

	[AUTOMATION_GET]: async ({
		commit,
		rootState: { token }
	}, id) => {
		try {
			const automation = await api.automations.get(token, TYPES.MAILCHIMP_TO_HUBSPOT, id);

			commit(AUTOMATION, automation);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[AUTOMATION_POST]: async ({
		commit,
		rootState: { token }
	}, options) => {
		commit(STATUS, { type: STATUS_TYPE.SAVING, message: 'Saving' });

		const data = {
			type: TYPES.MAILCHIMP_TO_HUBSPOT,
			...options
		};

		try {
			const automation = await api.automations.post(token, data);

			commit(AUTOMATION, automation);
			commit(MODE, PAGE_MODE.EDIT);
			commit(STATUS, { type: STATUS_TYPE.SAVED, message: 'Draft saved' });

			router.replace(automation._id);
		} catch (exception) {
			if (exception.errors) {
				commit(STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to create automation' });

				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[AUTOMATION_PUT]: async ({
		commit,
		state: { automation },
		rootState: { token }
	}, changes) => {
		commit(STATUS, { type: STATUS_TYPE.SAVING, message: 'Saving' });

		try {
			const updatedAutomation = await api.automations.put(token, automation._id, changes);

			commit(AUTOMATION, updatedAutomation);
			let message = 'Saved';

			if (!automation.active && updatedAutomation.active) {
				message = 'Saved and queued sync';
			} else if (!updatedAutomation.active) {
				message = 'Draft saved';
			}

			commit(STATUS, { type: STATUS_TYPE.SAVED, message });
		} catch (exception) {
			if (exception.errors) {
				commit(STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to save changes' });

				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[LIST_SELECT]: ({
		commit,
		dispatch,
		state: { mode }
	}, listId) => {
		commit(LIST, listId);

		const automationData = { list: listId };

		if (mode === PAGE_MODE.CREATE) {
			dispatch(AUTOMATION_POST, automationData);
		} else {
			dispatch(AUTOMATION_PUT, automationData);
		}
	},

	[OPTION_CHANGE]: ({
		commit,
		dispatch,
		state: { mode }
	}, option) => {
		commit(OPTION, option);
		const automationData = { ...option };

		if (mode === PAGE_MODE.CREATE) {
			dispatch(AUTOMATION_POST, automationData);
		} else {
			dispatch(AUTOMATION_PUT, automationData);
		}
	},

	[SCHEDULE_SYNC]: async ({
		dispatch,
		state: { automation }
	}) => {
		if (automation && automation.active) {
			dispatch(POST_SCHEDULE_SYNC);
		} else if (automation) {
			dispatch(AUTOMATION_PUT, { active: true });
		} else {
			dispatch(AUTOMATION_POST, { active: true });
		}

		dispatch(
			`notifications/${SHOW_NOTIFICATION}`,
			'Sync is queued',
			{ root: true }
		);

		await utils.delay(1);
		router.push({ name: ROUTE_NAMES.AUTOMATIONS });
	},

	[POST_SCHEDULE_SYNC]: async ({
		commit,
		state: { automation },
		rootState: { token }
	}) => {
		commit(STATUS, { type: STATUS_TYPE.SAVING, message: 'Scheduling sync' });

		try {
			const id = automation._id;
			const type = TYPES.MAILCHIMP_TO_HUBSPOT;

			await api.automations.sync.post(token, type, id);

			commit(STATUS, { type: STATUS_TYPE.SAVED, message: 'Sync queued' });
		} catch (exception) {
			if (exception.errors) {
				commit(STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to queue sync' });

				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[DELETE]: async ({
		commit,
		state: { automation },
		rootState: { token }
	}) => {
		try {
			commit(STATUS, { type: STATUS_TYPE.DELETING, message: 'Deleting' });
			await api.automations.delete(token, automation._id);
			router.push({ name: ROUTE_NAMES.AUTOMATIONS });
		} catch (exception) {
			if (exception.errors) {
				commit(STATUS, { type: STATUS_TYPE.FAILED, message: 'Failed to delete automation' });

				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
