import {
	DOMAINS,
	GENERAL_ERRORS
} from './mutationTypes';

export const mutations = {
	[DOMAINS](state, domains) {
		state.domains = domains;
	},

	[GENERAL_ERRORS](state, errors) {
		state.generalErrors = errors;
	}
};
