import { api } from 'api';
import logger from 'utils/logger';

import { COUNTERS_GET as PARENT_COUNTERS_GET } from '../actionTypes';
import { ActionTypes } from './actionTypes';
import { MutationTypes } from './mutationTypes';

export const actions = {
	[ActionTypes.COUNTERS_GET]: async ({ rootState: { token }, commit }) => {
		try {
			const counters = await api.tracking.counters.get(token);

			commit(MutationTypes.COUNTERS, counters);
		} catch (exception) {
			if (exception.errors) {
				return commit(MutationTypes.GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ActionTypes.FEATURES_GET]: async ({
		rootState: { token },
		commit
	}) => {
		try {
			const features = await api.user.features.get(token);

			commit(MutationTypes.FEATURES, features);
		} catch (exception) {
			if (exception.errors) {
				commit(MutationTypes.GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ActionTypes.TRACKING_VERIFY]: async ({
		commit,
		dispatch
	}) => {
		commit(MutationTypes.VERIFICATION_IN_PROGRESS, true);
		await dispatch(ActionTypes.COUNTERS_GET);
		await dispatch(`moduleWebTracking/${PARENT_COUNTERS_GET}`, null, { root: true });
		commit(MutationTypes.VERIFICATION_IN_PROGRESS, false);
	}
};
