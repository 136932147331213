export const SEQUENCE_ID = 'SEQUENCE_ID';
export const EMAIL_ID = 'EMAIL_ID';
export const EMAIL = 'EMAIL';
export const RECIPIENTS = 'RECIPIENTS';
export const CSV_DOWNLOAD_URL = 'CSV_DOWNLOAD_URL';
export const SORT_ORDER = 'SORT_ORDER';
export const SORT_BY = 'SORT_BY';
export const PAGINATION = 'PAGINATION';
export const GENERAL_ERRORS = 'GENERAL_ERRORS';
export const RESET = 'RESET';
