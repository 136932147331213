//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


const EVENT_NAVIGATE_BACK = 'navigate:back';

export default {
	props: {
		navigateBackText: {
			type: String,
			required: true
		}
	},
	methods: {
		navigateBack() {
			this.$emit(EVENT_NAVIGATE_BACK);
		}
	}
};

