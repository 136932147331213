//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
		disabled: {
			type: Boolean,
			default: false
		}
	}
};

