import { api } from 'api';
import logger from 'utils/logger';
import {
	getWebTrackingInstructionBoxState,
	setWebTrackingInstructionBoxState
} from 'utils/storage';
import router from 'router';
import ROUTE_NAMES from 'router/route-names';
import { MutationTypes } from './mutationTypes';
import { ActionTypes } from './actionTypes';

const VISITS_PER_PAGE = 50;

const InstructionBoxStates = {
	OPEN: 'open',
	CLOSED: 'closed'
};

export const actions = {
	[ActionTypes.MOUNTED]: async ({
		commit
	}) => {
		const instructionBoxState = getWebTrackingInstructionBoxState();

		const isVisible = instructionBoxState === InstructionBoxStates.OPEN;

		commit(MutationTypes.INSTRUCTIONS_VISIBLE, isVisible);
	},

	[ActionTypes.VISITS_GET]: async ({
		state,
		rootState: { token },
		commit,
		dispatch
	}, page = 1) => {
		await dispatch(ActionTypes.FEATURES_GET);

		if (!state.features?.webTracking?.enabled) {
			commit(MutationTypes.VISITS, []);

			return;
		}

		try {
			const { data, pagination } = await api.tracking.visits.get({
				token,
				limit: VISITS_PER_PAGE,
				page
			});

			commit(MutationTypes.VISITS, data);
			commit(MutationTypes.PAGINATION, pagination);
		} catch (exception) {
			if (exception.errors) {
				commit(MutationTypes.GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ActionTypes.FEATURES_GET]: async ({
		rootState: { token },
		commit
	}) => {
		try {
			const features = await api.user.features.get(token);

			commit(MutationTypes.FEATURES, features);
		} catch (exception) {
			if (exception.errors) {
				commit(MutationTypes.GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ActionTypes.COUNTERS_GET]: async ({ rootState: { token }, commit }) => {
		try {
			const counters = await api.tracking.counters.get(token);

			commit(MutationTypes.COUNTERS, counters);
		} catch (exception) {
			if (exception.errors) {
				return commit(MutationTypes.GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	[ActionTypes.NAVIGATE_SETUP]: () => {
		router.push({
			name: ROUTE_NAMES.WEB_TRACKING_SETUP
		});
	},

	[ActionTypes.INSTRUCTIONS_TOGGLE]: ({ state, commit }) => {
		const isVisible = !state.areInstructionsVisible;

		commit(MutationTypes.INSTRUCTIONS_VISIBLE, isVisible);

		const instructionBoxState = isVisible ? InstructionBoxStates.OPEN : InstructionBoxStates.CLOSED;

		setWebTrackingInstructionBoxState(instructionBoxState);
	}
};
