//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const EVENT_SAVE = 'save';
const EVENT_CLOSE = 'close';
const EVENT_CHANGE_AUTH_DATA = 'change:auth-data';

export default {
	props: {
		isVisible: {
			type: Boolean,
			default: false
		},
		authData: {
			type: Object,
			default: () => ({})
		},
		errors: {
			type: Array,
			default: () => ([])
		}
	},
	data: () => ({
		firstField: {
			id: 'apiKey',
			label: 'API Token'
		},
		apiKeysLink: 'https://dashboard.mailerlite.com/integrations/api',
		errorsToShow: {
			MAILERLITE_API_PERMISSION_DENIED: {
				error: 'mailerlite_api_permission_denied',
				message: 'Failed to authenticate with Mailerlite. Please check your API key and try again.',
				context: 'mailerlite'
			}
		}
	}),
	methods: {
		onSave() {
			this.$emit(EVENT_SAVE);
		},
		onClose() {
			this.$emit(EVENT_CLOSE);
		},
		onChangeAuthData(authData) {
			this.$emit(EVENT_CHANGE_AUTH_DATA, authData);
		},
		getErrorsToShow() {
			return this.errors.map(error => {
				if (error?.error === this.errorsToShow.MAILERLITE_API_PERMISSION_DENIED.error) {
					return this.errorsToShow.MAILERLITE_API_PERMISSION_DENIED;
				}

				return error;
			});
		}
	}
};
