export default {
	subscriptionPrice({ plans, subscription }) {
		const selectedPlan = plans.find(plan => plan.selected);
		let amount;

		if (selectedPlan && selectedPlan.price) {
			amount = selectedPlan.price;
		} else if (subscription && subscription.plan_price) {
			amount = subscription.plan_price;
		}

		return amount;
	}
};
