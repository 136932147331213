import { api } from 'api';
import router from 'router';
import utils from 'utils';
import logger from 'utils/logger';

import ROUTE_NAMES from 'router/route-names';

import {
	CAMPAIGN,
	CONVERSION,
	CSV_DOWNLOAD_URL,
	GENERAL_ERRORS,
	PAGINATION,
	RECIPIENTS,
	SORT_ORDER,
	SORT_BY
} from './mutationTypes';

import {
	LIST_NAVIGATE,
	RECIPIENT_NAVIGATE,
	CAMPAIGN_GET,
	CONVERSION_GET,
	RECIPIENTS_GET,
	RECIPIENTS_SORT
} from './actionTypes';

const RECIPIENTS_PER_PAGE = 20;

export default {
	[LIST_NAVIGATE]() {
		router.push({
			name: ROUTE_NAMES.CAMPAIGNS_SINGLE_EMAIL
		});
	},

	[RECIPIENT_NAVIGATE]({ state: { campaignId } }, recipientId) {
		router.push({
			name: ROUTE_NAMES.CONTACTS_PROFILE,
			params: {
				id: recipientId
			},
			query: {
				campaign: campaignId
			}
		});
	},

	async [CAMPAIGN_GET]({
		commit,
		state: { campaignId },
		rootState: { token }
	}) {
		try {
			const { data } = await api.singleEmailCampaigns.get({
				token,
				campaignId
			});

			commit(CAMPAIGN, data);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [CONVERSION_GET]({
		commit,
		state: { campaignId },
		rootState: { token }
	}) {
		try {
			const conversion = await api.singleEmailCampaigns.conversion.get({ token, campaignId });

			commit(CONVERSION, conversion);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [RECIPIENTS_GET]({
		commit,
		state: {
			campaignId, pagination, sortOrder, sortBy
		},
		rootState: { token }
	}, page = 1) {
		if (pagination.currentPage === page) {
			return;
		}

		try {
			const recipientsData = await api.singleEmailCampaigns.recipients.get(
				token,
				campaignId,
				RECIPIENTS_PER_PAGE,
				page,
				sortBy,
				sortOrder
			);

			commit(RECIPIENTS, recipientsData.data);
			commit(CSV_DOWNLOAD_URL, recipientsData.downloadUrl);
			commit(PAGINATION, recipientsData.pagination);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	},

	async [RECIPIENTS_SORT]({
		commit,
		state: {
			campaignId, pagination, sortBy, sortOrder
		},
		rootState: { token }
	}, newSortBy) {
		let newSortOrder = sortOrder;
		const isSameSortBy = sortBy === newSortBy;

		if (isSameSortBy) {
			newSortOrder = utils.reverseSortOrder(sortOrder);
		}

		try {
			const recipientsData = await api.singleEmailCampaigns.recipients.get(
				token,
				campaignId,
				RECIPIENTS_PER_PAGE,
				pagination.currentPage,
				newSortBy,
				newSortOrder
			);

			commit(SORT_BY, newSortBy);
			commit(SORT_ORDER, newSortOrder);
			commit(RECIPIENTS, recipientsData.data);
		} catch (exception) {
			if (exception.errors) {
				return commit(GENERAL_ERRORS, exception.errors);
			}

			logger.error(exception);
		}
	}
};
