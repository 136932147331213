import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { ListsException } from './helpers';

/**
 * @param {String} token
 * @return {Promise<Object>}
 */
const httpGet = async token => {
	const result = await request.get(endpoints.userLists, token);

	if (!result.success) {
		return Promise.reject(new ListsException(result));
	}

	return result.data;
};

export {
	httpGet
};
