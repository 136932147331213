import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { CredentialsException } from './helpers';

/**
 * POST email and password and get back JWT token if login is successful.
 * @param {Object} params
 * @param {String} params.email - User's email.
 * @param {String} params.password - User's password.
 * @param {String} params.captchaToken
 */
export const httpPost = async ({ email, password, captchaToken }) => {
	const result = await request.post(endpoints.auth.credentials, { email, password, captchaToken });

	if (!result.success) {
		return Promise.reject(new CredentialsException(result));
	}

	return result.data.token;
};
