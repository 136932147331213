//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
		addButtonText: {
			type: String,
			required: true
		},
		color: {
			type: String,
			required: false,
			default: 'primary'
		}
	}
};

