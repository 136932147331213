//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	props: {
		top: {
			type: Boolean,
			default: true
		},
		offsetY: {
			type: Boolean,
			default: true
		},
		iconName: {
			type: String,
			default: 'info'
		},
		iconSize: {
			type: String,
			default: '20'
		},
		iconClasses: {
			type: String,
			default: ''
		}
	}
};
