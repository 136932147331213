const apiUrl = process.env.API_URL;
const apiVersion = 'v1';

/**
 * Get full URL for given API path.
 * @param {String} apiPath Path of the API endpoint.
 * @return {String}
 */
const getUrl = apiPath => `${apiUrl}/${apiVersion}${apiPath}`;

const endpoints = {
	apps: getUrl('/apps'),
	auth: {
		credentials: getUrl('/auth/credentials'),
		oauthCode: getUrl('/auth/oauth-code'),
		passwordlessEmail: getUrl('/auth/passwordless/start'),
		passwordlessVerify: getUrl('/auth/passwordless/verify'),
		emailVerification: getUrl('/auth/email-verification'),
		emailVerificationResend: getUrl('/auth/email-verification/resend'),
		passwordResetEmail: getUrl('/auth/password-reset/start'),
		passwordChange: getUrl('/auth/password-reset/change')
	},
	connections: getUrl('/connections'),
	connectionsId: id => getUrl(`/connections/${id}`),
	connectionsOptions: getUrl('/connections/options'),
	connectionsContacts: connectionId => getUrl(`/connections/${connectionId}/contacts`),
	connectionsStatus: connectionId => getUrl(`/connections/${connectionId}/status`),
	connectionsIdContactSyncRules: connectionId => getUrl(`/connections/${connectionId}/contact-sync-rules`),
	connectionsIdContactCreationRules: connectionId => getUrl(`/connections/${connectionId}/contact-creation-rules`),
	connectionsIdContactCreationRulesId: (connectionId, contactCreationRuleId) => getUrl(`/connections/${connectionId}/contact-creation-rules/${contactCreationRuleId}`),
	connectionsIdContactCreationRuleOptions: connectionId => getUrl(`/connections/${connectionId}/contact-creation-rules/options`),
	connectionsIdContactSyncRulesId: (connectionId, contactSyncRuleId) => getUrl(`/connections/${connectionId}/contact-sync-rules/${contactSyncRuleId}`),
	connectionsIdFieldMappingsOptions: connectionId => getUrl(`/connections/${connectionId}/field-mappings/options`),
	connectionsIdFieldMappings: connectionId => getUrl(`/connections/${connectionId}/field-mappings`),
	connectionsIdFieldMappingsId: (connectionId, fieldMappingId) => getUrl(`/connections/${connectionId}/field-mappings/${fieldMappingId}`),
	connectionsIdEngagementSyncRules: connectionId => getUrl(`/connections/${connectionId}/engagement-sync-rules`),
	connectionsIdEngagementSyncRulesId: (connectionId, engagementSyncRuleId) => getUrl(`/connections/${connectionId}/engagement-sync-rules/${engagementSyncRuleId}`),
	connectionsIdEngagementSyncRuleOptions: connectionId => getUrl(`/connections/${connectionId}/engagement-sync-rules/options`),
	connectionsIdConnectionStatusOptions: connectionId => getUrl(`/connections/${connectionId}/connection-status/options`),
	connectionsIdStats: connectionId => getUrl(`/connections/${connectionId}/stats`),
	connectionsIdFeed: connectionId => getUrl(`/connections/${connectionId}/feed`),
	connectionsIdSettings: connectionId => getUrl(`/connections/${connectionId}/settings`),
	email: {
		triggers: getUrl('/email/triggers')
	},
	users: getUrl('/users'),
	user: getUrl('/user'),
	userFlags: getUrl('/user/flags'),
	userSettings: {
		webTracking: getUrl('/user/settings/web-tracking'),
		webTrackingOptions: getUrl('/user/settings/web-tracking/options'),
		leadScoring: getUrl('/user/settings/lead-scoring'),
		leadScoringOptions: getUrl('/user/settings/lead-scoring/options'),
		leadScoringOptionsActivityTypes: getUrl('/user/settings/lead-scoring/options/activity-types'),
		leadScoringOptionsContactsFields: getUrl('/user/settings/lead-scoring/options/contacts/fields'),
		emailEngagement: getUrl('/user/settings/email-engagement'),
		emailEngagementOptions: getUrl('/user/settings/email-engagement/options'),
		email: {
			schedule: getUrl('/user/settings/email/schedule'),
			footer: getUrl('/user/settings/email/footer'),
			footerOptions: getUrl('/user/settings/email/footer/options'),
			scheduleOptions: getUrl('/user/settings/email/schedule/options'),
			replyTracking: getUrl('/user/settings/email/reply-tracking'),
			domains: getUrl('/user/settings/email/domains'),
			domainsId: id => getUrl(`/user/settings/email/domains/${id}`),
			domainsIdVerify: id => getUrl(`/user/settings/email/domains/${id}/verify`)
		}
	},
	userUsage: getUrl('/user/usage'),
	userEmailStatistics: getUrl('/user/email-statistics'),
	userLists: getUrl('/user/lists'),
	userListsIdOptions: id => getUrl(`/user/lists/${id}/options`),
	userListsIdMappings: id => getUrl(`/user/lists/${id}/mappings`),
	userListsIdMappingsId: (listId, mappingId) => getUrl(`/user/lists/${listId}/mappings/${mappingId}`),
	userFeatures: getUrl('/user/features'),
	integrations: getUrl('/integrations'),
	singleEmailCampaigns: getUrl('/single-email-campaigns'),
	singleEmailCampaignsOptions: getUrl('/single-email-campaigns/options'),
	singleEmailCampaignsId: id => getUrl(`/single-email-campaigns/${id}`),
	singleEmailCampaignsIdSend: id => getUrl(`/single-email-campaigns/${id}/send`),
	singleEmailCampaignsIdTest: id => getUrl(`/single-email-campaigns/${id}/test`),
	singleEmailCampaignsIdDuplicate: id => getUrl(`/single-email-campaigns/${id}/duplicate`),
	singleEmailCampaignsIdRecipients: id => getUrl(`/single-email-campaigns/${id}/recipients`),
	singleEmailCampaignsIdConversion: id => getUrl(`/single-email-campaigns/${id}/conversion`),
	segments: getUrl('/segments'),
	segmentsId: id => getUrl(`/segments/${id}`),
	segmentsOptions: getUrl('/segments/options'),
	sequenceEmailCampaigns: getUrl('/sequence-email-campaigns'),
	sequenceEmailCampaignsOptions: getUrl('/sequence-email-campaigns/options'),
	sequenceEmailCampaignsId: id => getUrl(`/sequence-email-campaigns/${id}`),
	sequenceEmailCampaignsIdStart: id => getUrl(`/sequence-email-campaigns/${id}/start`),
	sequenceEmailCampaignsIdPause: id => getUrl(`/sequence-email-campaigns/${id}/pause`),
	sequenceEmailCampaignsIdDuplicate: id => getUrl(`/sequence-email-campaigns/${id}/duplicate`),
	sequenceEmailCampaignsQueue: id => getUrl(`/sequence-email-campaigns/${id}/queue`),
	sequenceEmailCampaignsIdEmails: id => getUrl(`/sequence-email-campaigns/${id}/emails`),
	sequenceEmailCampaignsIdEmailsId: (sequenceId, emailId) => getUrl(`/sequence-email-campaigns/${sequenceId}/emails/${emailId}`),
	sequenceEmailCampaignsIdEmailsIdTest: (sequenceId, emailId) => getUrl(`/sequence-email-campaigns/${sequenceId}/emails/${emailId}/test`),
	sequenceEmailCampaignsIdEmailsIdTrigger: (sequenceId, emailId) => getUrl(`/sequence-email-campaigns/${sequenceId}/emails/${emailId}/trigger`),
	sequenceEmailCampaignsIdEmailsIdRecipients: (sequenceId, emailId) => getUrl(`/sequence-email-campaigns/${sequenceId}/emails/${emailId}/recipients`),
	sequenceEmailCampaignsEmailsOptions: getUrl('/sequence-email-campaigns/emails/options'),
	automations: getUrl('/automations'),
	automationsOptions: getUrl('/automations/options'),
	automationsSync: getUrl('/automations/sync'),
	filters: getUrl('/filters'),
	persons: getUrl('/persons'),
	personsImport: getUrl('/persons/import'),
	personsId: id => getUrl(`/persons/${id}`),
	personsIdEvents: id => getUrl(`/persons/${id}/events`),
	personsLeadScore: id => getUrl(`/persons/${id}/leadscore`),
	trackingCounters: getUrl('/tracking/counters'),
	trackingVisits: getUrl('/tracking/visits'),
	trackingChannelReport: getUrl('/tracking/channel-report'),
	trackingChannelReportOptions: getUrl('/tracking/channel-report/options'),
	billingInvoices: getUrl('/billing/invoices'),
	billingPlans: getUrl('/billing/plans'),
	billingSubscription: getUrl('/billing/subscription'),
	billingPaymentMethodToken: getUrl('/billing/payment-method/token'),
	billingPaymentMethod: getUrl('/billing/payment-method'),
	content: {
		upload: getUrl('/content/upload')
	}
};

export {
	endpoints
};
