import { getConfValue } from 'utils/appconfig';
import { Providers } from './constants';

/**
 * @return {String}
 */
export const getKlaviyoOauthDialogUrl = pkceCodeChallenge => {
	const {
		clientId,
		oauthUrl,
		redirectUrl,
		scope
	} = getConfValue(Providers.KLAVIYO);

	const oauthDialogUrl = new URL(oauthUrl);

	oauthDialogUrl.searchParams.set('client_id', clientId);
	oauthDialogUrl.searchParams.set('redirect_uri', redirectUrl);
	oauthDialogUrl.searchParams.set('response_type', 'code');
	oauthDialogUrl.searchParams.set('scope', scope);
	oauthDialogUrl.searchParams.set('code_challenge_method', 'S256');
	oauthDialogUrl.searchParams.set('code_challenge', pkceCodeChallenge);

	return oauthDialogUrl.href;
};
