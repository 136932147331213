import { endpoints } from 'api/endpoints';
import { request } from 'utils/request';
import { FieldMappingsOptionsException } from './helpers';

/**
 * @param {Object} params
 * @param {String} params.connectionId
 * @param {String=} params.destinationSegmentId
 * @param {String=} params.engagementEventSegmentId
 * @param {String} params.token
 * @param {Boolean=} params.setNoCacheHeader
 * @return {Promise<Object>}
 */
export const httpGet = async ({
	connectionId,
	destinationSegmentId,
	engagementEventSegmentId,
	token,
	setNoCacheHeader
}) => {
	const url = new URL(endpoints.connectionsIdFieldMappingsOptions(connectionId));

	if (destinationSegmentId) {
		url.searchParams.append('destinationSegmentId', destinationSegmentId);
	}

	if (engagementEventSegmentId) {
		url.searchParams.append('engagementEventSegmentId', engagementEventSegmentId);
	}

	const response = await request.get({ url: url.href, token, setNoCacheHeader });

	if (!response.success) {
		return Promise.reject(new FieldMappingsOptionsException(response));
	}

	return response.data;
};
