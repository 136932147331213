import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import getInitialState from './state';
import { moduleEmailHealth } from './moduleEmailHealth';

export default {
	namespaced: true,
	state: getInitialState(),
	getters,
	actions,
	mutations,
	modules: {
		moduleEmailHealth
	}
};
