const getInitialState = () => ({
	campaignId: null,
	campaign: {},
	recipients: null,
	conversion: {},
	csvDownloadUrl: '',
	sortBy: 'toName',
	sortOrder: 'asc',
	pagination: {
		currentPage: 0,
		pageCount: 0
	},
	generalErrors: []
});

export default getInitialState;
