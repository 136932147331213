export const LIST_NAVIGATE = 'LIST_NAVIGATE';
export const OPTIONS_GET = 'OPTIONS_GET';
export const SETTINGS_GET = 'SETTINGS_GET';
export const SETTINGS_PUT = 'SETTINGS_PUT';
export const FIELDS_EDIT = 'FIELDS_EDIT';

/** Select Pipedrive contact's field to be updated when user unsubscribes on OF emails. */
export const UNSUBSCRIBE_FIELD_SELECT = 'UNSUBSCRIBE_FIELD_SELECT';

/** Select the value to be set for the Pipedrive contact field, when user unsubscribes. */
export const UNSUBSCRIBE_OPTION_FIELD_SELECT = 'UNSUBSCRIBE_OPTION_FIELD_SELECT';
