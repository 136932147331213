import { options } from './options';
import { httpGet } from './httpGet';
import { httpPost } from './httpPost';
import { httpPatch } from './httpPatch';
import { httpDelete } from './httpDelete';

export const contactCreationRules = {
	options,
	get: httpGet,
	post: httpPost,
	patch: httpPatch,
	delete: httpDelete
};
