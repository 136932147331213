var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasInvoices)?_c('div',{staticClass:"my-3"},[_c('h2',{staticClass:"mb-3"},[_vm._v("\n\t\tInvoices\n\t")]),_vm._v(" "),_c('v-layout',{staticClass:"invoices-table-header"},[_c('v-flex',{attrs:{"xs4":""}},[_vm._v("\n\t\t\tDescription\n\t\t")]),_vm._v(" "),_c('v-flex',{attrs:{"xs3":""}},[_vm._v("\n\t\t\tDate\n\t\t")]),_vm._v(" "),_c('v-flex',{attrs:{"grow":"","text-right":""}},[_vm._v("\n\t\t\tAmount\n\t\t")]),_vm._v(" "),_c('v-flex',{attrs:{"xs2":"","text-center":""}},[_vm._v("\n\t\t\tDownload\n\t\t")])],1),_vm._v(" "),_vm._l((_vm.invoices),function(ref){
var id = ref.id;
var description = ref.description;
var date = ref.date;
var total = ref.total;
var currency_code = ref.currency_code;
var download_url = ref.download_url;
return _c('v-layout',{key:id,staticClass:"invoices-table-row",attrs:{"align-center":""}},[_c('v-flex',{attrs:{"xs4":"","py-3":""}},[_vm._v("\n\t\t\t"+_vm._s(description)+"\n\t\t")]),_vm._v(" "),_c('v-flex',{attrs:{"xs3":"","py-3":""}},[_vm._v("\n\t\t\t"+_vm._s(_vm.formattedInvoiceDate(date))+"\n\t\t")]),_vm._v(" "),_c('v-flex',{attrs:{"grow":"","py-3":"","text-right":""}},[_vm._v("\n\t\t\t"+_vm._s(_vm.formattedTotal(total, currency_code))+"\n\t\t")]),_vm._v(" "),_c('v-flex',{attrs:{"xs2":"","text-center":""}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('a',{staticClass:"d-flex download-link",attrs:{"d-flex":"","href":download_url}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("\n\t\t\t\t\t\tcloud_download\n\t\t\t\t\t")])],1)])],1)],1)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }