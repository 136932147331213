//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * @constant {String}
 */
const EVENT_CLOSE = 'close';
/**
 * @constant {String}
 */
const EVENT_NAVIGATE_CONTINUE = 'navigate:continue';

export default {
	props: {
		isVisible: {
			type: Boolean,
			required: true
		}
	},

	data: () => ({
		isRecentEngagementSyncEnabled: true
	}),

	computed: {
		learnMoreLink() {
			return 'https://support.outfunnel.com/en/articles/6493239-sync-historical-marketing-engagements';
		}
	},

	methods: {
		close() {
			this.$emit(EVENT_CLOSE);
		},

		navigateContinue() {
			this.$emit(EVENT_NAVIGATE_CONTINUE, this.isRecentEngagementSyncEnabled);
		},

		onChangeRecentEngagementSync(isEnabled) {
			this.isRecentEngagementSyncEnabled = isEnabled;
		}
	}
};

