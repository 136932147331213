import {
	SET_OPTIONS,
	SET_CAMPAIGNS,
	PAGINATION,
	SET_ERRORS,
	RESET_STATE
} from './mutationTypes';

import getInitialState from './state';

export default {
	[SET_OPTIONS](state, options) {
		state.options = options;
	},

	[SET_CAMPAIGNS](state, campaigns) {
		state.campaigns = campaigns;
	},

	[SET_ERRORS](state, errors) {
		state.errors = errors;
	},

	[PAGINATION](state, pagination) {
		state.pagination = pagination;
	},

	[RESET_STATE](state) {
		Object.assign(state, getInitialState());
	}
};
