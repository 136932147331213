import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { SingleEmailCampaignsException } from './helpers';

/**
 * Delete a single email campaign.
 * @param {String} token - User's JWT authorization token.
 * @param {String} id - ID of the campaign.
 */
async function httpDelete(token, id) {
	const result = await request.delete(endpoints.singleEmailCampaignsId(id), token);

	if (!result.success) {
		return Promise.reject(new SingleEmailCampaignsException(result));
	}

	return true;
}

export {
	httpDelete
};
