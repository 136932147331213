//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ROUTE_NAMES from 'router/route-names';

const EVENT_DISMISS = 'dismiss';

export default {
	data: () => ({
		contactsRoute: { name: ROUTE_NAMES.CONTACTS },
		// eslint-disable-next-line max-len
		deliverabilityUrl: 'https://support.outfunnel.com/en/articles/3155138-how-to-improve-deliverability-by-adding-custom-domain-authentication-dkim-and-spf-for-outfunnel',
		singleEmailUrl: 'https://support.outfunnel.com/en/articles/3167670-how-to-use-our-email-marketing-feature'
	}),

	methods: {
		onDismiss() {
			this.$emit(EVENT_DISMISS);
		}
	}
};
