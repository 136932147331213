import billing from './billing';
import integrations from './integrations';
import profile from './profile';
import fields from './fields';
import emailSettings from './emailSettings';

const state = {};

export default {
	namespaced: true,
	state,
	modules: {
		billing,
		integrations,
		profile,
		fields,
		emailSettings
	}
};
