import { httpGet } from './httpGet';
import { httpPut } from './httpPut';
import { flags } from './flags';
import { settings } from './settings';
import { usage } from './usage';
import { emailStatistics } from './email-statistics';
import { lists } from './lists';
import { features } from './features';
import { email } from './email';

export const user = {
	get: httpGet,
	put: httpPut,
	flags,
	settings,
	usage,
	emailStatistics,
	lists,
	features,
	email
};
