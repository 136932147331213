import 'core-js';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import Vue from 'vue';

import {
	VAutocomplete,
	VLayout,
	VFlex,
	VIcon,
	VList,
	VListItem,
	VListItemTitle,
	VBtn,
	VSelect,
	VSpacer,
	VTabs,
	VTab,
	VToolbar,
	VTextField,
	VToolbarItems,
	VTooltip,
	VMenu,
	VColorPicker,
	VExpansionPanel,
	VExpansionPanels,
	VExpansionPanelHeader,
	VExpansionPanelContent,
	VCard,
	VCardText,
	VCardActions,
	VRow,
	VSubheader
} from 'vuetify/lib';

import VueDOMPurifyHTML from 'vue-dompurify-html';
import { sync } from 'vuex-router-sync';
import requireGlobalComponents from 'utils/require-global-components';
import EmailEditor from '@outfunnel/email-editor';
import { loadAnalytics } from 'utils/analytics';
import { initializePosthog } from 'utils/analytics/posthog';
import { loadCaptcha } from 'utils/captcha';
import { vuetify } from './plugins/vuetify';
import '@outfunnel/email-editor/dist/main.css';
import store from './store';
import router from './router';
import App from './App.vue';

/** Must be imported last for the Theme overrides to work. */
import 'styles/index.scss';

requireGlobalComponents(Vue);

Vue.component('VAutocomplete', VAutocomplete);
Vue.component('VLayout', VLayout);
Vue.component('VFlex', VFlex);
Vue.component('VTooltip', VTooltip);
Vue.component('VIcon', VIcon);
Vue.component('VList', VList);
Vue.component('VListItem', VListItem);
Vue.component('VListItemTitle', VListItemTitle);
Vue.component('VBtn', VBtn);
Vue.component('VSelect', VSelect);
Vue.component('VSpacer', VSpacer);
Vue.component('VTabs', VTabs);
Vue.component('VTab', VTab);
Vue.component('VToolbar', VToolbar);
Vue.component('VToolbarItems', VToolbarItems);
Vue.component('VTextField', VTextField);
Vue.component('VMenu', VMenu);
Vue.component('VColorPicker', VColorPicker);
Vue.component('VExpansionPanel', VExpansionPanel);
Vue.component('VExpansionPanels', VExpansionPanels);
Vue.component('VExpansionPanelHeader', VExpansionPanelHeader);
Vue.component('VExpansionPanelContent', VExpansionPanelContent);
Vue.component('VCard', VCard);
Vue.component('VCardText', VCardText);
Vue.component('VCardActions', VCardActions);
Vue.component('VRow', VRow);
Vue.component('VSubheader', VSubheader);

Vue.use(VueDOMPurifyHTML);

Vue.use(EmailEditor);
sync(store, router);

loadAnalytics();
initializePosthog();
loadCaptcha();

new Vue({
	router,
	store,
	vuetify,
	/**
	 * Render function that renders the app.
	 * @param {Function} createElement
	 */
	render: createElement => createElement(App)
}).$mount('#app');
